import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useReducer, useState } from 'react';
import { BillingSearchFilter } from '../Billing';
import {
  getMonthBillingDateRange,
  getMonthBillingDateRangeForYearMonth,
  getYearlyBillingDateRangeForYear,
} from '../helpers/dateHelpers';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: theme.palette.background.default,
    minWidth: '380px',
  },
  label: {
    fontWeight: 700,
    color: '#fff',
  },
}));

interface FilterState {
  fromDate: Dayjs | null;
  toDate: Dayjs | null;
}

const { dateTo: defaultDateTo, dateFrom: defaultDateFrom } = getMonthBillingDateRange(new Date());

const defaultFilter: FilterState = {
  fromDate: dayjs(defaultDateFrom),
  toDate: dayjs(defaultDateTo),
};

type Actions = { type: 'RESET' } | { type: 'SET'; payload: Partial<FilterState> };

function reducer(state: FilterState, action: Actions): FilterState {
  switch (action.type) {
    case 'RESET':
      return { ...defaultFilter };
    case 'SET':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

const billingPeriodOptions = ['Billing year', 'Month', 'Custom range'];

const maxYear = defaultDateTo.getFullYear();
const years = [maxYear, maxYear - 1, maxYear - 2];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const defaultBillingPeriod = 'Month';
const defaultYear = defaultDateTo.getFullYear();
const defaultMonth = months[defaultDateTo.getMonth()];

interface FilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  filter: BillingSearchFilter;
  setFilter: (newFilter: BillingSearchFilter, isCustomFilter: boolean) => void;
}

export default function FilterDrawer({ isOpen, onClose, filter, setFilter }: FilterDrawerProps) {
  const classes = useStyles();

  const [state, dispatch] = useReducer(reducer, {
    fromDate: filter.reportPeriodFrom,
    toDate: filter.reportPeriodTo,
  });

  // work out the billing period

  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState<string | null>(defaultBillingPeriod);
  const [selectedYear, setSelectedYear] = useState<number | null>(defaultYear);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(defaultMonth);

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: 'SET',
        payload: {
          fromDate: filter.reportPeriodFrom,
          toDate: filter.reportPeriodTo,
        },
      });
    }
  }, [filter, isOpen]);

  useEffect(() => {
    console.log(selectedBillingPeriod, selectedMonth, selectedYear);

    let dateRange = null;
    if (selectedBillingPeriod === 'Billing year' && selectedYear) {
      dateRange = getYearlyBillingDateRangeForYear(selectedYear);
    } else if (selectedBillingPeriod === 'Month' && selectedMonth && selectedYear) {
      dateRange = getMonthBillingDateRangeForYearMonth(selectedYear, months.indexOf(selectedMonth));
    }

    if (!dateRange) {
      return;
    }

    dispatch({
      type: 'SET',
      payload: {
        fromDate: dayjs(dateRange.dateFrom),
        toDate: dayjs(dateRange.dateTo),
      },
    });
  }, [selectedBillingPeriod, selectedMonth, selectedYear]);

  const applyFilter = () => {
    const isCustomFilter =
      !isEqual(state, defaultFilter) ||
      defaultBillingPeriod !== selectedBillingPeriod ||
      defaultYear !== selectedYear ||
      defaultMonth !== selectedMonth;
    setFilter(
      {
        ...filter,
        reportPeriodFrom: state.fromDate,
        reportPeriodTo: state.toDate,
      },
      isCustomFilter
    );
    onClose();
  };

  const resetFilter = () => {
    dispatch({ type: 'RESET' });
    setSelectedBillingPeriod(defaultBillingPeriod);
    setSelectedYear(defaultYear);
    setSelectedMonth(defaultMonth);
  };

  const onYearChanged = (event: any) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const onMonthChanged = (event: any) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} classes={{ paper: classes.drawerPaper }}>
      <Box px={4} pt={10}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Filter billing period</Typography>
          <Box ml={2}>
            <IconButton aria-label="close" onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box pr={4}>
          <Box mt={5}>
            <Autocomplete
              fullWidth
              options={billingPeriodOptions}
              value={selectedBillingPeriod}
              renderInput={(params) => <TextField {...params} label="Date filter type" variant="outlined" fullWidth />}
              onChange={(_, newValue) => {
                setSelectedBillingPeriod(newValue);
              }}
            />
            <Box mt={3}>
              <Grid container direction="column" spacing={4}>
                {['Billing year', 'Month'].includes(selectedBillingPeriod ?? '') && (
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="year">Year</InputLabel>
                      <Select
                        labelId="year-select-label"
                        id="year-select"
                        value={selectedYear}
                        label="Year"
                        onChange={onYearChanged}
                      >
                        {years.map((y, i) => (
                          <MenuItem key={i} value={y}>
                            {y}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {selectedBillingPeriod === 'Month' && (
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="month">Month</InputLabel>
                      <Select
                        labelId="month-select-label"
                        id="month-select"
                        value={selectedMonth}
                        label="Month"
                        onChange={onMonthChanged}
                      >
                        {months.map((m, i) => (
                          <MenuItem value={m} key={i}>
                            {m}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {selectedBillingPeriod === 'Custom range' && (
                  <>
                    <Grid item>
                      <KeyboardDatePicker
                        value={state.fromDate}
                        onChange={(date) => dispatch({ type: 'SET', payload: { fromDate: date } })}
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        label="From date"
                        placeholder="From date"
                      />
                    </Grid>
                    <Grid item>
                      <KeyboardDatePicker
                        value={state.toDate}
                        onChange={(date) => dispatch({ type: 'SET', payload: { toDate: date } })}
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        label="To date"
                        placeholder="To date"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Box>

          <Box mt={5}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={applyFilter}
                  disabled={!selectedBillingPeriod}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item>
                <Button fullWidth variant="outlined" onClick={resetFilter}>
                  Reset filters
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
