import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MaterialLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import DoorModelDetailsEdit from '../components/door-model/DoorModelDetailsEdit';
import DoorModelPartsListEdit from '../components/door-model/DoorModelPartsListEdit';
import Layout from '../components/Layout';
import CustomStepConnector from '../components/stepper/CustomStepConnector';
import CustomStepIcon from '../components/stepper/CustomStepIcon';
import * as Routes from '../constants/Routes';
import DoorModelContext from '../context/DoorModelContext';
import useDialog from '../hooks/useDialog';
import DoorModelDocumentationEdit from '../components/door-model/DoorModelDocumentationEdit';
const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    marginTop: theme.spacing(3),
  },
}));

const steps = ['Details', 'Parts list', 'Documentation'];

export interface DoorModelDocument {
  id?: string | null;
  file: Partial<File>;
  documentTypeCode: string;
}

export interface DoorModelFormData {
  id?: string;
  name: string;
  fdRatingId?: number;
  coreSupplier?: string | null;
  lockLatch?: string;
  closer?: string;
  intumescentStrip?: string;
  hinges?: string;
  handles?: string;
  smokeSeals?: string;
  dropSeal?: string;
  cylinder?: string;
  letterbox?: string;
  spyhole?: string;
  thresholdStrip?: string;
  additionalComments?: string;

  // documents
  //documents?: DoorModelDocument[];
}

export default function DoorModelAdd() {
  const [doorModel, setDoorModel] = useState<DoorModelFormData | undefined>();
  const [activeStep, setActiveStep] = useState(0);
  const { showDialog } = useDialog();
  const history = useHistory();
  const classes = useStyles();

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => history.push(Routes.DoorModels),
    });
  };

  const contextValue = {
    doorModel,
    setDoorModel,
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">Add door model</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.DoorModels}>
                  <Typography variant="body2">Door models</Typography>
                </MaterialLink>
                <Typography variant="body2">Add door model</Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>

          <Paper className={classes.container}>
            <Box>
              <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
                {steps.map((step, i) => (
                  <Step key={step}>
                    <StepLabel StepIconComponent={CustomStepIcon}>{step}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <DoorModelContext.Provider value={contextValue}>
                {activeStep === 0 && <DoorModelDetailsEdit onContinue={() => setActiveStep(1)} />}
                {activeStep === 1 && (
                  <DoorModelPartsListEdit onBack={() => setActiveStep(0)} onContinue={() => setActiveStep(2)} />
                )}
                {activeStep === 2 && <DoorModelDocumentationEdit onBack={() => setActiveStep(1)} />}
              </DoorModelContext.Provider>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Layout>
  );
}
