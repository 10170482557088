import React, { useMemo } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import KpiCard from '../KpiCard';
import { useDashboardAvgRepairTimeLast30DaysKpiQuery } from '../../../queries';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Build from '@material-ui/icons/Build';
import theme from '../../../theme';

const formatToDays = (minutes: number | undefined) => {
  if (minutes === undefined) {
    return undefined;
  }

  if (minutes > 1440) {
    const days = Math.floor(minutes / 1440);
    return `${days} day${days !== 1 ? 's' : ''}`;
  } else if (minutes > 60) {
    const hours = Math.floor(minutes / 60);
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  } else {
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }
};

const useStyles = makeStyles({
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
  },
  footer: {
    color: '#FFFFFF',
  },
  valueImproved: {
    color: theme.palette.primary.main,
  },
  valueDeteriorated: {
    color: theme.palette.error.main,
  },
});

type FooterProps = {
  last30Days: number | undefined | null;
  diff: number | undefined;
  change: 'neutral' | 'improvement' | 'deterioration';
};

const Footer = ({ last30Days, diff, change }: FooterProps) => {
  const classes = useStyles();
  let direction = '';

  if (!last30Days) {
    return (
      <Box className={classes.footerContainer}>
        <Typography variant="body1" className={classes.footer}>
          {'No repairs completed in the last 30 days'}
        </Typography>
      </Box>
    );
  }

  if (diff === undefined) {
    return null;
  }

  if (diff > 0) {
    direction = `Up ${formatToDays(Math.abs(diff!))}`;
  } else if (diff < 0) {
    direction = `Down ${formatToDays(Math.abs(diff!))}`;
  } else {
    direction = 'No change';
  }
  const message = `${direction} since previous 30 days`;
  const iconClass = change === 'improvement' ? classes.valueImproved : classes.valueDeteriorated;
  return (
    <Box className={classes.footerContainer}>
      {diff && diff > 0 && <ArrowUpward className={iconClass} />}
      {diff && diff < 0 && <ArrowDownward className={iconClass} />}
      <Typography variant="body1" className={classes.footer}>
        {message}
      </Typography>
    </Box>
  );
};

type Props = {
  siteId?: string | null;
};

export default function AverageRepairTimeKpi({ siteId }: Props) {
  const { loading, data } = useDashboardAvgRepairTimeLast30DaysKpiQuery({
    variables: { siteId: siteId ?? null },
    fetchPolicy: 'cache-and-network',
  });

  const { diff, change } = useMemo<{
    diff: number | undefined;
    change: 'neutral' | 'improvement' | 'deterioration';
  }>(() => {
    if (!data) {
      return { diff: undefined, change: 'neutral' };
    }

    if (!data.dashboard.avgRepairMinutesLast30Days || !data.dashboard.avgRepairMinutesPrevious30Days) {
      return { diff: undefined, change: 'neutral' };
    }

    const diff = data.dashboard.avgRepairMinutesLast30Days! - data.dashboard.avgRepairMinutesPrevious30Days!;

    if (diff === 0) {
      return { diff, change: 'neutral' };
    } else if (diff > 0) {
      return { diff, change: 'deterioration' };
    } else {
      return { diff, change: 'improvement' };
    }
  }, [data]);

  const value = useMemo(() => {
    if (!data?.dashboard.avgRepairMinutesLast30Days) {
      return '-';
    } else {
      return formatToDays(data.dashboard.avgRepairMinutesLast30Days);
    }
  }, [data?.dashboard.avgRepairMinutesLast30Days]);

  return (
    <KpiCard
      title="Average repair time last 30 days"
      value={value}
      loading={loading}
      change={change}
      footer={<Footer last30Days={data?.dashboard.avgRepairMinutesLast30Days} diff={diff} change={change} />}
      icon={
        <Box style={{ position: 'relative', width: 86 }}>
          <Box
            style={{
              position: 'absolute',
              top: -15,
              right: -20,
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="106" height="105" viewBox="0 0 106 105" fill="none">
              <g filter="url(#filter0_d_5019_1006)">
                <ellipse cx="52.9575" cy="48.5" rx="32.8359" ry="32.5" fill="#2C2F52" />
                <path
                  d="M84.7934 48.5C84.7934 65.8873 70.5497 80 52.9575 80C35.3653 80 21.1216 65.8873 21.1216 48.5C21.1216 31.1127 35.3653 17 52.9575 17C70.5497 17 84.7934 31.1127 84.7934 48.5Z"
                  stroke="#F6AE2D"
                  strokeWidth="2"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5019_1006"
                  x="0.901855"
                  y="0"
                  width="105.84"
                  height="105"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="10" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.964706 0 0 0 0 0.682353 0 0 0 0 0.176471 0 0 0 0.5 0"
                  />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5019_1006" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5019_1006" result="shape" />
                </filter>
              </defs>
            </svg>
            <Build style={{ position: 'absolute', left: 41, top: 35 }} />
          </Box>
        </Box>
      }
    />
  );
}
