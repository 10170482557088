import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { DoorHistoryFragment } from '../../../../../queries';
import { baseUrl } from '../../../../../utils/EnvironmentService';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import StandardGridItem from './StandardGridItem';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Image from '../../../../Image';

const useStyles = makeStyles({
  image: {
    height: 150,
    width: '100%',
    objectFit: 'contain',
  },
});

interface DoorNotAccessibleDetailDialogProps {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

export interface DoorNotAccssibleAdditionalInfo {
  reason: string;
  documentIds: number[];
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
}

function DetailDialog({ history }: DetailDialogProps) {
  const classes = useStyles();

  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorNotAccssibleAdditionalInfo;
  const hasPhotos = additionalInfo.documentIds && additionalInfo.documentIds.length > 0;
  // surround with box because of negative margin https://material-ui.com/components/grid/#negative-margin
  return (
    <Box pt={1} pb={1}>
      <Grid container spacing={3}>
        <StandardGridItem title="Reason" value={additionalInfo.reason} />
      </Grid>
      {hasPhotos && (
        <Grid container spacing={3}>
          {additionalInfo.documentIds.map((documentId) => (
            <Grid key={documentId} item xs={12} sm={2}>
              <Zoom zoomMargin={75} overlayBgColorStart="rgba(0,0,0,0)" overlayBgColorEnd="rgba(0,0,0,0.9)">
                <Image
                  src={`${baseUrl}/document/${documentId}`}
                  alt={`document-${documentId}`}
                  className={classes.image}
                />
              </Zoom>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default function DoorNotAccessibleDetailDialog({ open, onClose, history }: DoorNotAccessibleDetailDialogProps) {
  return (
    <DoorHistoryDetailDialog open={open} onClose={onClose} history={history} actorName="Reported by">
      <DetailDialog history={history} />
    </DoorHistoryDetailDialog>
  );
}
