import React from 'react';
import { Box, Paper, Typography, Grid, makeStyles, FormControl, Divider } from '@material-ui/core';
import Layout from '../components/Layout';
import ControlledSiteAutocomplete, { FormSite } from '../components/forms/ControlledSiteAutocomplete';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledDatePicker from '../components/forms/ControlledDatePicker';
import dayjs from 'dayjs';
import FMInspectionAcivityReportCard from '../components/reports/FMInspectionAcivityReportCard';
import ProtectedSection from '../components/ProtectedSection';
import Roles from '../constants/Roles';
import FMDoorStateReportCard from '../components/reports/FMDoorStateReportCard';
import InspectorInspectionActivityReportCard from '../components/reports/InspectorInspectionActivityReportCard';
import InstallerInstallationActivityReportCard from '../components/reports/InstallerInstallationActivityReportCard';
import FMInstallationActivityReportCard from '../components/reports/FMInstallationActivityReportCard';

type FormFields = {
  site?: FormSite;
  fromDate?: dayjs.Dayjs;
  toDate?: dayjs.Dayjs;
};
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
  },
  body: {
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  site: {
    minWidth: '350px',
    width: '100%',
  },
  date: {
    minWidth: '350px',
    width: '100%',
  },
  filterContainer: {
    padding: theme.spacing(2, 3),
  },
  reportContainer: {
    padding: theme.spacing(2, 3),
  },
  divider: {
    margin: theme.spacing(0, -3),
    marginTop: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
    color: '#fff',
  },
}));

export default function Reports() {
  const classes = useStyles();

  const form = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { watch, handleSubmit } = form;
  const { site, fromDate, toDate } = watch(['site', 'fromDate', 'toDate']);

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8} className={classes.body}>
          <Box className={classes.header}>
            <Typography variant="h5">Reports</Typography>
          </Box>

          <Paper className={classes.filterContainer}>
            <Typography variant="h5">Report settings</Typography>
            <Divider className={classes.divider} />

            <Box paddingTop={4} paddingBottom={2}>
              <FormProvider {...form}>
                <form onSubmit={handleSubmit(() => {})}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={12}>
                      <FormControl className={classes.site}>
                        <ControlledSiteAutocomplete name="site" label="Site" />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl className={classes.date}>
                        <ControlledDatePicker name="fromDate" label="Date from" />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormControl className={classes.date}>
                        <ControlledDatePicker name="toDate" label="Date to" />
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Box>
          </Paper>

          <ProtectedSection roles={[Roles.FacilityManagerAdmin]}>
            <FMInstallationActivityReportCard site={site} fromDate={fromDate} toDate={toDate} />
            <FMInspectionAcivityReportCard site={site} fromDate={fromDate} toDate={toDate} />
            <FMDoorStateReportCard site={site} />
          </ProtectedSection>

          <ProtectedSection roles={[Roles.InspectorAdmin]}>
            <InspectorInspectionActivityReportCard site={site} fromDate={fromDate} toDate={toDate} />
          </ProtectedSection>

          <ProtectedSection roles={[Roles.InstallerAdmin]}>
            <InstallerInstallationActivityReportCard site={site} fromDate={fromDate} toDate={toDate} />
          </ProtectedSection>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
