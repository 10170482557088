import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DoorModelFragment, Status, useFdRatingsQuery, useIsBarcodeValidLazyQuery } from '../../../queries';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';
import Tooltip from '@material-ui/core/Tooltip';
import DoorStatus from '../../../constants/Status';
import useAuth from '../../../hooks/useAuth';
import { useDebounce } from 'use-debounce/lib';

interface DoorDetailsFieldsProps {
  isEdit?: boolean;
  doorModel?: Partial<DoorModelFragment> | null;
  doorStatus?: Pick<Status, 'code'> | null;
  doorFdRatingId?: number;
  readOnly?: boolean;
}

export default function DoorDetailsFields({
  doorModel,
  doorStatus,
  doorFdRatingId,
  isEdit = false,
  readOnly = false,
}: DoorDetailsFieldsProps) {
  const { data: fdRatingLookup } = useFdRatingsQuery();
  const [isBarcodeValid, { data: barcodeValid }] = useIsBarcodeValidLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { register, control, setValue, errors, watch, setError, clearErrors } = useFormContext();
  const { hasAnyRole } = useAuth();

  const doorId = watch('id');
  const barcode = watch('barcode');
  const [debouncedBarcode] = useDebounce<string>(barcode, 500);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'doorLeafs',
  });

  useEffect(() => {
    if (debouncedBarcode?.length > 0) {
      isBarcodeValid({
        variables: {
          doorId,
          barcode: debouncedBarcode,
        },
      });
    }
  }, [debouncedBarcode, doorId, isBarcodeValid]);

  useEffect(() => {
    if (barcodeValid?.isBarcodeValid === false) {
      setError('barcode', { type: 'manual', message: 'Barcode already in use' });
    } else {
      clearErrors('barcode');
    }
  }, [barcodeValid, clearErrors, setError]);

  useEffect(() => {
    if (fdRatingLookup) {
      setValue('fdRatingId', doorModel?.fdRating?.id || doorFdRatingId);
    }
  }, [setValue, fdRatingLookup, doorModel, doorFdRatingId]);

  const isDisabled = readOnly || hasAnyRole([Roles.FacilityManager, Roles.FacilityManagerAdmin]);

  const canEditInspectionFrequency = () => {
    if (!doorStatus) {
      return true;
    }
    return ([DoorStatus.NoInspectionSet, DoorStatus.Passed] as string[]).includes(doorStatus?.code || '');
  };

  return (
    <Grid container spacing={3}>
      {isEdit && (
        <Grid item lg={6} xs={12}>
          <TextField
            name="id"
            label="DDS Id"
            variant="outlined"
            fullWidth
            inputRef={register}
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      )}
      <Grid item lg={6} xs={12}>
        <TextField
          name="projectReference"
          label="Project Reference"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="doorReference"
          label="Door Reference"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <FormControl variant="outlined" fullWidth disabled={!!doorModel?.fdRating}>
          <InputLabel htmlFor="fdRatingId" shrink={doorModel?.fdRating || !!doorFdRatingId ? true : undefined}>
            FD rating
          </InputLabel>

          <Select
            label="FD rating"
            native
            inputProps={{
              name: 'fdRatingId',
              id: 'fdRatingIdInput',
            }}
            inputRef={register}
            disabled={isDisabled}
          >
            <option aria-label="None" value="" />
            {fdRatingLookup?.fdRatings &&
              fdRatingLookup?.fdRatings.length > 0 &&
              fdRatingLookup?.fdRatings.map((fdRating) => (
                <option value={fdRating.id} key={fdRating.id}>
                  {fdRating.value}
                </option>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="tagId"
          label="Tag ID"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={isDisabled}
        />
      </Grid>
      <ProtectedSection roles={[Roles.ManufacturerAdmin, Roles.Manufacturer]} exclusive>
        <Grid item lg={6} xs={12}>
          <TextField
            name="barcode"
            label="Barcode"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 255 }}
            disabled={isDisabled}
            error={!!errors.barcode}
            helperText={errors?.barcode?.message}
            inputRef={register}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            name="lineBarcode"
            label="Line barcode"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 255 }}
            disabled={isDisabled}
            error={!!errors.lineBarcode}
            helperText={errors?.lineBarcode?.message}
            inputRef={register}
          />
        </Grid>
      </ProtectedSection>
      <Grid item lg={6} xs={12}>
        <FormControl variant="outlined" fullWidth disabled>
          <InputLabel htmlFor="doorModelId" shrink>
            Door model
          </InputLabel>

          <Select
            label="Door model"
            native
            inputProps={{
              name: 'doorModelId',
              id: 'doorModelIdInput',
            }}
            inputRef={register}
            disabled={isDisabled}
          >
            <option value={doorModel?.id}>{doorModel?.name}</option>
          </Select>
        </FormControl>
      </Grid>

      <ProtectedSection roles={[Roles.FacilityManagerAdmin, Roles.FacilityManager]}>
        <Grid item lg={6} xs={12}>
          <Tooltip
            title="Inspection frequency cannot be updated at the current door stage"
            aria-label="Inspection frequency cannot be updated at the current door stage"
            open={canEditInspectionFrequency() ? false : undefined}
          >
            <TextField
              disabled={!canEditInspectionFrequency()}
              name="inspectionFrequency"
              label="Inspection Frequency (months)"
              variant="outlined"
              type="number"
              fullWidth
              inputRef={register}
              inputProps={{
                min: 1,
                max: 99,
              }}
            />
          </Tooltip>
        </Grid>
      </ProtectedSection>
      {fields.map((field, i) => (
        <React.Fragment key={field.id}>
          <Grid item xs={12}>
            <Typography variant="h6">Door leaf {i + 1}</Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              name={`doorLeafs[${i}].acousticRating`}
              label="Acoustic rating (dB)"
              variant="outlined"
              fullWidth
              inputRef={register()}
              inputProps={{ maxLength: 255 }}
              defaultValue={field.acousticRating}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              name={`doorLeafs[${i}].coreSupplier`}
              label="Core supplier"
              variant="outlined"
              fullWidth
              inputRef={register()}
              inputProps={{ maxLength: 255 }}
              disabled={!!doorModel?.coreSupplier || readOnly}
              defaultValue={field.coreSupplier}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              name={`doorLeafs[${i}].width`}
              label="Leaf width (mm)"
              variant="outlined"
              fullWidth
              inputRef={register()}
              type="number"
              defaultValue={field.width}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              name={`doorLeafs[${i}].height`}
              label="Leaf height (mm)"
              variant="outlined"
              fullWidth
              inputRef={register()}
              type="number"
              defaultValue={field.height}
              disabled={isDisabled}
            />
          </Grid>
          {fields.length === 1 && (
            <Grid item xs={12}>
              <Link
                component="button"
                onClick={() => append({ coreSupplier: doorModel?.coreSupplier })}
                disabled={isDisabled}
              >
                Add second leaf
              </Link>
            </Grid>
          )}
          {i === 1 && fields.length === 2 && (
            <Grid item xs={12}>
              <Link component="button" color="error" onClick={() => remove(i)} disabled={isDisabled}>
                Remove second leaf
              </Link>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
}
