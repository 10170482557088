import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import * as Routes from '../../../constants/Routes';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';

export default function BillingMenuItem() {
  const location = useLocation();
  return (
    <ProtectedSection roles={[Roles.SuperUser]}>
      <ListItem button component={Link} to={Routes.Billing} selected={location.pathname.startsWith(Routes.Billing)}>
        <ListItemIcon>
          <CreditCardIcon />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItem>
    </ProtectedSection>
  );
}
