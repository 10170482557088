import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DoorModelContext from '../../context/DoorModelContext';
import { useDoorModelsByNameLazyQuery, useFdRatingsQuery } from '../../queries';
import LoadingButton from '../LoadingButton';

interface DoorModelFormData {
  name: string;
  fdRatingId?: number;
  coreSupplier?: string | null;
}

interface DoorModelDetailsEditProps {
  onContinue: () => void;
}

export default function DoorModelDetailsEdit({ onContinue }: DoorModelDetailsEditProps) {
  const { data } = useFdRatingsQuery();
  const { setDoorModel, doorModel } = useContext(DoorModelContext);
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, register, formState, setError, errors, getValues } = useForm<DoorModelFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: doorModel,
  });

  const [getDoorModels, { loading, data: existingDoorModels }] = useDoorModelsByNameLazyQuery();

  useEffect(() => {
    if (existingDoorModels) {
      const currentNameValue = getValues('name');
      if (
        existingDoorModels.doorModelsByName.length > 0 &&
        existingDoorModels.doorModelsByName[0].name === currentNameValue
      ) {
        setError('name', {
          type: 'duplicate-name',
          message: 'Door model with this name already exists for this company.',
        });
      } else {
        onContinue();
      }
    }
  }, [onContinue, existingDoorModels, getValues, setError]);

  const submit = async (values: DoorModelFormData) => {
    setDoorModel((prev: DoorModelFormData) => ({ ...prev, ...values }));
    try {
      await getDoorModels({
        variables: {
          name: values.name,
        },
      });
    } catch {
      enqueueSnackbar('There was an error, please try again', { variant: 'error' });
    }
  };

  return (
    <Box p={5} pt={2}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <TextField
              name="name"
              label="Door model name"
              variant="outlined"
              fullWidth
              error={!!errors.name}
              helperText={errors.name?.message}
              inputRef={register({
                required: 'Door model name required',
              })}
              inputProps={{ maxLength: 255 }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="fdRatingId">FD rating</InputLabel>

              <Select
                label="FD rating"
                native
                inputProps={{
                  name: 'fdRatingId',
                  id: 'fdRatingIdInput',
                }}
                inputRef={register}
              >
                <option aria-label="None" value="" />

                {data?.fdRatings &&
                  data?.fdRatings.length > 0 &&
                  data?.fdRatings.map((fdRating) => (
                    <option value={fdRating.id} key={fdRating.id}>
                      {fdRating.value}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField name="coreSupplier" label="Core supplier" variant="outlined" fullWidth inputRef={register} />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formState.isValid || loading}
          >
            Continue
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
