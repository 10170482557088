import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MaterialLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import CustomStepConnector from '../components/stepper/CustomStepConnector';
import CustomStepIcon from '../components/stepper/CustomStepIcon';
import React, { useEffect, useState } from 'react';
import SiteContext from '../context/SiteContext';
import { useHistory } from 'react-router';
import { Link, useRouteMatch, useLocation, Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../components/Layout';
import SiteDetailsAdd from '../components/site/add/SiteDetailsAdd';
import SiteContactAdd from '../components/site/add/SiteContactAdd';
import SiteDrawingAdd from '../components/site/add/SiteDrawingAdd';
import * as Routes from '../constants/Routes';
import useDialog from '../hooks/useDialog';
import { Company } from '../queries';

export interface SiteAddParams {
  //SiteDetails
  id: string;
}

export interface SiteDocument {
  id?: string | null;
  file: Partial<File>;
  description?: string | null;
}

export interface SiteAddFormData {
  //SiteDetails
  id: string;
  name: string;
  reference: string | null;
  owner?: Pick<Company, 'id' | 'name'> | null;
  areas: string | null;
  addressOne: string | null;
  addressTwo: string | null;
  city: string | null;
  postcode: string | null;
  //SiteContact
  contactId?: string | null;
  contactName?: string;
  contactPhoneNumber?: string | null;
  contactMobileNumber?: string | null;
  contactEmailAddress?: string | null;
  //SiteDocuments
  photos?: SiteDocument[] | null;
  drawings?: SiteDocument[] | null;
}

const steps = [
  { name: 'Site details', route: 'details' },
  { name: 'Site contact', route: 'contact' },
  /* { name: 'Site photo', route: 'photo' }, // Hide site photo for now*/
  { name: 'Site drawings', route: 'drawings' },
];

export default function SiteAdd() {
  const [site, setSite] = useState<SiteAddFormData | undefined>();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const { showDialog } = useDialog();

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        history.push(`${Routes.MySites}`);
      },
    });
  };

  useEffect(() => {
    const split = location.pathname.split('/');
    const currentRoute = split.pop();
    const stepIndex = steps.findIndex((s) => s.route === currentRoute);
    if (stepIndex > -1) {
      setActiveStep(stepIndex);
    }
  }, [location.pathname]);

  const onContinue = () => history.push(`${url}/${steps[activeStep + 1].route}`);
  const onBack = () => history.push(`${url}/${steps[activeStep - 1].route}`);

  const contextValue = {
    site,
    setSite,
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">Add new site</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.MySites}>
                  <Typography variant="body2">My site list</Typography>
                </MaterialLink>
                <Typography variant="body2">Add new site</Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>

          <Box>
            <Paper>
              <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
                {steps.map((step) => (
                  <Step key={step.name}>
                    <StepLabel StepIconComponent={CustomStepIcon}>{step.name}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box p={5} pt={2}>
                <SiteContext.Provider value={contextValue}>
                  <Switch>
                    <Route exact path={`${path}/details`}>
                      <SiteDetailsAdd onContinue={onContinue} />
                    </Route>
                    <Route exact path={`${path}/contact`}>
                      <SiteContactAdd onContinue={onContinue} onBack={onBack} />
                    </Route>
                    {/*
                      // Hide site photo for now
                      <Route exact path={`${path}/photo`}>
                        <SitePhotoAdd onContinue={onContinue} onBack={onBack} />
                      </Route>
                      */}
                    <Route exact path={`${path}/drawings`}>
                      <SiteDrawingAdd onBack={onBack} />
                    </Route>
                    <Route path={path}>
                      <Redirect to={`${url}/details`} />
                    </Route>
                  </Switch>
                </SiteContext.Provider>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
