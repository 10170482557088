import React from 'react';
import { SiteDocument } from '../../../pages/SiteAdd';
import SiteDrawingsUpload from '../add/SiteDrawingUpload';

interface SiteDrawingsEditProps {}

interface SiteDrawingsEditProps {
  drawings: SiteDocument[];
  setDrawings: React.Dispatch<React.SetStateAction<SiteDocument[]>>;
}

export default function SiteDrawingsEdit({ drawings, setDrawings }: SiteDrawingsEditProps) {
  return <SiteDrawingsUpload drawings={drawings} setDrawings={setDrawings} />;
}
