import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { GraphQLError } from 'graphql/error';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import * as Routes from '../../../constants/Routes';
import DoorContext from '../../../context/DoorContext';
import { DoorFormData, DoorPhoto } from '../../../pages/DoorAdd';
import { useAddDoorMutation } from '../../../queries';
import { removeEmptyFields } from '../../../utils/FormHelpers';
import LoadingButton from '../../LoadingButton';
import DoorPhotosUpload from './DoorPhotosUpload';

interface DoorPhotosAddProps {
  onBack: () => void;
}

export default function DoorPhotosAdd({ onBack }: DoorPhotosAddProps) {
  const { door, setDoor } = useContext(DoorContext);
  const [photos, setPhotos] = useState<DoorPhoto[]>(door?.photos ?? []);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [addDoorMutation, { loading }] = useAddDoorMutation();

  const onBackClick = () => {
    setDoor((prev: DoorFormData | undefined) => ({ ...prev, photos }));
    onBack();
  };

  const addDoor = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    removeEmptyFields(door);
    const doorCopy = { ...door, photos };
    try {
      await addDoorMutation({
        variables: {
          // @ts-ignore
          door: doorCopy!,
        },
      });
      enqueueSnackbar('Door added successfully', { variant: 'success' });
      history.push(Routes.ManufacturedDoors);
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        if (gqlerror.extensions?.code === 'BARCODE_IN_USE') {
          enqueueSnackbar(`Barcode ${doorCopy.barcode} is already in use`, { variant: 'error' });
        } else {
          enqueueSnackbar('There was an error attempting to add this door', { variant: 'error' });
        }
      });
    }
  };

  return (
    <form onSubmit={addDoor}>
      <DoorPhotosUpload photos={photos} setPhotos={setPhotos} />
      <Box display="flex" justifyContent="space-between" marginTop={3}>
        <Button variant="outlined" onClick={onBackClick}>
          Back
        </Button>
        <LoadingButton loading={loading} disabled={loading} type="submit" variant="contained" color="primary">
          Add door
        </LoadingButton>
      </Box>
    </form>
  );
}
