import { Box } from '@material-ui/core';
import React from 'react';
import { Document, DoorFragment } from '../../../queries';
import DocumentList from '../../DocumentList';

interface DoorFilesProps {
  door?: DoorFragment;
  documentTypeCodes?: string[];
  highlightMissingTypes?: string[];
  noFilesMessage?: string;
}

export default function DoorFiles({ door, documentTypeCodes, ...other }: DoorFilesProps) {
  const documents: Document[] = [];
  if (door?.documents) {
    for (const document of door?.documents) {
      if (documentTypeCodes?.includes(document.documentType.code)) {
        documents.push(document);
      }
    }
  }

  if (door?.model?.documents) {
    for (const document of door?.model?.documents) {
      if (documentTypeCodes?.includes(document.documentType.code)) {
        documents.push(document);
      }
    }
  }

  return (
    <Box p={5}>
      <DocumentList {...other} documents={documents} />
    </Box>
  );
}
