import React from 'react';
import { DoorFormData } from '../pages/DoorAdd';
import { DoorModelQuery } from '../queries';

interface DoorContextValue {
  doorModel?: DoorModelQuery;
  setDoorModel: React.Dispatch<React.SetStateAction<DoorModelQuery | undefined>>;
  door?: DoorFormData;
  setDoor: React.Dispatch<React.SetStateAction<DoorFormData | undefined>>;
}

// @ts-ignore
export default React.createContext<DoorContextValue>();
