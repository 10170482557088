import React, { useState } from 'react';
import clsx from 'clsx';
import { Typography, Grid, Button } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/Layout';
import FullNameAvatar from '../components/FullNameAvatar';
import { useLoggedInQuery } from '../queries';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 96,
    width: 96,
  },
  name: {
    textTransform: 'capitalize',
  },
  maskedText: {
    textSecurity: 'circle',
    mozTextSecurity: 'circle',
    webkitTextSecurity: 'circle',
  },
  rowWithTopBorder: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
    padding: theme.spacing(2, 0, 2, 0),
  },
  rowWithNoBorder: {
    padding: theme.spacing(2, 0, 2, 0),
  },
}));

export default function AccountInfoView() {
  const classes = useStyles();
  const { data } = useLoggedInQuery({ fetchPolicy: 'no-cache' });
  const [toggleAllButton, setToggleAllButton] = useState(true);
  const toggleAllButtons = () => {
    setToggleAllButton(!toggleAllButton);
  };
  const formatRoleText = (name: string, index: number) => {
    const rolesCount = data?.loggedIn.roles.length !== undefined ? data?.loggedIn.roles.length - 1 : 0;
    if (index < rolesCount) {
      return `${name}, `;
    }
    return `${name}`;
  };
  return (
    <Layout>
      <Grid container>
        <Grid item xs={12}>
          <FullNameAvatar className={classes.avatar} fullName={data?.loggedIn.fullName} />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h3" noWrap className={classes.name}>
            {data?.loggedIn.fullName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography noWrap align="right">
            read only
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.rowWithTopBorder}>
          <Grid container>
            <Grid item xs={4}>
              <Typography>Email:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap className={clsx({ [classes.maskedText]: toggleAllButton })}>
                {data?.loggedIn.email}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography align="right">{toggleAllButton ? <VisibilityOff /> : <Visibility />}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.rowWithTopBorder}>
          <Grid container>
            <Grid item xs={4}>
              <Typography noWrap>Company:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap className={clsx({ [classes.maskedText]: toggleAllButton })}>
                {data?.loggedIn.company?.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography align="right">{toggleAllButton ? <VisibilityOff /> : <Visibility />}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.rowWithTopBorder}>
          <Grid container>
            <Grid item xs={4}>
              <Typography noWrap>User Roles:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap className={clsx({ [classes.maskedText]: toggleAllButton })}>
                {data?.loggedIn.roles.map((a, index) => {
                  return formatRoleText(a.name, index);
                })}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography align="right">{toggleAllButton ? <VisibilityOff /> : <Visibility />}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.rowWithNoBorder}>
          <Typography align="right">
            <Button variant="outlined" onClick={toggleAllButtons}>
              {toggleAllButton ? 'Show' : 'hide'}
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}
