import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Routes from '../../constants/Routes';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: 16,
    height: 50,
  },
  input: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function PasswordResetRequested() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box paddingTop={3.5} paddingX={6} paddingBottom={8}>
        <Box marginBottom={6}>
          <Typography align="center" variant="h4" gutterBottom>
            Password reset requested
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography variant="body2" gutterBottom>
            If the email you provided exists in our system you should receive instructions to reset your password.
          </Typography>
        </Box>
        <Button
          onClick={() => history.push(Routes.Login)}
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          size="large"
          className={classes.button}
        >
          Return to login
        </Button>
      </Box>
    </Container>
  );
}
