import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import usePartList from '../hooks/usePartList';
import { DoorModelPartsFragment, DoorParts } from '../queries';

interface PartsListFieldsProps {
  doorModelParts?: Partial<DoorModelPartsFragment> | null;
  doorParts?: Partial<DoorParts> | null;
  readOnly?: boolean;
}

export default function PartsListFields({ doorModelParts, doorParts, readOnly = false }: PartsListFieldsProps) {
  const { register } = useFormContext();
  const { isEditable } = usePartList(doorModelParts, doorParts, readOnly);
  return (
    <>
      <Grid item lg={6} xs={12}>
        <TextField
          name="lockLatch"
          label="Lock / latch"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('lockLatch')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="closer"
          label="Door closer"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('closer')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="intumescentStrip"
          label="Intumescent strip"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('intumescentStrip')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="hinges"
          label="Hinges"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('hinges')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="handles"
          label="Handles"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('handles')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="smokeSeals"
          label="Smoke seals"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('smokeSeals')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="dropSeal"
          label="Drop seal"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('dropSeal')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="cylinder"
          label="Cylinder"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('cylinder')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="letterbox"
          label="Letterbox"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('letterbox')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="spyhole"
          label="Spyhole"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('spyhole')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="thresholdStrip"
          label="Threshold strip"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('thresholdStrip')}
        />
      </Grid>
    </>
  );
}
