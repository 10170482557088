import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  messageWithButtonCell: {
    borderBottom: 'none',
    padding: '50px',
  },
  messageButton: {
    marginTop: '20px',
  },
});

interface MessageWithButtonRowProps {
  colSpan: number;
  onClicked: (event: React.SyntheticEvent) => void;
  message: string;
  buttonText: string;
  buttonColor: 'inherit' | 'default' | 'primary' | 'secondary' | undefined;
}

export default function MessageWithButtonRow({
  colSpan,
  onClicked,
  message,
  buttonText,
  buttonColor,
}: MessageWithButtonRowProps) {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} className={classes.messageWithButtonCell} align="center">
        <Typography>{message}</Typography>
        <Button variant="contained" color={buttonColor} className={classes.messageButton} onClick={onClicked}>
          {buttonText}
        </Button>
      </TableCell>
    </TableRow>
  );
}
