import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { Company, CompanyTypes, useCompaniesByNameAndTypeLazyQuery } from '../../../queries';

interface SiteDetailsEditProps {
  siteOwnedByName?: string | null;
  enableOwnerEdition?: boolean;
}

export default function SiteDetailsEdit({ siteOwnedByName, enableOwnerEdition = true }: SiteDetailsEditProps) {
  const [siteOwnerInputValue, setSiteOwnerInputValue] = useState('');
  const [debouncedTextCriteria] = useDebounce(siteOwnerInputValue, 300);
  const { enqueueSnackbar } = useSnackbar();
  const [
    getFacilityManagementCompanies,
    { loading: facilityManagementLoading, data: facilityManagement, error: facilityManagementError },
  ] = useCompaniesByNameAndTypeLazyQuery({ fetchPolicy: 'no-cache' });

  const { register, control, errors } = useFormContext();

  useEffect(() => {
    if (facilityManagementError) {
      enqueueSnackbar('There was an error fetching site names.', { variant: 'error' });
    }
  }, [facilityManagementError, enqueueSnackbar]);

  useEffect(() => {
    if (debouncedTextCriteria) {
      getFacilityManagementCompanies({
        variables: {
          name: debouncedTextCriteria.trim(),
          type: [CompanyTypes.FacilityManagement],
        },
      });
    }
  }, [debouncedTextCriteria, getFacilityManagementCompanies]);

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} xs={12}>
        <TextField
          name="name"
          label="Site name"
          variant="outlined"
          fullWidth
          error={!!errors.name}
          helperText={errors?.name?.message}
          inputRef={register({
            required: 'Site name required',
          })}
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="reference"
          label="Site reference"
          variant="outlined"
          fullWidth
          inputRef={register()}
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Controller
            control={control}
            name="owner"
            defaultValue={null}
            rules={{
              required: 'Site owner is required',
            }}
            render={({ onChange, value }) => (
              <Autocomplete
                fullWidth
                autoSelect
                autoComplete
                selectOnFocus
                handleHomeEndKeys
                freeSolo
                disabled={!enableOwnerEdition}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.name;
                }}
                options={facilityManagement?.companiesByNameAndType ?? []}
                value={value?.id === null ? null : siteOwnedByName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Site owner"
                    helperText={errors?.owner?.message}
                    error={!!errors.owner}
                    variant="outlined"
                    fullWidth
                  />
                )}
                onInputChange={(_, newInputValue) => setSiteOwnerInputValue(newInputValue)}
                onChange={(_, newValue: Pick<Company, 'id' | 'name'> | string | null) => {
                  if (!newValue) {
                    onChange(newValue);
                    return;
                  }

                  if (typeof newValue === 'string') {
                    const existingOption = facilityManagement?.companiesByNameAndType.find(
                      (f) => f.name.trim() === newValue.trim()
                    );
                    if (existingOption) {
                      onChange({ id: existingOption.id, name: existingOption.name });
                    } else {
                      onChange({ id: undefined, name: newValue });
                    }
                    return;
                  }

                  onChange(newValue);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                loading={facilityManagementLoading}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="areas"
          label="Areas / floors"
          variant="outlined"
          fullWidth
          error={!!errors.areas}
          helperText={errors?.areas?.message}
          inputRef={register({
            pattern: {
              value: /^[0-9]*$/i,
              message: 'Areas / floors must be a number',
            },
          })}
          inputProps={{ maxLength: 9 }}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Box marginTop={3}>
          <Typography variant="h6">Address</Typography>
        </Box>
      </Grid>
      <Grid item lg={8} xs={12}>
        <TextField
          name="addressOne"
          label="Address line 1"
          variant="outlined"
          fullWidth
          inputRef={register()}
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
      <Grid item lg={8} xs={12}>
        <TextField
          name="addressTwo"
          label="Address line 2"
          variant="outlined"
          fullWidth
          inputRef={register()}
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="city"
          label="City"
          variant="outlined"
          fullWidth
          inputRef={register()}
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
      <Hidden mdDown>
        <Grid item lg={6} sm={false}></Grid>
      </Hidden>
      <Grid item lg={3} xs={12}>
        <TextField
          name="postcode"
          label="Postcode"
          variant="outlined"
          fullWidth
          inputRef={register()}
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
    </Grid>
  );
}
