import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Drawing, Photo } from '../../../constants/DocumentTypes';
import { SiteQuery, Document } from '../../../queries';
import DocumentList from '../../DocumentList';
import DetailBox from './DetailBox';

interface SiteAttachmentsProps {
  loading: boolean;
  siteQuery?: SiteQuery;
}

export default function SiteAttachments({ loading, siteQuery }: SiteAttachmentsProps) {
  let sitePhotos: Document[] = [];
  let siteDrawings: Document[] = [];

  if (siteQuery?.site.documents) {
    sitePhotos = [...siteQuery?.site.documents.filter((d) => d.documentType.code === Photo)!];
    siteDrawings = [...siteQuery?.site.documents.filter((d) => d.documentType.code === Drawing)!];
  }

  return (
    <Box py={3}>
      <Grid container spacing={3}>
        {loading && (
          <Grid item container justify="center" alignItems="center" xs={12}>
            <CircularProgress />
          </Grid>
        )}
        {!loading && (
          <>
            <Grid item md={12} lg={6}>
              <DetailBox title="Site drawings">
                <DocumentList
                  documents={siteDrawings}
                  noFilesMessage={'There are no site drawings for the site'}
                ></DocumentList>
              </DetailBox>
            </Grid>
            {/*
              <Grid item md={12} lg={6}>
                <DetailBox title="Site photo">
                  <DocumentList documents={sitePhotos} noFilesMessage={'There is no photo for the site'}></DocumentList>
                </DetailBox>
              </Grid>*/}
          </>
        )}
      </Grid>
    </Box>
  );
}
