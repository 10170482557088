import React from 'react';
import Layout from '../components/Layout';
import TermsAndConditionsContent from '../components/TermsAndConditionsContent';
import useAuth from '../hooks/useAuth';

export default function TermsAndConditions() {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return (
      <Layout>
        <TermsAndConditionsContent />
      </Layout>
    );
  }

  return <TermsAndConditionsContent paddingTop={5} />;
}
