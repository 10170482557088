import React from 'react';
import Avatar from '@material-ui/core/Avatar';

interface FullNameAvatarProps {
  fullName?: string;
  className?: string;
}

const getInitials = (userFullname?: string) => {
  if (!userFullname) {
    return '';
  }
  return userFullname
    .split(' ')
    .map((x) => x[0])
    .join('')
    .toUpperCase();
};

export default function FullNameAvatar({ fullName, className }: FullNameAvatarProps) {
  return <Avatar className={className}>{getInitials(fullName)}</Avatar>;
}
