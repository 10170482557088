import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MaterialLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import DoorModelDetails from '../components/door-model/DoorModelDetails';
import DoorModelPartsList from '../components/door-model/DoorModelPartsList';
import Layout from '../components/Layout';
import * as Routes from '../constants/Routes';
import { useDoorModelQuery, useSaveDoorModelDocumentsMutation } from '../queries';
import LoadingButton from '../components/LoadingButton';
import DoorModelDocumentation from '../components/door-model/DoorModelDocumentation';
import { FormProvider, useForm } from 'react-hook-form';
import DoorModelDocumentationUpload from '../components/door-model/DoorModelDocumentationUpload';
import { DoorModelDocument } from './DoorModelAdd';
import { useSnackbar } from 'notistack';
import useDialog from '../hooks/useDialog';

interface DoorModelParams {
  id?: string;
}

export default function DoorModel() {
  const { showDialog } = useDialog();
  const [tabIndex, setTabIndex] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [documents, setDocuments] = useState<DoorModelDocument[]>([]);
  const history = useHistory();
  const { id } = useParams<DoorModelParams>();
  const [saveDocumentsMutation, { loading: savingDoorModel }] = useSaveDoorModelDocumentsMutation();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error } = useDoorModelQuery({
    variables: {
      id: id!,
    },
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar('There was an error fetching this door model', { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data && data.doorModel.documents) {
      setDocuments(
        data.doorModel.documents.map((d) => ({
          documentTypeCode: d.documentType.code,
          id: d.id,
          file: {
            name: d.originalFilename,
          },
        }))
      );
    }
  }, [data]);

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        if (isEdit) {
          setIsEdit(false);
        } else {
          history.goBack();
        }
      },
    });
  };

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { handleSubmit } = form;

  const onSaveDocuments = async () => {
    await saveDocumentsMutation({
      variables: {
        doorModelDocumentsInput: {
          doorModelId: data?.doorModel.id!,
          documents: documents.map((d) => ({
            documentTypeCode: d.documentTypeCode,
            id: d.id,
            file: d.id ? null : d.file, // send the file object for new files only or they will not match the FileUpload type
          })),
        },
      },
    });
    setIsEdit(false);
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">{data?.doorModel.name}</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink
                  color="inherit"
                  component="button"
                  variant="body2"
                  onClick={() => history.push(Routes.DoorModels)}
                >
                  <Typography variant="body2">Door models</Typography>
                </MaterialLink>
                <Typography variant="body2">{data?.doorModel.name}</Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>

          <Paper>
            <Box mt={3}>
              <Tabs
                value={tabIndex}
                onChange={(_, index: number) => setTabIndex(index)}
                aria-label="door model information"
                indicatorColor="primary"
                variant="fullWidth"
              >
                <Tab label="Details" />
                <Tab label="Parts list" />
                <Tab label="Documentation" />
              </Tabs>
              <Divider />
              {loading && (
                <Grid item container justify="center" alignItems="center" xs={12}>
                  <Box m={10}>
                    <CircularProgress />
                  </Box>
                </Grid>
              )}

              {tabIndex === 0 && !loading && <DoorModelDetails doorModel={data?.doorModel} />}
              {tabIndex === 1 && !loading && <DoorModelPartsList doorModelParts={data?.doorModel.doorModelParts} />}
              {tabIndex === 2 && !loading && !isEdit && (
                <DoorModelDocumentation documents={data?.doorModel.documents} />
              )}
              {tabIndex === 2 && !loading && isEdit && (
                <Box p={5}>
                  <FormProvider {...form}>
                    <form id="edit-model-documents" onSubmit={handleSubmit(onSaveDocuments)}>
                      <DoorModelDocumentationUpload documents={documents} setDocuments={setDocuments} />
                    </form>
                  </FormProvider>
                </Box>
              )}
            </Box>
            {tabIndex === 2 && (
              <Box display="flex" justifyContent="flex-end" p={5} pt={0}>
                {!isEdit && (
                  <Button variant="contained" color="primary" onClick={() => setIsEdit(true)}>
                    Edit
                  </Button>
                )}
                {isEdit && (
                  <LoadingButton
                    loading={savingDoorModel}
                    disabled={documents.some((d) => !d.documentTypeCode) || savingDoorModel}
                    variant="contained"
                    color="primary"
                    type="submit"
                    form="edit-model-documents"
                  >
                    Save
                  </LoadingButton>
                )}
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Layout>
  );
}
