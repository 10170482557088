import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MaterialLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Layout from '../components/Layout';
import DoorDetailsAdd, { DoorLeaf } from '../components/manufactured-doors/add/DoorDetailsAdd';
import DoorDoorModelAdd from '../components/manufactured-doors/add/DoorDoorModelAdd';
import DoorPartsListAdd from '../components/manufactured-doors/add/DoorPartsListAdd';
import DoorPhotosAdd from '../components/manufactured-doors/add/DoorPhotosAdd';
import CustomStepConnector from '../components/stepper/CustomStepConnector';
import CustomStepIcon from '../components/stepper/CustomStepIcon';
import * as Routes from '../constants/Routes';
import DoorContext from '../context/DoorContext';
import useDialog from '../hooks/useDialog';
import { DoorModelQuery } from '../queries';
import DoorDocumentationAdd from '../components/manufactured-doors/add/DoorDocumentationAdd';

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    marginTop: theme.spacing(3),
  },
}));

const steps = [
  { name: 'Door model', route: 'model' },
  { name: 'Details', route: 'details' },
  { name: 'Parts list', route: 'parts-list' },
  { name: 'Documentation', route: 'documents' },
  { name: 'Photos', route: 'photos' },
];

export interface DoorDocument {
  id?: string | null;
  file: Partial<File>;
  documentTypeCode: string;
}

export interface DoorPhoto {
  id?: string | null;
  file: Partial<File>;
  description?: string | null;
}

export interface DoorFormData {
  // model
  doorModelId?: number;
  // details
  id?: string;
  projectReference?: string | null;
  doorReference?: string | null;
  fdRatingId?: number | null;
  tagId?: string | null;
  barcode?: string | null;
  lineBarcode?: string | null;
  doorLeafs?: DoorLeaf[];
  // parts list
  lockLatch?: string | null;
  closer?: string | null;
  intumescentStrip?: string | null;
  hinges?: string | null;
  handles?: string | null;
  smokeSeals?: string | null;
  dropSeal?: string | null;
  cylinder?: string | null;
  letterbox?: string | null;
  spyhole?: string | null;
  thresholdStrip?: string | null;
  keeps?: string | null;
  numerals?: string | null;
  chain?: string | null;
  weatherSeals?: string | null;
  additionalComments?: string | null;
  // photos
  photos?: DoorPhoto[];
  documents?: DoorDocument[];
  inspectionFrequency?: number | null;
}

export default function DoorAdd() {
  const [door, setDoor] = useState<DoorFormData | undefined>();
  const [doorModel, setDoorModel] = useState<DoorModelQuery | undefined>();
  const [activeStep, setActiveStep] = useState(0);
  const { showDialog } = useDialog();
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => history.push(Routes.ManufacturedDoors),
    });
  };

  useEffect(() => {
    const split = location.pathname.split('/');
    const currentRoute = split.pop();
    const stepIndex = steps.findIndex((s) => s.route === currentRoute);
    if (stepIndex > -1) {
      setActiveStep(stepIndex);
    }
  }, [location.pathname]);

  const onContinue = () => history.push(`${url}/${steps[activeStep + 1].route}`);
  const onBack = () => history.push(`${url}/${steps[activeStep - 1].route}`);

  const contextValue = {
    door,
    setDoor,
    doorModel,
    setDoorModel,
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">Add door manually</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.ManufacturedDoors}>
                  <Typography variant="body2">Manufactured doors</Typography>
                </MaterialLink>
                <Typography variant="body2">Add door manually</Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>

          <Paper className={classes.container}>
            <Box>
              <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
                {steps.map((step) => (
                  <Step key={step.name}>
                    <StepLabel StepIconComponent={CustomStepIcon}>{step.name}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Box p={5} pt={2}>
                <DoorContext.Provider value={contextValue}>
                  <Switch>
                    <Route exact path={`${path}/model`}>
                      <DoorDoorModelAdd onContinue={onContinue} />
                    </Route>
                    <Route exact path={`${path}/details`}>
                      <DoorDetailsAdd onContinue={onContinue} onBack={onBack} />
                    </Route>
                    <Route exact path={`${path}/parts-list`}>
                      <DoorPartsListAdd onContinue={onContinue} onBack={onBack} />
                    </Route>
                    <Route exact path={`${path}/documents`}>
                      <DoorDocumentationAdd onContinue={onContinue} onBack={onBack} />
                    </Route>
                    <Route exact path={`${path}/photos`}>
                      <DoorPhotosAdd onBack={onBack} />
                    </Route>
                    <Route path={path}>
                      <Redirect to={`${path}/model`} />
                    </Route>
                  </Switch>
                </DoorContext.Provider>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
