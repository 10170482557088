import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import * as Routes from '../../../constants/Routes';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';
import useAuth from '../../../hooks/useAuth';

export default function
  MyCompanyMenuItem() {
  const location = useLocation();
  const { loggedInUser } = useAuth();
  return (
    <ProtectedSection roles={[
      Roles.ManufacturerAdmin,
      Roles.InstallerAdmin,
      Roles.InspectorAdmin,
      Roles.FacilityManagerAdmin,
      Roles.ServiceEngineerAdmin,
    ]}>
      <ListItem
        button
        component={Link}
        to={`${Routes.Companies}/${loggedInUser?.companyId}`}
        selected={location.pathname.startsWith(Routes.Companies)}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary={loggedInUser?.companyName} />
      </ListItem>
    </ProtectedSection>
  );
}
