import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { DoorFragment } from '../../../queries';
import DoorField from './DoorField';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';
import dayjs from 'dayjs';

interface DoorDetailsProps {
  door?: DoorFragment;
}

const fdRatingformatter = (value: any): string | undefined => {
  if (value) {
    if (String(value).startsWith('FD')) {
      return String(value);
    }
    return `FD ${value}`;
  }
};

const mmformatter = (value: any): string | undefined => {
  if (value) {
    return `${value}mm`;
  }
};

const dbformatter = (value: any): string | undefined => {
  if (value) {
    if (String(value).endsWith('dB') || String(value).endsWith('db')) {
      return String(value);
    }
    return `${value}dB`;
  }
};

const monthFormatter = (value: any): string | undefined => {
  if (value) {
    return `${value} month(s)`;
  }
};

const dateFormatter = (date?: Date) => {
  if (!date) {
    return '';
  }

  return dayjs(date).format('DD/MM/YYYY');
};

export default function DoorDetails({ door }: DoorDetailsProps) {
  const hasSecondLeaf = Boolean(
    door?.leaf2AccousticRating || door?.leaf2CoreSupplier || door?.leaf2Height || door?.leaf2Width
  );

  return (
    <Box p={5}>
      <Grid container spacing={3}>
        <DoorField title="DDS Id" value={door?.id} />
        <DoorField title="Project reference" value={door?.projectReference} />
        <DoorField title="Door reference" value={door?.doorReference} />
        <DoorField
          title="FD rating"
          value={door?.model?.fdRating?.value || door?.fdRating?.value}
          format={fdRatingformatter}
        />

        <DoorField title="Tag ID" value={door?.tagId} />
        <ProtectedSection roles={[Roles.ManufacturerAdmin, Roles.Manufacturer]} exclusive>
          <DoorField title="Barcode" value={door?.barcode} />
          <DoorField title="Line barcode" value={door?.lineBarcode} />
        </ProtectedSection>
        <DoorField title="Door model" value={door?.model?.name} />
        <ProtectedSection roles={[Roles.FacilityManagerAdmin, Roles.FacilityManager]}>
          <DoorField title="Inspection frequency" value={door?.inspectionFrequency} format={monthFormatter} />
          <DoorField title="Next inspection date" value={dateFormatter(door?.nextInspectionDate)} />
        </ProtectedSection>

        <Grid item xs={12}>
          <Typography variant="h6">Door leaf 1</Typography>
        </Grid>
        <DoorField title="Acoustic rating" value={door?.leaf1AccousticRating} format={dbformatter} />
        <DoorField title="Core supplier" value={door?.model?.coreSupplier || door?.leaf1CoreSupplier} />
        <DoorField title="Leaf width" value={door?.leaf1Width} format={mmformatter} />
        <DoorField title="Leaf height" value={door?.leaf1Height} format={mmformatter} />

        {hasSecondLeaf && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Door leaf 2</Typography>
            </Grid>
            <DoorField title="Acoustic rating" value={door?.leaf2AccousticRating} format={dbformatter} />
            <DoorField title="Core supplier" value={door?.model?.coreSupplier || door?.leaf2CoreSupplier} />
            <DoorField title="Leaf width" value={door?.leaf2Width} format={mmformatter} />
            <DoorField title="Leaf height" value={door?.leaf2Height} format={mmformatter} />
          </>
        )}
      </Grid>
    </Box>
  );
}
