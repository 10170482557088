import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import * as Routes from '../constants/Routes';
import Alert from '../components/Alert';
import { useResetPasswordMutation } from '../queries';
import { GraphQLError } from 'graphql/error';
import { InvalidToken, InvalidPassword } from '../constants/GraphQLErrorCodes';
import Roles from '../constants/Roles';
import SetPasswordForm, { SetPasswordFormData } from '../components/SetPasswordForm';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: 16,
    height: 50,
  },
  input: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface ResetPasswordParams {
  token: string;
}

export default function ResetPassword() {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { token } = useParams<ResetPasswordParams>();

  const [acceptInviteMutation, { loading }] = useResetPasswordMutation();
  const classes = useStyles();
  const history = useHistory();

  const process = async (values: SetPasswordFormData) => {
    try {
      const roles = await acceptInviteMutation({
        variables: {
          resetPasswordInput: {
            password: values.password,
            token,
          },
        },
      });

      const rolesThatLogin = [
        Roles.ManufacturerAdmin,
        Roles.InstallerAdmin,
        Roles.FacilityManager,
        Roles.Inspector,
        Roles.SuperUser,
        Roles.InspectorAdmin,
        Roles.ServiceEngineerAdmin,
      ];

      const shouldLogin = roles.data!.resetPassword.filter((role) => rolesThatLogin.includes(role.code)).length > 0;

      if (shouldLogin) {
        history.push(Routes.Login);
      } else {
        history.push(Routes.Welcome);
      }
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        if (gqlerror?.extensions?.code === InvalidToken) {
          setErrorMessage(gqlerror.message);
          setIsError(true);
        } else if (gqlerror?.extensions?.code === InvalidPassword) {
          setErrorMessage(gqlerror.message);
          setIsError(true);
        }
      });
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box paddingTop={3.5} paddingX={6} paddingBottom={8}>
        <Box marginBottom={6}>
          <Typography align="center" variant="h4" gutterBottom>
            Change Password
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography variant="body2" gutterBottom>
            Use 8 or more characters in both upper and lower case with a mix of letters, numbers & symbols
          </Typography>
        </Box>
        <Alert
          text={errorMessage}
          visible={isError}
          onClose={() => {
            setIsError(false);
          }}
        />
        <SetPasswordForm
          process={process}
          loading={loading}
          showTermsAndConditions={false}
          disableSubmit={loading || isError}
          buttonText={'Set password'}
        />
      </Box>
    </Container>
  );
}
