import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Layout from '../components/Layout';
import { useDoorLocationQuery, useLocationTypesQuery, useReportInstallationMutation } from '../queries';
import * as Routes from '../constants/Routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useDialog from '../hooks/useDialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField/TextField';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import DropZone from '../components/DropZone';
import AddedFile from '../components/AddedFile';
import { InstallationCertificate, InstallationReport } from '../constants/DocumentTypes';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';

interface ReportDoorInstallationFormData {
  installationDate: dayjs.Dayjs;
  location?: string;
  locationTypeId?: string;
  signOffComment?: string | undefined;
}

interface ReportDoorInstallationParams {
  doorId: string;
  siteId: string;
}

export default function ReportDoorInstallation() {
  const { showDialog } = useDialog();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: locationTypes } = useLocationTypesQuery({ fetchPolicy: 'cache-first' });
  const { doorId, siteId } = useParams<ReportDoorInstallationParams>();
  const { data: doorLocationData } = useDoorLocationQuery({
    variables: {
      id: doorId,
    },
  });
  const [installationReport, setInstallationReport] = useState<File>();
  const [installationCertificate, setInstallationCertificate] = useState<File>();

  const [reportInstallation, { loading }] = useReportInstallationMutation();

  const form = useForm<ReportDoorInstallationFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, formState, register, errors, control, reset } = form;

  useEffect(() => {
    reset({
      location: doorLocationData?.door.location ?? undefined,
      locationTypeId: String(doorLocationData?.door.locationType?.id) ?? undefined,
      installationDate: dayjs(new Date()),
    });
  }, [doorLocationData, reset]);

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        history.goBack();
      },
    });
  };

  const saveChanges = async (values: ReportDoorInstallationFormData) => {
    try {
      await reportInstallation({
        variables: {
          reportInstallationDetails: {
            doorId,
            location: values.location,
            locationTypeId: values.locationTypeId,
            installedAt: values.installationDate,
            signOffComments: values.signOffComment,
            installationReport,
            installationCertificate,
          },
        },
      });

      enqueueSnackbar('Door installation reported.', { variant: 'success' });
      history.goBack();
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    }
  };

  const onInstallationReportAdded = (selectedFiles: File[]) => {
    setInstallationReport(selectedFiles[0]);
  };
  const onInstallationCertificateAdded = (selectedFiles: File[]) => {
    setInstallationCertificate(selectedFiles[0]);
  };

  if (!doorLocationData) {
    // Need to not render the page to avoid artifacts with the text inputs
    return null;
  }

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5">Report installation</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.MySites}>
                  <Typography variant="body2">My site list</Typography>
                </MaterialLink>
                {(location?.state as any)?.siteName && (
                  <MaterialLink color="inherit" component={Link} to={`${Routes.Sites}/${siteId}`}>
                    <Typography variant="body2">{(location?.state as any)?.siteName}</Typography>
                  </MaterialLink>
                )}
                <Typography variant="body2">Report installation</Typography>
              </Breadcrumbs>
            </Box>
            <Box minWidth={96}>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>
          <Box mt={1}>
            <Paper>
              <Box p={5} marginTop={3}>
                <Box marginBottom={3}>
                  <Typography>Installation details.</Typography>
                </Box>
                <form onSubmit={handleSubmit(saveChanges)}>
                  <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                      {!installationReport && (
                        <>
                          <DropZone
                            onFilesAdded={onInstallationReportAdded}
                            title="Upload installation report"
                            accept=".pdf"
                            acceptHelperText="Accepted format: PDF"
                            maxSizeMb={50}
                          />
                          <Typography variant="subtitle2">The installation report is required</Typography>
                        </>
                      )}
                      {installationReport && (
                        <AddedFile
                          documentType={InstallationReport}
                          fileName={installationReport.name}
                          onRemove={() => setInstallationReport(undefined)}
                        >
                          <Box flex={1} display="flex" justifyContent="center">
                            <Typography variant="body1">Installation report</Typography>
                          </Box>
                        </AddedFile>
                      )}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      {!installationCertificate && (
                        <DropZone
                          onFilesAdded={onInstallationCertificateAdded}
                          title="Upload installation certificate"
                          accept=".pdf"
                          acceptHelperText="Accepted format: PDF"
                          maxSizeMb={50}
                        />
                      )}
                      {installationCertificate && (
                        <AddedFile
                          documentType={InstallationCertificate}
                          fileName={installationCertificate.name}
                          onRemove={() => setInstallationCertificate(undefined)}
                        >
                          <Box flex={1} display="flex" justifyContent="center">
                            <Typography variant="body1">Installation certificate</Typography>
                          </Box>
                        </AddedFile>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        name="location"
                        label="Location"
                        variant="outlined"
                        fullWidth
                        inputRef={register}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="locationTypeId">Location type</InputLabel>

                        <Select
                          label="Location type"
                          defaultValue={undefined}
                          native
                          inputProps={{
                            name: 'locationTypeId',
                            id: 'locationTypeIdInput',
                          }}
                          inputRef={register}
                        >
                          <option aria-label="None" value="" />
                          {locationTypes?.locationTypes &&
                            locationTypes?.locationTypes.length > 0 &&
                            locationTypes?.locationTypes.map((locationType) => (
                              <option value={locationType.id} key={locationType.id}>
                                {locationType.name}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Controller
                        control={control}
                        name="installationDate"
                        rules={{
                          required: 'An installation date is required',
                        }}
                        render={({ onChange, value }) => (
                          <KeyboardDatePicker
                            style={{ width: '100%' }}
                            value={value}
                            onChange={onChange}
                            inputVariant="outlined"
                            format="DD/MM/YYYY"
                            label="Installation date"
                            placeholder="Installation date"
                            maxDate={dayjs(new Date())}
                            error={!!errors.installationDate}
                            helperText={(errors.installationDate as any)?.message}
                          />
                        )}
                      ></Controller>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        name="signOffComment"
                        label="Sign off comment"
                        variant="outlined"
                        defaultValue={undefined}
                        fullWidth
                        inputRef={register}
                        multiline
                        rows={4}
                        error={!!errors.signOffComment}
                        helperText={errors.signOffComment?.message}
                      />
                    </Grid>
                  </Grid>
                  <Box display="flex" justifyContent="flex-end" marginTop={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!formState.isValid || !installationReport || loading}
                    >
                      {loading ? 'Saving' : 'Report installation'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
