import dayjs from 'dayjs';

export function getMonthBillingDateRange(date: Date): { dateFrom: Date; dateTo: Date } {
  let dateToTest = dayjs(date);

  if (dateToTest.date() > 28) {
    dateToTest = dateToTest.add(5, 'day'); // add days so it corrects the year as well if adding to the end of december
  }

  let month = dateToTest.month();
  let year = dateToTest.year();

  const periodTo = new Date(Date.UTC(year, month, 28, 23, 59, 59, 999));
  const periodFrom = new Date(Date.UTC(year, month - 1, 29, 0, 0, 0, 0));

  return { dateFrom: periodFrom, dateTo: periodTo };
}

export function getMonthBillingDateRangeForYearMonth(year: number, month: number): { dateFrom: Date; dateTo: Date } {
  return getMonthBillingDateRange(new Date(Date.UTC(year, month, 15)));
}

export function getYearlyBillingDateRange(date: Date): { dateFrom: Date; dateTo: Date } {
  let dateToTest = dayjs(date);

  let month = dateToTest.month();
  let year = dateToTest.year();

  if (month >= 3) {
    year++;
  }

  const periodTo = new Date(Date.UTC(year, 2, 31, 23, 59, 59, 999));
  const periodFrom = new Date(Date.UTC(year - 1, 3, 1, 0, 0, 0, 0));

  return { dateFrom: periodFrom, dateTo: periodTo };
}

export function getYearlyBillingDateRangeForYear(year: number): { dateFrom: Date; dateTo: Date } {
  return getYearlyBillingDateRange(new Date(Date.UTC(year, 7, 15)));
}
