import { Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MaterialLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import AddedFile from '../components/AddedFile';
import DropZone from '../components/DropZone';
import Layout from '../components/Layout';
import * as Routes from '../constants/Routes';
import { useUploadDoorsFileMutation } from '../queries';

interface ImportDoorParams {
  id?: string;
}

export default function ImportDoors() {
  const [cancelling, setCancelling] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const { id: siteId } = useParams<ImportDoorParams>();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadDoorsFileMutation, { data, error }] = useUploadDoorsFileMutation();

  const onFilesAdded = (files: File[]): void => {
    setFile(files[0]);
  };

  const uploadFile = () => {
    if (file)
      uploadDoorsFileMutation({
        variables: {
          file,
          siteId,
        },
      });
  };

  useEffect(() => {
    if (data?.uploadDoorsFile) {
      enqueueSnackbar('Doors successfully scheduled for import.', { variant: 'success' });
      history.goBack();
    }
  }, [data, enqueueSnackbar, history]);

  useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">{'Import doors'}</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                {!siteId && (
                  <MaterialLink color="inherit" component={Link} to={Routes.ManufacturedDoors}>
                    <Typography variant="body2">Manufactured doors</Typography>
                  </MaterialLink>
                )}
                {siteId && (
                  <MaterialLink color="inherit" component={Link} to={`${Routes.Sites}/${siteId}`}>
                    <Typography variant="body2">Site</Typography>
                  </MaterialLink>
                )}

                <Typography variant="body2">Import doors</Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              <Button
                variant="outlined"
                onClick={() => {
                  setCancelling(true);
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
          <Paper>
            <Box p={3}>
              <Box>
                <DropZone
                  onFilesAdded={onFilesAdded}
                  title="Upload doors file"
                  accept=".csv"
                  acceptHelperText="Accepted format: CSV"
                />
              </Box>
              {file && <AddedFile fileName={file.name} onRemove={() => setFile(undefined)} />}
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!file}
                  onClick={() => {
                    uploadFile();
                  }}
                >
                  Import
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
      <Dialog open={cancelling}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">Are you sure you want to cancel this operation?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setCancelling(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
