import { Theme, withStyles } from '@material-ui/core';
import React from 'react';
import LoadingButton, { LoadingButtonProps } from './LoadingButton';

const CustomButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))(LoadingButton);

export default function ErrorButton(props: LoadingButtonProps) {
  return <CustomButton {...props} />;
}
