import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { DoorHistoryFragment } from '../../../../../queries';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import StandardGridItem from './StandardGridItem';

interface AdditionalInfo {
  message?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
}

function DetailDialog({ history }: DetailDialogProps) {
  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as AdditionalInfo;
  return (
    <Box pt={1} pb={1}>
      <Grid container spacing={3}>
        <StandardGridItem title={'Message'} value={additionalInfo.message} minWidth={12} />
      </Grid>
    </Box>
  );
}

export default function DoorRepairRequestedDetailDialog({ open, onClose, history }: Props) {
  return (
    <DoorHistoryDetailDialog open={open} onClose={onClose} history={history} actorName="Requested by">
      <DetailDialog history={history} />
    </DoorHistoryDetailDialog>
  );
}
