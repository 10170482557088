import React, { useState } from 'react';
import clsx from 'clsx';
import HeaderBar from './HeaderBar';
import { makeStyles } from '@material-ui/core/styles';
import SideMenu from './side-menu/SideMenu';
const drawerWidth = 250;
const appBarHeight = 67;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  appBarPadding: {
    ...theme.mixins.toolbar,
  },
}));

export default function Layout({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  return (
    <div className={classes.root}>
      <HeaderBar
        appBarHeight={appBarHeight}
        drawerWidth={drawerWidth}
        toggleDrawer={() => setOpen((prev) => !prev)}
        drawerOpen={open}
      />
      <SideMenu open={open} drawerWidth={drawerWidth} appBarHeight={appBarHeight}></SideMenu>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.appBarPadding} />
        {children}
      </main>
    </div>
  );
}
