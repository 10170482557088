import React, { useEffect, useMemo, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { Site, useMySitesHeaderLazyQuery } from '../../queries';
import { useDebounce } from 'use-debounce';

export type FormSite = {
  id: string;
  name: string;
};

type Props = {
  name: string;
  label: string;
};

/** Currently this uses only mysites */
export default function ControlledSiteAutocomplete({ name, label }: Props) {
  const [selectedSite, setSelectedSite] = useState<string | undefined>(undefined);
  const [debouncedSite] = useDebounce(selectedSite, 300);
  const [getSites, { loading: sitesLoading, data: sitesData }] = useMySitesHeaderLazyQuery();
  const { control, errors, watch } = useFormContext();

  useEffect(() => {
    getSites({
      variables: {
        skip: 0,
        searchTerm: selectedSite,
        sortDirection: 'asc',
        sortField: 'name',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSite, getSites]);

  const site = watch(name);
  const options = useMemo(() => {
    const temp = sitesData?.mySites.items.map(({ id, name }) => ({ id, name })) ?? [];

    if (site && temp.findIndex((t) => t.id === site.id) === -1) {
      temp.push(site);
    }

    return temp;
  }, [sitesData, site]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ onChange }) => (
        <Autocomplete
          fullWidth
          autoSelect
          autoComplete
          value={site ?? ''}
          selectOnFocus
          handleHomeEndKeys
          freeSolo
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option.name;
          }}
          options={options}
          renderInput={(params) => (
            <TextField {...params} label={label} variant="outlined" fullWidth error={!!errors.destinationCompany} />
          )}
          onInputChange={(_, newInputValue) => {
            setSelectedSite(newInputValue);
          }}
          onChange={(_, newValue: Pick<Site, 'id' | 'name'> | string | null) => {
            if (!newValue) {
              onChange(newValue);
            }

            if (newValue && typeof newValue === 'object') {
              newValue = newValue?.name;
            }

            if (typeof newValue === 'string') {
              const existingOption = sitesData?.mySites.items.find((f) => f.name === (newValue as string).trim());
              if (existingOption) {
                onChange({ id: existingOption.id, name: existingOption.name } as FormSite);
              } else {
                onChange(undefined);
              }
            }
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          loading={sitesLoading}
        />
      )}
    />
  );
}
