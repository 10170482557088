import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';

interface AlertProps {
  text?: string;
  visible: boolean;
  severity?: 'success' | 'info' | 'warning' | 'error';
  onClose?: (event: React.SyntheticEvent) => void;
  animate?: boolean;
}

export default function Alert({ text, visible, onClose, severity, animate = true }: AlertProps) {
  const alertSeverity = severity ? severity : 'error';
  const timeout = animate ? 100 : 0;
  return (
    <>
      <Collapse in={visible} timeout={timeout}>
        <Grow in={visible} mountOnEnter unmountOnExit timeout={timeout * 3}>
          <div>
            <MuiAlert elevation={6} variant="filled" severity={alertSeverity} onClose={onClose}>
              {text}
            </MuiAlert>
          </div>
        </Grow>
      </Collapse>
    </>
  );
}
