import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import useIcon from '../hooks/useIcon';
import { DocumentType } from '../queries';
import FileIcon from './FileIcon';
import { baseUrl } from '../utils/EnvironmentService';

const useStyles = makeStyles((theme) => ({
  divider: {
    color: '#fff',
  },
  fileName: {
    marginLeft: theme.spacing(3),
    color: '#fff',
  },
  downloadButton: {
    opacity: 0.6,
  },
}));

interface DocumentRowProps {
  description?: string;
  documentId: number | string;
  documentType: Partial<DocumentType>;
  originalFilename: string;
}

export default function DocumentRow({ description, documentType, originalFilename, documentId }: DocumentRowProps) {
  const { getIconByDocumentType } = useIcon();
  const classes = useStyles();

  return (
    <>
      <Grid item md={6} xs={12}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <FileIcon icon={getIconByDocumentType(documentType.code)} />
          <Typography noWrap className={classes.fileName}>
            {originalFilename}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" height="100%">
          <Typography>{description ? description : documentType.name}</Typography>
          <Tooltip title="download" aria-label="download">
            <IconButton
              className={classes.downloadButton}
              color="inherit"
              aria-label="download"
              edge="start"
              target="_blank"
              href={`${baseUrl}/document/${documentId}`}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
    </>
  );
}
