import Skeleton from '@material-ui/lab/Skeleton';
import React, { useState } from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderWidth?: number;
  placeholderHeight?: number;
}

export default function Image({ placeholderWidth = 125, placeholderHeight = 150, ...other }: ImageProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  // manually set the alt attribute to get rid of the eslint warning
  return (
    <>
      {!isLoaded && <Skeleton variant="rect" width={placeholderWidth} height={placeholderHeight} />}
      <img alt={other.alt} {...other} onLoad={() => setIsLoaded(true)} style={isLoaded ? {} : { display: 'none' }} />
    </>
  );
}
