import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { DoorModelFragment } from '../../queries';

const useStyles = makeStyles({
  label: {
    color: '#fff',
    fontWeight: 600,
  },
});

interface DoorModelDetailsProps {
  doorModel?: DoorModelFragment;
}

export default function DoorModelDetails({ doorModel }: DoorModelDetailsProps) {
  const classes = useStyles();

  return (
    <Box p={5}>
      <Grid container spacing={3}>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Door model name</Typography>
          <Typography>{doorModel?.name ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>FD rating</Typography>
          <Typography>{doorModel?.fdRating?.value ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Core supplier</Typography>
          <Typography>{doorModel?.coreSupplier ?? '-'}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
