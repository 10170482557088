import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { build, version } from '../utils/EnvironmentService';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'middle',
    justifyContent: 'center',
  },
  item: {
    opacity: 0.4,
    fontSize: 10,
    color: '#fff',
  },
});

interface VersionProps {
  className?: string;
}

export default function Version({ className }: VersionProps) {
  const classes = useStyles();
  return (
    <Box className={className}>
      <Box className={classes.container}>
        <span className={classes.item}>Version: {version}</span>&nbsp;
        <span className={classes.item}>Build: {build}</span>
      </Box>
    </Box>
  );
}
