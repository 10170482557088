import React, { useCallback } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  container: {
    borderStyle: 'dashed',
    borderColor: 'rgba(255, 255, 255, 0.5)',
    borderWidth: 0.1,
    padding: '20px',
  },
  button: {
    margin: theme.spacing(2),
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    opacity: 1,
  },
  uploadLogo: {
    height: '100px',
    marginRight: 30,
  },
  whiteText: {
    color: 'white',
  },
}));

export interface DropZoneProps {
  onFilesAdded: (files: File[]) => void;
  onFilesRejected?: (rejectedFiles: FileRejection[]) => void;
  accept: string | string[];
  acceptHelperText: string;
  title: string;
  multiple?: boolean;
  maxSizeMb?: number;
}

export default function DropZone({
  onFilesAdded,
  onFilesRejected,
  accept,
  acceptHelperText,
  title,
  multiple = false,
  maxSizeMb = 20,
}: DropZoneProps) {
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(
    (acceptedFiles) => {
      onFilesAdded(acceptedFiles);
    },
    [onFilesAdded]
  );

  const onDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      const getFileRejectionReason = (rejection: FileRejection): string => {
        let errorMessage = '';
        for (const error of rejection.errors) {
          let errorText;
          switch (error.code) {
            case 'file-invalid-type':
              errorText = `File format not accepted.`;
              break;
            case 'file-too-large':
              errorText = `Max size of ${maxSizeMb} MB exceeded.`;
              break;
            default:
              errorText = error.message;
          }
          if (errorMessage.length === 0) {
            errorMessage = errorText;
          } else {
            errorMessage = `${errorMessage}. ${errorText}`;
          }
        }
        return errorMessage;
      };
      // if a custom handler is passed as a prop the component will not display the snackbars
      if (onFilesRejected) {
        onFilesRejected(rejectedFiles);
      } else {
        for (const rejectedfile of rejectedFiles) {
          const errorReason = getFileRejectionReason(rejectedfile);
          const errorMessage = `File ${rejectedfile.file.name} can not be uploaded. ${errorReason}`;

          enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, maxSizeMb, onFilesRejected]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    minSize: 1,
    maxSize: maxSizeMb * 1048576,
    accept,
    multiple,
  });

  const classes = useStyles();
  //https://react-dropzone.js.org/
  return (
    <div {...getRootProps()} className={classes.container}>
      <input {...getInputProps()} />
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Box>
          <img src="/assets/add-file.png" alt="logo" className={classes.uploadLogo} />
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="body1" className={classes.whiteText}>
              {title}
            </Typography>
            <Typography variant="body2">
              Drop files here or
              <span onClick={open} className={classes.link}>
                {' '}
                browse
              </span>{' '}
              your machine
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Typography variant="body2">{acceptHelperText}</Typography>
      </Box>
    </div>
  );
}
