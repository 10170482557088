import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Document, useDocumentTypesQuery } from '../queries';
import DocumentRow from './DocumentRow';

const useStyles = makeStyles({
  noResults: {
    opacity: 0.6,
    fontSize: 14,
  },
});

interface FileListProps {
  documents: Document[];
  highlightMissingTypes?: string[];
  noFilesMessage?: string;
}

export default function DocumentList({ documents, highlightMissingTypes, noFilesMessage }: FileListProps) {
  const classes = useStyles();
  const [missingDocumentationMessages, setMissingDocumentationMessage] = useState<string>();
  const { data: documentTypes } = useDocumentTypesQuery();

  useEffect(() => {
    if (documents) {
      let errorMessage = '';
      if (highlightMissingTypes && highlightMissingTypes.length > 0) {
        for (const requiredType of highlightMissingTypes) {
          const found = !!documents.find((d) => d.documentType.code === requiredType);
          if (!found) {
            if (errorMessage.length === 0) {
              errorMessage = `Missing documentation: `;
            }
            const requiredTypeName =
              documentTypes?.documentTypes.find((dt) => dt.code === requiredType)?.name || requiredType;
            errorMessage += `${requiredTypeName}, `;
          }
        }

        if (errorMessage.length > 0) {
          errorMessage = errorMessage.slice(0, -2);
          errorMessage += '.';
        }
      }

      setMissingDocumentationMessage(errorMessage);
    }
  }, [documents, documentTypes, highlightMissingTypes]);

  return (
    <>
      <Grid container spacing={3}>
        {documents && documents.length === 0 && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography noWrap className={classes.noResults}>
                {noFilesMessage || 'There are no attachments to display'}
              </Typography>
            </Box>
          </Grid>
        )}
        {documents.map((d, i) => {
          return (
            <DocumentRow
              key={i}
              documentType={d.documentType}
              originalFilename={d.originalFilename}
              description={d.description ?? undefined}
              documentId={d.id}
            />
          );
        })}

        {highlightMissingTypes && missingDocumentationMessages && (
          <Box mb={3} mt={3}>
            <Typography color="error">{missingDocumentationMessages}</Typography>
          </Box>
        )}
      </Grid>
    </>
  );
}
