import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { SiteQuery } from '../../../queries';
import DetailBox from './DetailBox';

interface SiteDetailsProps {
  loading: boolean;
  siteQuery?: SiteQuery;
}

export default function SiteDetails({ loading, siteQuery }: SiteDetailsProps) {
  const siteDetailsRows = [
    { label: 'Site reference', value: siteQuery?.site.reference },
    { label: 'Site name', value: siteQuery?.site.name },
    { label: 'Site ID', value: siteQuery?.site.id },
    { label: 'Site owner', value: siteQuery?.site.ownedBy?.name },
    { label: 'Areas / floors', value: siteQuery?.site.areas?.toString() },
  ];

  const addressRows = [
    { label: 'Address line 1', value: siteQuery?.site.addressOne },
    { label: 'Address line 2', value: siteQuery?.site.addressTwo },
    { label: 'Town/City', value: siteQuery?.site.city },
    { label: 'Postcode', value: siteQuery?.site.postcode },
  ];

  const contactRows = [
    { label: 'Contact name', value: siteQuery?.site.siteContact?.name },
    { label: 'Landline number', value: siteQuery?.site.siteContact?.phoneNumber },
    { label: 'Mobile number', value: siteQuery?.site.siteContact?.mobileNumber },
    { label: 'Email', value: siteQuery?.site.siteContact?.emailAddress },
  ];

  return (
    <Box py={3}>
      <Grid container spacing={3}>
        {loading && (
          <Grid item container justify="center" alignItems="center" xs={12}>
            <CircularProgress />
          </Grid>
        )}
        {!loading && (
          <>
            <Grid item xs={4}>
              <DetailBox title="Site details" rows={siteDetailsRows} />
            </Grid>
            <Grid item xs={4}>
              <DetailBox title="Address" rows={addressRows} />
            </Grid>
            <Grid item xs={4}>
              <DetailBox title="Site contact" rows={contactRows} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
