import { Box, Breadcrumbs, Button, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../components/Layout';
import ProtectedSection from '../components/ProtectedSection';
import SitesExtendedFilter from '../components/site/SitesExtendedFilter';
import SiteAttachments from '../components/site/view/SiteAttachments';
import SiteDetails from '../components/site/view/SiteDetails';
import SiteDoors from '../components/site/view/SiteDoors';
import Roles from '../constants/Roles';
import * as Routes from '../constants/Routes';
import DoorStatus from '../constants/Status';
import TransferTypes from '../constants/TransferTypes';
import useAuth from '../hooks/useAuth';
import useUrlSearchParam from '../hooks/useUrlSearchParam';
import { Door, useSiteQuery } from '../queries';
import useAssignDoorsToContractorStore from '../store/AssingDoorsToContractorStore';
import useRetireDoorsStore from '../store/RetireDoorsStore';
import useTransferDoorsStore from '../store/TransferDoorsStore';
import MaterialLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import assert from 'assert';

interface SiteParams {
  id: string;
}

export default function Site() {
  const { id } = useParams<SiteParams>();
  const { value: tabName, setValue: setTabName } = useUrlSearchParam('tab');

  const tabIndex = useMemo(() => {
    if (tabName === 'details') return 0;
    else if (tabName === 'attachments') return 1;
    else if (tabName === 'doors') return 2;
    else return 0;
  }, [tabName]);

  const [selectedDoors, setSelectedDoors] = useState<Partial<Door>[]>([]);
  const [enableTransfer, setEnableTransfer] = useState(false);
  const [enableRetirement, setEnableRetirement] = useState(false);
  const [enableRepair, setEnableRepair] = useState(false);
  const [enableRecordInstallation, setEnableRecordInstallation] = useState(false);
  const [enableContractorAssignments, setEnableContractorAssignments] = useState(false);
  const history = useHistory();
  const { hasRole, hasAnyRole, loggedInUser } = useAuth();
  const { setDoorIds, setTransferType, setCompany } = useTransferDoorsStore();

  const setAssignToContractorDoorIds = useAssignDoorsToContractorStore((state) => state.setDoorIds);
  const setRetirementDoorIds = useRetireDoorsStore((state) => state.setDoorIds);

  const { loading, data } = useSiteQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const userCompanyName = loggedInUser?.companyName;
  const siteName = hasRole(Roles.SuperUser) ? data?.site.name : userCompanyName;

  useEffect(() => {
    const nbDoorsSelected = selectedDoors.length;
    const nbReadyDoorsSelected = selectedDoors.filter(
      (d) => d.status?.code === DoorStatus.InstallerInstallationComplete
    ).length;

    setEnableTransfer(nbReadyDoorsSelected > 0 && nbReadyDoorsSelected === nbDoorsSelected);
    setEnableRecordInstallation(nbDoorsSelected === 1 && selectedDoors[0].status?.code === DoorStatus.InstallerReady);
    const assignContractorReadyDoorsSelected = selectedDoors.filter((d) =>
      [
        DoorStatus.FacilityManagementReady,
        DoorStatus.NoInspectionSet,
        DoorStatus.InspectionDue,
        DoorStatus.InspectionOverdue,
        DoorStatus.InspectionRequiresSignOff,
        DoorStatus.InspectionFailedSignOff,
        DoorStatus.Inspecting,
        DoorStatus.Passed,
        DoorStatus.RequiresRepair,
        DoorStatus.InstallerReady,
        DoorStatus.InstallerFailedSignOff,
      ].includes(d.status?.code as DoorStatus)
    ).length;

    setEnableContractorAssignments(
      assignContractorReadyDoorsSelected > 0 && assignContractorReadyDoorsSelected === nbDoorsSelected
    );

    const retirementReadyDoorsSelected = selectedDoors.filter((d) => d.status?.code !== DoorStatus.Retired).length;

    setEnableRetirement(retirementReadyDoorsSelected > 0 && retirementReadyDoorsSelected === nbDoorsSelected);

    setEnableRepair(selectedDoors.length === 1 && selectedDoors[0].status?.code === DoorStatus.Passed);
  }, [selectedDoors]);

  const onTabChange = (_: any, value: number) => {
    setSelectedDoors([]);
    setTabName(
      ({
        0: 'details',
        1: 'attachments',
        2: 'doors',
      } as Record<number, string>)[value]
    );
  };

  const onEdit = () => {
    history.push(`${Routes.Sites}/${id}/edit`);
  };

  const getBreadcrumb = () => {
    return (
      <>
        {(hasRole(Roles.InstallerAdmin) || hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager])) && (
          <Breadcrumbs aria-label="breadcrumb">
            <MaterialLink color="inherit" component={Link} to={Routes.MySites}>
              <Typography variant="body2">My site list</Typography>
            </MaterialLink>
            <Typography variant="body2">{data?.site.name}</Typography>
          </Breadcrumbs>
        )}
      </>
    );
  };

  const getSiteName = () => {
    return <Typography variant="h5">{loading ? <Skeleton width="150px" /> : siteName}</Typography>;
  };

  const onTransferDoorsClicked = () => {
    const selectedDoorIds = selectedDoors.map((d) => d.id).filter((d): d is string => !!d);
    setDoorIds(selectedDoorIds);
    setTransferType(TransferTypes.InstallerToFacilityManagement);
    setCompany(data?.site.ownedBy ?? undefined);
    history.push(`${Routes.Sites}/${id}/transfer`);
  };

  const onAssignToContractorClicked = () => {
    const selectedDoorIds = selectedDoors.map((d) => d.id).filter((d): d is string => !!d);
    setAssignToContractorDoorIds(selectedDoorIds);
    history.push(`${Routes.Sites}/${id}/assign-doors-to-contractor`, { siteName: data?.site.name });
  };

  const onRetireDoorsClicked = () => {
    const selectedDoorIds = selectedDoors.map((d) => d.id).filter((d): d is string => !!d);
    setRetirementDoorIds(selectedDoorIds);
    history.push(`${Routes.Sites}/${id}/retire-doors`);
  };

  const onRepairDoorClicked = () => {
    const selectedDoorIds = selectedDoors.map((d) => d.id).filter((d): d is string => !!d);
    assert(selectedDoorIds.length === 1, 'Only one door at a time can be reported for repair');
    history.push(`${Routes.Repair}/${selectedDoorIds[0]}`);
  };

  const setDoors = useCallback((newDoors: Partial<Door>[]) => {
    setSelectedDoors(newDoors);
  }, []);

  const onUploadDoors = () => {
    history.push(`${Routes.Sites}/${id}/import-doors`);
  };

  const onRecordInstallationClicked = () => {
    if (selectedDoors && selectedDoors.length > 0) {
      const doorId = selectedDoors[0].id;
      history.push(`${Routes.Sites}/${data?.site.id}/report-installation/${doorId}`, { siteName: data?.site.name });
    }
  };

  return (
    <Layout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          {getSiteName()}
          {loading && <Skeleton width="175px" />}
          {!loading && getBreadcrumb()}
        </Box>
        <Box minWidth={96}>
          {tabIndex < 2 && (
            <Button variant="contained" color="primary" fullWidth onClick={onEdit}>
              Edit
            </Button>
          )}
          {tabIndex === 2 && (
            <>
              <ProtectedSection roles={[Roles.FacilityManagerAdmin, Roles.FacilityManager]}>
                <Box display="inline" ml={2}>
                  <Button variant="contained" color="secondary" onClick={onUploadDoors}>
                    Upload doors
                  </Button>
                </Box>
                <Box display="inline" ml={2}>
                  <Button variant="contained" color="secondary" onClick={onRepairDoorClicked} disabled={!enableRepair}>
                    Repair door
                  </Button>
                </Box>
                <Box display="inline" ml={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onRetireDoorsClicked}
                    disabled={!enableRetirement}
                  >
                    Retire door
                  </Button>
                </Box>
              </ProtectedSection>
              <ProtectedSection
                roles={[
                  Roles.FacilityManagerAdmin,
                  Roles.FacilityManager,
                  Roles.InspectorAdmin,
                  Roles.ServiceEngineerAdmin,
                  Roles.InstallerAdmin,
                ]}
              >
                {loggedInUser?.options.canReportInstallations && (
                  <ProtectedSection roles={[Roles.InstallerAdmin]}>
                    <Box display="inline" ml={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={onRecordInstallationClicked}
                        disabled={!enableRecordInstallation}
                      >
                        Record installation
                      </Button>
                    </Box>
                  </ProtectedSection>
                )}
                <Box display="inline" ml={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onAssignToContractorClicked}
                    disabled={!enableContractorAssignments}
                  >
                    Assign to contractor
                  </Button>
                </Box>
              </ProtectedSection>
              <ProtectedSection roles={[Roles.InstallerAdmin]}>
                <Box display="inline" ml={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onTransferDoorsClicked}
                    disabled={!enableTransfer}
                  >
                    Transfer doors
                  </Button>
                </Box>
              </ProtectedSection>
            </>
          )}
        </Box>
      </Box>
      <Box mt={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Tabs value={tabIndex} onChange={onTabChange} aria-label="Details or users" indicatorColor="primary">
            <Tab label="Details" />
            <Tab label="Attachments" />
            <Tab label="Doors" />
          </Tabs>
          <ProtectedSection roles={[Roles.FacilityManagerAdmin, Roles.FacilityManager]}>
            {tabIndex === 2 && <SitesExtendedFilter siteId={id!} />}
          </ProtectedSection>
        </Box>
        <Divider />
        {
          ({
            0: <SiteDetails loading={loading} siteQuery={data} />,
            1: <SiteAttachments loading={loading} siteQuery={data} />,
            2: <SiteDoors siteId={id!} selectedDoors={selectedDoors} setSelectedDoors={setDoors} />,
          } as Record<number, React.ReactNode>)[tabIndex]
        }
      </Box>
    </Layout>
  );
}
