import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MaterialLink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { GraphQLError } from 'graphql';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import SiteDetailsEdit from '../components/site/edit/SiteDetailsEdit';
import SiteContactEdit from '../components/site/edit/SiteContactEdit';
import * as Routes from '../constants/Routes';
import useDialog from '../hooks/useDialog';
import { useSiteQuery, useEditSiteMutation, SiteQuery } from '../queries';
import { useSnackbar } from 'notistack';
import LoadingButton from '../components/LoadingButton';
import SiteDrawingsEdit from '../components/site/edit/SiteDrawingsEdit';
import { SiteDocument } from './SiteAdd';
import { Drawing } from '../constants/DocumentTypes';

export interface SiteEditParams {
  //SiteDetails
  id: string;
}
export interface SiteOwnerFormData {
  id?: string;
  name?: string;
}
export interface SiteEditFormData {
  //SiteDetails
  id: string;
  name: string;
  reference?: string | null;
  owner?: SiteOwnerFormData | null;
  areas?: number | null;
  addressOne?: string | null;
  addressTwo?: string | null;
  city?: string | null;
  postcode?: string | null;
  //SiteContact
  contactId?: string | null;
  contactName?: string;
  contactPhoneNumber?: string | null;
  contactMobileNumber?: string | null;
  contactEmailAddress?: string | null;

  drawings?: SiteDocument[] | null;
}

const translateSite = (model: SiteQuery): SiteEditFormData => {
  const newSite: SiteEditFormData = {
    id: model.site.id,
    name: model.site.name,
    reference: model.site.reference,

    owner: {
      id: model.site.ownedBy?.id,
      name: model.site.ownedBy?.name,
    },

    areas: model.site.areas,
    addressOne: model.site.addressOne,
    addressTwo: model.site.addressTwo,
    city: model.site.city,
    postcode: model.site.postcode,

    //SiteContact
    contactId: model.site.siteContact?.id,
    contactName: model.site.siteContact?.name,
    contactPhoneNumber: model.site.siteContact?.phoneNumber,
    contactMobileNumber: model.site.siteContact?.mobileNumber,
    contactEmailAddress: model.site.siteContact?.emailAddress,
  };

  const existingDrawings = model.site.documents?.filter((d) => d.documentType.code === Drawing);
  if (existingDrawings && existingDrawings.length > 0) {
    const translatedDrawings: SiteDocument[] = existingDrawings.map((p) => ({
      id: p.id,
      description: p.description,
      file: {
        name: p.originalFilename,
      },
    }));

    newSite.drawings = translatedDrawings;
  }

  return newSite;
};

export default function SiteEdit() {
  const [site, setSite] = useState<SiteEditFormData | undefined>();
  const [tabIndex, setTabIndex] = useState(0);
  const [editSite, { loading: savingSite }] = useEditSiteMutation();
  const { id } = useParams<SiteEditParams>();
  const { enqueueSnackbar } = useSnackbar();
  const [drawings, setDrawings] = useState<SiteDocument[]>([]);

  const { data: siteModel } = useSiteQuery({
    variables: {
      id: String(id),
    },
  });

  const history = useHistory();
  const { showDialog } = useDialog();

  const form = useForm<SiteEditFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      //SiteDetails
      id: siteModel?.site.id,
      name: siteModel?.site.name,
      reference: siteModel?.site.reference,
      owner: {
        id: siteModel?.site.ownedBy?.id,
        name: siteModel?.site.ownedBy?.name,
      },
      areas: siteModel?.site.areas,
      addressOne: siteModel?.site.addressOne,
      addressTwo: siteModel?.site.addressTwo,
      city: siteModel?.site.city,
      postcode: siteModel?.site.postcode,
      //SiteContact
      contactId: siteModel?.site.siteContact?.id,
      contactName: siteModel?.site.siteContact?.name,
      contactPhoneNumber: siteModel?.site.siteContact?.phoneNumber,
      contactMobileNumber: siteModel?.site.siteContact?.mobileNumber,
      contactEmailAddress: siteModel?.site.siteContact?.emailAddress,
    },
  });

  const { handleSubmit, getValues, reset } = form;

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        history.push(`${Routes.Sites}/${id}`);
      },
    });
  };

  useEffect(() => {
    if (siteModel) {
      const newSite = translateSite(siteModel);
      setDrawings(newSite.drawings ?? []);
      setSite(newSite);
      reset(newSite);
    }
  }, [siteModel, reset]);

  const onTabChange = (_: any, value: number) => {
    const values = getValues() as SiteEditFormData;
    // save the form values

    setSite((prev) => ({ ...prev, ...values }));

    reset(site);

    setTabIndex(value);
  };

  const saveChanges = async (values: SiteEditFormData) => {
    values.name = values.name?.trim() ?? site?.name?.trim();
    let siteInput: any = { ...site, ...values };
    siteInput.contact = {
      id: siteInput?.contactId,
      name: siteInput?.contactName!,
      phoneNumber: siteInput?.contactPhoneNumber,
      mobileNumber: siteInput?.contactMobileNumber,
      emailAddress: siteInput?.contactEmailAddress,
    };

    if ('contactId' in siteInput) {
      delete siteInput['contactId'];
    }
    if ('contactName' in siteInput) {
      delete siteInput['contactName'];
    }
    if ('contactPhoneNumber' in siteInput) {
      delete siteInput['contactPhoneNumber'];
    }
    if ('contactMobileNumber' in siteInput) {
      delete siteInput['contactMobileNumber'];
    }
    if ('contactEmailAddress' in siteInput) {
      delete siteInput['contactEmailAddress'];
    }
    siteInput.areas = Number(siteInput.areas);

    siteInput.drawings = drawings?.map((d) => ({
      id: d.id,
      description: d.description,
      file: d.id ? null : d.file, // send the file object for new files only or they will not match the FileUpload type
    }));

    try {
      await editSite({
        variables: {
          siteInput: siteInput,
        },
      });
      enqueueSnackbar('Site added successfully.', { variant: 'success' });
      history.push(`${Routes.Sites}/${id}`);
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    }
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">Edit site</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.MySites}>
                  <Typography variant="body2">My site list</Typography>
                </MaterialLink>
                <MaterialLink color="inherit" href={`${Routes.Sites}/${id}`}>
                  <Typography variant="body2">{siteModel?.site.name}</Typography>
                </MaterialLink>
                <Typography variant="body2">Edit site</Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>

          <Box mt={1}>
            <Paper>
              <Tabs value={tabIndex} onChange={onTabChange} indicatorColor="primary" scrollButtons="auto">
                <Tab label="Details" />
                <Tab label="Site contact" />
                <Tab label="Site drawings" />
              </Tabs>
              <Divider />
              <Box p={5}>
                <FormProvider {...form}>
                  <form id="edit-site" onSubmit={handleSubmit(saveChanges)}>
                    {tabIndex === 0 && (
                      <SiteDetailsEdit
                        siteOwnedByName={site?.owner?.name ? site?.owner?.name : siteModel?.site.ownedBy?.name}
                        enableOwnerEdition={false}
                      />
                    )}
                    {tabIndex === 1 && <SiteContactEdit />}
                    {tabIndex === 2 && <SiteDrawingsEdit drawings={drawings} setDrawings={setDrawings} />}
                  </form>
                </FormProvider>
              </Box>
              <Box display="flex" justifyContent="flex-end" p={5} pt={0}>
                <LoadingButton
                  loading={savingSite}
                  disabled={savingSite}
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="edit-site"
                >
                  Save
                </LoadingButton>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
