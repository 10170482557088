import { Photo, InstallationGuide, FireCertificate, Warranty, CSV } from '../constants/DocumentTypes';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ImportContacts from '@material-ui/icons/ImportContacts';
import CSVFileIcon from '@material-ui/icons/InsertDriveFile';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import PanoramaIcon from '@material-ui/icons/Panorama';

export default function useIcon() {
  const getIconByDocumentType = (documentType: string | undefined): any => {
    switch (documentType) {
      case Photo:
        return PanoramaIcon;
      case InstallationGuide:
        return ImportContacts;
      case FireCertificate:
      case Warranty:
        return DescriptionRoundedIcon;
      case CSV:
        return CSVFileIcon;
      default:
        return PictureAsPdf;
    }
  };

  return { getIconByDocumentType };
}
