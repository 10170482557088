import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

interface TermsAndConditionsContentProps {
  paddingTop?: number;
}

export default function TermsAndConditionsContent({ paddingTop }: TermsAndConditionsContentProps) {
  return (
    <Container maxWidth="md">
      <Box paddingTop={paddingTop} paddingBottom={5}>
        <Typography gutterBottom paragraph variant="h4">
          License Terms and conditions of use
        </Typography>
        <Typography variant="h5" gutterBottom>
          Door Data Systems Ltd. Door Data Systems App
        </Typography>

        <Typography gutterBottom paragraph variant="h6">
          Introduction
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          Door Data Systems Ltd distributes and manages the ‘Door Data Systems’ software and mobile application.The
          Terms and conditions are dated 01 July 2020 and form the licence of agreement.
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          This Licence Agreement (licence) is a legal arrangement between you (licensee or you) and Door Data Systems
          Ltd (licensor or we) for the software product (software) which includes computer software, on-line
          documentation(documentation), supplied data, advertising and written materials.
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          Door Data Systems registered office is, 203 Pointon Way, Stoney Bridge Cross, Hampton Lovett, Worcestershire,
          WR9 0LW.
        </Typography>
        <Typography gutterBottom paragraph variant="subtitle1">
          By downloading software from the licensor the licensee agrees to the terms of this licence which will bind you
          and your employees and users invited to the terms of the licence. Should you or an employee or any persons
          disagree with the terms of the license they should not use the software and remove themselves from being a
          registered user.
        </Typography>

        <Typography gutterBottom paragraph variant="h6">
          A. Scope of licence
        </Typography>
        <Typography gutterBottom variant="h6">
          A.1
          <Box ml={2} display="inline">
            <Typography display="inline" variant="subtitle2">
              In consideration of payments for the use inline with the agreed schedule of rates as quoted or annual
              fee,you agree to abide by the terms of this licence, the licensor hereby grants you a non-exclusive
              licence for the software to be used by you and persons invited subject to any limitations of users numbers
              and the invitees accepting the terms. The license is non-transferable and those using the system are
              considered to be the licensee.
            </Typography>
          </Box>
        </Typography>
        <Typography gutterBottom variant="h6">
          A.2
          <Box ml={2} display="inline">
            <Typography display="inline" variant="subtitle2">
              You are licensed and granted permission to:
            </Typography>
          </Box>
        </Typography>
        <List>
          • Install via download and use the software for internal business use onlyas the software is intended.
        </List>
        <List>
          • Install software on multiple devices, with limited users licenses as peragreed under a multi-user pricing
          structure, or unlimited for user licences on the basis of click and pay ( see user pricing structure).
        </List>
        <List>• Receive and upload supporting documentation as issued by the licensor.</List>
        <List>• Use the software for the purpose it was intended.</List>
        <List>• Extract information that is held on the system other than software operating systems.</List>
        <Typography gutterBottom paragraph></Typography>

        <Typography gutterBottom paragraph variant="h6">
          B. Licensee undertakings and agreement
        </Typography>
        <Typography gutterBottom variant="h6">
          B.1
          <Box ml={2} display="inline">
            <Typography display="inline" variant="subtitle2">
              Except as set out in this licence or as permitted by any local law, youthelicensee undertake:
            </Typography>
          </Box>
        </Typography>
        <List>
          • Not to rent, lease, sub-lease, translate, vary, adapt, merge, loan or make changes in the way of modifying
          the software or any documentation.
        </List>
        <List>• Not to copy or make copy of the software.</List>
        <List>
          • Not to copy the documentation except where being copied for the purpose of back up or in the normal use of
          the software or for operational security purposes.
        </List>
        <List>
          • Not to make changes, alterations or modifications to the software or any part of the software to be combined
          with or incorporated with any other programmes.
        </List>
        <List>
          • Not to decompile, disassemble assemble, reverse engineer or create derivative works based on the software in
          whole or in-part. Any action that maybe contrary must be by written consent of the licensor and will only be
          granted if the actions cannot be avoided(under the Copyright and Design and Patents Act 1988, section 296A)
          for the purpose of inter-operation of software with another software programme and that information obtained
          is for the purpose of the inter-operation and must not be disclosed for any other purpose. Or be used to
          develop or create software that is substantially similar to the software.
        </List>
        <List>
          To supervise users (invited) under this license and that the invited users are aware of the conditions of use.
        </List>
        <List>Upload and replace system with upgraded versions as issued by the licensor.</List>
        <List>
          To allow access and use by the licensor of allinformation and records added and / or stored on the software by
          the licensee(s), the invited user(s) or any other persons granted user license, with the understanding that
          the information and records produced and stored on the software shall be made available to those of
          responsibility / accountability and or persons who require the records in line with their professional duties.
        </List>
        <List>
          Not to use or attempt to use NFC or RFid tagsof any description other than those issued by the licensor in
          conjunction with the software.
        </List>

        <Typography gutterBottom variant="h6">
          B.2
        </Typography>
        <List>
          • You will allow at any time the licensor and / or the licensor representativesaccess to property to complete
          inspections of the use or the documentation relating to the use of the softwareand apparatus that the software
          has been used.
        </List>
        <List>
          • You agree that the information and records addedby the licensee, the licensees invitees or representatives
          that isstored on or stored via the software will be made available to others and the access rights of
          information registeredandrecorded,once added to the system, arepassed to Door Data Systems Ltd.{' '}
        </List>
        <Typography gutterBottom paragraph></Typography>

        <Typography gutterBottom paragraph variant="h6">
          C. Intellectual Property Rights
        </Typography>
        <Typography gutterBottom variant="h6">
          C.1
        </Typography>
        <List>
          • Intellectual property rights in the software and the documentation both original and added by licensees and
          invited users anywhere in the world belong to the licensor. The software and its use are licensed and not sold
          to you the licensee and you the licensee and the invited users have no rights in, or to, the software or
          documentation other than the to use and access them in accordance with the licence.
        </List>
        <Typography gutterBottom paragraph></Typography>

        <Typography gutterBottom paragraph variant="h6">
          D. Warranty of product
        </Typography>
        <Typography gutterBottom variant="h6">
          D.1
        </Typography>
        <List>
          • The Licensor warrants that the software has been checked for viruses and will continue to check in-line with
          good practice industry.
        </List>
        <List>
          • The licensee acknowledges that the software is not bespoke to themselves and therefore the use and functions
          of the software are as laid down by the licensor.
        </List>
        <List>
          • You the licensee accepts that the software my not be free or errors and bugs and agree that small failings
          do not constitute to a failing of the software..
        </List>
        <List>
          • The Licensee must notify the licensor of any defects found to be with the system in writing via email. The
          licensor cannot be responsible and will not act should it be found that the software system or documentation
          has been tampered with altered.
        </List>

        <Typography gutterBottom paragraph variant="h6">
          E. Liability
        </Typography>
        <Typography gutterBottom variant="h6">
          E.1
          <Box ml={2} display="inline">
            <Typography display="inline" variant="subtitle2">
              The condition sets out the financial liability of all parties, including liability for any acts or
              omissions of those representing, working for, employed, agents or sub-contract for either party and are
              inrespect of:
            </Typography>
          </Box>
        </Typography>
        <List>• Use of the software or documentation in the way of re-sale.</List>
        <List>
          • Any breach of the licenseand / or the terms and conditions with regards to the use of the system in full or
          in part.
        </List>
        <List>
          • Any statement, omission, negligence, representation or tortious act in connection with the license.
        </List>
        <Typography gutterBottom variant="h6">
          E.2
          <Box ml={2} display="inline">
            <Typography display="inline" gutterBottom variant="subtitle2">
              You the licensee acknowledges that the software is not bespoke to you and / or your organisation and has
              not been written or developed to any individual requirements. It is therefore your responsibility to be
              sure that the software is suitable and the you, your users (invitees), representatives understand the
              terms of use.
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              You agree and acknowledge that the software may not be free of bugs or errors and agree that minor errors
              do not constitute a breach of license.
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              You agree to notify the licensor in writing of any defects in the software, where upon receiptof written
              notification of any fault, the licensor will repair or replace the software, subject to the software not
              being tampered, abused or manipulated. The licensee agrees to support the licensor with the repairs and
              investigation.
            </Typography>
          </Box>
        </Typography>
        <Typography gutterBottom variant="h6">
          E.3
          <Box ml={2} display="inline">
            <Typography display="inline" gutterBottom variant="subtitle2">
              The licensor can not accept and does not accept any financial liabilityor loss relating to the use,
              storageand sharing of information related to information and /or documentation placed on or within the
              software.
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              The licensor does not accept any liability for the information, documentation and data stored and made
              available on the software for others. It remains the responsibility of the licensee, users, invitees to
              ensure that the information being retrieved, downloaded, exported, referred is correct.
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              The licensor does not accept liability for any losses, financial, reputation, interruption, income from
              use of the information held and added to the Door Data System by others.
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              The licensor does not accept liability for loss of information, loss of opportunity, goodwill or
              reputation.
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              The licensor does not accept liability for loss or damage or corruption of data or any indirect or
              consequential loss or damage of any kind howsoever arising and whether caused by negligence, breach of
              contract, tort or otherwise.
            </Typography>
          </Box>
        </Typography>

        <Typography gutterBottom paragraph variant="h6">
          F. Termination
        </Typography>
        <Typography gutterBottom variant="h6">
          F.1
          <Box ml={2} display="inline">
            <Typography display="inline" variant="subtitle2">
              Termination of use may be made by the licensor with immediate effect following written notification if
              you:
            </Typography>
          </Box>
        </Typography>
        <List>• You commit a breach of the terms and conditions and or licence.</List>
        <List>
          • You fail to pay for the services used or fail to pay the annualuserfee in line with the payments terms
          issued and agreed.
        </List>
        <List>
          • Licenses are issued for a period of 24 months, with costs being fixed for the same period, after which a new
          license is issued or use of the system is terminated.
        </List>
        <Typography gutterBottom></Typography>
        <Typography gutterBottom variant="h6">
          F.2
          <Box ml={2} display="inline">
            <Typography display="inline" gutterBottom variant="subtitle2">
              The account maybe at the discretion of the licensor place the account in temporary suspension. This will
              allow you the licensor access to view records but not use the system to record, add data, export or
              download documentation.
            </Typography>
            <Typography variant="subtitle2">Temporary Suspension will follow notification of:</Typography>
          </Box>
        </Typography>
        <List>• Overdue payment prior to termination notification.</List>
        <List>• Oversubscribed user numbers (effecting last registered invitees) under the license agreement.</List>
        <Typography gutterBottom paragraph variant="subtitle2">
          Termination and Suspensions can be re-instated on payment of overdue monies or at the discretion of Door Data
          Systems Ltd. Breach of the terms and conditions and or license may lead to permanent terminationat the
          discretion of Door Data Systems Ltd.
        </Typography>

        <Typography gutterBottom paragraph variant="h6">
          G. Transfer of Rights and Obligations
        </Typography>
        <Typography gutterBottom variant="h6">
          G.1
        </Typography>
        <List>• The license is binding to both parties you and us, and our respective successors and assigns.</List>
        <List>
          • You may not transfer, assign, charge or otherwise dispose of this license, or any rights or obligations
          arising to it, without prior written consent.
        </List>
        <List>
          • We may transfer, assign, charge, sub-contract or otherwise dispose of this license, or any of our rights or
          obligations arising under it, at any time during the term of the license.
        </List>
        <Typography gutterBottom paragraph></Typography>

        <Typography gutterBottom paragraph variant="h6">
          H. Waiver
        </Typography>
        <Typography gutterBottom variant="h6">
          H.1
        </Typography>
        <List>
          • At any time during the term of the licence, we fail to insist up strict performance of any of your
          obligations under the terms and conditions or licence , or if we fail to exercise any of the rights or
          remedies to which we are entitled under the issued terms and conditions or licence, this will shall not
          constitute a waiver of such rights or remedies and shall not relieve you from compliance with such
          obligations.
        </List>
        <List>• A waiver by us of any default shall not constitute a waiver of any subsequent defaults.</List>
        <List>
          • No waiver by us of any of these terms and conditions shall be effective unless it is expressly stated to be
          a waiver and is communicated to you in writing.
        </List>
        <Typography gutterBottom paragraph></Typography>

        <Typography gutterBottom paragraph variant="h6">
          I. Exceptional events outside our control
        </Typography>
        <Typography gutterBottom variant="h6">
          I.1
        </Typography>
        <List>
          • We will not be liable or responsible for any failure to perform, or delays in performance of, any of our
          obligations under this license that is caused by events outside our reasonable control. (force Majeure Event).
        </List>
        <Typography gutterBottom paragraph></Typography>

        <Typography gutterBottom paragraph variant="h6">
          J. The agreement (entire)
        </Typography>
        <Typography gutterBottom variant="h6">
          J.1
        </Typography>
        <List>
          • This licence and documents referred in it constitute the agreement between us and supersedes any previous
          agreements or terms and conditions issued with reference to the licensing of Door Data Systems software,
          documentation and data held.
        </List>
      </Box>
    </Container>
  );
}
