enum DoorStatus {
  ManufacturerIncomplete = 'ManufacturerIncomplete',
  ManufacturerReady = 'ManufacturerReady',
  InstallerReady = 'InstallerReady',
  InstallerIncomplete = 'InstallerIncomplete',
  InstallerInstalling = 'InstallerInstalling',
  InstallerRequiresSignOff = 'InstallerRequiresSignOff',
  InstallerFailedSignOff = 'InstallerFailedSignOff',
  InstallerInstallationComplete = 'InstallerInstallationComplete',
  FacilityManagementReady = 'FacilityManagementReady',
  NoInspectionSet = 'NoInspectionSet',
  Passed = 'Passed',
  InspectionDue = 'InspectionDue',
  InspectionOverdue = 'InspectionOverdue',
  /**
   * @deprecated The status will be removed and should not be assigned to doors in any new process
   */
  InspectionFailedSignOff = 'InspectionFailedSignOff',
  Inspecting = 'Inspecting',
  InspectionRequiresSignOff = 'InspectionRequiresSignOff',
  InspectorIncomplete = 'InspectorIncomplete',
  Retired = 'Retired',
  RequiresRepair = 'RequiresRepair',
}
export default DoorStatus;
