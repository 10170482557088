import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import Roles from '../../constants/Roles';
import DoorStatus from '../../constants/Status';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  stage: {
    minWidth: '120px',
    fontSize: '10px',
    lineHeight: '24px',
    color: '#ffffff',
    whiteSpace: 'nowrap',
    padding: '0 5px',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
  grey: {
    backgroundColor: '#696A81',
  },
  yellow: {
    backgroundColor: '#C68D50',
  },
  blue: {
    backgroundColor: '#408be9',
  },
}));

interface DoorStageProps {
  doorStageCode: string;
  transferredToInstallationId?: string;
}

export default function DoorStage({ doorStageCode, transferredToInstallationId }: DoorStageProps) {
  const { hasRole, hasAnyRole, loggedInUser } = useAuth();
  const classes = useStyles();

  let className: string | undefined = undefined;
  let statusName: string;

  const showManufacturerStatuses = !hasRole(Roles.InstallerAdmin) && hasRole(Roles.ManufacturerAdmin);
  const showServiceEngineerOnlyStatuses =
    hasRole(Roles.ServiceEngineerAdmin) &&
    ![
      Roles.InstallerAdmin,
      Roles.ManufacturerAdmin,
      Roles.FacilityManager,
      Roles.FacilityManagerAdmin,
      Roles.InspectorAdmin,
    ].some((role) => hasRole(role));

  if (transferredToInstallationId && transferredToInstallationId.toString() !== loggedInUser?.companyId?.toString()) {
    className = classes.grey;
    statusName = 'Transferred';
  } else if (showManufacturerStatuses) {
    switch (doorStageCode) {
      case DoorStatus.ManufacturerReady:
        className = classes.primary;
        statusName = 'Ready';
        break;
      case DoorStatus.ManufacturerIncomplete:
        className = classes.red;
        statusName = 'Incomplete';
        break;
      default:
        className = classes.grey;
        statusName = 'Transferred';
        break;
    }
  } else if (showServiceEngineerOnlyStatuses) {
    className = classes.primary;
    statusName = 'Requires Repair';
  } else {
    // Show Transferred status if user has no permissions to see exact status
    className = classes.grey;
    statusName = 'Transferred';

    switch (doorStageCode) {
      case DoorStatus.ManufacturerReady:
      case DoorStatus.InstallerReady:
        className = classes.primary;
        statusName = 'Ready';
        break;
      case DoorStatus.ManufacturerIncomplete:
      case DoorStatus.InstallerIncomplete:
        className = classes.red;
        statusName = 'Incomplete';
        break;
      case DoorStatus.InstallerInstalling:
        className = classes.yellow;
        statusName = 'Installing';
        break;
      case DoorStatus.InstallerRequiresSignOff:
        className = classes.primary;
        statusName = 'Req sign off';
        break;
      case DoorStatus.InstallerInstallationComplete:
        className = classes.primary;
        statusName = 'Install complete';
        break;
      case DoorStatus.InstallerFailedSignOff:
        className = classes.red;
        statusName = 'Install failed';
        break;
      case DoorStatus.FacilityManagementReady:
      case DoorStatus.NoInspectionSet:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager])) {
          className = classes.red;
          statusName = 'No inspection set';
        }
        break;
      case DoorStatus.Passed:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager, Roles.InspectorAdmin])) {
          className = classes.primary;
          statusName = 'Passed';
        }
        break;
      case DoorStatus.InspectionDue:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager, Roles.InspectorAdmin])) {
          className = classes.secondary;
          statusName = 'Inspection due';
        }
        break;
      case DoorStatus.InspectionOverdue:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager, Roles.InspectorAdmin])) {
          className = classes.red;
          statusName = 'Inspection overdue';
        }
        break;
      case DoorStatus.InspectionFailedSignOff:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager, Roles.InspectorAdmin])) {
          className = classes.red;
          statusName = 'Inspection failed';
        }
        break;
      case DoorStatus.InspectionRequiresSignOff:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager, Roles.InspectorAdmin])) {
          className = classes.primary;
          statusName = 'Inspection req sign off';
        }
        break;
      case DoorStatus.Inspecting:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager, Roles.InspectorAdmin])) {
          className = classes.yellow;
          statusName = 'Inspecting';
        }
        break;
      case DoorStatus.InspectorIncomplete:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager, Roles.InspectorAdmin])) {
          className = classes.red;
          statusName = 'Incomplete';
        }
        break;
      case DoorStatus.Retired:
        className = classes.red;
        statusName = 'Retired';
        break;
      case DoorStatus.RequiresRepair:
        if (hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager, Roles.ServiceEngineerAdmin])) {
          className = classes.blue;
          statusName = 'Requires Repair';
        }
        break;
      default:
        className = classes.grey;
        statusName = 'Transferred';
        break;
    }
  }

  return (
    <Paper className={clsx(className, classes.stage)} elevation={3}>
      <Box width={1}>
        <Typography className={classes.stage} align="center">
          {statusName}
        </Typography>
      </Box>
    </Paper>
  );
}
