import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import ReportCard from './ReportCard';
import { useInstallerInstallationActivityLazyQuery } from '../../queries';
import { baseUrl } from '../../utils/EnvironmentService';

type Props = {
  site?: { id: string; name: string };
  fromDate?: dayjs.Dayjs;
  toDate?: dayjs.Dayjs;
};

export default function InstallerInstallationActivityReportCard({ site, fromDate, toDate }: Props) {
  const [
    generateReport,
    { data: reportGeneratedData, loading: generatingReport },
  ] = useInstallerInstallationActivityLazyQuery({ fetchPolicy: 'no-cache' });
  const [lastDownloadedDocument, setLastDownloadedDocument] = useState<string | null>(null);

  const filename = useMemo(() => {
    if (!fromDate || !toDate) {
      return '';
    }
    const siteName = site?.name ?? 'AllSites';
    const from = fromDate.format('YYYYMMDD');
    const to = toDate.format('YYYYMMDD');

    return `InstallationActivity_${siteName}_${from}_${to}.csv`.replaceAll(' ', '');
  }, [site, fromDate, toDate]);

  useEffect(() => {
    if (
      reportGeneratedData?.installerInstallationActivity.path &&
      reportGeneratedData.installerInstallationActivity.path !== lastDownloadedDocument
    ) {
      let url = `${baseUrl}${reportGeneratedData.installerInstallationActivity.path}`;

      setLastDownloadedDocument(reportGeneratedData.installerInstallationActivity.path ?? null);
      // fetch data from url
      fetch(url, {
        credentials: 'include',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    }
  }, [reportGeneratedData, filename, lastDownloadedDocument]);

  const onGenerate = () => {
    generateReport({
      variables: {
        siteId: site?.id,
        reportPeriodFrom: fromDate?.startOf('day').toDate(),
        reportPeriodTo: toDate?.endOf('day').toDate(),
      },
    });
  };

  return (
    <ReportCard
      title="Installation activity"
      description="A report of installation activity based on site selection and date range"
      requiresDateRange
      site={site}
      fromDate={fromDate?.toDate()}
      toDate={toDate?.toDate()}
      onGenerateReport={onGenerate}
      disabled={generatingReport}
    />
  );
}
