import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';

interface PrivacyPolicyContentProps {
  paddingTop?: number;
}

export default function PrivacyPolicyContent({ paddingTop }: PrivacyPolicyContentProps) {
  return (
    <Container maxWidth="md">
      <Box paddingTop={paddingTop} paddingBottom={5}>
        <Typography gutterBottom paragraph variant="h4">
          Privacy Policy
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          We, Door Data Systems Ltd, are committed to protecting the privacy of anyone using our softwareand the
          confidentiality of any information that you provide us with.
        </Typography>
        <Typography variant="subtitle2">
          The purpose of this statement is to set out how we use any personal information that we may obtain from you
          via our software. You may opt out of receiving information from us at any time by email, telephone, or post.
        </Typography>
        <Typography variant="subtitle2">
          Email – <Link href="https://www.doordatasystems.co.uk">enquiries@https://www.doordatasystems.co.uk</Link>
        </Typography>
        <Typography variant="subtitle2">Telephone – 03301 330 679</Typography>
        <Typography gutterBottom paragraph variant="subtitle2">
          Post – Unit 203, Pointon Way Stoney Bridge Cross, Hampton Lovett, WR9 0LW
        </Typography>

        <Typography gutterBottom paragraph variant="h6">
          Use and Collection of Personal Information
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          To use the software and mobile application you will require to login and create an account. In the case of
          multi-user applications the key account holder(s) information will be required only. Invitees information is
          limited to enable login to the invitors account.
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          If you wish to use the software / mobile application to record information as the principal account holder we
          may require further information. If you are registered with us on the system as a user at your discretion you
          may have added information regarding your qualifications or certifications to complete tasks.
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          We will store this data and hold it via our externally hosted server(s).We willuse information that you
          provide:
        </Typography>
        <List>• To register you with our software / applicationto administer it</List>
        <List>
          • To notify you of updates or changes to the system orour services. •To send you newsletters if you have opted
          in to receive them
        </List>
        <List>
          • For assessment and analysis, e.g. marketing, customer and product analysis, to enable us to review, develop
          and improve our services
        </List>
        <List>
          • If you provide financial details for automated billing this information is not held by Door Data Systems on
          the system. Financial billing is dealt with by independent financial services.
        </List>
        <Typography gutterBottom paragraph></Typography>

        <Typography gutterBottom paragraph variant="h6">
          Cookies
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          We collect information directly from you in a number of ways. One way is through our use of “cookies”. Most
          websites, mobile apps, web based software use cookies in order to make them work, or to work more efficiently,
          as well as to provide information to the owners.
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          They may beused help us to understand how our customers and potential customers use our website, software or
          mobile Appso we can develop and improve the design, layout, content and function of the site. Cookies are
          small text files that are placed on your computer’s hard drive by websites that you visit.
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          They save and retrieve pieces of information about your visit to the website –for example, how you entered the
          site, how you navigated through the site and what information and documentation was of interest to you.Our
          software does not use cookies for the purpose of tracking your activity through the system, if this was to
          change you would be notified in advance.
        </Typography>
        <Typography gutterBottom paragraph variant="subtitle2">
          To find out more about cookies, including seeing what cookies have been set and how to manage and delete them,
          visit <Link href="http://www.allaboutcookies.org"> http://www.allaboutcookies.org</Link>.
        </Typography>

        <Typography gutterBottom paragraph variant="h6">
          Security
        </Typography>
        <Typography gutterBottom paragraph variant="subtitle2">
          We endeavour to take all reasonable steps to protect your personal information However, we cannot guarantee
          the security of any data that you disclose online and we will not be responsible for any breach of security
          unless this is due to our negligence or wilful default.
        </Typography>

        <Typography gutterBottom paragraph variant="h6">
          General
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          You have the right to see personal data (as defined in current data protection legislation including GDPR)
          that we keep about you upon receipt of a written request.
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          In the event that you wish to complain about how we have handled your personal data you may also make a formal
          complaint in writing and we will look in to your complaint and work with you to resolve the matter.
        </Typography>
        <Typography variant="subtitle2">
          Any requests in line with the above should be sent to our Data Protection Officer (DPO): Jim Kerr, contactable
          via
          <Link href="https://www.doordatasystems.co.uk"> jim.kerr@https://www.doordatasystems.co.uk</Link> If you still
          feel that your personal data has not been handled appropriately according to the law, you can contact the ICO
          and file a complaint with them
          <Link href="https://ico.org.uk/concerns/handling/"> https://ico.org.uk/concerns/handling/</Link>
        </Typography>
      </Box>
    </Container>
  );
}
