import {
  TableRow,
  TableSortLabel,
  Typography,
  Box,
  Button,
  Checkbox,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CompletenessProgress from '../components/CompletenessProgress';
import ErrorButton from '../components/ErrorButton';
import Layout from '../components/Layout';
import AddDoorDialog from '../components/manufactured-doors/AddDoorDialog';
import DoorStage from '../components/manufactured-doors/DoorStage';
import ManufacturedDoorsFilter, {
  ManufacturedDoorsSearchFilter,
} from '../components/manufactured-doors/ManufacturedDoorsFilter';
import NewDoorChip from '../components/manufactured-doors/NewDoorChip';
import NoResultsRow from '../components/NoResultsRow';
import ProtectedSection from '../components/ProtectedSection';
import TableLoading from '../components/TableLoading';
import Roles from '../constants/Roles';
import * as Routes from '../constants/Routes';
import DoorStatus from '../constants/Status';
import TransferTypes from '../constants/TransferTypes';
import useAuth from '../hooks/useAuth';
import useDialog from '../hooks/useDialog';
import useTable from '../hooks/useTable';
import { Door, DoorModel, useDisableDoorsMutation, useDoorsQuery } from '../queries';
import useTransferDoorsStore from '../store/TransferDoorsStore';
import useAssignDoorsToContractorStore from '../store/AssingDoorsToContractorStore';

import theme from '../theme';

const useStyles = makeStyles({
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeStatus: {
    color: theme.palette.primary.main,
  },
  disabledStatus: {
    color: theme.palette.error.main,
  },
  buttonContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});

export default function ManufacturedDoors() {
  const { hasRole, loggedInUser } = useAuth();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [enableTransfer, setEnableTransfer] = useState(false);
  const [enableAssignToSite, setEnableAssignToSite] = useState(false);
  const [selectedDoors, setSelectedDoors] = useState<Partial<Door>[]>([]);
  const { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort } = useTable('projectReference');
  const [filter, setFilter] = useState<ManufacturedDoorsSearchFilter>({} as ManufacturedDoorsSearchFilter);

  const { loading, error, data, refetch } = useDoorsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: pageSize * page,
      take: pageSize,
      sortDirection,
      sortField,
      active: filter?.active,
      textFilter: filter?.textFilter,
      statuses: filter?.statuses,
      stage: filter.doorStage,
      excludeDoorsWithSite: hasRole(Roles.Installer) || hasRole(Roles.InstallerAdmin),
    },
  });
  const [disableDoorsMutation, { loading: disableDoorsLoading }] = useDisableDoorsMutation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();
  const history = useHistory();
  const setDoorIds = useTransferDoorsStore((state) => state.setDoorIds);
  const setTransferType = useTransferDoorsStore((state) => state.setTransferType);

  useEffect(() => {
    const nbDoorsSelected = selectedDoors.length;
    const nbActiveDoorsSelected = selectedDoors.filter((d) => !d.deletedAt).length;
    const nbDoorsWithSitesSelected = selectedDoors.filter((d) => d.site).length;
    const nbManufacturerReadyDoorsSelected = selectedDoors.filter(
      (d) => d.status?.code === DoorStatus.ManufacturerReady
    ).length;
    const nbInstallerReadyDoorsSelected = selectedDoors.filter(
      (d) => d.status?.code === DoorStatus.InstallerReady && d.installer?.id === loggedInUser!.companyId
    ).length;

    const totalReadyDoors = nbManufacturerReadyDoorsSelected + nbInstallerReadyDoorsSelected;

    setShowDisable(nbDoorsSelected > 0 && nbActiveDoorsSelected === nbDoorsSelected);
    setEnableTransfer(nbDoorsSelected > 0 && totalReadyDoors === nbDoorsSelected);
    setEnableAssignToSite(nbDoorsSelected > 0 && nbDoorsWithSitesSelected === 0);
  }, [selectedDoors, loggedInUser]);

  useEffect(() => {
    pageChanged(0);
    if (refetch) {
      refetch();
    }
  }, [filter, refetch, pageChanged]);

  const [addNewDoorsDialogOpen, setAddNewDoorsDialogOpen] = useState(false);
  const onRowClick = (doorId: string) => {
    history.push(`${Routes.ViewDoor}/${doorId}`);
  };

  const onAddNewDoorsClicked = () => {
    setAddNewDoorsDialogOpen(true);
  };

  const onTransferDoorsClicked = () => {
    const selectedDoorIds = selectedDoors.map((d) => d.id).filter((d): d is string => !!d);
    setDoorIds(selectedDoorIds);
    setTransferType(TransferTypes.ManufacturerToInstaller);
    history.push(`${Routes.TransferDoors}`);
  };

  const onAssignToSiteClicked = () => {
    const selectedDoorIds = selectedDoors.map((d) => d.id).filter((d): d is string => !!d);
    setDoorIds(selectedDoorIds);
    history.push(`${Routes.ManufacturedDoors}/assign-doors-to-site`);
  };

  const getDoorStatus = (door: Partial<Door> | null | undefined) => {
    const status = door?.deletedAt ? 'Disabled' : 'Active';
    const className = door?.deletedAt ? classes.disabledStatus : classes.activeStatus;
    return (
      <Typography variant="body2" className={className}>
        {status}
      </Typography>
    );
  };

  const isDoorSelected = (door: Partial<Door> | null | undefined): boolean => {
    return selectedDoors.filter((d) => d?.id === door?.id).length > 0;
  };

  const toggleDoor = (door: Partial<Door> | null | undefined, checked: boolean): void => {
    if (!door) {
      return;
    }
    if (checked) {
      setSelectedDoors((prevState) => [...prevState, door]);
    } else {
      setSelectedDoors((prevState) => prevState.filter((u) => u?.id !== door?.id));
    }
  };

  const toggleAllDoors = (e: any) => {
    setIsSelectAllChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedDoors([]);
      const paganationSelection = Object.assign([], data?.doors.items).splice(0, pageSize);
      paganationSelection?.map((a: any) => {
        setSelectedDoors((selectedDoors) => [...selectedDoors, a]);
        return null;
      });
    } else {
      setSelectedDoors([]);
    }
  };

  const changePager = (page: number) => {
    setIsSelectAllChecked(false);
    setSelectedDoors([]);
    pageChanged(page);
  };
  const changePagerSize = (e: any) => {
    setIsSelectAllChecked(false);
    setSelectedDoors([]);
    pageSizeChanged(e);
  };

  const disableSelectedDoors = async () => {
    const validIds: number[] = [];
    selectedDoors.forEach((d) => {
      if (d && d.id) {
        validIds.push(parseInt(d.id));
      }
    });

    try {
      await disableDoorsMutation({
        variables: {
          idListInput: {
            ids: validIds,
          },
        },
      });
      setSelectedDoors([]);
      refetch();
      enqueueSnackbar('The selected doors have been disabled successfully.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('An error has occurred executing the action.', { variant: 'error' });
    }
  };

  const onDisableClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to disable the selected doors?',
      okText: 'Disable doors',
      onClose: () => disableSelectedDoors(),
    });
  };

  return (
    <Layout>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Manufactured doors</Typography>
        <Box display="flex" className={classes.buttonContainer}>
          <ProtectedSection roles={[Roles.ManufacturerAdmin]}>
            <Button variant="contained" color="secondary" onClick={onTransferDoorsClicked} disabled={!enableTransfer}>
              Transfer doors
            </Button>
          </ProtectedSection>
          <ProtectedSection roles={[Roles.InstallerAdmin, Roles.Installer]}>
            <Button variant="contained" color="primary" disabled={!enableAssignToSite} onClick={onAssignToSiteClicked}>
              Assign to site
            </Button>
          </ProtectedSection>
          {showDisable && (
            <ErrorButton variant="contained" onClick={onDisableClicked} loading={disableDoorsLoading}>
              Disable
            </ErrorButton>
          )}
          {!showDisable && (
            <Button variant="contained" color="primary" onClick={onAddNewDoorsClicked}>
              Add new doors
            </Button>
          )}
        </Box>
      </Box>
      <Box mt={3}>
        <TableContainer component={Paper}>
          <ManufacturedDoorsFilter onFilterChanged={(filter) => setFilter(filter)} />
          <Divider />
          <Table aria-label="Manufactured doors table">
            <TableHead>
              <TableRow>
                {data?.doors.items?.length === 0 ? (
                  <TableCell padding="checkbox" />
                ) : (
                  <TableCell>
                    <Checkbox
                      color="primary"
                      onClick={(event) => event.stopPropagation()}
                      onChange={(e) => toggleAllDoors(e)}
                      checked={isSelectAllChecked}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <TableSortLabel active={sortField === 'id'} direction={sortDirection} onClick={() => sort('id')}>
                    DDS Id
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'projectReference'}
                    direction={sortDirection}
                    onClick={() => sort('projectReference')}
                  >
                    Project Reference
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'doorReference'}
                    direction={sortDirection}
                    onClick={() => sort('doorReference')}
                  >
                    Door Reference
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'model.name'}
                    direction={sortDirection}
                    onClick={() => sort('model.name')}
                  >
                    Model
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'tagId'}
                    direction={sortDirection}
                    onClick={() => sort('tagId')}
                  >
                    Tag ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'status.name'}
                    direction={sortDirection}
                    onClick={() => sort('status.name')}
                  >
                    Stage
                  </TableSortLabel>
                </TableCell>
                <TableCell>Progress</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'deletedAt'}
                    direction={sortDirection}
                    onClick={() => sort('deletedAt')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoading />}
              {!loading && !error && data?.doors?.items.length === 0 && <NoResultsRow message="No doors to display" />}
              {!loading && error && <NoResultsRow message="There was an error fetching doors." />}
              {!loading &&
                !error &&
                data?.doors &&
                data.doors?.items?.length > 0 &&
                data.doors.items.map((door, i) => (
                  <TableRow hover key={i} onClick={() => onRowClick(door.id)} className={classes.tableRow}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={isDoorSelected(door as Partial<Door>)}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(e, checked) => toggleDoor(door as Partial<Door>, checked)}
                      />
                    </TableCell>
                    <TableCell>
                      {door.id}
                      <NewDoorChip
                        transferredAt={door.transferredToInstallerAt}
                        transferredToInstallerId={door.installer?.id}
                      />
                    </TableCell>
                    <TableCell>{door.projectReference}</TableCell>
                    <TableCell>{door.doorReference}</TableCell>
                    <TableCell>{door.model?.name}</TableCell>
                    <TableCell>{door.tagId}</TableCell>
                    <TableCell>
                      <DoorStage doorStageCode={door.status.code} transferredToInstallationId={door.installer?.id} />
                    </TableCell>
                    <TableCell>
                      <CompletenessProgress door={door as Partial<Door>} doorModel={door.model as Partial<DoorModel>} />
                    </TableCell>
                    <TableCell>{getDoorStatus(door as Partial<Door>)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.doors?.total ?? 0}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={(_, newPage) => changePager(newPage)}
            onChangeRowsPerPage={changePagerSize}
          />
        </TableContainer>
      </Box>
      <AddDoorDialog open={addNewDoorsDialogOpen} onCancel={() => setAddNewDoorsDialogOpen(false)} />
    </Layout>
  );
}
