import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import Business from '@material-ui/icons/Business';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(2, 3),
  },
  reportCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  reportCardSiteBlock: {
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },

  reportCardFilterBlock: {
    display: 'flex',
    gap: `${theme.spacing(4)}px`,
  },

  divider: {
    margin: theme.spacing(0, -3),
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  title: string;
  description: string;
  requiresDateRange: boolean;
  site?: { id: string; name: string };
  fromDate?: Date;
  toDate?: Date;
  onGenerateReport?: () => void;
  disabled?: boolean;
};

export default function ReportCard({
  title,
  description,
  requiresDateRange,
  site,
  fromDate,
  toDate,
  onGenerateReport,
  disabled,
}: Props) {
  const classes = useStyles();
  const [error, setError] = useState<{ title: string; description: string } | null>(null);

  const onGenerate = () => {
    if (requiresDateRange && (!fromDate || !toDate)) {
      setError({
        title: 'Date range required',
        description: 'You need to se a date range to generate this report type.',
      });
      return;
    }

    if (requiresDateRange && dayjs(fromDate).isAfter(dayjs(toDate), 'day')) {
      setError({
        title: 'Invalid date range',
        description: 'The date from needs to be before the date to.',
      });

      return;
    }

    if (onGenerateReport) {
      onGenerateReport();
    }
  };

  const calendarText = useMemo(() => {
    if (!requiresDateRange) {
      return 'N/A';
    }

    if (!fromDate || !toDate) {
      return 'No date set';
    }

    if (dayjs(fromDate).isAfter(dayjs(toDate), 'day')) {
      return 'Invalid date range';
    }

    return `${dayjs(fromDate).format('DD MMM YYYY')} - ${dayjs(toDate).format('DD MMM YYYY')}`;
  }, [requiresDateRange, fromDate, toDate]);

  return (
    <>
      <Paper className={classes.filterContainer}>
        <Box className={classes.reportCardHeader}>
          <Typography variant="h5">{title}</Typography>
          <Box className={classes.reportCardFilterBlock}>
            <Box className={classes.reportCardSiteBlock}>
              <Business />
              <Typography>{site?.name ?? 'All sites'}</Typography>
            </Box>
            <Box className={classes.reportCardSiteBlock}>
              <CalendarToday />
              <Typography>{calendarText}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider className={classes.divider} />

        <Box paddingTop={2} paddingBottom={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>{description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={onGenerate} disabled={disabled}>
                Download report
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Dialog open={Boolean(error)}>
        <DialogTitle>{error?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">{error?.description}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setError(null);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
