import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NoAccessPhoto, Photo } from '../../constants/DocumentTypes';
import { DoorModelDocument } from '../../pages/DoorModelAdd';
import { DocumentType, useDocumentTypesQuery } from '../../queries';
import AddedFile from '../AddedFile';
import DropZone from '../DropZone';

interface DoorModelDocumentationUploadProps {
  documents: DoorModelDocument[];
  setDocuments: React.Dispatch<React.SetStateAction<DoorModelDocument[]>>;
}

export default function DoorModelDocumentationUpload({ documents, setDocuments }: DoorModelDocumentationUploadProps) {
  const [documentTypes, setDocumentTypes] = useState<Partial<DocumentType>[]>([]);
  const { loading: loadingDocTypes, data } = useDocumentTypesQuery();
  const { register, reset } = useFormContext();

  useEffect(() => {
    if (data) {
      // exclude the photo type
      setDocumentTypes(data.documentTypes.filter((dt) => dt.code !== Photo && dt.code !== NoAccessPhoto));
    }
  }, [data]);

  // having to use useEffect and reset to initialize the document types. for some obscure reason defaultValues on useform does not work
  useEffect(() => {
    if (documents) {
      const defaultValues: any = {};
      documents.forEach((d, i) => {
        defaultValues[`documents[${i}].documentTypeCode`] = d.documentTypeCode;
      });

      reset(defaultValues, {
        isValid: true, // This ensures the validity of the form is reevaluated
      });
    }
  }, [documents, reset]);

  const onFilesAdded = (selectedFiles: File[]) => {
    const newDocuments: DoorModelDocument[] = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // have to provide a default description otherwise React complains about controlled/uncontrolled components in console
      newDocuments.push({ file, documentTypeCode: '' });
    }
    setDocuments((prev) => [...prev, ...newDocuments]);
  };

  const onFileRemoved = (fileIndex: number) => {
    const filesCopy = Array.from(documents);
    filesCopy.splice(fileIndex, 1);
    setDocuments(filesCopy);
  };

  const onDocumentTypeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    fileIndex: number
  ) => {
    const documentTypeCode = String(e.target.value);
    setDocuments((prev) => prev.map((x, i) => (i === fileIndex ? { ...x, documentTypeCode } : x)));
  };

  // handling error manually here
  const hasError = (doc: DoorModelDocument): boolean => {
    return doc.documentTypeCode === undefined || doc.documentTypeCode.length === 0;
  };

  return (
    <>
      <DropZone
        onFilesAdded={onFilesAdded}
        title="Upload documentation"
        accept=".pdf"
        acceptHelperText="Accepted format: PDF"
        multiple
        maxSizeMb={50}
      />
      <Box mt={3} mb={3}>
        <Typography>Required documentation: Installation guide, Fire certificate, Warranty.</Typography>
        <Typography>Any missing documents must be assigned to the manufactured door.</Typography>
      </Box>
      {documents.map((document, i) => (
        <AddedFile
          key={i}
          documentType={document.documentTypeCode}
          fileName={document.file.name}
          onRemove={() => onFileRemoved(i)}
        >
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor={`documents[${i}].documentTypeCode`} error={hasError(document)}>
              Type
            </InputLabel>
            <Select
              label="Type"
              native
              inputProps={{
                name: `documents[${i}].documentTypeCode`,
                id: `documents[${i}]documentTypeCodeInput`,
              }}
              inputRef={register({ required: 'Type required' })}
              onChange={(e) => onDocumentTypeChange(e, i)}
              error={hasError(document)}
            >
              <option aria-label="None" value="" />

              {!loadingDocTypes &&
                documentTypes &&
                documentTypes.map((documentType) => (
                  <option value={documentType.code} key={documentType.code}>
                    {documentType.name}
                  </option>
                ))}
            </Select>
            <FormHelperText error={hasError(document)}>
              {hasError(document) ? 'Type is required' : undefined}
            </FormHelperText>
          </FormControl>
        </AddedFile>
      ))}
    </>
  );
}
