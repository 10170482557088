import { Box } from '@material-ui/core';
import React from 'react';
import { Document, CompanyQuery } from '../../../queries';
import DocumentList from '../../DocumentList';

interface CompanyFilesProps {
  company?: CompanyQuery['company'];
  documentTypeCodes?: string[];
  highlightMissingTypes?: string[];
  noFilesMessage?: string;
}

export default function CompanyFiles({ company, documentTypeCodes, ...other }: CompanyFilesProps) {
  const documents: Document[] = [];
  if (company?.documents) {
    for (const document of company?.documents) {
      if (documentTypeCodes?.includes(document.documentType.code)) {
        documents.push(document);
      }
    }
  }

  return (
    <Box p={5}>
      <DocumentList {...other} documents={documents} />
    </Box>
  );
}
