import ApolloClient from 'apollo-boost-upload';
import { apiUrl } from './utils/EnvironmentService';

const client = new ApolloClient({
  uri: apiUrl,
  credentials: 'include',
  fetchOptions: {
    credentials: 'include',
  },
  onError: ({ operation, networkError }) => {
    if (networkError && (networkError as any).statusCode === 401 && operation.operationName !== 'LoggedIn') {
      // will have to suffice until I find a way to access the react context from here
      window.location.href = '/login';
    }
  },
});

export default client;
