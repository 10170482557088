import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Layout from '../components/Layout';
import { Company, CompanyTypes, useAssignDoorsToContractorMutation, User } from '../queries';
import * as Routes from '../constants/Routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useDialog from '../hooks/useDialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingButton from '../components/LoadingButton';
import { GraphQLError } from 'graphql';
import useAssignDoorsToContractorStore from '../store/AssingDoorsToContractorStore';
import { Link } from 'react-router-dom';
import DoorUserAssignmentAdd from '../components/DoorUserAssignmentAdd';
import WorkTypes from '../constants/WorkTypes';

interface AssignDoorsFormData {
  destinationCompany: Pick<Company, 'id' | 'name'> | null;
  userEmail: string;
  workType: string;
}

export default function AssignDoorsToContractor() {
  const { showDialog } = useDialog();
  const history = useHistory();
  const location = useLocation();
  const { id: siteId } = useParams<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { doorIds, setDoorIds } = useAssignDoorsToContractorStore();
  const [doorUserAssignmentDetailsLoading, setDoorUserAssignmentDetailsLoading] = useState<boolean>(false);
  let workTypeOptions = [
    { id: WorkTypes.Installation.toString(), name: WorkTypes.Installation.toString() },
    { id: WorkTypes.Inspection.toString(), name: WorkTypes.Inspection.toString() },
    { id: WorkTypes.Repair.toString(), name: WorkTypes.Repair.toString() },
    { id: WorkTypes.Replacement.toString(), name: WorkTypes.Replacement.toString() },
  ];

  const [assignDoorsToContractor] = useAssignDoorsToContractorMutation();

  const form = useForm<AssignDoorsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, formState, errors } = form;

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        setDoorIds([]);
        history.goBack();
      },
    });
  };

  const saveChanges = async (values: AssignDoorsFormData) => {
    try {
      await assignDoorsToContractor({
        variables: {
          destinationCompanyId: values.destinationCompany!.id,
          destinationUserEmail: values.userEmail,
          ids: doorIds.map((did) => Number(did)),
          workTypeCode: values.workType,
        },
      });

      enqueueSnackbar('Doors assigned successfully.', { variant: 'success' });
      history.goBack();
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
          enqueueSnackbar(gqlerror.message, { variant: 'error' });
        });
      }
      console.log(e);
    }
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5">Assign doors to contractor</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.MySites}>
                  <Typography variant="body2">My site list</Typography>
                </MaterialLink>
                {(location?.state as any)?.siteName && (
                  <MaterialLink color="inherit" component={Link} to={`${Routes.Sites}/${siteId}`}>
                    <Typography variant="body2">{(location?.state as any)?.siteName}</Typography>
                  </MaterialLink>
                )}
                <Typography variant="body2">Assign doors</Typography>
              </Breadcrumbs>
            </Box>
            <Box minWidth={96}>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>
          <Box mt={1}>
            <Paper>
              <Box p={5} marginTop={3}>
                <Box marginBottom={3}>
                  <Typography>
                    Please enter the details of the Door Data Systems account you wish to assign doors to
                  </Typography>
                </Box>
                <FormProvider {...form}>
                  <form onSubmit={handleSubmit(saveChanges)}>
                    <DoorUserAssignmentAdd
                      companyTypes={[CompanyTypes.Installer, CompanyTypes.Inspection, CompanyTypes.Service]}
                      errors={errors}
                      workTypes={workTypeOptions}
                      setIsLoading={setDoorUserAssignmentDetailsLoading}
                    />
                    <Box display="flex" justifyContent="flex-end" marginTop={3}>
                      <LoadingButton
                        loading={doorUserAssignmentDetailsLoading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!formState.isValid || doorUserAssignmentDetailsLoading}
                      >
                        Assign to contractor
                      </LoadingButton>
                    </Box>
                  </form>
                </FormProvider>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
