import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import * as Routes from '../../../constants/Routes';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';

export default function DashboardMenuItem() {
  const location = useLocation();
  return (
    <ProtectedSection roles={[Roles.FacilityManagerAdmin]}>
      <ListItem button component={Link} to={Routes.Dashboard} selected={location.pathname.startsWith(Routes.Dashboard)}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={'Dashboard'} />
      </ListItem>
    </ProtectedSection>
  );
}
