import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 3),
  },
  divider: {
    margin: theme.spacing(0, -3),
    marginTop: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
    color: '#fff',
  },
}));

interface DetailBoxRow {
  label: string;
  value?: string | number | null;
}

interface DetailBoxProps {
  title: string;
  rows: DetailBoxRow[];
  children?: React.ReactNode;
}

export default function DetailBox({ title, rows, children }: DetailBoxProps) {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <Typography variant="h5">{title}</Typography>
      <Divider className={classes.divider} />

      <Box py={2} pt={3}>
        <Grid container direction="column" spacing={3}>
          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
          {rows.map((row, i) => {
            return (
              <Grid item xs={12} key={i}>
                <Typography variant="body1" className={classes.label}>
                  {row.label}
                </Typography>
                <Typography variant="body1">{row.value}</Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Paper>
  );
}
