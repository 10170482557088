import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import React, { useEffect, useReducer, useState } from 'react';
import DoorStatus from '../../constants/Status';
import { useSnackbar } from 'notistack';
import { LocationType, useLocationTypesQuery } from '../../queries';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: theme.palette.background.default,
  },
  label: {
    fontWeight: 700,
    color: '#fff',
  },
}));

interface InspectionState {
  [DoorStatus.NoInspectionSet]: boolean;
  [DoorStatus.InspectionDue]: boolean;
  [DoorStatus.InspectionOverdue]: boolean;
}

interface StageState {
  [DoorStatus.FacilityManagementReady]: boolean;
  [DoorStatus.Inspecting]: boolean;
  [DoorStatus.InspectionRequiresSignOff]: boolean;
  [DoorStatus.Passed]: boolean;
  [DoorStatus.InspectionFailedSignOff]: boolean;
  [DoorStatus.RequiresRepair]: boolean;
  [DoorStatus.Retired]: boolean;
  [DoorStatus.InspectorIncomplete]: boolean;
}

interface DataState {
  installationFrom: Dayjs | null;
  installationTo: Dayjs | null;
  inspectionFrom: Dayjs | null;
  inspectionTo: Dayjs | null;
}

export interface SiteDrawerFilters {
  statuses: string[];
  locationTypes: string[];
  installationDate: {
    from: Dayjs | null;
    to: Dayjs | null;
  };
  inspectionDate: {
    from: Dayjs | null;
    to: Dayjs | null;
  };
}

const defaultInspectionState: InspectionState = {
  [DoorStatus.NoInspectionSet]: true,
  [DoorStatus.InspectionDue]: true,
  [DoorStatus.InspectionOverdue]: true,
};

const defaultStageState: StageState = {
  [DoorStatus.FacilityManagementReady]: true,
  [DoorStatus.Inspecting]: true,
  [DoorStatus.InspectionRequiresSignOff]: true,
  [DoorStatus.Passed]: true,
  [DoorStatus.InspectionFailedSignOff]: true,
  [DoorStatus.RequiresRepair]: true,
  [DoorStatus.Retired]: true,
  [DoorStatus.InspectorIncomplete]: true,
};

const defaultDataState: DataState = {
  installationFrom: null,
  installationTo: null,
  inspectionFrom: null,
  inspectionTo: null,
};

type checkboxesActions =
  | { type: 'RESET'; payload: InspectionState | StageState }
  | { type: 'TOGGLE_MASTER' }
  | { type: 'SET'; payload: Partial<InspectionState | StageState> };
type dataActions = { type: 'RESET'; payload: DataState } | { type: 'SET'; payload: Partial<DataState> };

const doesAllChecked = (source: InspectionState | StageState) => Object.values(source).every(Boolean);

function checkboxesReducer(state: InspectionState | StageState, action: checkboxesActions) {
  switch (action.type) {
    case 'RESET':
      return { ...action.payload };
    case 'SET':
      return { ...state, ...action.payload };
    case 'TOGGLE_MASTER':
      const doesAllCheckboxesSelected = doesAllChecked(state);
      const result = mapValues(state, () => !doesAllCheckboxesSelected);
      return { ...result };
    default:
      throw new Error();
  }
}

function dateReducer(state: DataState, action: dataActions) {
  switch (action.type) {
    case 'RESET':
      return { ...action.payload };
    case 'SET':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

interface SitesExtendedFilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  setFilter: (newFilter: SiteDrawerFilters) => void;
  installationFrom: Dayjs | null;
  installationTo: Dayjs | null;
  inspectionFrom: Dayjs | null;
  inspectionTo: Dayjs | null;
  selectedStatuses?: string[];
  initialLocationTypes?: (string | null)[];
}

const getDefaultInspectionState = (initialStatuses: string[] | undefined) => {
  const clone = { ...defaultInspectionState };
  if (!initialStatuses) {
    return clone;
  }

  Object.keys(clone).map((k) => (clone[k as keyof typeof defaultInspectionState] = initialStatuses.includes(k)));

  return clone;
};

const getDefaultStageState = (initialStatuses: string[] | undefined) => {
  const clone = { ...defaultStageState };
  if (!initialStatuses) {
    return clone;
  }

  Object.keys(clone).map((k) => (clone[k as keyof typeof defaultStageState] = initialStatuses.includes(k)));

  return clone;
};

export default function SitesExtendedFilterDrawer({
  isOpen,
  onClose,
  setFilter,
  installationFrom,
  installationTo,
  inspectionFrom,
  inspectionTo,
  selectedStatuses,
  initialLocationTypes,
}: SitesExtendedFilterDrawerProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: locationTypes } = useLocationTypesQuery({ fetchPolicy: 'cache-first' });
  const [inspectionState, inspectionDispatch] = useReducer(
    checkboxesReducer,
    getDefaultInspectionState(selectedStatuses)
  );

  const [stageState, stageDispatch] = useReducer(checkboxesReducer, getDefaultStageState(selectedStatuses));

  const [dateState, dateDispatch] = useReducer(dateReducer, {
    installationFrom,
    installationTo,
    inspectionFrom,
    inspectionTo,
  });

  const [selectedLocationTypes, setSelectedLocationTypes] = useState<(LocationType | null)[]>([]);

  useEffect(() => {
    if (locationTypes?.locationTypes) {
      if (initialLocationTypes) {
        const temp: (LocationType | null)[] = locationTypes.locationTypes.filter((lt) =>
          initialLocationTypes.includes(lt.code)
        );
        if (initialLocationTypes.includes('NO_LOCATION_SET')) {
          temp.push(null);
        }
        setSelectedLocationTypes([...temp]);
      } else {
        setSelectedLocationTypes([...locationTypes.locationTypes, null]);
      }
    }
  }, [locationTypes, initialLocationTypes]);

  const collectAppliedStatuses = () => {
    const statesCollection = { ...inspectionState, ...stageState };
    // typing Object.keys https://fettblog.eu/typescript-better-object-keys/
    return Object.keys(statesCollection).filter((k) => statesCollection[k as keyof typeof statesCollection]);
  };

  const conditionToShowNotification = () => {
    const defaultState = { ...defaultInspectionState, ...defaultStageState, ...defaultDataState };
    const currentState = { ...inspectionState, ...stageState, ...dateState };

    return !isEqual(currentState, defaultState);
  };

  const applyFilter = () => {
    const statuses = collectAppliedStatuses();
    const notificationCondition = conditionToShowNotification();

    if (notificationCondition) {
      enqueueSnackbar('This list has been filtered', { variant: 'success' });
    }

    const appliedFilters: SiteDrawerFilters = {
      statuses,
      locationTypes: selectedLocationTypes.map((slt) => slt?.code ?? 'NO_LOCATION_SET'),
      installationDate: {
        from: dateState.installationFrom,
        to: dateState.installationTo,
      },
      inspectionDate: {
        from: dateState.inspectionFrom,
        to: dateState.inspectionTo,
      },
    };
    setFilter(appliedFilters);
    onClose();
  };

  const resetFilter = () => {
    inspectionDispatch({ type: 'RESET', payload: defaultInspectionState });
    stageDispatch({ type: 'RESET', payload: defaultStageState });
    dateDispatch({ type: 'RESET', payload: defaultDataState });
    setSelectedLocationTypes([...(locationTypes?.locationTypes ?? []), null]);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} classes={{ paper: classes.drawerPaper }}>
      <Box px={4} pt={10}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Filter doors</Typography>
          <Box ml={2}>
            <IconButton aria-label="close" onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box pr={4}>
          <Box mt={6}>
            <Typography variant="subtitle1" className={classes.label}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doesAllChecked(inspectionState)}
                    onChange={(_, checked) => inspectionDispatch({ type: 'TOGGLE_MASTER' })}
                    name="inspection-status"
                    color="primary"
                  />
                }
                label=""
              />
              Inspection status
            </Typography>
            <Box ml={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={inspectionState[DoorStatus.NoInspectionSet]}
                      onChange={(_, checked) =>
                        inspectionDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.NoInspectionSet]: checked },
                        })
                      }
                      name="no-inspection-set"
                      color="primary"
                    />
                  }
                  label="No inspection set"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={inspectionState[DoorStatus.InspectionDue]}
                      onChange={(_, checked) =>
                        inspectionDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.InspectionDue]: checked },
                        })
                      }
                      name="due-inspection"
                      color="primary"
                    />
                  }
                  label="Due inspection"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={inspectionState[DoorStatus.InspectionOverdue]}
                      onChange={(_, checked) =>
                        inspectionDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.InspectionOverdue]: checked },
                        })
                      }
                      name="overdue-inspection"
                      color="primary"
                    />
                  }
                  label="Overdue inspection"
                />
              </FormGroup>
            </Box>
          </Box>

          <Box mt={6}>
            <Typography variant="subtitle1" className={classes.label}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doesAllChecked(stageState)}
                    onChange={(_, checked) => stageDispatch({ type: 'TOGGLE_MASTER' })}
                    name="stage"
                    color="primary"
                  />
                }
                label=""
              />
              Stage
            </Typography>
            <Box ml={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stageState[DoorStatus.FacilityManagementReady]}
                      onChange={(_, checked) =>
                        stageDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.FacilityManagementReady]: checked },
                        })
                      }
                      name="ready"
                      color="primary"
                    />
                  }
                  label="Ready"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stageState[DoorStatus.Inspecting]}
                      onChange={(_, checked) =>
                        stageDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.Inspecting]: checked },
                        })
                      }
                      name="inspecting"
                      color="primary"
                    />
                  }
                  label="Inspecting"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stageState[DoorStatus.InspectionRequiresSignOff]}
                      onChange={(_, checked) =>
                        stageDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.InspectionRequiresSignOff]: checked },
                        })
                      }
                      name="requires-sign-off"
                      color="primary"
                    />
                  }
                  label="Requires sign off"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stageState[DoorStatus.Passed]}
                      onChange={(_, checked) =>
                        stageDispatch({ type: 'SET', payload: { [DoorStatus.Passed]: checked } })
                      }
                      name="passed"
                      color="primary"
                    />
                  }
                  label="Passed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stageState[DoorStatus.InspectionFailedSignOff]}
                      onChange={(_, checked) =>
                        stageDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.InspectionFailedSignOff]: checked },
                        })
                      }
                      name="failed"
                      color="primary"
                    />
                  }
                  label="Failed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stageState[DoorStatus.RequiresRepair]}
                      onChange={(_, checked) =>
                        stageDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.RequiresRepair]: checked },
                        })
                      }
                      name="repair-required"
                      color="primary"
                    />
                  }
                  label="Repair required"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stageState[DoorStatus.Retired]}
                      onChange={(_, checked) =>
                        stageDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.Retired]: checked },
                        })
                      }
                      name="retired"
                      color="primary"
                    />
                  }
                  label="Retired"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stageState[DoorStatus.InspectorIncomplete]}
                      onChange={(_, checked) =>
                        stageDispatch({
                          type: 'SET',
                          payload: { [DoorStatus.InspectorIncomplete]: checked },
                        })
                      }
                      name="incomplete"
                      color="primary"
                    />
                  }
                  label="Incomplete"
                />
              </FormGroup>
            </Box>
          </Box>

          <Box mt={6}>
            <Typography variant="subtitle1" className={classes.label}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={locationTypes && selectedLocationTypes.length === locationTypes?.locationTypes.length + 1} // +1 for the null option
                    onChange={(_, checked) => {
                      if (checked) {
                        setSelectedLocationTypes([...(locationTypes?.locationTypes ?? []), null]);
                      } else {
                        setSelectedLocationTypes([]);
                      }
                    }}
                    name="door-location-type"
                    color="primary"
                  />
                }
                label=""
              />
              Type
            </Typography>
            <Box ml={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedLocationTypes?.includes(null)}
                      onChange={(_, checked) => {
                        if (checked) {
                          setSelectedLocationTypes([...new Set([...selectedLocationTypes, null])]);
                        } else {
                          setSelectedLocationTypes(selectedLocationTypes?.filter((slt) => slt !== null));
                        }
                      }}
                      name="no-location-type-set"
                      color="primary"
                    />
                  }
                  label="No type set"
                />
                {locationTypes?.locationTypes.map((lt, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={selectedLocationTypes?.includes(lt)}
                        onChange={(_, checked) => {
                          if (checked) {
                            setSelectedLocationTypes([...new Set([...selectedLocationTypes, lt])]);
                          } else {
                            setSelectedLocationTypes(selectedLocationTypes?.filter((slt) => slt !== lt));
                          }
                        }}
                        name={lt.code}
                        color="primary"
                      />
                    }
                    label={lt.name}
                  />
                ))}
              </FormGroup>
            </Box>
          </Box>

          <Box mt={5}>
            <Typography variant="subtitle1" className={classes.label}>
              Installation time period
            </Typography>

            <Box mt={3}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <KeyboardDatePicker
                    value={dateState.installationFrom}
                    onChange={(date) => dateDispatch({ type: 'SET', payload: { installationFrom: date } })}
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label="From date"
                    placeholder="From date"
                  />
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    value={dateState.installationTo}
                    onChange={(date) => dateDispatch({ type: 'SET', payload: { installationTo: date } })}
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label="To date"
                    placeholder="To date"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box mt={5}>
            <Typography variant="subtitle1" className={classes.label}>
              Inspection time period
            </Typography>

            <Box mt={3}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <KeyboardDatePicker
                    value={dateState.inspectionFrom}
                    onChange={(date) => dateDispatch({ type: 'SET', payload: { inspectionFrom: date } })}
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label="From date"
                    placeholder="From date"
                  />
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    value={dateState.inspectionTo}
                    onChange={(date) => dateDispatch({ type: 'SET', payload: { inspectionTo: date } })}
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label="To date"
                    placeholder="To date"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box mt={5}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Button fullWidth variant="contained" color="primary" onClick={applyFilter}>
                  Apply
                </Button>
              </Grid>
              <Grid item>
                <Button fullWidth variant="outlined" onClick={resetFilter}>
                  Reset filters
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
