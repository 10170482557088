import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useDashboardNbInspectionsDueByMonthQuery } from '../../../queries';
import Assignment from '@material-ui/icons/Assignment';
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import LoadingOverlay from './LoadingOverlay';
import ErrorOverlay from './ErrorOverlay';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#2E3047',
    borderRadius: '4px',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
  },
  chartContainer: {
    padding: '30px',
    paddingLeft: 0,
    position: 'relative',
  },
  titleContainer: {
    paddingLeft: '20px',
    paddingTop: '16px',
    paddingBottom: '16px',
    borderBottomColor: '#97979780',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    alignItems: 'center',
  },
  title: {
    fontSize: '18.5px',
  },

  tooltipContainer: {
    borderRadius: '4px',
    border: '1px solid #63646F',
    backgroundColor: '#26283E',
    padding: '8px',
  },

  tooltipText: {
    color: '#FFFFFF',
  },
}));

type CustomTooltipProps = {
  active?: boolean;
  payload?: any;
  label?: string;
};

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
      <Box className={classes.tooltipContainer}>
        <Typography variant="subtitle1" className={classes.tooltipText}>{`Nb inspections: ${
          payload[0].value ?? 0
        }`}</Typography>
      </Box>
    );
  } else {
    return null;
  }
};

type Props = {
  siteId?: string | null;
};

export default function NumberOfInspectionsDueByMonthChart({ siteId }: Props) {
  const classes = useStyles();
  const { loading, data, error } = useDashboardNbInspectionsDueByMonthQuery({
    variables: { siteId: siteId ?? null },
    fetchPolicy: 'cache-and-network',
  });

  const dataKeyFn = (d: any) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const monthIdx = d.month - 1;
    if (months.length > monthIdx) {
      return months[monthIdx];
    } else {
      return '';
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Assignment />
        <Typography className={classes.title} variant="h5">
          Inspections due by month
        </Typography>
      </Box>
      <Box className={classes.chartContainer}>
        <ResponsiveContainer width="100%" aspect={3} maxHeight={300}>
          <AreaChart data={data?.dashboard.nbInspectionsDueByMonth?.data} margin={{ top: 10 }}>
            {!error && data?.dashboard.nbInspectionsDueByMonth?.data && (
              <>
                <defs>
                  <linearGradient id="areaColor" x1="0.5" y1="0" x2="0.5" y2="1">
                    <stop offset="0%" stopColor="#8f6def" />
                    <stop offset="33%" stopColor="rgba(103, 85, 199, 0.67)" />
                    <stop offset="100%" stopColor="rgba(46, 48, 71, 0)" />
                  </linearGradient>
                </defs>
                <XAxis dataKey={dataKeyFn} tick={{ fill: '#FFFFFF' }} tickLine={false} tickSize={15} />
                <YAxis axisLine={false} tick={{ fill: '#FFFFFF' }} tickLine={false} tickSize={10} />
                <Tooltip content={CustomTooltip} cursorStyle={{ stroke: 'red', strokeWidth: 2 }} />
                <Area type="linear" dataKey="nbInspections" stroke="#8F6DEF" strokeWidth={2} fill="url(#areaColor)" />
                <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#979797" />
              </>
            )}
          </AreaChart>
        </ResponsiveContainer>
        <ErrorOverlay show={Boolean(error)} />
        <LoadingOverlay show={loading} />
      </Box>
    </Box>
  );
}
