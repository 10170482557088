import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { googleAppId, appleAppId } from '../utils/EnvironmentService';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: '#fff',
  },
  badges: {
    marginTop: '25px',
  },
}));

export default function Welcome() {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Box paddingTop={10}>
        <Box marginBottom={5}>
          <Typography align="center" variant="h4" gutterBottom>
            Invite accepted
          </Typography>
        </Box>
        <Box textAlign="center" mt={5}>
          <Typography variant="body2" className={classes.text}>
            Welcome to Door Data Systems! Your password has been set.
          </Typography>
          <Typography variant="body2" className={classes.text}>
            Please log in to the mobile app to continue.
          </Typography>
          <div className={classes.badges}>
            {googleAppId && (
              <a href={`https://play.google.com/store/apps/details?id=${googleAppId}`}>
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  style={{ height: '83px' }}
                />
              </a>
            )}
            {appleAppId && (
              <a href={`https://apps.apple.com/gb/app/${appleAppId}`}>
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83"
                  alt="Download on the App Store"
                  style={{ height: '83px', padding: '12px' }}
                />
              </a>
            )}
          </div>
        </Box>
      </Box>
    </Container>
  );
}
