import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Roles from '../../constants/Roles';
import ProtectedSection from '../ProtectedSection';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles({
  chip: {
    marginLeft: 6,
  },
});

interface NewDoorChipProps {
  transferredAt?: string;
  transferredToInstallerId?: string;
}

export default function NewDoorChip({ transferredAt, transferredToInstallerId }: NewDoorChipProps) {
  const classes = useStyles();
  const { loggedInUser } = useAuth();

  if (!transferredAt) {
    return null;
  }

  const transferDate = dayjs(transferredAt);
  const oneDayAgo = dayjs().subtract(1, 'day');
  if (
    !transferDate.isAfter(oneDayAgo) ||
    !transferredToInstallerId ||
    transferredToInstallerId.toString() !== loggedInUser?.companyId?.toString()
  ) {
    return null;
  }

  return (
    <ProtectedSection roles={[Roles.InstallerAdmin, Roles.FacilityManager, Roles.FacilityManagerAdmin]}>
      <Chip size="small" label="New" color="primary" className={classes.chip} />
    </ProtectedSection>
  );
}
