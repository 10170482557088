import React, { useEffect, useState } from 'react';
import useMySitesSearchStore from '../store/MySitesSearchStore';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  TextField,
} from '@material-ui/core';
import Layout from '../components/Layout';
import NoResultsRow from '../components/NoResultsRow';
import TableLoading from '../components/TableLoading';
import useTable from '../hooks/useTable';
import { useHistory } from 'react-router';
import * as Routes from '../constants/Routes';
import { Company, Site, useMySitesQuery, useRemoveFromMySitesMutation } from '../queries';
import Checkbox from '@material-ui/core/Checkbox';
import { useSnackbar } from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ProtectedSection from '../components/ProtectedSection';
import Roles from '../constants/Roles';
import { useDebounce } from 'use-debounce';

export default function MySites() {
  const { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort } = useTable('name');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { searchTerm, setSearchTerm } = useMySitesSearchStore();
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  const [selectedSites, setSelectedSites] = useState<Partial<Company>[]>([]);
  const [removeFromMySitesMutation] = useRemoveFromMySitesMutation();

  const { loading, error, data, refetch } = useMySitesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: pageSize * page,
      take: pageSize,
      sortDirection,
      sortField,
      searchTerm: debouncedSearchTerm,
    },
  });

  const useStyles = makeStyles({
    tableRow: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    noBorderTableRow: {
      borderBottom: 'none',
    },
  });

  const classes = useStyles();

  const onRowClick = (siteId: string) => {
    history.push(`${Routes.Sites}/${siteId}`);
  };

  const isUserSelected = (site: Partial<Site> | null | undefined): boolean => {
    return selectedSites.filter((s) => s?.id === site?.id).length > 0;
  };

  const toggleUser = (site: Partial<Site> | null | undefined, checked: boolean): void => {
    if (!site) {
      return;
    }

    if (checked) {
      setSelectedSites((prevState) => [...prevState, site]);
    } else {
      setSelectedSites((prevState) => prevState.filter((u) => u?.id !== site?.id));
    }
  };

  const removeSelectedSites = async () => {
    const validIds: number[] = [];
    selectedSites.forEach((s) => {
      if (s && s.id) {
        validIds.push(parseInt(s.id));
      }
    });

    try {
      await removeFromMySitesMutation({
        variables: {
          idListInput: {
            ids: validIds,
          },
        },
      });
      setSelectedSites([]);
      refetch();
      const message =
        validIds.length > 1
          ? `The selected Sites have been removed from your site list.`
          : `${selectedSites[0].name} has been removed from your site list successfully.`;
      enqueueSnackbar(message, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('An error has occurred executing the action.', { variant: 'error' });
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Layout>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">My site list</Typography>
        <Box>
          <ProtectedSection roles={[Roles.InstallerAdmin, Roles.InspectorAdmin]}>
            <Button
              variant="contained"
              color="secondary"
              disabled={selectedSites.length < 1}
              onClick={() => removeSelectedSites()}
            >
              Remove from list
            </Button>
          </ProtectedSection>
          <ProtectedSection roles={[Roles.FacilityManagerAdmin]}>
            <Button variant="contained" color="secondary" onClick={() => history.push(Routes.SitesAdd)}>
              Add new site
            </Button>
          </ProtectedSection>
        </Box>
      </Box>
      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table aria-label="Search sites input">
            <TableBody>
              <TableRow>
                <TableCell align="right" className={classes.noBorderTableRow}>
                  <TextField
                    defaultValue={searchTerm}
                    label="Search"
                    variant="outlined"
                    onChange={(e: any) => setSearchTerm(e.target.value)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table aria-label="Search sites table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>
                  <TableSortLabel active={sortField === 'name'} direction={sortDirection} onClick={() => sort('name')}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Site ID</TableCell>
                <TableCell>Postcode</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'ownedBy.name'}
                    direction={sortDirection}
                    onClick={() => sort('ownedBy.name')}
                  >
                    Site owner
                  </TableSortLabel>
                </TableCell>
                <TableCell>Areas / floors</TableCell>
                <ProtectedSection roles={[Roles.FacilityManager, Roles.FacilityManagerAdmin]}>
                  <TableCell>Installed doors</TableCell>
                </ProtectedSection>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoading />}
              {!loading && !error && data?.mySites.items.length === 0 && <NoResultsRow message="No sites to display" />}
              {!loading && error && <NoResultsRow message="There was an error fetching sites." />}
              {!loading &&
                !error &&
                data?.mySites &&
                data?.mySites.items.length > 0 &&
                data?.mySites.items.map((site, i) => (
                  <TableRow hover key={i} className={classes.tableRow} onClick={() => onRowClick(site.id)}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={isUserSelected(site as Partial<Site>)}
                        onChange={(e, checked) => toggleUser(site as Partial<Site>, checked)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell>
                    <TableCell>{site.name}</TableCell>
                    <TableCell>{site.id}</TableCell>
                    <TableCell>{site.postcode}</TableCell>
                    <TableCell>{site.ownedBy?.name}</TableCell>
                    <TableCell>{site.areas}</TableCell>
                    <ProtectedSection roles={[Roles.FacilityManager, Roles.FacilityManagerAdmin]}>
                      <TableCell>{site.installedDoorCount ?? '-'}</TableCell>
                    </ProtectedSection>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.mySites.total ?? 0}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={(_, newPage) => pageChanged(newPage)}
            onChangeRowsPerPage={pageSizeChanged}
          />
        </TableContainer>
      </Box>
    </Layout>
  );
}
