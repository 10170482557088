import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CompanyType, CompanyTypeFragment, useCompanyTypesQuery } from '../queries';

function validateCompanyTypes(values?: CompanyType[]) {
  if (!values || values.length === 0) {
    return 'At least one company type is required';
  }

  return undefined;
}

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  companyTypeInput: {
    height: 55,
  },
});

interface CompanyTypesInputProps {
  disabled?: boolean;
  isEdit?: boolean;
  onSelectedTypesChange: (selectedTypes: CompanyTypeFragment[]) => void;
  selectedIds: number[];
  InputLabelProps?: Partial<InputLabelProps>;
}

export default function CompanyTypesInput({
  disabled,
  isEdit,
  onSelectedTypesChange,
  selectedIds,
  InputLabelProps,
}: CompanyTypesInputProps) {
  const { loading, data } = useCompanyTypesQuery();
  const { errors, control } = useFormContext();
  const classes = useStyles();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        {...InputLabelProps}
        htmlFor="companyTypes"
        error={!!errors.companyTypes}
        shrink={isEdit ? true : undefined}
        disabled={disabled}
        id="companyTypesLabel"
      >
        Type
      </InputLabel>

      <Controller
        control={control}
        name="companyTypes"
        rules={{
          validate: validateCompanyTypes,
        }}
        defaultValue={selectedIds}
        render={({ onChange }) => (
          <Select
            label="Type"
            labelId="companyTypesLabel"
            error={!!errors.companyTypes}
            inputProps={{
              name: 'companyTypes',
              id: 'companyTypesInput',
            }}
            disabled={disabled}
            multiple
            value={selectedIds}
            onChange={(e) => {
              const ids = e.target.value as number[];
              const selected = data?.companyTypes.filter((ct) => ids.includes(ct.id));
              onSelectedTypesChange(selected ?? []);
              onChange(ids);
            }}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {(selected as number[]).map((value) => {
                  const companyType = data?.companyTypes.find((ct) => ct.id === value);
                  return <Chip key={value} label={companyType?.name} className={classes.chip} />;
                })}
              </div>
            )}
            className={classes.companyTypeInput}
          >
            {!loading &&
              data?.companyTypes &&
              data?.companyTypes.length > 0 &&
              data?.companyTypes.map((companyType) => (
                <MenuItem key={companyType.id} value={companyType.id}>
                  {companyType.name}
                </MenuItem>
              ))}
          </Select>
        )}
      />

      <FormHelperText error={!!errors.companyTypes}>{(errors.companyTypes as any)?.message}</FormHelperText>
    </FormControl>
  );
}
