import { Checkbox, FormControlLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce/lib';
import CompanyTypes from '../../../constants/CompanyTypes';
import Roles from '../../../constants/Roles';
import useAuth from '../../../hooks/useAuth';
import { CompanyQuery, useCompanyAdminsByNameLazyQuery, useCompanyTypesQuery, User } from '../../../queries';
import CompanyTypesInput from '../../CompanyTypesInput';

export type UserLookup = Pick<User, 'id' | 'fullName' | 'email'> | null;

interface CompanyDetailsAddEditProps {
  isEdit: boolean;
  control: Control<any>;
  companyError: string | undefined;
  updateSiteAdmin: (newValue: UserLookup) => void;
  selectedSiteAdmin: UserLookup;
  adminEmailError: string | undefined;
  existingCompany: CompanyQuery | undefined;
}

const useStyles = makeStyles({
  input: {
    maxWidth: 400,
  },
});

export default function CompanyDetailsAddEdit(props: CompanyDetailsAddEditProps) {
  const [siteAdminInputValue, setSiteAdminInputValue] = useState('');
  const [debouncedTextCriteria] = useDebounce(siteAdminInputValue, 300);
  const [selectedCompanyTypes, setSelectedCompanyTypes] = useState<number[]>([]);

  const [getAdminUsers, { loading, data }] = useCompanyAdminsByNameLazyQuery();
  const { data: companyTypes } = useCompanyTypesQuery();
  const { register, errors, control } = useFormContext();
  const { hasRole } = useAuth();
  const classes = useStyles();

  const installerType = companyTypes?.companyTypes.find((ct) => ct.code === CompanyTypes.Installer);

  useEffect(() => {
    if (debouncedTextCriteria && debouncedTextCriteria.length >= 1) {
      if (props.existingCompany) {
        getAdminUsers({
          variables: {
            companyId: props.existingCompany.company.id,
            name: debouncedTextCriteria,
            take: 10,
          },
        });
      }
    }
  }, [debouncedTextCriteria, getAdminUsers]);

  useEffect(() => {
    if (props.existingCompany) {
      setSelectedCompanyTypes(props.existingCompany.company.types.map((t) => t.id));
    }
  }, [props.existingCompany]);

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} xs={12}>
        <TextField
          name="companyName"
          label="Company name"
          variant="outlined"
          fullWidth
          error={!!props.companyError}
          helperText={props.companyError}
          inputRef={register({
            required: 'Company name required',
          })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="contactPhone"
          label="Contact phone"
          variant="outlined"
          fullWidth
          type="phone"
          error={!!errors.contactPhone}
          helperText={errors?.contactPhone?.message}
          inputRef={register({
            required: 'Contact phone required',
          })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Grid item xs={12}>
        <CompanyTypesInput
          disabled={props.isEdit && !hasRole(Roles.SuperUser)}
          isEdit={props.isEdit}
          selectedIds={selectedCompanyTypes}
          onSelectedTypesChange={(types) => setSelectedCompanyTypes(types.map((t) => t.id))}
        />
      </Grid>
      {installerType && selectedCompanyTypes.includes(installerType.id) && (
        <>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name={'isT1Contractor'}
                  defaultValue={false}
                  control={control}
                  render={({ value, onChange }) => (
                    <Checkbox color="primary" checked={value} onChange={(e) => onChange(e.target.checked)} />
                  )}
                />
              }
              label="T1 Contractor"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name={'canReportInstallations'}
                  defaultValue={false}
                  control={control}
                  render={({ value, onChange }) => (
                    <Checkbox color="primary" checked={value} onChange={(e) => onChange(e.target.checked)} />
                  )}
                />
              }
              label="Can report installations"
            />
          </Grid>
        </>
      )}
      <Grid item lg={6} xs={12}>
        {(hasRole(Roles.SuperUser) && props.isEdit && (
          <Controller
            control={props.control}
            name="adminName"
            rules={{
              required: 'Admin name required',
            }}
            render={({ onChange }) => (
              <Autocomplete
                fullWidth
                className={classes.input}
                getOptionLabel={(option) => option.fullName ?? ''}
                options={data?.companyAdminsByName ?? []}
                value={props.selectedSiteAdmin}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={props.selectedSiteAdmin?.fullName}
                    label="Admin name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.adminName}
                  />
                )}
                onInputChange={(_, newInputValue) => setSiteAdminInputValue(newInputValue)}
                onChange={(_, newValue: UserLookup) => {
                  props.updateSiteAdmin(newValue);
                  onChange(newValue?.fullName);
                }}
                getOptionSelected={(option, value) => option.email === value.email}
                loading={loading}
                autoComplete
              />
            )}
          />
        )) || (
          <TextField
            name="adminName"
            label="Admin name"
            variant="outlined"
            fullWidth
            error={!!errors.adminName}
            helperText={errors?.adminName?.message}
            inputRef={register({
              required: 'Admin name required',
            })}
            disabled={props.isEdit}
            InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
          />
        )}
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="adminEmail"
          label="Admin email"
          variant="outlined"
          fullWidth
          error={!!props.adminEmailError}
          helperText={props.adminEmailError}
          inputRef={register({
            required: 'Admin email required',
            pattern: {
              value: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address',
            },
          })}
          disabled={props.isEdit}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Box marginTop={3}>
          <Typography variant="h6">Billing information</Typography>
        </Box>
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="billingEmail"
          label="Billing email"
          variant="outlined"
          fullWidth
          error={!!errors.billingEmail}
          helperText={errors?.billingEmail?.message}
          inputRef={register({
            required: 'Billing email required',
            pattern: {
              value: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid billing email address',
            },
          })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Grid item lg={8} xs={12}>
        <TextField
          name="billingAddressLine1"
          label="Address line 1"
          variant="outlined"
          fullWidth
          error={!!errors.billingAddressLine1}
          helperText={errors?.billingAddressLine1?.message}
          inputRef={register({
            required: 'Address line 1 required',
          })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Grid item lg={8} xs={12}>
        <TextField
          name="billingAddressLine2"
          label="Address line 2"
          variant="outlined"
          fullWidth
          error={!!errors.billingAddressLine2}
          helperText={errors?.billingAddressLine2?.message}
          inputRef={register({
            required: 'Address line 2 required',
          })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="billingCity"
          label="Town/city"
          variant="outlined"
          fullWidth
          error={!!errors.billingCity}
          helperText={errors?.billingCity?.message}
          inputRef={register({
            required: 'Town/city required',
          })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Hidden mdDown>
        <Grid item lg={6} sm={false}></Grid>
      </Hidden>
      <Grid item lg={3} xs={12}>
        <TextField
          name="billingPostcode"
          label="Postcode"
          variant="outlined"
          fullWidth
          error={!!errors.billingPostcode}
          helperText={errors?.billingPostcode?.message}
          inputRef={register({
            required: 'Postcode required',
          })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
    </Grid>
  );
}
