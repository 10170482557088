import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import LoadingButton from '../components/LoadingButton';
import Alert from '../components/Alert';
import { usePasswordResetRequestMutation } from '../queries';
import { GraphQLError } from 'graphql/error';
import TextField from '@material-ui/core/TextField';
import PasswordResetRequested from '../components/reset-password/PasswordResetRequested';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: 16,
    height: 50,
  },
  input: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface ForgotPasswordFormData {
  email: string;
}
export default function ForgotPassword() {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { handleSubmit, register, errors, formState } = useForm<ForgotPasswordFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [resetPasswordMutation, { loading, data }] = usePasswordResetRequestMutation();
  const classes = useStyles();

  const resetPassword = async (values: ForgotPasswordFormData) => {
    try {
      await resetPasswordMutation({
        variables: {
          userEmail: values.email,
        },
      });
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        setErrorMessage(gqlerror.message);
        setIsError(true);
      });
    }
  };

  if (data) {
    return <PasswordResetRequested />;
  }
  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box paddingTop={3.5} paddingX={6} paddingBottom={8}>
        <Box marginBottom={6}>
          <Typography align="center" variant="h4" gutterBottom>
            Forgot your password?
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography variant="body2" gutterBottom>
            Enter your email address below and we we'll send you instructions to reset your password
          </Typography>
        </Box>
        <Alert
          text={errorMessage}
          visible={isError}
          onClose={() => {
            setIsError(false);
          }}
        />
        <form onSubmit={handleSubmit(resetPassword)}>
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors?.email?.message}
            inputRef={register({
              required: 'Email required',
              pattern: {
                value: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            size="large"
            loading={loading}
            disabled={!formState.isValid || loading || isError}
            className={classes.button}
          >
            Send
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
}
