import { StepIconProps } from '@material-ui/core/StepIcon';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import Error from '@material-ui/icons/Error';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'rgba(255, 255, 255, 0.1)',
    display: 'flex',
    height: 31,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
  error: {
    color: theme.palette.error.main,
    zIndex: 1,
    fontSize: 18,
  },
}));

export default function CustomStepIcon({ active, completed, error }: StepIconProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {error && <Error className={classes.error} />}
      {!error && (completed ? <Check className={classes.completed} /> : <div className={classes.circle} />)}
    </div>
  );
}
