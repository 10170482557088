import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import React from 'react';
import { DoorHistoryFragment } from '../../../../queries';
import StandardGridItem from './details/StandardGridItem';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    color: 'rgba(255, 255, 255, 0.87)',
    fontSize: 20,
    letterSpacing: 0.25,
    lineHeight: '30px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    opacity: 0.6,
  },
  subtitle: {
    color: '#fff',
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  summaryContainer: {
    overflow: 'visible',
  },
  detailsHeader: {
    paddingTop: theme.spacing(1.5),
    fontSize: 18,
    fontWeight: 500,
  },
}));

interface DoorHistoryDetailDialogProps {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
  actorName?: string;
  children?: React.ReactNode;
}

export default function DoorHistoryDetailDialog({
  open,
  onClose,
  history,
  actorName = 'User',
  children,
}: DoorHistoryDetailDialogProps) {
  const classes = useStyles();
  const createdAt = dayjs(history.createdAt);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" scroll="paper">
      <DialogTitle>
        <Typography variant="h6" component="h2" className={classes.title}>
          {history.historyEventType.name}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.summaryContainer}>
        <Grid container spacing={3}>
          <StandardGridItem title={actorName} value={`${history.addedByName} - ${history.addedByCompanyName}`} />
          <StandardGridItem title="Date" value={`${createdAt.format('D MMMM YYYY')} at ${createdAt.format('HH:mm')}`} />
        </Grid>
      </DialogContent>
      {children && (
        <>
          <DialogContent>
            <Typography variant="h6" className={classes.detailsHeader}>
              Details
            </Typography>
          </DialogContent>
          <DialogContent className={classes.summaryContainer}>{children}</DialogContent>
        </>
      )}
    </Dialog>
  );
}
