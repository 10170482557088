import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import CompanyDetails from '../components/company/CompanyDetails';
import Layout from '../components/Layout';
import {
  useCompanyQuery,
  User,
  useDisableUsersMutation,
  useEnableUsersMutation,
  useGenerateApiKeyMutation,
} from '../queries';
import * as Routes from '../constants/Routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import CompanyUsers from '../components/company/CompanyUsers';
import ErrorButton from '../components/ErrorButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Roles from '../constants/Roles';
import useAuth from '../hooks/useAuth';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import CompanyFiles from '../components/company/edit/CompanyFiles';
import { Photo } from '../constants/DocumentTypes';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useDialog from '../hooks/useDialog';
import DetailBox from '../components/company/DetailBox';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 3),
  },
  divider: {
    margin: theme.spacing(0, -3),
    marginTop: theme.spacing(2),
  },
}));
interface CompanyParams {
  id: string;
}

export default function Company() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [activeButton, setActivebutton] = useState('invite');
  const [selectedUsers, setSelectedUsers] = useState<Partial<User>[]>([]);
  const [refreshUsers, setRefreshUsers] = useState<Date>();
  const [apiKey, setApiKey] = useState<string | undefined>();
  const { id } = useParams<CompanyParams>();
  const history = useHistory();
  const [disableUsersMutation] = useDisableUsersMutation();
  const [enableUsersMutation] = useEnableUsersMutation();
  const [generateApiKeyMutation] = useGenerateApiKeyMutation();
  const [confirmingDisableUsers, setConfirmingDisableUsers] = useState(false);
  const [confirmingEnableUsers, setConfirmingEnableUsers] = useState(false);
  const { hasRole, loggedInUser } = useAuth();
  const { showDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, data } = useCompanyQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const userCompanyName = loggedInUser?.companyName;
  const companyName = hasRole(Roles.SuperUser) ? data?.company.name : userCompanyName;

  useEffect(() => {
    if (data?.company) {
      setApiKey(data.company.apiKey ?? undefined);
    }
  }, [data]);

  const onTabChange = (_: any, value: number) => {
    setTabIndex(value);
  };

  const onEdit = () => {
    history.push(`${Routes.Companies}/${id}/edit`);
  };

  const disableSelectedUsers = async () => {
    const validIds: number[] = [];
    selectedUsers.forEach((u) => {
      if (u && u.id) {
        validIds.push(parseInt(u.id));
      }
    });

    try {
      await disableUsersMutation({
        variables: {
          idListInput: {
            ids: validIds,
          },
        },
      });
      setSelectedUsers([]);
      setRefreshUsers(new Date());
      const message =
        validIds.length > 1
          ? `The selected users have been disabled successfully.`
          : `${selectedUsers[0].fullName} has been disabled successfully.`;
      enqueueSnackbar(message, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('An error has occurred executing the action.', { variant: 'error' });
    }
  };

  const enableSelectedUsers = async () => {
    const validIds: number[] = [];
    selectedUsers.forEach((u) => {
      if (u && u.id) {
        validIds.push(parseInt(u.id));
      }
    });

    try {
      await enableUsersMutation({
        variables: {
          idListInput: {
            ids: validIds,
          },
        },
      });
      setSelectedUsers([]);
      setRefreshUsers(new Date());
      const message =
        validIds.length > 1
          ? `The selected users have been enabled successfully.`
          : `${selectedUsers[0].fullName} has been enabled successfully.`;
      enqueueSnackbar(message, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('An error has occurred executing the action.', { variant: 'error' });
    }
  };
  const onEnableClicked = async () => {
    setConfirmingEnableUsers(true);
  };

  const onDisableClicked = async () => {
    setConfirmingDisableUsers(true);
  };

  const onInviteUserClicked = async () => {
    history.push(`${Routes.Companies}/${id}/add-user`, {
      companyName: companyName,
    });
  };

  const onResetApiKey = async () => {
    const reset = async () => {
      try {
        const result = await generateApiKeyMutation({
          variables: {
            companyId: id,
          },
        });
        setApiKey(result.data?.generateApiKey);
        enqueueSnackbar('API key reset succesfully.', { variant: 'success', preventDuplicate: false });
      } catch (e) {
        enqueueSnackbar('An error has occurred resetting the API key.', { variant: 'error', preventDuplicate: false });
      }
    };

    if (apiKey) {
      showDialog({
        dialogText: 'Are you sure you want to reset the API key?',
        okText: 'Yes',
        cancelText: 'No',
        onClose: () => {
          reset();
        },
      });
    } else {
      reset();
    }
  };

  useEffect(() => {
    const nbActiveUsersSelected = selectedUsers.filter((u) => !u.deletedAt).length;
    const nbDisabledUsersSelected = selectedUsers.filter((u) => u.deletedAt).length;
    if (selectedUsers.length > 0) {
      if (nbActiveUsersSelected === 0) {
        setActivebutton('enable');
      } else if (nbDisabledUsersSelected === 0) {
        setActivebutton('disable');
      } else {
        setActivebutton('invite');
      }
    } else {
      setActivebutton('invite');
    }
  }, [selectedUsers]);

  const getBreadcrumb = () => {
    return (
      <>
        {hasRole(Roles.SuperUser) && (
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href={Routes.Companies}>
              <Typography variant="body2">Companies</Typography>
            </Link>
            <Typography variant="body2">{data?.company.name}</Typography>
          </Breadcrumbs>
        )}
      </>
    );
  };

  const getCompanyName = () => {
    return <Typography variant="h5">{loading ? <Skeleton width="150px" /> : companyName}</Typography>;
  };
  return (
    <Layout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          {getCompanyName()}
          {loading && <Skeleton width="175px" />}
          {!loading && getBreadcrumb()}
        </Box>
        <Box minWidth={96}>
          {(tabIndex === 0 || tabIndex === 2) && (
            <Button variant="contained" color="primary" fullWidth onClick={onEdit}>
              Edit
            </Button>
          )}
          {tabIndex === 1 && activeButton === 'invite' && (
            <Button variant="contained" color="primary" fullWidth onClick={onInviteUserClicked}>
              Invite new user
            </Button>
          )}
          {tabIndex === 1 && activeButton === 'enable' && (
            <Button variant="contained" color="primary" fullWidth onClick={onEnableClicked}>
              Enable
            </Button>
          )}
          {tabIndex === 1 && activeButton === 'disable' && (
            <ErrorButton variant="contained" fullWidth onClick={onDisableClicked}>
              Disable
            </ErrorButton>
          )}
        </Box>
      </Box>
      <Box mt={1}>
        <Tabs value={tabIndex} onChange={onTabChange} aria-label="Details or users" indicatorColor="primary">
          <Tab label="Details" />
          <Tab label="Users" />
          <Tab label="Certification" />
        </Tabs>
        <Divider />
        {tabIndex === 0 && (
          <CompanyDetails loading={loading} companyQuery={data} apiKey={apiKey} onResetApiKey={onResetApiKey} />
        )}
        {tabIndex === 1 && (
          <CompanyUsers
            companyId={Number(id)}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            refresh={refreshUsers}
          />
        )}
        {tabIndex === 2 && (
          <>
            <Box pt={3}>
              <DetailBox
                title="Additional text"
                rows={[
                  {
                    label: 'Installation certificate',
                    value: data?.company.installationCertificateAdditionalText ?? '-',
                  },
                  {
                    label: 'Inspection certificate',
                    value: data?.company.inspectionCertificateAdditionalText ?? '-',
                  },
                ]}
              />
            </Box>

            <Box pt={3}>
              <Paper className={classes.container}>
                <Typography variant="h5">Accreditation Logos</Typography>
                <Divider className={classes.divider} />
                <CompanyFiles
                  company={data?.company}
                  documentTypeCodes={[Photo]}
                  noFilesMessage="There are no photos to display."
                />
              </Paper>
            </Box>
          </>
        )}
      </Box>
      <Dialog open={confirmingDisableUsers}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">Are you sure you want to disable the selected users?</Typography>{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setConfirmingDisableUsers(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setConfirmingDisableUsers(false);
              disableSelectedUsers();
            }}
          >
            Disable users
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmingEnableUsers}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">Are you sure you want to enable the selected users?</Typography>{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setConfirmingEnableUsers(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setConfirmingEnableUsers(false);
              enableSelectedUsers();
            }}
          >
            Enable users
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}
