import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import DoorModelContext from '../../context/DoorModelContext';
import { DoorModelFormData } from '../../pages/DoorModelAdd';
import PartsListFields from '../PartsListFields';

interface DoorModelPartsListEditProps {
  onContinue: () => void;
  onBack: () => void;
}

export default function DoorModelPartsListEdit({ onBack, onContinue }: DoorModelPartsListEditProps) {
  const { doorModel, setDoorModel } = useContext(DoorModelContext);

  const form = useForm<DoorModelFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: doorModel,
  });

  const { handleSubmit, register, formState, getValues } = form;

  const onBackClick = () => {
    const currentFormValues = getValues();
    setDoorModel((prev: DoorModelFormData) => ({ ...prev, ...currentFormValues }));
    onBack();
  };

  const submit = (values: DoorModelFormData) => {
    setDoorModel((prev: DoorModelFormData) => ({ ...prev, ...values }));
    onContinue();
  };

  return (
    <Box p={5} pt={2}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={3}>
            <PartsListFields />
            <Grid item xs={12}>
              <TextField
                name="additionalComments"
                label="Additional comments"
                variant="outlined"
                fullWidth
                inputRef={register}
                inputProps={{ maxLength: 1024 }}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="space-between" marginTop={3}>
            <Button variant="outlined" onClick={onBackClick}>
              Back
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={!formState.isValid}>
              Continue
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
