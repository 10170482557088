import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  DoorInspectionOverview,
  useDashboardAccountInspectionsOverviewQuery,
  useDashboardSiteInspectionsOverviewQuery,
} from '../../../queries';
import Assignment from '@material-ui/icons/Assignment';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import LoadingOverlay from './LoadingOverlay';
import ErrorOverlay from './ErrorOverlay';
import LegendItem from './LegendItem';
import NoDataIcon from './NoDataIcon';
import DoorStatus from '../../../constants/Status';
import DoorStage from '../../manufactured-doors/DoorStage';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#2E3047',
    borderRadius: '4px',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
  },
  chartContainer: {
    padding: '30px',
    position: 'relative',
    display: 'flex',
    flex: 1,
  },
  titleContainer: {
    paddingLeft: '20px',
    paddingTop: '16px',
    paddingBottom: '16px',
    borderBottomColor: '#97979780',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    alignItems: 'center',
  },
  title: {
    fontSize: '18.5px',
  },
  noDataContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
  },
  legendContainer: { flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly' },

  breakdownContainer: { gap: '20px', display: 'flex', flexDirection: 'column' },
}));

const formatPercent = (value?: number, total?: number) => {
  if (total === undefined || value === undefined) {
    return undefined;
  }
  return (value / total).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 });
};

type InspectionOverviewStatus = 'failed' | 'passed' | 'overdue' | 'due' | 'noFreq';

type ProcessedStatusData = {
  total: number;
  items: { id: string; name: string; value: number; doorStatus?: DoorStatus }[];
};

type ProcessedData = {
  total: number;
  failed: ProcessedStatusData;
  passed: ProcessedStatusData;
  due: ProcessedStatusData;
  overdue: ProcessedStatusData;
  noFreq: ProcessedStatusData;
};

const useInspectionOverViewData = (siteId?: string | null) => {
  const {
    loading: loadingAccountData,
    data: accountData,
    error: accountError,
  } = useDashboardAccountInspectionsOverviewQuery({
    fetchPolicy: 'cache-and-network',
    skip: Boolean(siteId),
  });

  const { loading: loadingSiteData, data: siteData, error: siteError } = useDashboardSiteInspectionsOverviewQuery({
    variables: { siteId: siteId ?? null },
    fetchPolicy: 'cache-and-network',
    skip: !Boolean(siteId),
  });

  const data: ProcessedData | null = useMemo(() => {
    if (siteId && siteData) {
      const processType = (type: InspectionOverviewStatus) => {
        const matchingDoors: Pick<
          DoorInspectionOverview,
          'doorId' | 'locationOrDoorRef' | 'inspectionOverviewStatus' | 'doorStatusCode'
        >[] =
          siteData?.dashboard?.siteInspectionsOverview?.doors.filter((s) => s.inspectionOverviewStatus === type) ?? [];

        return {
          total: matchingDoors.length,
          items: matchingDoors
            .map((s) => ({
              id: s.doorId,
              name: s.locationOrDoorRef,
              value: 0,
              doorStatus: s.doorStatusCode as DoorStatus.InstallerFailedSignOff,
            }))
            .sort((a, b) => b.name.localeCompare(a.name)),
        };
      };
      const result: ProcessedData = {
        total: 0,
        failed: processType('failed'),
        passed: processType('passed'),
        overdue: processType('overdue'),
        due: processType('due'),
        noFreq: processType('noFreq'),
      };

      result.total =
        result.failed.total + result.passed.total + result.overdue.total + result.due.total + result.noFreq.total;
      return result;
    } else if (!siteId && accountData) {
      const processType = (type: InspectionOverviewStatus) => {
        return {
          total:
            accountData?.dashboard?.accountInspectionsOverview?.sites
              .map((s) => s[type])
              .reduce((acc, value) => (acc += value), 0) ?? 0,
          items:
            accountData?.dashboard?.accountInspectionsOverview?.sites
              .filter((s) => s[type] > 0)
              .map((s) => ({ id: s.id, name: s.name, value: s[type] }))
              .sort((a, b) => b.value - a.value) ?? [],
        };
      };
      const result: ProcessedData = {
        total: 0,
        failed: processType('failed'),
        passed: processType('passed'),
        overdue: processType('overdue'),
        due: processType('due'),
        noFreq: processType('noFreq'),
      };

      result.total =
        result.failed.total + result.passed.total + result.overdue.total + result.due.total + result.noFreq.total;
      return result;
    }

    return null;
  }, [accountData, siteData, siteId]);

  const loading = siteId ? loadingSiteData : loadingAccountData;
  const error = siteId ? siteError : accountError;

  return { loading, error, data };
};

type StatusButtonProps = {
  selected: boolean;
  onClick: () => void;
  label: string;
  total: number;
};

const getStatusButton = ({ selected, onClick, total, label }: StatusButtonProps) => {
  const textColor = selected ? '#FFFFFF' : '#979797';
  return (
    <Button variant={selected ? 'contained' : 'outlined'} onClick={onClick} style={{ borderColor: '#979797' }}>
      <Typography variant="subtitle1" style={{ fontWeight: 700, fontSize: '13px', color: textColor }}>
        {`${label} `}
        <Typography
          variant="subtitle1"
          component="span"
          style={{ fontWeight: 400, fontSize: '13px', color: textColor }}
        >
          {total}
        </Typography>
      </Typography>
    </Button>
  );
};

type Props = {
  siteId?: string | null;
  onSiteSelected: (site: { id: string; name: string }) => void;
};

export default function InspectionsOverviewWidget({ siteId, onSiteSelected }: Props) {
  const classes = useStyles();
  const { loading, data: processedData, error } = useInspectionOverViewData(siteId);

  const [selectedTab, setSelectedTab] = useState<InspectionOverviewStatus>('passed');
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Assignment />
        <Typography className={classes.title} variant="h5">
          Inspections overview
        </Typography>
      </Box>
      <Box className={classes.chartContainer}>
        {!error && processedData?.total === 0 && (
          <Box className={classes.noDataContainer}>
            <NoDataIcon />
            <Typography variant="subtitle1">{`There is no data to display${
              siteId ? ' for this site' : ''
            }`}</Typography>
          </Box>
        )}
        {processedData && processedData.total > 0 && (
          <>
            <Grid container>
              <Grid item lg={12} xl={6} style={{ width: '100%' }}>
                <Box className={classes.legendContainer}>
                  <LegendItem
                    name="Passed"
                    percent={formatPercent(processedData?.passed.total, processedData?.total)}
                    color="#02E495"
                    loading={loading}
                  />
                  <LegendItem
                    name="Failed"
                    percent={formatPercent(processedData?.failed.total, processedData?.total)}
                    color="#FF6860"
                    loading={loading}
                  />
                  <LegendItem
                    name="Due"
                    percent={formatPercent(processedData?.due.total, processedData?.total)}
                    color="#656DFF"
                    loading={loading}
                  />
                  <LegendItem
                    name="Overdue"
                    percent={formatPercent(processedData?.overdue.total, processedData?.total)}
                    color="#FF0000"
                    loading={loading}
                  />
                  <LegendItem
                    name="No freq"
                    percent={formatPercent(processedData?.noFreq.total, processedData?.total)}
                    color="#B7B7B7"
                    loading={loading}
                  />
                </Box>
                <Box style={{ minHeight: '400px' }}>
                  {processedData && (
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart margin={{ top: 10 }}>
                        <defs>
                          <filter id="shadow" height="200%">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5189_706" />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_5189_706"
                              result="shape"
                            />
                          </filter>
                          <linearGradient
                            id="passedBg"
                            x1="132"
                            y1="12.9998"
                            x2="132"
                            y2="243"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#02E495" />
                            <stop offset="1" stopColor="#08B87B" />
                          </linearGradient>
                          <linearGradient
                            id="failedBg"
                            x1="132"
                            y1="12.9998"
                            x2="132"
                            y2="243"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FF6860" />
                            <stop offset="1" stopColor="#FF4E6A" />
                          </linearGradient>
                          <linearGradient
                            id="dueBg"
                            x1="257.666"
                            y1="106.656"
                            x2="217.007"
                            y2="177.079"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#656DFF" />
                            <stop offset="1" stopColor="#4353FF" />
                          </linearGradient>
                        </defs>

                        <Pie
                          isAnimationActive={false}
                          data={[
                            processedData.passed,
                            processedData.failed,
                            processedData.due,
                            processedData.overdue,
                            processedData.noFreq,
                          ]}
                          dataKey="total"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          innerRadius={115}
                          outerRadius={150}
                          fill="#82ca9d"
                          strokeWidth={0}
                        >
                          <Cell fill="url(#passedBg)" filter="url(#shadow)"></Cell>
                          <Cell fill="url(#failedBg)" filter="url(#shadow)"></Cell>
                          <Cell fill="url(#dueBg)" filter="url(#shadow)"></Cell>
                          <Cell fill="#FF0000" filter="url(#shadow)"></Cell>
                          <Cell fill="#B7B7B7" filter="url(#shadow)"></Cell>
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </Box>
              </Grid>
              <Grid item lg={12} xl={6} className={classes.breakdownContainer} style={{ width: '100%' }}>
                {processedData && (
                  <>
                    <ButtonGroup
                      color="secondary"
                      aria-label="outlined primary button group"
                      disableElevation
                      fullWidth
                      size="large"
                    >
                      {getStatusButton({
                        label: 'Passed',
                        total: processedData.passed.total,
                        onClick: () => {
                          setSelectedTab('passed');
                        },
                        selected: selectedTab === 'passed',
                      })}
                      {getStatusButton({
                        label: 'Failed',
                        total: processedData.failed.total,
                        onClick: () => {
                          setSelectedTab('failed');
                        },
                        selected: selectedTab === 'failed',
                      })}
                      {getStatusButton({
                        label: 'Due',
                        total: processedData.due.total,
                        onClick: () => {
                          setSelectedTab('due');
                        },
                        selected: selectedTab === 'due',
                      })}
                      {getStatusButton({
                        label: 'Overdue',
                        total: processedData.overdue.total,
                        onClick: () => {
                          setSelectedTab('overdue');
                        },
                        selected: selectedTab === 'overdue',
                      })}

                      {getStatusButton({
                        label: 'No Freq',
                        total: processedData.noFreq.total,
                        onClick: () => {
                          setSelectedTab('noFreq');
                        },
                        selected: selectedTab === 'noFreq',
                      })}
                    </ButtonGroup>
                    <Box style={{ overflowY: 'auto', maxHeight: '400px' }}>
                      <Table aria-label="Details table">
                        <TableBody>
                          {processedData &&
                            processedData[selectedTab].items.map((d, i) => (
                              <TableRow
                                hover
                                key={i}
                                style={{ cursor: siteId ? undefined : 'pointer' }}
                                onClick={() => {
                                  if (siteId) {
                                    return;
                                  }
                                  onSiteSelected({ id: d.id, name: d.name });
                                }}
                              >
                                <TableCell>
                                  <Typography
                                    variant="subtitle1"
                                    style={{ color: '#FFFFFF' }}
                                  >{`${d.name}`}</Typography>
                                </TableCell>
                                <TableCell align="right" style={{ width: ' 150px' }}>
                                  {d.doorStatus ? <DoorStage doorStageCode={d.doorStatus} /> : <>{d.value}</>}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}

        <ErrorOverlay show={Boolean(error)} />
        <LoadingOverlay show={loading} />
      </Box>
    </Box>
  );
}
