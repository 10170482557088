import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import * as Routes from '../../../constants/Routes';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';

export default function ReportsMenuItem() {
  const location = useLocation();
  return (
    <ProtectedSection roles={[Roles.FacilityManagerAdmin, Roles.InspectorAdmin, Roles.InstallerAdmin]}>
      <ListItem button component={Link} to={Routes.Reports} selected={location.pathname.startsWith(Routes.Reports)}>
        <ListItemIcon>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText primary={'Reports'} />
      </ListItem>
    </ProtectedSection>
  );
}
