import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import Roles from '../../constants/Roles';
import useAuth from '../../hooks/useAuth';
import { DoorFilterStages } from '../../queries';
import TallTab from '../TallTab';

const useStyles = makeStyles({
  selectedMenuItem: {
    color: '#fff',
  },
});

export interface ManufacturedDoorsSearchFilter {
  active?: boolean;
  textFilter?: string;
  statuses?: string[];
  doorStage: DoorFilterStages;
  installationFrom?: Dayjs | null;
  installationTo?: Dayjs | null;
  inspectionFrom?: Dayjs | null;
  inspectionTo?: Dayjs | null;
}

interface ManufacturedDoorsFilterProps {
  onFilterChanged: (filter: ManufacturedDoorsSearchFilter) => void;
}

export default function ManufacturedDoorsFilter(props: ManufacturedDoorsFilterProps) {
  const classes = useStyles();
  const { hasRole } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<ManufacturedDoorsSearchFilter>({
    active: true,
    doorStage: DoorFilterStages.Ready,
  } as ManufacturedDoorsSearchFilter);
  const [textCriteria, setTextCriteria] = useState<string | undefined>();
  const [selectedTab, setSelectedTab] = useState(DoorFilterStages.Ready);
  const [debouncedTextCriteria] = useDebounce(textCriteria, 300); // this is to prevent calling the api with every key stroke in the text input.

  useEffect(() => {
    if (filter && props.onFilterChanged) {
      props.onFilterChanged(filter);
    }
  }, [filter, props]);

  useEffect(() => {
    setFilter((prevFilter) => {
      return { ...prevFilter, textFilter: debouncedTextCriteria };
    });
  }, [debouncedTextCriteria, setFilter]);

  const tabChanged = (_: any, newValue: string) => {
    const doorStage = newValue as DoorFilterStages;
    setSelectedTab(doorStage);
    setFilter((prevFilter) => ({ ...prevFilter, doorStage }));
  };

  const onTextCriteriaChanged = (event: any) => {
    setTextCriteria(event.currentTarget.value);
  };

  const handleMenuItemClick = (menuOption: string) => {
    let activeFilterValue: undefined | boolean;
    switch (menuOption) {
      case 'nofilter':
        activeFilterValue = undefined;
        break;
      case 'active':
        activeFilterValue = true;
        break;
      case 'disabled':
        activeFilterValue = false;
        break;
    }
    setFilter((prevFilter) => {
      return { ...prevFilter, active: activeFilterValue };
    });

    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" alignItems="flex-end">
          <Tabs value={selectedTab} onChange={tabChanged} indicatorColor="primary">
            <TallTab value={DoorFilterStages.All} label="All" />
            <TallTab value={DoorFilterStages.Ready} label="Ready" />
            <TallTab value={DoorFilterStages.Incomplete} label="Incomplete" />
            {hasRole(Roles.InstallerAdmin) && <TallTab value={DoorFilterStages.Failed} label="Failed" />}
            {/** can't use ProtectedSection here otherwise the tab won't work */}
            {hasRole(Roles.ManufacturerAdmin) && <TallTab value={DoorFilterStages.Transferred} label="Transferred" />}
          </Tabs>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box p={1}>
            <TextField variant="outlined" name="textCriteria" label="Search doors" onChange={onTextCriteriaChanged} />
          </Box>
          <Box pr={2} display="flex" alignItems="center">
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuOpenClick}>
              <FilterListIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Menu id="filter-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => handleMenuItemClick('nofilter')}
          selected={filter.active === undefined}
          classes={{ selected: classes.selectedMenuItem }}
        >
          No filter
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick('active')}
          selected={filter.active === true}
          classes={{ selected: classes.selectedMenuItem }}
        >
          Active
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick('disabled')}
          selected={filter.active === false}
          classes={{ selected: classes.selectedMenuItem }}
        >
          Disabled
        </MenuItem>
      </Menu>
    </>
  );
}
