import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from 'use-debounce/lib';

interface Props {
  searchText: string | undefined;
  onSearchTextChanged: (searchText: string | undefined) => void;
  label: string;
  fieldName?: string;
}

export default function TextFilter({ searchText, onSearchTextChanged, label, fieldName }: Props) {
  const [textCriteria, setTextCriteria] = useState<string | undefined>(searchText);
  const [debouncedTextCriteria] = useDebounce(textCriteria, 300); // this is to prevent calling the api with every key stroke in the text input.

  useEffect(() => {
    onSearchTextChanged(debouncedTextCriteria);
  }, [debouncedTextCriteria]);

  const onTextCriteriaChanged = (event: any) => {
    setTextCriteria(event.currentTarget.value);
  };

  return (
    <>
      <Box display="flex" alignItems="center" p={1} pr={3}>
        <Box display="flex" alignItems="center" mr={1}>
          <SearchIcon />
        </Box>
        <Box>
          <TextField
            name={fieldName ?? 'search'}
            label={label}
            variant="outlined"
            onChange={onTextCriteriaChanged}
            value={textCriteria}
          />
        </Box>
      </Box>
    </>
  );
}
