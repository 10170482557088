import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  name: {
    color: 'rgba(255, 255, 255, 0.87)',
    fontSize: '14px',
    fontWeight: 400,
  },
  percent: { color: 'rgba(255, 255, 255, 0.87)', fontSize: '24px', fontWeight: 700 },
}));

type Props = {
  color: string;
  name: string;
  percent?: string;
  loading: boolean;
};
const LegendItem = ({ color, name, percent, loading }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <div style={{ backgroundColor: color, width: '9px', height: '9px', borderRadius: '9px' }}></div>
      <Box>
        <Typography className={classes.name}>{name}</Typography>
        {loading || percent === undefined ? (
          <Skeleton variant="text" animation="wave">
            <Typography className={classes.percent}>80%</Typography>
          </Skeleton>
        ) : (
          <Typography className={classes.percent}>{percent}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default LegendItem;
