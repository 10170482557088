import React, { useEffect, useMemo, useState } from 'react';

import ReportCard from './ReportCard';
import { useOwnerDoorStateLazyQuery } from '../../queries';
import { baseUrl } from '../../utils/EnvironmentService';

type Props = {
  site?: { id: string; name: string };
};

export default function FMDoorStateReportCard({ site }: Props) {
  const [generateReport, { data: reportGeneratedData, loading: generatingReport }] = useOwnerDoorStateLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [lastDownloadedDocument, setLastDownloadedDocument] = useState<string | null>(null);

  const filename = useMemo(() => {
    const siteName = site?.name ?? 'AllSites';

    return `DoorState_${siteName}.csv`.replaceAll(' ', '');
  }, [site]);

  useEffect(() => {
    if (
      reportGeneratedData?.ownerDoorState.path &&
      reportGeneratedData.ownerDoorState.path !== lastDownloadedDocument
    ) {
      let url = `${baseUrl}${reportGeneratedData.ownerDoorState.path}`;

      setLastDownloadedDocument(reportGeneratedData.ownerDoorState.path ?? null);
      // fetch data from url
      fetch(url, {
        credentials: 'include',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    }
  }, [reportGeneratedData, filename, lastDownloadedDocument]);

  const onGenerate = () => {
    generateReport({
      variables: {
        siteId: site?.id,
      },
    });
  };

  return (
    <ReportCard
      title="Door state"
      description="A summary report of all doors based on site selection"
      requiresDateRange={false}
      site={site}
      onGenerateReport={onGenerate}
      disabled={generatingReport}
    />
  );
}
