import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';

type Props = {
  name: string;
  label: string;
  rules?: RegisterOptions;
};

export default function ControlledDatePicker({ name, label, rules }: Props) {
  const { control, errors } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      rules={rules}
      render={({ onChange, value }) => (
        <KeyboardDatePicker
          value={value}
          onChange={onChange}
          inputVariant="outlined"
          format="DD/MM/YYYY"
          label={label}
          placeholder={label}
          error={Boolean(errors[name])}
        />
      )}
    />
  );
}
