import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CompaniesFilter, { CompaniesSearchFilter } from '../components/companies/CompaniesFilter';
import Layout from '../components/Layout';
import NoResultsRow from '../components/NoResultsRow';
import TableLoading from '../components/TableLoading';
import * as Routes from '../constants/Routes';
import useTable from '../hooks/useTable';
import { useCompaniesQuery } from '../queries';

const useStyles = makeStyles({
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chipCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  chip: {
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
  },
});

export default function Companies() {
  const { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort } = useTable('name');
  const [filter, setFilter] = useState<CompaniesSearchFilter>({
    active: true,
    disabled: false,
    reportPeriodFrom: null,
    reportPeriodTo: null,
    textFilter: null,
  });

  const { loading, error, data } = useCompaniesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: pageSize * page,
      take: pageSize,
      sortDirection,
      sortField,
      active: filter.active,
      disabled: filter.disabled,
      reportPeriodFrom: filter.reportPeriodFrom,
      reportPeriodTo: filter.reportPeriodTo,
      textCriteria: filter.textFilter,
    },
  });
  const history = useHistory();
  const classes = useStyles();

  const onRowClick = (companyId: string) => {
    history.push(`${Routes.Companies}/${companyId}`);
  };

  return (
    <Layout>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Companies</Typography>
        <Button variant="contained" color="primary" onClick={() => history.push(Routes.InviteCompany)}>
          Invite new company
        </Button>
      </Box>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <CompaniesFilter onFilterChanged={(newFilter) => setFilter(newFilter)} />
          <Divider />
          <Table aria-label="Company table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel active={sortField === 'name'} direction={sortDirection} onClick={() => sort('name')}>
                    Company name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'billingEmail'}
                    direction={sortDirection}
                    onClick={() => sort('billingEmail')}
                  >
                    Billing email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'companyTypes'}
                    direction={sortDirection}
                    onClick={() => sort('companyTypes')}
                  >
                    Company type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'passedReports'}
                    direction={sortDirection}
                    onClick={() => sort('passedReports')}
                  >
                    Passed reports
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'status'}
                    direction={sortDirection}
                    onClick={() => sort('status')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoading />}
              {!loading && !error && data?.companies.items.length === 0 && (
                <NoResultsRow message="No companies to display" />
              )}
              {!loading && error && <NoResultsRow message="There was an error fetching companies." />}
              {!loading &&
                !error &&
                data?.companies &&
                data?.companies.items.length > 0 &&
                data?.companies.items.map((company, i) => (
                  <TableRow hover key={i} onClick={() => onRowClick(company.id)} className={classes.tableRow}>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{company.billingEmail}</TableCell>
                    <TableCell className={classes.chipCell}>
                      {company.companyTypes.map((type) => (
                        <Chip key={type} label={type} className={classes.chip} />
                      ))}
                    </TableCell>
                    <TableCell>{company.passedReports}</TableCell>
                    <TableCell>{company.status}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.companies.total ?? 0}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={(_, newPage) => pageChanged(newPage)}
            onChangeRowsPerPage={pageSizeChanged}
          />
        </TableContainer>
      </Box>
    </Layout>
  );
}
