import { RoleFragment } from '../queries';
import Roles from '../constants/Roles';

export const rolePriorityOrder = [
  Roles.SuperUser,
  Roles.ManufacturerAdmin,
  Roles.InstallerAdmin,
  Roles.InspectorAdmin,
  Roles.FacilityManagerAdmin,
  Roles.ServiceEngineerAdmin,
  Roles.Manufacturer,
  Roles.Installer,
  Roles.Inspector,
  Roles.FacilityManager,
  Roles.ServiceEngineer,
];

export const getMainRoleCode = (userRoles: RoleFragment[]) => {
  let minRoleIdx = Number.MAX_SAFE_INTEGER;
  for (const role of userRoles) {
    const roleIdx = rolePriorityOrder.indexOf(role.code);
    minRoleIdx = Math.min(minRoleIdx, roleIdx);
  }

  return rolePriorityOrder[minRoleIdx];
};

export const getOrderedRoleNames = (userRoles: RoleFragment[]): string[] => {
  const orderedRoles: string[] = [];
  rolePriorityOrder.forEach((rolePriority) => {
    userRoles.forEach((role) => {
      if (role.code === rolePriority) {
        orderedRoles.push(role.name);
      }
    });
  });

  return orderedRoles;
};
