import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import LoadingButton from '../components/LoadingButton';
import { useHistory, useLocation } from 'react-router-dom';
import * as Routes from '../constants/Routes';
import Alert from '../components/Alert';
import Roles from '../constants/Roles';
import useAuth from '../hooks/useAuth';
import Version from '../components/Version';
import { Link } from '@material-ui/core';
import { getMainRoleCode } from '../utils/Roles';
import useSiteDoorsFiltersStore from '../store/SiteDoorsFiltersStore';

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: 30,
    height: 50,
  },
  version: {
    position: 'fixed',
    left: '50%',
    bottom: '20px',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    zIndex: -1, // Display behind the login box in tiny screens
  },
  forgotPasswordLink: {
    float: 'right',
  },
});

interface LoginFormData {
  email: string;
  password: string;
}

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  // these next two state properties will be returned from the graphql query, so can be removed
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, register, errors, formState } = useForm<LoginFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { login } = useAuth();
  const { reset } = useSiteDoorsFiltersStore();

  const onLogin = (values: LoginFormData) => {
    reset(); // should be done on logout but there an error updating state on an unmounted component is thrown
    setIsLoading(true);

    login(values.email, values.password)
      .then((loginResponse) => {
        const previousLocation = (location.state as any)?.prevLocation as string | undefined;
        if (previousLocation) {
          history.push(previousLocation, { fromLogin: true });
          return;
        }
        // redirect based on role
        const mainRole = getMainRoleCode(loginResponse.data.roles);

        // The dictionary to identify where to redirect depends on user role
        const roleToRoute = {
          [Roles.SuperUser]: Routes.Companies,
          [Roles.ManufacturerAdmin]: Routes.ManufacturedDoors,
          [Roles.InstallerAdmin]: Routes.MySites,
          [Roles.InspectorAdmin]: Routes.MySites,
          [Roles.FacilityManagerAdmin]: Routes.Dashboard,
          [Roles.FacilityManager]: Routes.MySites,
          [Roles.ServiceEngineerAdmin]: Routes.MySites,
        };

        if (roleToRoute[mainRole]) {
          history.push(roleToRoute[mainRole]);
          return;
        }

        throw new Error('No valid role');
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper elevation={5}>
        <Box paddingTop={3.5} paddingX={6} paddingBottom={8}>
          <Box padding={5} paddingBottom={3}>
            <Typography align="center" variant="h5" gutterBottom>
              <img src="/assets/logo.svg" alt="logo" />
            </Typography>
          </Box>

          <Alert
            text="Sorry, we don't recognise that email or password."
            visible={isError}
            onClose={() => {
              setIsError(false);
            }}
          />
          <form onSubmit={handleSubmit(onLogin)}>
            <TextField
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors?.email?.message}
              inputRef={register({
                required: 'Email required',
                pattern: {
                  value: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email address',
                },
              })}
            />
            <FormControl variant="outlined" fullWidth margin="normal" error={!!errors.password}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                name="password"
                type={showPassword ? 'text' : 'password'}
                labelWidth={80}
                inputRef={register({ required: 'Password required' })}
                aria-describedby="password-helper-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="password-helper-text">{errors?.password?.message}</FormHelperText>
            </FormControl>
            <Link className={classes.forgotPasswordLink} href="/forgot-password">
              Forgot Password?
            </Link>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              size="large"
              disabled={!formState.isValid || isLoading}
              loading={isLoading}
              className={classes.button}
            >
              Login
            </LoadingButton>
          </form>
        </Box>
      </Paper>
      <Version className={classes.version} />
    </Container>
  );
}
