import * as React from 'react';

type Props = {};
const NoDataIcon = (props: Props) => {
  return (
    <svg width={140} height={100} viewBox="0 0 140 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5245_22126)">
        <path
          opacity={0.3}
          d="M99.042 99.781H30.14v-72C30.14 12.463 42.377 0 57.417 0h14.347c15.041 0 27.278 12.463 27.278 27.781v72z"
          fill="#F2F2F2"
        />
        <path
          d="M84.695 99.781H30.14v-72a27.911 27.911 0 015.589-16.846 27.504 27.504 0 015.805-5.74 27.043 27.043 0 016.696-3.58A26.776 26.776 0 0154.482.16a27.117 27.117 0 015.872 0c.127.014.254.027.38.045a26.77 26.77 0 0112.5 4.94 27.69 27.69 0 016.252 6.305 27.91 27.91 0 015.21 16.331v72z"
          fill="#CCC"
        />
        <path
          d="M80.259 67.578c1.668 0 3.02-1.377 3.02-3.076s-1.352-3.076-3.02-3.076-3.02 1.377-3.02 3.076 1.352 3.076 3.02 3.076z"
          fill="#656DFF"
        />
        <path d="M93.397 97.625h-2.315l-1.1-9.092h3.415v9.092z" fill="#FFB8B8" />
        <path
          d="M89.43 96.951h4.463v2.862h-7.274a2.906 2.906 0 01.823-2.024c.261-.265.571-.476.912-.62a2.767 2.767 0 011.075-.218z"
          fill="#2F2E41"
        />
        <path d="M104.912 97.625h-2.314l-1.101-9.092h3.415v9.092z" fill="#FFB8B8" />
        <path
          d="M100.944 96.951h4.464v2.862h-7.274c0-.376.073-.748.214-1.095.141-.347.348-.663.61-.929.26-.265.57-.476.911-.62.341-.144.706-.218 1.075-.218z"
          fill="#2F2E41"
        />
        <path
          d="M81.787 65.111a2.088 2.088 0 01-.273-1.725c.082-.29.224-.56.419-.787a2.03 2.03 0 01.705-.533l7.868-22.084 3.87 2.151-8.982 21.104a2.112 2.112 0 01-.014 1.476 2.064 2.064 0 01-.972 1.093 2.006 2.006 0 01-1.44.16 2.04 2.04 0 01-1.181-.855zM103.73 69.261a2.05 2.05 0 01-.65-.603 2.094 2.094 0 01.104-2.515l-2.437-23.36 4.405.204 1.013 22.979a2.103 2.103 0 01.219 2.75c-.3.405-.736.685-1.225.786-.489.1-.998.015-1.429-.24zM96.701 35.891c2.561 0 4.637-2.114 4.637-4.722 0-2.608-2.076-4.722-4.637-4.722-2.56 0-4.636 2.114-4.636 4.722 0 2.608 2.076 4.722 4.636 4.722z"
          fill="#FFB8B8"
        />
        <path
          d="M103.7 63.948H89.722l.017-.11c.025-.166 2.491-16.62.672-22.002a2.31 2.31 0 01.045-1.625 2.254 2.254 0 011.101-1.178c2.6-1.247 7.59-2.783 11.802.943a5.428 5.428 0 011.414 2.035c.31.78.437 1.624.372 2.463L103.7 63.948z"
          fill="#656DFF"
        />
        <path
          d="M92.407 49.65l-6.293-1.3 2.95-7.12c.176-.689.614-1.279 1.217-1.64a2.6 2.6 0 012-.286 2.648 2.648 0 011.614 1.236c.356.614.459 1.346.285 2.036l-1.773 7.074zM100.467 52.065l-.379-8.833c-.286-1.66.647-3.23 2.082-3.486 1.436-.256 2.838.895 3.126 2.568l1.422 8.253-6.251 1.498z"
          fill="#656DFF"
        />
        <path
          d="M103.235 62.89c2.249 8.725 2.495 19.816 1.888 31.915l-3.021-.384-5.474-23.071-3.02 23.455-3.398-.192c-1.015-12.695-2.004-23.593-.378-30.761l13.403-.961zM99.756 34.818c-.865.939-2.471.435-2.584-.847-.009-.1-.008-.2.002-.299.058-.568.38-1.083.303-1.683a.893.893 0 00-.158-.413c-.69-.94-2.308.42-2.958-.43-.399-.522.07-1.344-.236-1.927-.404-.77-1.6-.39-2.351-.812-.835-.469-.785-1.773-.235-2.567.67-.968 1.845-1.484 3.005-1.558 1.161-.075 2.314.245 3.397.675 1.23.488 2.452 1.164 3.209 2.266.921 1.34 1.01 3.143.549 4.71-.28.954-1.236 2.12-1.943 2.885z"
          fill="#2F2E41"
        />
        <path
          d="M139.718 99.905H.35a.223.223 0 01-.159-.067.231.231 0 010-.324.222.222 0 01.159-.067h139.368c.06 0 .117.024.159.067a.23.23 0 01.065.162.23.23 0 01-.065.162.223.223 0 01-.159.067z"
          fill="#3F3D56"
        />
        <path
          opacity={0.2}
          d="M69.688 78.921h-24.54a1.209 1.209 0 01-.865-.358 1.254 1.254 0 01-.363-.875V21.901c.003-.33.133-.644.363-.875.23-.231.541-.36.864-.358h24.54a1.21 1.21 0 01.865.358c.23.231.36.546.363.875v55.787c-.003.33-.133.644-.363.875-.23.231-.541.36-.864.358z"
          fill="#60616D"
        />
        <path d="M70.915 38.718H43.92v.385h26.995v-.385zM71.009 60.657H44.015v.384h26.994v-.384z" fill="#CCC" />
        <path d="M52.793 20.668h-.378v58.446h.378V20.668zM62.231 20.668h-.378v58.446h.378V20.668z" fill="#CCC" />
      </g>
      <defs>
        <clipPath id="clip0_5245_22126">
          <path fill="#fff" d="M0 0H140V100H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoDataIcon;
