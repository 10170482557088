import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import FilterListIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import SitesExtendedFilterDrawer, { SiteDrawerFilters } from './SitesExtendedFilterDrawer';
import { makeStyles } from '@material-ui/core/styles';
import useSiteDoorsFiltersStore from '../../store/SiteDoorsFiltersStore';
import { useLocationTypesQuery } from '../../queries';

const useStyles = makeStyles(() => ({
  whiteText: {
    color: 'white',
    cursor: 'pointer',
  },
}));

interface Props {
  siteId: string;
}

export default function SitesExtendedFilter({ siteId }: Props) {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { data: locationTypes } = useLocationTypesQuery({ fetchPolicy: 'cache-first' });

  const { filters, saveFilter } = useSiteDoorsFiltersStore();
  const savedFilter = useMemo(() => filters.find((f) => f.siteId === siteId), [siteId, filters]);
  const handleMenuOpenClick = () => {
    setIsDrawerOpen(true);
  };

  const isCustomFilter = useMemo(() => {
    let result = false;
    if (savedFilter && savedFilter.statuses) {
      result =
        !!savedFilter.inspectionFrom ||
        !!savedFilter.inspectionTo ||
        !!savedFilter.installationFrom ||
        !!savedFilter.installationTo ||
        savedFilter.statuses.length !== 11;
    }

    if (savedFilter && savedFilter.locationTypes && locationTypes?.locationTypes) {
      result = result || locationTypes.locationTypes.length + 1 !== savedFilter.locationTypes.length;
    }
    return result;
  }, [savedFilter, locationTypes]);

  const onFilterChange = (newFilter: SiteDrawerFilters) => {
    const { statuses, installationDate, inspectionDate, locationTypes } = newFilter;

    const newPersistedFilter = {
      ...savedFilter!,
      siteId,
      statuses,
      locationTypes,
      installationFrom: installationDate.from ?? null,
      installationTo: installationDate.to ?? null,
      inspectionFrom: inspectionDate.from ?? null,
      inspectionTo: inspectionDate.to ?? null,
    };

    saveFilter(newPersistedFilter);
  };

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box pr={2} display="flex" alignItems="center" onClick={handleMenuOpenClick}>
          <Typography className={classes.whiteText}>Filter doors</Typography>
          <IconButton aria-controls="simple-menu" aria-haspopup="true">
            <Badge variant="dot" color="primary" invisible={!isCustomFilter}>
              <FilterListIcon />
            </Badge>
          </IconButton>
        </Box>
      </Box>
      <SitesExtendedFilterDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        setFilter={onFilterChange}
        installationFrom={savedFilter?.installationFrom ?? null}
        installationTo={savedFilter?.installationTo ?? null}
        inspectionFrom={savedFilter?.inspectionFrom ?? null}
        inspectionTo={savedFilter?.inspectionTo ?? null}
        selectedStatuses={savedFilter?.statuses}
        initialLocationTypes={savedFilter?.locationTypes}
      />
    </>
  );
}
