import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import * as Routes from '../../../constants/Routes';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';

export default function CompaniesMenuItem() {
  const location = useLocation();
  return (
    <ProtectedSection roles={[Roles.SuperUser]}>
      <ListItem button component={Link} to={Routes.Companies} selected={location.pathname.startsWith(Routes.Companies)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Companies" />
      </ListItem>
    </ProtectedSection>
  );
}
