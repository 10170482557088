import React, { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const useStyles = makeStyles((theme) => ({
  fileIcon: {
    backgroundColor: '#656DFF',
    borderRadius: 4,
    height: '100%',
    width: '100%',
  },
  paper: {
    width: 66,
    height: 66,
  },
}));

interface FileIconProps {
  icon?: any;
}

export default function FileIcon({ icon }: FileIconProps) {
  const classes = useStyles();
  const getIcon = (): ReactElement => {
    return icon ? React.createElement(icon) : <InsertDriveFileIcon />;
  };
  return (
    <>
      <Paper elevation={5} className={classes.paper}>
        <Box p={2} className={classes.fileIcon} display="flex" justifyContent="center" alignItems="center">
          {getIcon()}
        </Box>
      </Paper>
    </>
  );
}
