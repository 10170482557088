import Box from '@material-ui/core/Box';
import React from 'react';
import { SiteDocument } from '../../../pages/SiteAdd';
import AddedFile from '../../AddedFile';
import DropZone from '../../DropZone';
import { Photo } from '../../../constants/DocumentTypes';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  descriptionInput: {
    maxWidth: 400,
  },
});

interface SiteDrawingsUploadProps {
  drawings: SiteDocument[];
  setDrawings: React.Dispatch<React.SetStateAction<SiteDocument[]>>;
}

export default function SiteDrawingsUpload({ drawings, setDrawings }: SiteDrawingsUploadProps) {
  const classes = useStyles();

  const onFilesAdded = (selectedFiles: File[]) => {
    const newPhotos: SiteDocument[] = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // have to provide a default description otherwise React complains about controlled/uncontrolled components in console
      newPhotos.push({ file });
    }
    setDrawings((prev) => [...prev, ...newPhotos]);
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fileIndex: number) => {
    const description = e.target.value;
    setDrawings((prev) => prev.map((x, i) => (i === fileIndex ? { ...x, description } : x)));
  };

  const onFileRemoved = (fileIndex: number) => {
    const filesCopy = Array.from(drawings);
    filesCopy.splice(fileIndex, 1);
    setDrawings(filesCopy);
  };

  return (
    <Box>
      <DropZone
        onFilesAdded={onFilesAdded}
        title="Upload site drawings"
        accept={['.jpg', '.png', '.pdf']}
        acceptHelperText="Accepted formats: PDF, JPG, PNG"
        multiple
      />
      {drawings.map((drawing, i) => (
        <AddedFile key={i} fileName={drawing.file.name!} documentType={Photo} onRemove={() => onFileRemoved(i)}>
          <Box flex={1} display="flex" justifyContent="center">
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 255 }}
              className={classes.descriptionInput}
              value={drawing.description}
              onChange={(e) => onDescriptionChange(e, i)}
            />
          </Box>
        </AddedFile>
      ))}
    </Box>
  );
}
