import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import LoadingButton from './LoadingButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import { termsAndConditionsUrl } from '../utils/EnvironmentService';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: 16,
    height: 50,
  },
  input: {
    backgroundColor: theme.palette.background.default,
  },
}));

export interface SetPasswordFormData {
  password: string;
  confirmPassword: string;
  termsAndConditions: boolean;
}

interface SetPasswordFormProps {
  process: (setPasswordFormData: SetPasswordFormData) => void;
  loading: boolean;
  showTermsAndConditions: boolean;
  disableSubmit: boolean;
  buttonText: string;
}

export default function SetPasswordForm({
  process,
  loading = false,
  showTermsAndConditions = true,
  disableSubmit = false,
  buttonText,
}: SetPasswordFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const classes = useStyles();

  const { handleSubmit, register, errors, formState, watch } = useForm<SetPasswordFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const doesPasswordMatchPolicy = (password: string): boolean => {
    if (password.length < 8) {
      return false;
    }
    if (!password.match(/[a-z]+/)) {
      return false;
    }
    if (!password.match(/[A-Z]+/)) {
      return false;
    }
    if (!password.match(/[0-9]+/)) {
      return false;
    }
    if (!password.match(/[^\w\s]/)) {
      // not a digit, an english letter or a whitespace
      return false;
    }

    return true;
  };

  return (
    <form onSubmit={handleSubmit(process)}>
      <Box marginBottom={2}>
        <FormControl variant="outlined" fullWidth margin="normal" error={!!errors.password}>
          <InputLabel htmlFor="password" className={classes.input}>
            Password
          </InputLabel>
          <OutlinedInput
            tabIndex={0}
            name="password"
            type={showPassword ? 'text' : 'password'}
            labelWidth={80}
            inputRef={register({
              required: 'Password required',
              validate: (value) => doesPasswordMatchPolicy(value) || 'Password does not meet the requirements.',
            })}
            aria-describedby="password-helper-text"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="password-helper-text">{errors?.password?.message}</FormHelperText>
        </FormControl>
      </Box>
      <Box marginBottom={2}>
        <FormControl variant="outlined" fullWidth margin="normal" error={!!errors.confirmPassword}>
          <InputLabel htmlFor="confirmPassword" className={classes.input}>
            Confirm password
          </InputLabel>
          <OutlinedInput
            name="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            labelWidth={150}
            inputRef={register({
              required: 'Confirm password required',
              validate: (value) => value === watch('password') || "Passwords don't match.",
            })}
            aria-describedby="confirm-password-helper-text"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle confirm password visibility"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="confirm-password-helper-text">{errors?.confirmPassword?.message}</FormHelperText>
        </FormControl>
      </Box>
      {showTermsAndConditions && (
        <Box marginBottom={1}>
          <FormControlLabel
            name="termsAndConditions"
            control={<Checkbox color="primary" />}
            inputRef={register({ required: 'Confirm Terms & Conditions required' })}
            label={
              <Typography>
                {'I have read the '}
                <Link target="_blank" href={termsAndConditionsUrl}>
                  Terms and Conditions
                </Link>
              </Typography>
            }
          />
        </Box>
      )}

      <LoadingButton
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        size="large"
        loading={loading}
        disabled={!formState.isValid || disableSubmit}
        className={classes.button}
      >
        {buttonText}
      </LoadingButton>
    </form>
  );
}
