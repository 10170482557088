import React from 'react';
import useAuth from '../hooks/useAuth';

interface ProtectedSectionProps {
  roles: string[];
  /** Exclusive will indicate that if the user has at least one role that is not in the list of roles allowed then the children will not render */
  exclusive?: boolean;
}

export default function ProtectedSection({
  roles,
  children,
  exclusive,
}: React.PropsWithChildren<ProtectedSectionProps>) {
  const { hasAnyRole, loggedInUser } = useAuth();

  if (!hasAnyRole(roles)) {
    return null;
  }

  if (exclusive && loggedInUser!.roles.filter((r) => !roles.includes(r.code)).length > 0) {
    return null;
  }
  return <>{children}</>;
}
