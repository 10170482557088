import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useReducer } from 'react';
import { CompaniesSearchFilter } from './CompaniesFilter';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: theme.palette.background.default,
  },
  label: {
    fontWeight: 700,
    color: '#fff',
  },
}));

interface FilterState {
  reportFromDate: Dayjs | null;
  reportToDate: Dayjs | null;
  activeChecked: boolean;
  disabledChecked: boolean;
}

const defaultFilter: FilterState = {
  reportFromDate: null,
  reportToDate: null,
  activeChecked: true,
  disabledChecked: false,
};

type Actions = { type: 'RESET' } | { type: 'SET'; payload: Partial<FilterState> };

function reducer(state: FilterState, action: Actions): FilterState {
  switch (action.type) {
    case 'RESET':
      return { ...defaultFilter };
    case 'SET':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

interface CompaniesFilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  filter: CompaniesSearchFilter;
  setFilter: (newFilter: CompaniesSearchFilter, isCustomFilter: boolean) => void;
}

export default function CompaniesFilterDrawer({ isOpen, onClose, filter, setFilter }: CompaniesFilterDrawerProps) {
  const classes = useStyles();

  const [state, dispatch] = useReducer(reducer, {
    reportFromDate: filter.reportPeriodFrom,
    reportToDate: filter.reportPeriodTo,
    activeChecked: filter.active,
    disabledChecked: filter.disabled,
  });

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: 'SET',
        payload: {
          reportFromDate: filter.reportPeriodFrom,
          reportToDate: filter.reportPeriodTo,
          activeChecked: filter.active,
          disabledChecked: filter.disabled,
        },
      });
    }
  }, [filter, isOpen]);

  const applyFilter = () => {
    const isCustomFilter = !isEqual(state, defaultFilter);

    setFilter(
      {
        ...filter,
        active: state.activeChecked,
        disabled: state.disabledChecked,
        reportPeriodFrom: state.reportFromDate,
        reportPeriodTo: state.reportToDate,
      },
      isCustomFilter
    );
    onClose();
  };

  const resetFilter = () => {
    dispatch({ type: 'RESET' });
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} classes={{ paper: classes.drawerPaper }}>
      <Box px={4} pt={10}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Filter companies</Typography>
          <Box ml={2}>
            <IconButton aria-label="close" onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box pr={4}>
          <Box mt={5}>
            <Typography variant="subtitle1" className={classes.label}>
              Passed report period
            </Typography>

            <Box mt={3}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <KeyboardDatePicker
                    value={state.reportFromDate}
                    onChange={(date) => dispatch({ type: 'SET', payload: { reportFromDate: date } })}
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label="From date"
                    placeholder="From date"
                  />
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    value={state.reportToDate}
                    onChange={(date) => dispatch({ type: 'SET', payload: { reportToDate: date } })}
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label="To date"
                    placeholder="To date"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box mt={6}>
            <Typography variant="subtitle1" className={classes.label}>
              Company status
            </Typography>
            <Box mt={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.activeChecked}
                      onChange={(_, checked) => dispatch({ type: 'SET', payload: { activeChecked: checked } })}
                      name="active"
                      color="primary"
                    />
                  }
                  label="Active"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.disabledChecked}
                      onChange={(_, checked) => dispatch({ type: 'SET', payload: { disabledChecked: checked } })}
                      name="disabled"
                      color="primary"
                    />
                  }
                  label="Disabled"
                />
              </FormGroup>
            </Box>
          </Box>

          <Box mt={5}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Button fullWidth variant="contained" color="primary" onClick={applyFilter}>
                  Apply
                </Button>
              </Grid>
              <Grid item>
                <Button fullWidth variant="outlined" onClick={resetFilter}>
                  Reset filters
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
