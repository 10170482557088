import React from 'react';
import { DoorModelFormData } from '../pages/DoorModelAdd';

interface DoorModelContextValue {
  doorModel: DoorModelFormData | undefined;
  setDoorModel: React.Dispatch<React.SetStateAction<DoorModelFormData | undefined>>;
}

// @ts-ignore
export default React.createContext<DoorModelContextValue>();
