import { ApolloProvider } from '@apollo/react-hooks';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// IMPORTANT - only import the font weights needed to lower bundle size
import 'fontsource-poppins/latin-200-normal.css';
import 'fontsource-poppins/latin-400-normal.css';
import 'fontsource-poppins/latin-500-normal.css';
import 'fontsource-poppins/latin-600-normal.css';
import 'fontsource-poppins/latin-700-normal.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import client from './client';
import './index.css';
import DialogProvider from './providers/DialogProvider';
import { NotistackProvider } from './providers/NotistackProvider';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import init from './utils/Logging';
import DayJsUtils from '@date-io/dayjs';

init();
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NotistackProvider>
          <DialogProvider>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </DialogProvider>
        </NotistackProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
