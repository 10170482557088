import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles({
  subtitle: {
    color: '#fff',
    fontWeight: 500,
    letterSpacing: 0.2,
  },
});

type columns = boolean | 'auto' | 10 | 6 | 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9 | 11 | 12 | undefined;

interface StandardGridItemProps {
  title: string;
  value?: string;
  minWidth?: columns;
}

export default function StandardGridItem({ title, value, minWidth = 6 }: StandardGridItemProps) {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={minWidth}>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {title}
      </Typography>
      <Typography>{value ? `${value}` : '-'}</Typography>
    </Grid>
  );
}
