import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Layout from '../components/Layout';
import * as Routes from '../constants/Routes';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell, TableSortLabel, TableBody, Checkbox, makeStyles, TablePagination } from '@material-ui/core';
import TableLoading from '../components/TableLoading';
import NoResultsRow from '../components/NoResultsRow';
import { DoorModel, useDoorModelsQuery, useDeleteDoorModelsMutation } from '../queries';
import useTable from '../hooks/useTable';
import theme from '../theme';
import CompletenessProgress from '../components/CompletenessProgress';
import ErrorButton from '../components/ErrorButton';
import useDialog from '../hooks/useDialog';
import { useSnackbar } from 'notistack';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { DoorModelUsed } from '../constants/GraphQLErrorCodes';

const useStyles = makeStyles({
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeStatus: {
    color: theme.palette.primary.main,
  },
  disabledStatus: {
    color: theme.palette.error.main,
  },
  stage: {
    width: '120px',
    fontSize: '10px',
    lineHeight: '24px',
  },
  readyStage: {
    backgroundColor: theme.palette.primary.main,
  },
  incompleteStage: {
    backgroundColor: theme.palette.error.main,
  },
  transferredStage: {
    backgroundColor: '#696A81',
  },
  selectedOption: {
    backgroundColor: 'rgba(255, 255, 255, 0.16) !important',
  },
  buttonContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});
export default function DoorModels() {
  const [selectedDoorModels, setSelectedDoorModels] = useState<Partial<DoorModel>[]>([]);
  const [enableDoorModelDeletion, setEnableDoorModelDeletion] = useState<boolean>(false);
  const history = useHistory();
  const classes = useStyles();
  const { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort } = useTable('name');

  const { loading, error, data, refetch } = useDoorModelsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { skip: pageSize * page, take: pageSize, sortDirection, sortField },
  });
  const [deleteDoorModelsMutation] = useDeleteDoorModelsMutation();
  const { showDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setEnableDoorModelDeletion(selectedDoorModels.length > 0);
  }, [selectedDoorModels]);

  const onRowClick = (doorId: string) => {
    history.push(`${Routes.DoorModels}/${doorId}`);
  };

  const isDoorModelSelected = (doorModel: Partial<DoorModel> | null | undefined): boolean => {
    return selectedDoorModels.filter((d) => d?.id === doorModel?.id).length > 0;
  };

  const toggleDoorModel = (doorModel: Partial<DoorModel> | null | undefined, checked: boolean): void => {
    if (!doorModel) {
      return;
    }

    if (checked) {
      setSelectedDoorModels((prevState) => [...prevState, doorModel]);
    } else {
      setSelectedDoorModels((prevState) => prevState.filter((u) => u?.id !== doorModel?.id));
    }
  };

  const getFDRating = (doorModel: Partial<DoorModel>): string => {
    if (doorModel.fdRating) {
      if (doorModel.fdRating.value.startsWith('FD')) {
        return doorModel.fdRating.value;
      }
      return 'FD' + doorModel.fdRating.value;
    }
    return '';
  };

  const deleteSelectedDoorModels = async () => {
    const validIds: number[] = [];
    selectedDoorModels.forEach((m) => {
      if (m && m.id) {
        validIds.push(parseInt(m.id));
      }
    });

    try {
      await deleteDoorModelsMutation({
        variables: {
          ids: validIds.map((x) => x.toString()),
        },
      });
      setSelectedDoorModels([]);
      refetch();
      enqueueSnackbar('The selected doors modeles have been deleted successfully.', { variant: 'success' });
    } catch (e) {
      if (e.graphQLErrors.some((gqlerror: GraphQLError) => gqlerror?.extensions?.code === DoorModelUsed)) {
        showDialog({
          dialogTitle: 'Door model in use',
          dialogText: 'You cannot remove a door model which is being used by a manufactured door.',
          okText: 'OK',
          hasCancel: false,
          hasTitle: true,
        });
      } else {
        enqueueSnackbar('An error has occurred executing the action.', { variant: 'error' });
      }
    }
  };

  return (
    <Layout>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Door models</Typography>
        <Box display="flex" className={classes.buttonContainer}>
          {(enableDoorModelDeletion && (
            <ErrorButton variant="contained" disabled={!enableDoorModelDeletion} onClick={deleteSelectedDoorModels}>
              Remove model
            </ErrorButton>
          )) || (
            <Button variant="contained" color="primary" onClick={() => history.push(Routes.DoorModelAdd)}>
              Add new model
            </Button>
          )}
        </Box>
      </Box>
      <Box mt={3}>
        <TableContainer component={Paper}>
          <Table aria-label="Manufactured doors table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>
                  <TableSortLabel active={sortField === 'name'} direction={sortDirection} onClick={() => sort('name')}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'fdRating.value'}
                    direction={sortDirection}
                    onClick={() => sort('fdRating.value')}
                  >
                    FD Rating
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'coreSupplier'}
                    direction={sortDirection}
                    onClick={() => sort('coreSupplier')}
                  >
                    Core supplier
                  </TableSortLabel>
                </TableCell>
                <TableCell>Progress</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoading />}
              {!loading && !error && data?.doorModels.items.length === 0 && (
                <NoResultsRow message="No door models to display" />
              )}
              {!loading && error && <NoResultsRow message="There was an error fetching door models." />}
              {!loading &&
                !error &&
                data?.doorModels &&
                data?.doorModels.items.length > 0 &&
                data?.doorModels.items.map((doorModel, i) => (
                  <TableRow hover key={i} className={classes.tableRow}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={isDoorModelSelected(doorModel as Partial<DoorModel>)}
                        onChange={(e, checked) => toggleDoorModel(doorModel as Partial<DoorModel>, checked)}
                      />
                    </TableCell>
                    <TableCell onClick={() => onRowClick(doorModel.id)}>{doorModel.name}</TableCell>
                    <TableCell onClick={() => onRowClick(doorModel.id)}>
                      {getFDRating(doorModel as Partial<DoorModel>)}
                    </TableCell>
                    <TableCell onClick={() => onRowClick(doorModel.id)}>{doorModel.coreSupplier}</TableCell>
                    <TableCell>
                      <CompletenessProgress doorModel={doorModel as Partial<DoorModel>}></CompletenessProgress>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.doorModels.total ?? 0}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={(_, newPage) => pageChanged(newPage)}
            onChangeRowsPerPage={pageSizeChanged}
          />
        </TableContainer>
      </Box>
    </Layout>
  );
}
