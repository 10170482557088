import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import Layout from '../components/Layout';
import { Company, useAssignDoorsToSiteMutation, useDoorsByIdsQuery, useSitesLazyQuery } from '../queries';
import * as Routes from '../constants/Routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useDialog from '../hooks/useDialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import LoadingButton from '../components/LoadingButton';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { GraphQLError } from 'graphql';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useTransferDoorsStore from '../store/TransferDoorsStore';
import useDebounce from 'use-debounce/lib/useDebounce';
import { Controller, useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#fff',
    fontSize: '20px',
  },
  label: {
    color: '#fff',
  },
}));

export default function AssignDoorsToSite() {
  const classes = useStyles();
  const locationRefs = useRef([]);

  const { showDialog } = useDialog();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedSite, setSelectedSite] = useState<string | undefined>(undefined);
  const [debouncedSite] = useDebounce(selectedSite, 300);

  const { doorIds, setDoorIds } = useTransferDoorsStore();
  if (doorIds.length === 0) {
    navigateToManufacturedDoors();
  }

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { getValues, control, errors, formState } = form;

  const [getSites, { loading: sitesLoading, data: sitesData }] = useSitesLazyQuery();

  useEffect(() => {
    if (debouncedSite) {
      getSites({
        variables: {
          skip: 0,
          textFilter: selectedSite,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSite, getSites]);

  const [assignDoorsToContractor] = useAssignDoorsToSiteMutation();

  const { loading: doorsLoading, data: doorsData } = useDoorsByIdsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      ids: doorIds,
    },
  });

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        setDoorIds([]);
        navigateToManufacturedDoors();
      },
    });
  };

  const saveChanges = async () => {
    const doorsInput = locationRefs.current.map((ref) => {
      const { value: location, id: doorId } = ref;
      return { location: String(location), doorId: String(doorId) };
    });

    try {
      const { id: siteId } = getValues('selectedSite');
      await assignDoorsToContractor({
        variables: { doorsInput, siteId },
      });

      enqueueSnackbar('Doors assigned successfully.', { variant: 'success' });
      history.goBack();
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    }
  };

  function navigateToManufacturedDoors() {
    history.push(`${Routes.ManufacturedDoors}`);
  }

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5">Assign to site</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.ManufacturedDoors}>
                  <Typography variant="body2">Manufactured Doors</Typography>
                </MaterialLink>
                <Typography variant="body2">Assign to site</Typography>
              </Breadcrumbs>
            </Box>
          </Box>
          <Box mt={1}>
            <Paper>
              <Box p={5} marginTop={3}>
                <Box marginBottom={2}>
                  <Typography className={classes.heading}>Please select site to assign doors</Typography>
                </Box>
                <Box mb={6} maxWidth="300px">
                  <FormControl variant="outlined" fullWidth>
                    <Controller
                      control={control}
                      name="selectedSite"
                      defaultValue={null}
                      rules={{
                        required: 'Site is required',
                      }}
                      render={({ onChange }) => (
                        <Autocomplete
                          fullWidth
                          autoSelect
                          autoComplete
                          selectOnFocus
                          handleHomeEndKeys
                          freeSolo
                          getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            return option.name;
                          }}
                          options={sitesData?.sites.items.map(({ id, name }) => ({ id, name })) ?? []}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Site to assign"
                              variant="outlined"
                              fullWidth
                              error={!!errors.destinationCompany}
                            />
                          )}
                          onInputChange={(_, newInputValue) => {
                            setSelectedSite(newInputValue);
                          }}
                          onChange={(_, newValue: Pick<Company, 'id' | 'name'> | string | null) => {
                            if (!newValue) {
                              onChange(newValue);
                            }

                            if (newValue && typeof newValue === 'object') {
                              newValue = newValue?.name;
                            }

                            if (typeof newValue === 'string') {
                              const existingOption = sitesData?.sites.items.find(
                                (f) => f.name === (newValue as string).trim()
                              );
                              if (existingOption) {
                                onChange({ id: existingOption.id, name: existingOption.name });
                              } else {
                                onChange(undefined);
                              }
                            }
                          }}
                          getOptionSelected={(option, value) => option.id === value.id}
                          loading={sitesLoading}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <Typography className={classes.heading}>
                    Please specify a location you want to assign the doors
                  </Typography>
                </Box>
                {doorsData?.doorsByIds &&
                  doorsData?.doorsByIds.length > 0 &&
                  doorsData?.doorsByIds.map((door, i) => {
                    console.log('door', door);
                    return (
                      <Box display="flex" alignItems="center" mb={2} key={door.id}>
                        <Box mr={1} minWidth="400px">
                          <Typography className={classes.label}>
                            {door.id}
                            {door?.projectReference && ' | ' + door?.projectReference}
                            {door?.doorReference && ' | ' + door?.doorReference}
                          </Typography>
                        </Box>
                        <Box width="300px">
                          <TextField
                            label="Enter location name"
                            inputRef={(el) => ((locationRefs.current[i] as any) = el)}
                            id={door.id}
                            variant="outlined"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    );
                  })}
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                  <Box mr={2}>
                    <Button variant="outlined" onClick={onCancelClicked}>
                      Cancel
                    </Button>
                  </Box>
                  <LoadingButton
                    loading={doorsLoading || sitesLoading}
                    variant="contained"
                    color="primary"
                    disabled={!formState.isValid || !selectedSite}
                    onClick={() => saveChanges()}
                  >
                    Assign to site
                  </LoadingButton>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}
