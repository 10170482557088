enum HistoryEventTypes {
  DoorAdded = 'DoorAdded',
  DoorInstallationPassed = 'DoorInstallationPassed',
  DoorInstallationFailed = 'DoorInstallationFailed',
  DoorInspectionPassed = 'DoorInspectionPassed',
  DoorInspectionFailed = 'DoorInspectionFailed',
  DoorMaintenance = 'DoorMaintenance',
  DoorDisabled = 'DoorDisabled',
  DoorRetired = 'DoorRetired',
  DoorRepairRequested = 'DoorRepairRequested',
  DoorRepaired = 'DoorRepaired',
  DoorNotAccessible = 'DoorNotAccessible',
  DoorAssigned = 'DoorAssigned',
  DoorInstallationReported = 'DoorInstallationReported',
  DoorUpdated = 'DoorUpdated',
}
export default HistoryEventTypes;
