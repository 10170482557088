import { DialogTitle, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useCallback, useState } from 'react';
import DialogContext from '../context/DialogContext';

const useStyles = makeStyles({
  dialogTitle: {
    paddingBottom: 0,
    paddingTop: '26px',
  },
});

interface DialogProviderProps {
  children: React.ReactNode;
}

export interface DialogOptions {
  dialogTitle?: string;
  dialogText?: string;
  okText?: string;
  cancelText?: string;
  onClose?: () => void;
  hasCancel?: boolean;
  hasTitle?: boolean;
}

const defaultDialogOptions: DialogOptions = {
  okText: 'Ok',
  cancelText: 'Cancel',
  hasCancel: true,
};

export default function DialogProvider({ children }: DialogProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<DialogOptions>(defaultDialogOptions);

  const classes = useStyles();

  const showDialog = useCallback((options: DialogOptions) => {
    setOptions({ ...defaultDialogOptions, ...options });
    setIsOpen(true);
  }, []);

  const contextValue = { showDialog };

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      <Dialog open={isOpen} aria-describedby="dialog-description">
        {options.hasTitle && <DialogTitle className={classes.dialogTitle}>{options.dialogTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText variant="body1" id="dialog-description">
            {options.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {options.hasCancel && <Button onClick={() => setIsOpen(false)}>{options.cancelText}</Button>}
          <Button
            autoFocus
            onClick={() => {
              setIsOpen(false);
              if (options.onClose) {
                options.onClose();
              }
            }}
          >
            {options.okText}
          </Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
}
