import { makeStyles } from '@material-ui/core/styles';
import MaterialLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import * as Routes from '../constants/Routes';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import useAuth from '../hooks/useAuth';
import FullNameAvatar from './FullNameAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  textContainer: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  name: {
    fontSize: 13,
    color: '#fff',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 54,
    width: 54,
  },
}));

export default function LoggedInUser() {
  const classes = useStyles();
  const { loggedInUser, getRoleNameToDisplay } = useAuth();

  return (
    <div className={classes.root}>
      <FullNameAvatar className={classes.avatar} fullName={loggedInUser?.fullName} />
      <div className={classes.textContainer}>
        <MaterialLink color="inherit" underline="none" component={Link} to={Routes.AccountInfo}>
          <Typography className={classes.name}>{loggedInUser?.fullName}</Typography>
          {!loggedInUser && <Skeleton variant="text" width={135} height={20} />}
          {loggedInUser && <Typography variant="caption">{getRoleNameToDisplay(loggedInUser?.roles || [])}</Typography>}
        </MaterialLink>
      </div>
    </div>
  );
}
