import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

export default function useUrlSearchParam(param: string) {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const setValue = useCallback(
    (value: string) => {
      const params = new URLSearchParams({ [param]: value });
      history.replace({ pathname: pathname, search: params.toString() });
    },
    [history, param, pathname]
  );

  return { value: query.get(param), setValue };
}
