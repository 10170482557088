declare global {
  interface Window {
    appSettings: {
      apiUrl: string;
      baseUrl: string;
      logLevel: string;
      termsAndConditionsUrl: string;
      version: string;
      build: string;
      googleAppId: string;
      appleAppId: string;
    };
  }
}

export const apiUrl: string = window['appSettings'].apiUrl;
export const baseUrl: string = window['appSettings'].baseUrl;
export const logLevel: string = window['appSettings'].logLevel;
export const termsAndConditionsUrl: string = window['appSettings'].termsAndConditionsUrl;
export const version: string = window['appSettings'].version;
export const build: string = window['appSettings'].build;
export const googleAppId: string = window['appSettings'].googleAppId;
export const appleAppId: string = window['appSettings'].appleAppId;
