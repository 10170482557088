import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import FileIcon from './FileIcon';
import useIcon from '../hooks/useIcon';

const useStyles = makeStyles((theme) => ({
  fileIcon: {
    backgroundColor: '#656DFF',
    borderRadius: 4,
    height: '100%',
    width: '100%',
  },
  removeFileIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  paper: {
    width: 66,
    height: 66,
  },
}));

interface AddedFileProps {
  fileName: string | undefined;
  documentType?: string;
  onRemove?: () => void;
  children?: React.ReactNode;
}

export default function AddedFile({ fileName, onRemove, children, documentType }: AddedFileProps) {
  const classes = useStyles();
  const { getIconByDocumentType } = useIcon();

  let fileNameExtension = fileName?.substr(fileName.length - 4, 4);
  if (documentType === undefined && fileNameExtension === '.csv') {
    documentType = 'csv';
  }

  return (
    <>
      <Box mt={3} mb={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Grid container alignItems="center">
            <Grid item xs={1}>
              <FileIcon icon={getIconByDocumentType(documentType)}></FileIcon>
            </Grid>
            <Grid item xs={6} xl={5}>
              <Box ml={3} mr={1}>
                <Typography noWrap>{fileName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={5} xl={6}>
              {children}
            </Grid>
          </Grid>
          {onRemove && <CloseIcon className={classes.removeFileIcon} onClick={onRemove} />}
        </Box>
      </Box>
      <Divider />
    </>
  );
}
