import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SiteContext from '../../../context/SiteContext';
import { SiteAddFormData } from '../../../pages/SiteAdd';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import LoadingButton from '../../LoadingButton';
import Button from '@material-ui/core/Button';

interface SiteContactAddProps {
  onBack?: () => void;
  onContinue: () => void;
}

export default function SiteContactAdd({ onBack, onContinue }: SiteContactAddProps) {
  const { site, setSite } = useContext(SiteContext);

  const form = useForm<SiteAddFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...site,
    },
  });

  const { handleSubmit, getValues, register, errors, reset } = form;

  useEffect(() => {
    reset({
      ...site,
    });
  }, [reset, site]);

  const saveSite = (values: SiteAddFormData) => {
    setSite((prev: SiteAddFormData | undefined) => ({ ...prev, ...values }));
    if (onContinue) onContinue();
  };

  const onBackClick = () => {
    const values = getValues();
    setSite((prev: SiteAddFormData | undefined) => ({ ...prev, ...values }));
    if (onBack) onBack();
  };

  return (
    <form onSubmit={handleSubmit(saveSite)}>
      <Grid container spacing={3}>
        <Grid item lg={6} xs={12}>
          <TextField
            name="contactName"
            label="Contact name"
            variant="outlined"
            fullWidth
            inputRef={register()}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            name="contactPhoneNumber"
            label="Landline number"
            variant="outlined"
            fullWidth
            error={!!errors.contactPhoneNumber}
            helperText={errors?.contactPhoneNumber?.message}
            inputRef={register({
              pattern: {
                value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                message: 'Landline number must be a number',
              },
            })}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            name="contactMobileNumber"
            label="Mobile number"
            variant="outlined"
            fullWidth
            error={!!errors.contactMobileNumber}
            helperText={errors?.contactMobileNumber?.message}
            inputRef={register({
              pattern: {
                value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                message: 'Mobile number must be a number',
              },
            })}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            name="contactEmailAddress"
            label="Email"
            variant="outlined"
            fullWidth
            error={!!errors.contactEmailAddress}
            helperText={errors?.contactEmailAddress?.message}
            inputRef={register({
              pattern: {
                value: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" marginTop={3}>
        <Button variant="outlined" onClick={onBackClick}>
          Back
        </Button>
        <LoadingButton type="submit" variant="contained" color="primary">
          Continue
        </LoadingButton>
      </Box>
    </form>
  );
}
