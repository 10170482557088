import axios, { AxiosRequestConfig } from 'axios';
import { useContext } from 'react';
import AppContext from '../context/app/AppContext';
import { RoleFragment } from '../queries';
import { baseUrl } from '../utils/EnvironmentService';
import { getMainRoleCode, getOrderedRoleNames } from '../utils/Roles';

export default function useAuth() {
  const appContext = useContext(AppContext);
  const hasRole = (roleCode: string): boolean => {
    return !!appContext.state.loggedInUser?.roles.map((r) => r.code).includes(roleCode);
  };

  const hasAnyRole = (roleCodes?: string[]): boolean => {
    if (!roleCodes) {
      return true;
    }
    const someRolesMatch = appContext.state.loggedInUser?.roles
      .map((r) => r.code)
      .some((roleCode) => roleCodes?.includes(roleCode));

    return !!someRolesMatch;
  };

  const logout = function (): Promise<void> {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    return axios.post(`${baseUrl}/logout`, undefined, config);
  };

  const login = function (email: string, password: string): Promise<any> {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    const promise = axios.post(`${baseUrl}/login`, { email, password }, config);

    promise
      .then((loginResponse) => {
        setLoggedInUser({
          id: loginResponse.data.id,
          fullName: loginResponse.data.fullName,
          companyId: loginResponse.data.companyId,
          companyName: loginResponse.data.companyName,
          roles: loginResponse.data.roles,
          options: {
            canReportInstallations: loginResponse.data.options.canReportInstallations,
          },
        });
      })
      .catch(() => {
        setLoggedInUser(undefined);
      });

    return promise;
  };

  const getRoleNameToDisplay = (userRoles: RoleFragment[]) => {
    if (!userRoles || userRoles.length === 0) {
      return '';
    }

    const mainRoleCode = getMainRoleCode(userRoles);

    const roleName = userRoles.find((r) => r.code === mainRoleCode)?.name;
    return roleName || '';
  };

  const getRoleNamesForHover = (userRoles: RoleFragment[]) => {
    if (!userRoles || userRoles.length === 0) {
      return '';
    }

    let orderedRoles = getOrderedRoleNames(userRoles);
    return orderedRoles.length === 0 ? '' : orderedRoles.join(', ');
  };

  const isLoggedIn = appContext.state.loggedIn;
  const loggedInUser = appContext.state.loggedInUser;
  const setLoggedInUser = appContext.setLoggedInUser;
  return {
    hasRole,
    hasAnyRole,
    isLoggedIn,
    loggedInUser,
    setLoggedInUser,
    logout,
    login,
    getRoleNameToDisplay,
    getRoleNamesForHover,
  };
}
