import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import usePartList from '../../../hooks/usePartList';
import { DoorParts, DoorModelPartsFragment } from '../../../queries';
import PartsListFields from '../../PartsListFields';

interface DoorPartsListFieldsProps {
  doorModelParts?: Partial<DoorModelPartsFragment> | null;
  doorParts?: Partial<DoorParts> | null;
  readOnly?: boolean;
}

export default function DoorPartsListFields({ doorModelParts, doorParts, readOnly = false }: DoorPartsListFieldsProps) {
  const { register } = useFormContext();
  const { isEditable } = usePartList(doorModelParts, doorParts, readOnly);

  return (
    <Grid container spacing={3}>
      <PartsListFields doorModelParts={doorModelParts} readOnly={readOnly} doorParts={doorParts} />
      <Grid item lg={6} xs={12}>
        <TextField
          name="keeps"
          label="Keeps"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('keeps')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="numerals"
          label="Numerals"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('numerals')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="chain"
          label="Chain"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('chain')}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <TextField
          name="weatherSeals"
          label="Weather seals"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 255 }}
          disabled={!isEditable('weatherSeals')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="additionalComments"
          label="Additional comments"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{ maxLength: 1024 }}
          multiline
          rows={4}
          disabled={!isEditable('additionalComments')}
        />
      </Grid>
    </Grid>
  );
}
