import create from 'zustand';
import { Dayjs } from 'dayjs';
import { SiteDoorsFilterTab } from '../components/site/view/SiteDoorsFilter';

export interface SiteDoorsFilter {
  siteId: string;
  pageSize: number;
  page: number;
  sortField: string;
  sortDirection: 'asc' | 'desc';
  active: boolean;
  statuses: string[];
  locationTypes: string[];
  tab: SiteDoorsFilterTab | undefined;
  textFilter: string | undefined;
  installationFrom: Dayjs | null;
  installationTo: Dayjs | null;
  inspectionFrom: Dayjs | null;
  inspectionTo: Dayjs | null;
}
type SiteDoorsFiltersStore = {
  filters: SiteDoorsFilter[];
  updateFilter: (siteId: string, partialFilter: Partial<SiteDoorsFilter>) => void;
  saveFilter: (filter: SiteDoorsFilter) => void;
  reset: () => void;
};
const useSiteDoorsFiltersStore = create<SiteDoorsFiltersStore>((set) => ({
  filters: [],
  reset: () => set(() => ({ filters: [] })),
  updateFilter: (siteId: string, partialFilter: Partial<SiteDoorsFilter>) => {
    set((state) => {
      const existingFilter = state.filters.find((f) => f.siteId === siteId);

      if (existingFilter) {
        const newFilters = state.filters.map((item) => {
          if (item.siteId === siteId) {
            return {
              ...item,
              ...partialFilter,
            };
          } else {
            return item;
          }
        });

        return {
          filters: newFilters,
        };
      } else {
        return { filters: [{ siteId } as any, ...state.filters] };
      }
    });
  },
  saveFilter: (filter: SiteDoorsFilter) => {
    set((state) => {
      const existingFilter = state.filters.find((f) => f.siteId === filter.siteId);

      if (existingFilter) {
        const newFilters = state.filters.map((item) => {
          if (item.siteId === filter.siteId) {
            return {
              ...filter,
            };
          } else {
            return item;
          }
        });

        return {
          filters: newFilters,
        };
      } else {
        return { filters: [filter, ...state.filters] };
      }
    });
  },
}));

export default useSiteDoorsFiltersStore;
