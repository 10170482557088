import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export default function SiteContactEdit() {
  const { register, errors } = useFormContext();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={6} xs={12}>
          <TextField
            name="contactName"
            label="Contact name"
            variant="outlined"
            fullWidth
            inputRef={register()}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            name="contactPhoneNumber"
            label="Landline number"
            variant="outlined"
            fullWidth
            error={!!errors.contactPhoneNumber}
            helperText={errors?.contactPhoneNumber?.message}
            inputRef={register({
              pattern: {
                value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                message: 'Landline number must be a number',
              },
            })}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            name="contactMobileNumber"
            label="Mobile number"
            variant="outlined"
            fullWidth
            error={!!errors.contactMobileNumber}
            helperText={errors?.contactMobileNumber?.message}
            inputRef={register({
              pattern: {
                value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                message: 'Mobile number must be a number',
              },
            })}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            name="contactEmailAddress"
            label="Email"
            variant="outlined"
            fullWidth
            error={!!errors.contactEmailAddress}
            helperText={errors?.contactEmailAddress?.message}
            inputRef={register({
              pattern: {
                value: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
      </Grid>
    </>
  );
}
