import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../components/Layout';
import { useMarkDoorForRepairMutation } from '../queries';
import useDialog from '../hooks/useDialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import TextField from '@material-ui/core/TextField/TextField';

interface RepairDoorFormData {
  message: string;
}

export default function RepairDoor() {
  const { showDialog } = useDialog();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { id: doorId } = useParams();

  const [repairDoor] = useMarkDoorForRepairMutation();

  const form = useForm<RepairDoorFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, formState, register, errors } = form;

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        history.goBack();
      },
    });
  };

  const saveChanges = async (values: RepairDoorFormData) => {
    try {
      await repairDoor({
        variables: {
          markDoorForRepairInput: {
            doorId,
            message: values.message,
          },
        },
      });
      enqueueSnackbar('Repair door recorded.', { variant: 'success' });
      history.goBack();
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    }
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5">Repair door</Typography>
            </Box>
            <Box minWidth={96}>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>
          <Box mt={1}>
            <Paper>
              <Box p={5} marginTop={3}>
                <Box marginBottom={3}>
                  <Typography>Provide any additional details</Typography>
                </Box>
                <form onSubmit={handleSubmit(saveChanges)}>
                  <Box flex={1} display="flex" justifyContent="center">
                    <TextField
                      name="message"
                      label="Message"
                      variant="outlined"
                      fullWidth
                      inputRef={register({
                        required: 'Please provide a message',
                      })}
                      multiline
                      rows={4}
                      error={!!errors.message}
                      helperText={errors.message?.message}
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end" marginTop={3}>
                    <Button type="submit" variant="contained" color="primary" disabled={!formState.isValid}>
                      Repair door
                    </Button>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
