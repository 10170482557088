import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import * as Routes from '../../../constants/Routes';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';
import { ExpandLess, ExpandMore, ViewArray } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

export default function DoorsMenuItem() {
  const location = useLocation();
  const [doorsMenuOpen, setDoorsMenuOpen] = useState(true);
  return (
    <ProtectedSection roles={[Roles.ManufacturerAdmin, Roles.InstallerAdmin]}>
      <ListItem
        button
        onClick={() => {
          setDoorsMenuOpen((prev) => !prev);
        }}
      >
        <ListItemIcon>
          <ViewArray />
        </ListItemIcon>
        <ListItemText primary="Doors" />
        {doorsMenuOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={doorsMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={Link}
            selected={location.pathname.startsWith(Routes.DoorModels)}
            to={Routes.DoorModels}
          >
            <ListItemText primary="Door models" />
          </ListItem>
          <ListItem
            button
            component={Link}
            selected={
              location.pathname.startsWith(Routes.ManufacturedDoors) ||
              location.pathname.startsWith(Routes.ImportDoors) ||
              location.pathname.startsWith(Routes.TransferDoors) ||
              (location.pathname.startsWith(Routes.ViewDoor) && !location.pathname.startsWith(Routes.DoorModels))
            }
            to={Routes.ManufacturedDoors}
          >
            <ListItemText primary="Manufactured doors" />
          </ListItem>
        </List>
      </Collapse>
    </ProtectedSection>
  );
}
