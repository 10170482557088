import Box from '@material-ui/core/Box';
import React from 'react';
import HistoryEventTypes from '../../../../../constants/HistoryEventTypes';
import { DoorHistoryFragment } from '../../../../../queries';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    color: '#fff',
    fontWeight: 500,
    letterSpacing: 0.2,
  },
});

interface DoorUpdatedAdditionalInfo {
  updates: { field: string; newValue: string }[];
}

const getFieldName = (field: string) => {
  switch (field) {
    case 'lockLatch':
      return 'Lock/latch';
    case 'closer':
      return 'Door closer';
    case 'intumescentStrip':
      return 'Intumescent strip';
    case 'hinges':
      return 'Hinges';
    case 'handles':
      return 'Handles';
    case 'smokeSeals':
      return 'Smoke seals';
    case 'dropSeal':
      return 'Drop seal';
    case 'cylinder':
      return 'Cylinder';
    case 'letterbox':
      return 'Letterbox';
    case 'spyhole':
      return 'Spyhole';
    case 'thresholdStrip':
      return 'Threshold strip';
    case 'keeps':
      return 'Keeps';
    case 'numerals':
      return 'Numerals';
    case 'chain':
      return 'Chain';
    case 'weatherSeals':
      return 'Weather seals';
    case 'additionalComments':
      return 'Additional parts info';
    case 'leaf1AcousticRating':
      return 'Leaf 1 acoustic rating';
    case 'leaf1CoreSupplier':
      return 'Leaf 1 core supplier';
    case 'leaf1Width':
      return 'Leaf 1 width (mm)';
    case 'leaf1Height':
      return 'Leaf 1 height';
    case 'leaf2AcousticRating':
      return 'Leaf 2 acoustic rating';
    case 'leaf2CoreSupplier':
      return 'Leaf 2 core supplier';
    case 'leaf2Width':
      return 'Leaf 2 width (mm)';
    case 'leaf2Height':
      return 'Leaf 2 height';
    case 'fdRating':
      return 'FD Rating';
    default:
      return field;
  }
};

interface DoorUpdatedDetailDialogProps {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
  isPass: boolean;
}

function DetailDialog({ history, isPass }: DetailDialogProps) {
  const styles = useStyles();
  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorUpdatedAdditionalInfo;
  return (
    <Box pt={1} pb={1}>
      <TableContainer style={{ padding: 0 }}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: 0 }}>
                <Typography variant="subtitle1" className={styles.header}>
                  Updated field
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: 0 }}>
                <Typography variant="subtitle1" className={styles.header}>
                  New value
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {additionalInfo.updates.map((update, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row" style={{ paddingLeft: 0 }}>
                  <Typography variant="subtitle1">{getFieldName(update.field)}</Typography>
                </TableCell>
                <TableCell align="left" style={{ paddingRight: 0, whiteSpace: 'pre-wrap' }}>
                  <Typography>{update.newValue}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default function DoorUpdatedDetailDialog({ open, onClose, history }: DoorUpdatedDetailDialogProps) {
  const isPass = history.historyEventType.code === HistoryEventTypes.DoorInspectionPassed;
  return (
    <DoorHistoryDetailDialog open={open} onClose={onClose} history={history} actorName={'Updated by'}>
      <DetailDialog history={history} isPass={isPass} />
    </DoorHistoryDetailDialog>
  );
}
