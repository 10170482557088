import { IconButton, InputAdornment, Link, TextField, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import CompanyTypes from '../../constants/CompanyTypes';
import { CompanyQuery } from '../../queries';
import DetailBox from './DetailBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { baseUrl } from '../../utils/EnvironmentService';

const useStyles = makeStyles({
  chip: {
    marginRight: 8,
    marginBottom: 8,
  },
  label: {
    fontWeight: 600,
    color: '#fff',
  },

  apiKeyInput: {
    '& .Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.6)',
      opacity: 1,
    },
  },
});

interface CompanyDetailsProps {
  loading: boolean;
  companyQuery?: CompanyQuery;
  apiKey: string | undefined;
  onResetApiKey: () => void;
}

export default function CompanyDetails({ loading, companyQuery, apiKey, onResetApiKey }: CompanyDetailsProps) {
  const classes = useStyles();

  const companyDetailsRows = [
    { label: 'Company name', value: companyQuery?.company.name },
    { label: 'Admin name', value: companyQuery?.company?.admin?.fullName },
    { label: 'Admin email', value: companyQuery?.company?.admin?.email },
    { label: 'Contact phone', value: companyQuery?.company.contactPhone },
  ];

  const billingInfoRows = [
    { label: 'Billing email', value: companyQuery?.company.billingEmail },
    { label: 'Address line 1', value: companyQuery?.company.billingAddressOne },
    { label: 'Address line 2', value: companyQuery?.company.billingAddressTwo },
    { label: 'Town/City', value: companyQuery?.company.billingCity },
    { label: 'Postcode', value: companyQuery?.company.billingPostcode },
  ];

  const passedReportRows = [{ label: 'This month', value: companyQuery?.company.passedReportsForCurrentMonth }];

  // Eventually, inspector will be included here
  const canHavePassedReports = companyQuery?.company.types.map((t) => t.code).includes(CompanyTypes.Installer);

  const showAPIIntegration =
    companyQuery &&
    companyQuery.company.types.filter((ct) =>
      [CompanyTypes.Manufacturer, CompanyTypes.Installer, CompanyTypes.FacilityManagement].includes(
        ct.code as CompanyTypes
      )
    ).length > 0;

  const onCopyApiKey = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
    }
  };

  return (
    <Box py={3}>
      <Grid container spacing={3}>
        {loading && (
          <Grid item container justify="center" alignItems="center" xs={12}>
            <CircularProgress />
          </Grid>
        )}
        {!loading && (
          <>
            <Grid item xs={4}>
              <DetailBox title="Company details" rows={companyDetailsRows} />
              {canHavePassedReports && (
                <Box mt={2.5}>
                  <DetailBox title="Passed reports" rows={passedReportRows} />
                </Box>
              )}
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DetailBox title="Permissions" rows={[]}>
                    {companyQuery?.company.types.map((type) => (
                      <Chip key={type.id} label={type.name} className={classes.chip} />
                    ))}
                    {companyQuery?.company.isT1Contractor && <Chip label={'T1 Contractor'} className={classes.chip} />}
                  </DetailBox>
                </Grid>
                <Grid item xs={12}>
                  <DetailBox
                    title="Options"
                    rows={[
                      {
                        label: 'Can report installations',
                        value: companyQuery?.company.canReportInstallations ? 'Yes' : 'No',
                      },
                    ]}
                  ></DetailBox>
                </Grid>
                {showAPIIntegration && (
                  <Grid item xs={12}>
                    <DetailBox title="Integration" rows={[]}>
                      <TextField
                        label="API key"
                        variant="outlined"
                        className={classes.apiKeyInput}
                        fullWidth
                        disabled
                        value={apiKey}
                        InputProps={{
                          startAdornment: apiKey && (
                            <InputAdornment position="start">
                              <Tooltip title="Copy API key to clipboard">
                                <IconButton onClick={onCopyApiKey} edge="start">
                                  <AssignmentIcon />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Regenerate API key">
                                <IconButton onClick={onResetApiKey} edge="end">
                                  <RefreshIcon />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box mt={1}>
                        <Link href={`${baseUrl}/api-docs`} target="_blank">
                          Review the latest API documentation
                        </Link>
                      </Box>
                    </DetailBox>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <DetailBox title="Billing information" rows={billingInfoRows} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
