import React from 'react';
import { Box, Chip, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, makeStyles } from '@material-ui/core';
import { Door } from '../../../queries';
import DoorStatus from '../../../constants/Status';
import WorkTypes from '../../../constants/WorkTypes';
import AssignmentTypes from '../../../constants/AssignmentTypes';

const getRelevantAssignment = (door: Partial<Door>, assignmentType: AssignmentTypes) => {
  const validAssignments = door?.activeDoorAssignments?.filter((ada) => ada.doorAssignmentType.code === assignmentType);

  if (!validAssignments || validAssignments.length === 0) {
    return null;
  }

  let assignment;
  switch (door.status!.code) {
    case DoorStatus.InstallerFailedSignOff:
    case DoorStatus.InstallerIncomplete:
    case DoorStatus.InstallerInstallationComplete:
    case DoorStatus.InstallerInstalling:
    case DoorStatus.InstallerReady:
    case DoorStatus.InstallerRequiresSignOff:
      assignment = validAssignments.find((va) => va.workType.code === WorkTypes.Installation);
      break;
    case DoorStatus.Inspecting:
    case DoorStatus.InspectionDue:
    case DoorStatus.InspectionOverdue:
    case DoorStatus.InspectionRequiresSignOff:
    case DoorStatus.InspectorIncomplete:
    case DoorStatus.NoInspectionSet:
      assignment = validAssignments.find((va) => va.workType.code === WorkTypes.Inspection);
      break;
    case DoorStatus.RequiresRepair:
      assignment = validAssignments.find((va) => va.workType.code === WorkTypes.Repair || WorkTypes.Replacement);

      break;
  }

  return assignment ?? validAssignments[0];
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
  },
  tooltip: {
    maxWidth: '500px',
  },
}));

type Props = {
  door: Partial<Door>;
  assignmentType: AssignmentTypes;
};
const ContractorTableCell = ({ door, assignmentType }: Props) => {
  const classes = useStyles();
  const relevantAssignment = getRelevantAssignment(door, assignmentType);
  const assignments = door.activeDoorAssignments?.filter((a) => a.doorAssignmentType.code === assignmentType);
  return (
    <TableCell>
      <Box className={classes.container}>
        {relevantAssignment?.contractorCompany.name}
        {assignments && assignments.length > 1 && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Table aria-label="Work table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell>Work type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignments.map((a, i) => (
                    <TableRow key={i}>
                      <TableCell size="small">{a.contractorCompany.name}</TableCell>
                      <TableCell size="small">{a.workType.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          >
            <Chip label={`+${assignments.length - 1}`} size="small" />
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
};

export default ContractorTableCell;
