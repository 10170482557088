import create from 'zustand'
type MySitesSearchStore = {
  searchTerm: string
  setSearchTerm: (textValue: string) => void
}

const useMySitesSearchStore = create<MySitesSearchStore>((set) => ({
  searchTerm: '',
  setSearchTerm: (textValue: string) => set(() => ({ searchTerm: textValue })),
}))

export default useMySitesSearchStore
