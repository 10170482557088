import { useCallback, useState } from 'react';

export default function useTable(
  defaultSortField: string,
  defaultPage: number = 0,
  defaultPageSize: number = 10,
  defaultSortDirection: 'asc' | 'desc' = 'asc'
) {
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(defaultSortDirection);

  const pageChanged = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const pageSizeChanged = (event: any) => {
    setPage(0);
    setPageSize(event.target.value);
  };

  const sort = (field: string) => {
    if (sortField === field) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortDirection('asc');
    }
    setSortField(field);
  };

  return { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort };
}
