import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper, Typography, Grid, makeStyles, FormControl } from '@material-ui/core';
import Layout from '../components/Layout';
import NumberOfDoorsKpi from '../components/dashboard/kpi/NumberOfDoorsKpi';
import InspectionsDueThisMonthKpi from '../components/dashboard/kpi/InspectionsDueThisMonthKpi';
import ControlledSiteAutocomplete, { FormSite } from '../components/forms/ControlledSiteAutocomplete';
import { FormProvider, useForm } from 'react-hook-form';
import NumberOfFailedInspectionsKpi from '../components/dashboard/kpi/NumberOfFailedInspectionsKpi';
import AverageRepairTimeKpi from '../components/dashboard/kpi/AverageRepairTimeKpi';
import NumberOfInspectionsDueByMonth from '../components/dashboard/charts/NumberOfInspectionsDueByMonthChart';
import InspectionsOverviewWidget from '../components/dashboard/charts/InspectionsOverviewWidget';

type FormFields = {
  site?: FormSite;
};
const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
  },
  site: {
    minWidth: '350px',
  },
});

export default function Dashboard() {
  const classes = useStyles();
  const [currentSiteId, setCurrentSiteId] = useState<string | undefined>(undefined);
  const form = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { watch, handleSubmit, setValue } = form;
  const site = watch('site');

  useEffect(() => {
    setCurrentSiteId(site?.id);
  }, [site]);

  const onSiteSelected = useCallback(
    (site: { id: string; name: string }) => {
      setValue('site', site);
    },
    [setValue]
  );

  return (
    <Layout>
      <Box className={classes.header}>
        <Typography variant="h5">Dashboard</Typography>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(() => {})}>
            <FormControl className={classes.site}>
              <ControlledSiteAutocomplete name="site" label="Site" />
            </FormControl>
          </form>
        </FormProvider>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={6} md={6} xs={12}>
            <NumberOfDoorsKpi siteId={currentSiteId} />
          </Grid>
          <Grid item xl={3} lg={6} md={6} xs={12}>
            <Paper>
              <InspectionsDueThisMonthKpi siteId={currentSiteId} />
            </Paper>
          </Grid>
          <Grid item xl={3} lg={6} md={6} xs={12}>
            <Paper>
              <NumberOfFailedInspectionsKpi siteId={currentSiteId} />
            </Paper>
          </Grid>
          <Grid item xl={3} lg={6} md={6} xs={12}>
            <Paper>
              <AverageRepairTimeKpi siteId={currentSiteId} />
            </Paper>
          </Grid>
          <Grid item xl={8} lg={8} md={12} xs={12}>
            <Paper>
              <InspectionsOverviewWidget siteId={currentSiteId} onSiteSelected={onSiteSelected} />
            </Paper>
          </Grid>
          <Grid item xl={8} lg={8} md={12} xs={12}>
            <Paper>
              <NumberOfInspectionsDueByMonth siteId={currentSiteId} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
