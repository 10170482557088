import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import KpiCard from '../KpiCard';
import { useDashboardNbDoorsKpiQuery } from '../../../queries';

const useStyles = makeStyles({
  footer: {
    color: '#FFFFFF',
  },
});

type Props = {
  siteId?: string | null;
};

export default function NumberOfDoorsKpi({ siteId }: Props) {
  const classes = useStyles();
  const { loading, data } = useDashboardNbDoorsKpiQuery({
    variables: { siteId: siteId ?? null },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <KpiCard
      title="Number of doors"
      value={data?.dashboard.nbDoors}
      loading={loading}
      footer={
        <Typography
          variant="body1"
          className={classes.footer}
        >{`${data?.dashboard.nbDoorsAddedThisMonth} installations since previous month`}</Typography>
      }
    />
  );
}
