import create from 'zustand';
type AssignDoorsToContractorState = {
  doorIds: string[];
  setDoorIds: (ids: string[]) => void;
};

const useAssignDoorsToContractorStore = create<AssignDoorsToContractorState>((set) => ({
  doorIds: [],
  setDoorIds: (ids: string[]) => set(() => ({ doorIds: ids })),
}));

export default useAssignDoorsToContractorStore;
