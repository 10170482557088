import React from 'react';
import { SiteAddFormData } from '../pages/SiteAdd';
import { SiteQuery } from '../queries';

interface SiteContextValue {
  siteModel?: SiteQuery;
  site?: SiteAddFormData;
  setSite: React.Dispatch<React.SetStateAction<SiteAddFormData | undefined>>;
}

// @ts-ignore
export default React.createContext<SiteContextValue>();
