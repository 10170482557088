import { Badge, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import React, { useState } from 'react';
import TextFilter from '../../../components/companies/TextFilter';
import TallTab from '../../../components/TallTab';
import FilterDrawer from './FilterDrawer';
import FilterListIcon from '@material-ui/icons/FilterList';
import { BillingSearchFilter } from '../Billing';

export type BillingFilterTab = 'installer' | 'fm';

interface Props {
  tab: BillingFilterTab;
  onTabChanged: (tab: BillingFilterTab) => void;

  filter?: BillingSearchFilter;
  onFilterChanged: (filter: BillingSearchFilter) => void;
}
export default function BillingFilter({ onTabChanged, tab, filter, onFilterChanged }: Props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showFilterBadge, setShowFilterBadge] = useState(false);

  const handleMenuOpenClick = () => {
    setIsDrawerOpen(true);
  };

  const tabChanged = (_: any, newValue: string) => {
    const newTab = newValue as BillingFilterTab;
    onTabChanged(newTab);
  };

  const onFilterChange = (newFilter: BillingSearchFilter, isCustomFilter: boolean) => {
    onFilterChanged(newFilter);
    setShowFilterBadge(isCustomFilter);
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" width="100%">
        <Tabs value={tab} onChange={tabChanged} indicatorColor="primary">
          <TallTab value={'installer'} label="Installer" />
          <TallTab value={'fm'} label="FM Company" />
        </Tabs>

        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <TextFilter
            searchText={filter?.textFilter ?? undefined}
            onSearchTextChanged={(text) => {
              onFilterChanged({ ...filter!, textFilter: text });
            }}
            label="Search companies"
          />

          <Box pr={2} display="flex" alignItems="center">
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuOpenClick}>
              <Badge variant="dot" color="primary" invisible={!showFilterBadge}>
                <FilterListIcon />
              </Badge>
            </IconButton>
          </Box>
        </Box>

        <FilterDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          filter={filter!}
          setFilter={onFilterChange}
        />
      </Box>
    </Box>
  );
}
