import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import HistoryEventTypes from '../../../../../constants/HistoryEventTypes';
import { DoorHistoryFragment } from '../../../../../queries';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import StandardGridItem from './StandardGridItem';

interface DoorAssignedAdditionalInfo {
  assignmentType: string;
  workType: string;
  destinationCompanyName: string;
  destinationUserName: string;
}

interface DoorAssignedDetailDialogProps {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
  isPass: boolean;
}

function DetailDialog({ history, isPass }: DetailDialogProps) {
  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorAssignedAdditionalInfo;
  return (
    <Box pt={1} pb={1}>
      <Grid container spacing={3}>
        <StandardGridItem title="Work type" value={additionalInfo.workType} />
        <StandardGridItem title="Assignment type" value={additionalInfo.assignmentType} />

        <StandardGridItem
          title="Assigned to"
          value={`${additionalInfo.destinationUserName} - ${additionalInfo.destinationCompanyName}`}
        />
      </Grid>
    </Box>
  );
}

export default function DoorAssignedDetailDialog({ open, onClose, history }: DoorAssignedDetailDialogProps) {
  const isPass = history.historyEventType.code === HistoryEventTypes.DoorInspectionPassed;
  return (
    <DoorHistoryDetailDialog open={open} onClose={onClose} history={history}>
      <DetailDialog history={history} isPass={isPass} />
    </DoorHistoryDetailDialog>
  );
}
