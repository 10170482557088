import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import React, { useState } from 'react';
import DoorStatus from '../../../constants/Status';
import useAuth from '../../../hooks/useAuth';
import Roles from '../../../constants/Roles';
import SiteDoorsSearchBar, { SiteDoorsSearchBarFilter } from './SiteDoorsSearchBar';
import TallTab from '../../TallTab';

export type SiteDoorsFilterTab =
  | 'installing'
  | 'transferred'
  | 'all'
  | DoorStatus.InstallerReady
  | DoorStatus.InstallerInstallationComplete
  | DoorStatus.InstallerFailedSignOff;

interface SiteDoorsFilterProps {
  onFilterChanged: (selectedTab: SiteDoorsFilterTab) => void;
  onTextFilterChange: (filter: SiteDoorsSearchBarFilter) => void;
  setAllChecked: (checked: boolean) => void;
  tab: SiteDoorsFilterTab;
  textFilter?: string;
}
export default function SiteDoorsFilter({
  onFilterChanged,
  onTextFilterChange,
  setAllChecked,
  tab,
  textFilter,
}: SiteDoorsFilterProps) {
  const { hasAnyRole } = useAuth();

  const [selectedTab, setSelectedTab] = useState(tab);

  const tabChanged = (_: any, newValue: string) => {
    const newTab = newValue as SiteDoorsFilterTab;
    setSelectedTab(newTab);
    setAllChecked(false);

    onFilterChanged(newTab);
  };

  const canSeeSearchBar =
    hasAnyRole([Roles.FacilityManager, Roles.FacilityManagerAdmin]) ||
    (hasAnyRole([Roles.InstallerAdmin]) && selectedTab === 'all');

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" width="100%">
        <Tabs value={selectedTab} onChange={tabChanged} indicatorColor="primary">
          {hasAnyRole([Roles.InstallerAdmin]) && <TallTab value={DoorStatus.InstallerReady} label="Not installed" />}
          {hasAnyRole([Roles.InstallerAdmin]) && <TallTab value="installing" label="Installing" />}
          {hasAnyRole([Roles.InstallerAdmin]) && (
            <TallTab value={DoorStatus.InstallerInstallationComplete} label="Installed" />
          )}

          {hasAnyRole([Roles.InstallerAdmin]) && <TallTab value="transferred" label="Transferred" />}
          {hasAnyRole([Roles.InstallerAdmin]) && <TallTab value={DoorStatus.InstallerFailedSignOff} label="Failed" />}
          {hasAnyRole([Roles.InstallerAdmin]) && <TallTab value="all" label="All" />}
        </Tabs>

        {canSeeSearchBar && <SiteDoorsSearchBar onTextFilterChange={onTextFilterChange} textFilter={textFilter} />}
      </Box>
    </Box>
  );
}
