import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import CompanyCertificationAddEdit from '../components/company/edit/CompanyCertificationAddEdit';
import CompanyDetailsAddEdit, { UserLookup } from '../components/company/edit/CompanyDetailsAddEdit';
import Layout from '../components/Layout';
import LoadingButton from '../components/LoadingButton';
import { Photo } from '../constants/DocumentTypes';
import { CompanyExists, UserExists } from '../constants/GraphQLErrorCodes';
import Roles from '../constants/Roles';
import * as Routes from '../constants/Routes';
import useAuth from '../hooks/useAuth';
import useDialog from '../hooks/useDialog';
import {
  CompanyQuery,
  NewCompanyInput,
  useCompanyLazyQuery,
  useEditCompanyMutation,
  useInviteCompanyMutation,
} from '../queries';
import { DoorPhoto } from './DoorAdd';

interface CompanyAddEditFormData {
  photos?: DoorPhoto[];
  companyName: string;
  companyTypes: number[];
  adminName: string;
  adminEmail: string;
  contactPhone: string;
  billingEmail: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingCity: string;
  billingPostcode: string;
  isT1Contractor: boolean;
  canReportInstallations: boolean;
  installationCertificateAdditionalText?: string;
  inspectionCertificateAdditionalText?: string;
}

interface CompanyAddEditParams {
  id?: string;
}

export default function CompanyAddEdit() {
  const [tabIndex, setTabIndex] = useState(0);
  const [companyError, setCompanyError] = useState<string | undefined>(undefined);
  const [adminEmailError, setAdminEmailError] = useState<string | undefined>(undefined);
  const [selectedSiteAdmin, setSelectedSiteAdmin] = useState<UserLookup>(null);
  const [accreditationLogos, setAccreditationLogos] = useState<DoorPhoto[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();
  const { hasRole } = useAuth();
  const history = useHistory();
  const { id } = useParams<CompanyAddEditParams>();
  const isEdit = !!id;
  const [getCompany, { data: existingCompany }] = useCompanyLazyQuery({
    variables: { id: id! },
  });
  const [inviteCompanyMutation, { loading: inviteLoading }] = useInviteCompanyMutation();
  const [editCompanyMutation, { loading: editLoading }] = useEditCompanyMutation();
  const form = useForm<CompanyAddEditFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });
  const { handleSubmit, errors, formState, control, reset, setValue, getValues, trigger } = form;

  useEffect(() => {
    getCompany();
  }, [id, getCompany]);

  useEffect(() => {
    if (existingCompany) {
      const newFormData = translateCompany(existingCompany);
      setAccreditationLogos(newFormData.photos ?? []);
      reset(newFormData);
      setSelectedSiteAdmin({
        id: '',
        fullName: existingCompany.company.admin?.fullName!,
        email: existingCompany.company.admin?.email!,
      });
    }
  }, [existingCompany, reset]);

  const companyName = useWatch({
    control,
    name: 'companyName',
  });
  const adminEmail = useWatch({
    control,
    name: 'adminEmail',
  });

  const translateCompany = (companyQuery: CompanyQuery): CompanyAddEditFormData => {
    const company = companyQuery.company;
    const newFormData: CompanyAddEditFormData = {
      companyName: company.name,
      companyTypes: company.types.map((t) => t.id),
      adminEmail: company.admin?.email!,
      adminName: company.admin?.fullName!,
      contactPhone: company.contactPhone!,
      billingEmail: company.billingEmail!,
      billingAddressLine1: company.billingAddressOne!,
      billingAddressLine2: company.billingAddressTwo!,
      billingCity: company.billingCity!,
      billingPostcode: company.billingPostcode!,
      isT1Contractor: company.isT1Contractor ?? false,
      installationCertificateAdditionalText: company.installationCertificateAdditionalText ?? undefined,
      inspectionCertificateAdditionalText: company.inspectionCertificateAdditionalText ?? undefined,
      canReportInstallations: company.canReportInstallations,
    };
    const existingPhotos = company.documents?.filter((d) => d.documentType.code === Photo);
    if (existingPhotos && existingPhotos.length > 0) {
      const translatedPhotos: DoorPhoto[] = existingPhotos.map((p) => ({
        id: p.id,
        description: p.description,
        file: {
          name: p.originalFilename,
        },
      }));

      newFormData.photos = translatedPhotos;
    }

    return newFormData;
  };

  const saveCompany = async () => {
    const values = getValues();
    values.companyName = values?.companyName?.trim();
    values.adminName = values?.adminName?.trim();
    values.adminEmail = values?.adminEmail?.trim();
    values.billingEmail = values?.billingEmail?.trim();
    setCompanyError(undefined);

    const completeFormData: CompanyAddEditFormData = { ...values, photos: accreditationLogos };

    const { companyTypes, ...excludeCompanyTypes } = completeFormData;
    const companyInput: NewCompanyInput = {
      ...excludeCompanyTypes,
      typeIds: completeFormData.companyTypes,
      id: existingCompany?.company.id,
      isT1Contractor: completeFormData.isT1Contractor ?? false,
      canReportInstallations: completeFormData.canReportInstallations ?? false,
    };

    companyInput.photos = accreditationLogos?.map((d) => ({
      id: d.id,
      description: d.description,
      file: d.id ? null : d.file, // send the file object for new files only or they will not match the FileUpload type
    }));

    try {
      if (isEdit) {
        await editCompanyMutation({
          variables: {
            companyInput,
          },
        });
      } else {
        await inviteCompanyMutation({
          variables: {
            companyInput,
          },
        });
      }
      if (hasRole(Roles.SuperUser)) {
        history.push(Routes.Companies, {
          companyAdded: completeFormData.companyName,
          isEdit,
        });
      } else if (hasRole(Roles.ManufacturerAdmin) || hasRole(Roles.InstallerAdmin)) {
        history.push(`${Routes.Companies}/${existingCompany?.company.id}`, {
          companyAdded: completeFormData.companyName,
          isEdit,
        });
      }
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        if (gqlerror?.extensions?.code === CompanyExists) {
          setCompanyError(gqlerror.message);
        } else if (gqlerror?.extensions?.code === UserExists) {
          setAdminEmailError(gqlerror.message);
        }
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    }
  };

  useEffect(() => {
    setCompanyError(errors.companyName?.message?.toString());
  }, [companyName, errors.companyName]);

  useEffect(() => {
    setAdminEmailError(errors.adminEmail?.message?.toString());
  }, [adminEmail, errors.adminEmail]);

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        if (hasRole(Roles.SuperUser)) {
          history.push(Routes.Companies);
        } else if (hasRole(Roles.ManufacturerAdmin) || hasRole(Roles.InstallerAdmin)) {
          history.push(`${Routes.Companies}/${existingCompany?.company.id}`);
        }
      },
    });
  };

  const onTabChange = (_: any, value: number) => {
    setTabIndex(value);
  };

  const updateSiteAdmin = (newValue: UserLookup) => {
    setSelectedSiteAdmin(newValue);
    setValue('adminEmail', newValue?.email);
    trigger(['adminEmail', 'adminName']);
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">{isEdit ? 'Edit company' : 'Invite new company'}</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href={Routes.Companies}>
                  <Typography variant="body2">Companies</Typography>
                </Link>
                {isEdit && (
                  <Link color="inherit" href={`${Routes.Companies}/${id}`}>
                    <Typography variant="body2">{existingCompany?.company.name}</Typography>
                  </Link>
                )}
                {isEdit && <Typography variant="body2">Edit company</Typography>}
                {!isEdit && <Typography variant="body2">Invite new company</Typography>}
              </Breadcrumbs>
            </Box>
            <Box>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>
          <Paper>
            <Tabs value={tabIndex} onChange={onTabChange} indicatorColor="primary" scrollButtons="auto">
              <Tab label="Details" />
              {hasRole(Roles.SuperUser) && <Tab label="Certification" />}
            </Tabs>
            <Box p={5} marginTop={3}>
              <FormProvider {...form}>
                <form onSubmit={handleSubmit(saveCompany)}>
                  {
                    ({
                      0: (
                        <CompanyDetailsAddEdit
                          isEdit={isEdit}
                          control={control}
                          companyError={companyError}
                          updateSiteAdmin={updateSiteAdmin}
                          selectedSiteAdmin={selectedSiteAdmin}
                          adminEmailError={adminEmailError}
                          existingCompany={existingCompany}
                        />
                      ),
                      1: (
                        <CompanyCertificationAddEdit
                          isEdit={isEdit}
                          existingCompany={existingCompany}
                          photos={accreditationLogos}
                          setPhotos={setAccreditationLogos}
                        />
                      ),
                    } as Record<number, React.ReactNode>)[tabIndex]
                  }
                  <Box display="flex" justifyContent="flex-end" marginTop={3}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!formState.isValid || inviteLoading || editLoading}
                      loading={inviteLoading || editLoading}
                    >
                      {isEdit ? 'Save' : 'Invite company'}
                    </LoadingButton>
                  </Box>
                </form>
              </FormProvider>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
