import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({
  line: {
    height: 8,
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 5,
  },
  active: {
    '& $line': {
      background: 'linear-gradient(270deg, #00BC91 0%, #00DCAA 100%)',
      boxShadow: '0 2px 15px 0 rgba(0,188,145,0.5)',
    },
  },
  completed: {
    '& $line': {
      background: 'linear-gradient(270deg, #00BC91 0%, #00DCAA 100%)',
      boxShadow: '0 2px 15px 0 rgba(0,188,145,0.5)',
    },
  },
})(StepConnector);
