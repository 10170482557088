import React, { useReducer } from 'react';
import { SET_LOGGED_IN_USER } from './Actions';
import Action from '../Action';
import * as winston from 'winston';

interface Role {
  code: string;
  name: string;
}

interface LoggedInUser {
  id: string;
  fullName: string;
  companyId?: string;
  companyName?: string;
  roles: Role[];
  options: {
    canReportInstallations: boolean;
  };
}

interface AppState {
  loggedIn?: boolean;
  loggedInUser?: LoggedInUser;
}

export interface AppContextContent {
  state: AppState;
  setLoggedInUser: (user: LoggedInUser | undefined) => void;
}

const AppContext = React.createContext<AppContextContent>({} as AppContextContent);

function reducerWrapper(state: AppState, action: Action) {
  const newState = reducer(state, action);
  winston.debug('AppContext reducer called with action', { action, prevState: state, newState });
  return newState;
}
function reducer(state: AppState, action: Action) {
  switch (action.type) {
    case SET_LOGGED_IN_USER:
      return { ...state, loggedInUser: action.payload, loggedIn: action.payload !== undefined };
    default:
      winston.debug('AppContext reducer called with action type not handled', action.type);
      return state;
  }
}

const initialState: AppState = {
  loggedIn: undefined,
};

interface AppContextProviderProps {
  children: React.ReactNode;
}

export function AppContextProvider(props: AppContextProviderProps) {
  const [state, dispatch] = useReducer(reducerWrapper, initialState);

  const value: AppContextContent = {
    state,
    setLoggedInUser: (user: LoggedInUser | undefined) => {
      dispatch({ type: SET_LOGGED_IN_USER, payload: user });
    },
  };

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
}

export default AppContext;
