import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default function Loading() {
  const ctrans: CSSProperties = {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    textAlign: 'center',
  };
  return (
    <Box style={ctrans}>
      <CircularProgress color="secondary" />
    </Box>
  );
}
