import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import SearchIcon from '@material-ui/icons/Search';

export interface SiteDoorsSearchBarFilter {
  active: boolean | undefined;
  textFilter: string | undefined;
}

interface SiteDoorsSearchBarProps {
  onTextFilterChange: (filter: SiteDoorsSearchBarFilter) => void;
  textFilter?: string;
}

export default function SiteDoorsSearchBar({ onTextFilterChange, textFilter }: SiteDoorsSearchBarProps) {
  const [filter, setFilter] = useState<SiteDoorsSearchBarFilter>({
    active: true,
  } as SiteDoorsSearchBarFilter);
  const [textCriteria, setTextCriteria] = useState<string | undefined>(textFilter);
  const [debouncedTextCriteria] = useDebounce(textCriteria, 300); // this is to prevent calling the api with every key stroke in the text input.

  useEffect(() => {
    if (filter) {
      onTextFilterChange(filter);
    }
  }, [filter, onTextFilterChange]);

  useEffect(() => {
    setFilter((prevFilter) => {
      return { ...prevFilter, textFilter: debouncedTextCriteria };
    });
  }, [debouncedTextCriteria]);

  const onTextCriteriaChanged = (event: any) => {
    setTextCriteria(event.currentTarget.value);
  };

  return (
    <>
      <Box display="flex" alignItems="center" p={1} pr={3}>
        <Box display="flex" alignItems="center" mr={1}>
          <SearchIcon />
        </Box>
        <Box>
          <TextField
            name="searchDoors"
            label="Search doors"
            variant="outlined"
            onChange={onTextCriteriaChanged}
            value={textCriteria}
          />
        </Box>
      </Box>
    </>
  );
}
