import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  infoImageCell: {
    borderBottom: 'none',
    padding: '50px',
  },
});

interface InfoImageRowProps {
  colSpan: number;
  imageSrc: string;
  alt?: string;
  text: string;
}

export default function NoResuInfoImageRow({ colSpan, imageSrc, text, alt }: InfoImageRowProps) {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} className={classes.infoImageCell} align="center">
        <img src={imageSrc} alt={alt} />
        <Typography>{text}</Typography>
      </TableCell>
    </TableRow>
  );
}
