import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import * as Routes from '../../constants/Routes';

const useStyles = makeStyles({
  selectedOption: {
    backgroundColor: 'rgba(255, 255, 255, 0.16) !important',
  },
});

interface AddDoorDialogProps {
  open: boolean;
  onCancel: () => void;
}

export default function AddDoorDialog({ open, onCancel }: AddDoorDialogProps) {
  const [newDoorDialogOption, setNewDoorDialogOption] = useState<'import' | 'manual' | undefined>();
  const history = useHistory();
  const classes = useStyles();

  const onContinue = () => {
    if (newDoorDialogOption === 'import') {
      history.push(Routes.ImportDoors);
    } else if (newDoorDialogOption === 'manual') {
      history.push(Routes.ManufacturedDoorsAdd);
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add new doors</DialogTitle>
      <List>
        <ListItem
          button
          onClick={() => setNewDoorDialogOption('import')}
          selected={newDoorDialogOption === 'import'}
          classes={{ selected: classes.selectedOption }}
        >
          <ListItemIcon>
            <ImportExportIcon />
          </ListItemIcon>
          <ListItemText primary="Import doors" />
        </ListItem>
        <ListItem
          button
          onClick={() => setNewDoorDialogOption('manual')}
          selected={newDoorDialogOption === 'manual'}
          classes={{ selected: classes.selectedOption }}
        >
          <ListItemIcon>
            <PlaylistAddIcon />
          </ListItemIcon>
          <ListItemText primary="Add door manually" />
        </ListItem>
      </List>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onContinue} disabled={!newDoorDialogOption}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
