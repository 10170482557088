import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { InstallationCertificate, InstallationReport } from '../../../../../constants/DocumentTypes';
import HistoryEventTypes from '../../../../../constants/HistoryEventTypes';
import { DoorHistoryFragment } from '../../../../../queries';
import DocumentRow from '../../../../DocumentRow';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import StandardGridItem from './StandardGridItem';

interface DoorInstallationAdditionalInfo {
  message?: string;
  installedBy: string;
  installCheckedBy: string;
  installationId: number;
  site: string;
  location: string;
  locationType?: string;
  reportId?: number;
  reportOriginalFilename: string;
  certificateId?: number;
  certificateOriginalFilename: string;
}

interface DoorInstallationDetailDialogProps {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
  isPass: boolean;
}

function DetailDialog({ history, isPass }: DetailDialogProps) {
  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorInstallationAdditionalInfo;
  return (
    <Box pt={1} pb={1}>
      <Grid container spacing={3}>
        <StandardGridItem title="Site" value={additionalInfo.site} />
        <StandardGridItem title="Location" value={additionalInfo.location} />
        <StandardGridItem title="Installed by" value={additionalInfo.installedBy} />
        {additionalInfo.locationType && <StandardGridItem title="Location type" value={additionalInfo.locationType} />}
        <StandardGridItem
          title={isPass ? 'Signoff comment' : 'Failure reason'}
          value={additionalInfo.message}
          minWidth={12}
        />
        {additionalInfo.reportId && (
          <DocumentRow
            originalFilename={additionalInfo.reportOriginalFilename}
            documentType={{ code: InstallationReport, name: 'Installation report' }}
            documentId={additionalInfo.reportId}
          />
        )}
        {additionalInfo.certificateId && (
          <DocumentRow
            originalFilename={additionalInfo.certificateOriginalFilename}
            documentType={{ code: InstallationCertificate, name: 'Installation certificate' }}
            documentId={additionalInfo.certificateId}
          />
        )}
      </Grid>
    </Box>
  );
}

export default function DoorInstallationDetailDialog({ open, onClose, history }: DoorInstallationDetailDialogProps) {
  const isPass = history.historyEventType.code === HistoryEventTypes.DoorInstallationPassed;
  const actorName = isPass ? 'Signed off' : 'Failed by';
  return (
    <DoorHistoryDetailDialog open={open} onClose={onClose} history={history} actorName={actorName}>
      <DetailDialog history={history} isPass={isPass} />
    </DoorHistoryDetailDialog>
  );
}
