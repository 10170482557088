import { useCallback } from 'react';
import { DoorModelPartsFragment, DoorParts } from '../queries';

export default function usePartList(
  doorModelParts?: Partial<DoorModelPartsFragment> | null,
  doorParts?: Partial<DoorParts> | null,
  readOnly?: boolean
) {
  const isEditable = useCallback(
    (field: string): boolean => {
      const isDoorModelPartPopulated = (doorModelParts && !!(doorModelParts as any)[field]) ?? false;
      const isDoorPartPopulated = (doorParts && !!(doorParts as any)[field]) ?? false;
      const isReadOnly = readOnly ?? false;
      return !(isDoorModelPartPopulated || (isReadOnly && isDoorPartPopulated));
    },
    [doorModelParts, doorParts, readOnly]
  );

  return { isEditable };
}
