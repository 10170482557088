import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

export interface SitesSearchFilter {
  active: boolean | undefined;
  textFilter: string | undefined;
}

interface SitesFilterProps {
  onFilterChanged: (filter: SitesSearchFilter) => void;
}

export default function SitesFilter(props: SitesFilterProps) {
  const [filter, setFilter] = useState<SitesSearchFilter>({
    active: true,
  } as SitesSearchFilter);
  const [textCriteria, setTextCriteria] = useState<string | undefined>();
  const [debouncedTextCriteria] = useDebounce(textCriteria, 300); // this is to prevent calling the api with every key stroke in the text input.

  useEffect(() => {
    if (filter && props.onFilterChanged) {
      props.onFilterChanged(filter);
    }
  }, [filter, props]);

  useEffect(() => {
    setFilter((prevFilter) => {
      return { ...prevFilter, textFilter: debouncedTextCriteria };
    });
  }, [debouncedTextCriteria, setFilter]);

  const onTextCriteriaChanged = (event: any) => {
    setTextCriteria(event.currentTarget.value);
  };

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row">
          <Box p={2}>
            <TextField variant="outlined" name="textCriteria" label="Search sites" onChange={onTextCriteriaChanged} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
