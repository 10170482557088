import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { RouteComponentProps, useHistory, useParams } from 'react-router';
import Layout from '../components/Layout';
import LoadingButton from '../components/LoadingButton';
import { UserExists } from '../constants/GraphQLErrorCodes';
import * as Routes from '../constants/Routes';
import useDialog from '../hooks/useDialog';
import {
  EditUserInput,
  NewUserInput,
  Role,
  useAddUserMutation,
  useEditUserMutation,
  useRolesByCompanyQuery,
  useUserLazyQuery,
} from '../queries';

interface UserAddEditLocationState {
  companyName: string;
}

interface UserAddEditFormData {
  roles: number[];
  fullName: string;
  email: string;
}

interface UserAddEditParams {
  id?: string;
  userId?: string;
}

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  roleInput: {
    height: 55,
  },
});

export default function UserAddEdit(props: RouteComponentProps<{}, any, UserAddEditLocationState>) {
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [saving, setSaving] = useState(false);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const { id, userId } = useParams<UserAddEditParams>();
  const isEdit = !!userId;
  const history = useHistory();
  const { showDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { data, loading } = useRolesByCompanyQuery({
    variables: {
      id: id!,
    },
  });
  const [getUser, { data: existingUser }] = useUserLazyQuery({
    variables: { id: Number(userId) },
  });

  const [addUserMutation] = useAddUserMutation({});
  const [editUserMutation] = useEditUserMutation({});

  const { handleSubmit, register, errors, formState, control, reset } = useForm<UserAddEditFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const email = useWatch({
    control,
    name: 'email',
  });

  useEffect(() => {
    getUser();
  }, [userId, getUser]);

  useEffect(() => {
    if (existingUser) {
      const newFormData: UserAddEditFormData = {
        fullName: existingUser.user.fullName,
        email: existingUser.user.email,
        roles: existingUser.user.roles.map((r) => r.id),
      };
      setSelectedRoles(existingUser.user.roles.map((r) => r.id));
      reset(newFormData);
    }
  }, [existingUser, reset]);

  useEffect(() => {
    setEmailError(errors.email?.message?.toString());
  }, [email, errors.email]);

  const saveUser = async (values: UserAddEditFormData) => {
    setSaving(true);

    try {
      const { roles, ...excludeRoles } = values;
      if (isEdit) {
        const editUserInput: EditUserInput = {
          userId: Number(userId),
          roleIds: roles,
        };
        await editUserMutation({
          variables: {
            editUserInput,
          },
        });
      } else {
        const newUserInput: NewUserInput = {
          ...excludeRoles,
          roleIds: roles,
          companyId: parseInt(id!),
        };
        await addUserMutation({
          variables: {
            newUserInput,
          },
        });
      }
      enqueueSnackbar('User successfully invited.', { variant: 'success' });
      history.goBack();
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        if (gqlerror?.extensions?.code === UserExists) {
          setEmailError(gqlerror.message);
        }
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    } finally {
      setSaving(false);
    }
  };

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        history.goBack();
      },
    });
  };

  const selectableRoles = useMemo(() => {
    const result = new Map<number, Pick<Role, 'id' | 'name' | 'code'>>();
    const addRole = (role: Pick<Role, 'id' | 'name' | 'code'>) => {
      if (!result.has(role.id)) {
        result.set(role.id, role);
      }
    };
    data?.rolesByCompany.map((r) => addRole(r));
    existingUser?.user.roles.map((r) => addRole(r));

    return [...result.values()];
  }, [data?.rolesByCompany, existingUser]);

  const validateRoles = (values?: number[]) => {
    if (!values || values.length === 0) {
      return 'At least one role is required';
    }

    return undefined;
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">{isEdit ? 'Edit user' : 'Add new user'}</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href={`${Routes.Companies}/${id}`}>
                  <Typography variant="body2">{props.location?.state?.companyName ?? 'Company'}</Typography>
                </Link>
                <Typography variant="body2">Users</Typography>
                <Typography variant="body2">{isEdit ? 'Edit user' : 'Add new user'}</Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>

          <Paper>
            <Box p={5} marginTop={3}>
              <form onSubmit={handleSubmit(saveUser)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        htmlFor="roles"
                        error={!!errors.roles}
                        shrink={isEdit ? true : undefined}
                        id="rolesLabel"
                      >
                        Type
                      </InputLabel>

                      <Controller
                        control={control}
                        name="roles"
                        rules={{
                          validate: validateRoles,
                        }}
                        defaultValue={selectedRoles}
                        render={({ onChange }) => (
                          <Select
                            label="Type"
                            labelId="rolesLabel"
                            error={!!errors.roles}
                            inputProps={{
                              name: 'roles',
                              id: 'rolesInput',
                            }}
                            multiple
                            value={selectedRoles}
                            onChange={(e) => {
                              setSelectedRoles(e.target.value as number[]);
                              onChange(e.target.value as number[]);
                            }}
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {(selected as number[]).map((value) => {
                                  const role = selectableRoles.find((ct) => ct.id === value);
                                  return <Chip key={value} label={role?.name} className={classes.chip} />;
                                })}
                              </div>
                            )}
                            className={classes.roleInput}
                          >
                            {selectableRoles.map((role) => (
                              <MenuItem key={role.id} value={role.id}>
                                {role.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />

                      <FormHelperText error={!!errors.roles}>{(errors.roles as any)?.message}</FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <TextField
                      name="fullName"
                      label="Name"
                      variant="outlined"
                      disabled={isEdit}
                      fullWidth
                      error={!!errors.fullName}
                      helperText={errors?.fullName?.message}
                      inputRef={register({
                        required: 'Name required',
                      })}
                      inputProps={{ maxLength: 100 }}
                      InputLabelProps={{ shrink: isEdit ? true : undefined }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      name="email"
                      label="Email address"
                      variant="outlined"
                      disabled={isEdit}
                      fullWidth
                      error={!!emailError}
                      helperText={emailError}
                      inputRef={register({
                        required: 'Email address required',
                        pattern: {
                          value: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Invalid email address',
                        },
                      })}
                      inputProps={{ maxLength: 256 }}
                      InputLabelProps={{ shrink: isEdit ? true : undefined }}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" marginTop={3}>
                  <LoadingButton
                    loading={saving}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!formState.isValid || loading || saving}
                  >
                    {isEdit ? 'Save changes' : 'Invite user'}
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
