import Box from '@material-ui/core/Box';
import { SiteAddFormData } from '../../../pages/SiteAdd';
import SiteContext from '../../../context/SiteContext';
import SiteDetailsEdit from '../edit/SiteDetailsEdit';
import React, { useEffect, useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { Company } from '../../../queries';
import useAuth from '../../../hooks/useAuth';
import Roles from '../../../constants/Roles';

export interface SiteDetailsFormData {
  id: string;
  name: string;
  reference: string | null;
  owner?: Pick<Company, 'id' | 'name'> | null;
  areas: string | null;
  addressOne: string | null;
  addressTwo: string | null;
  city: string | null;
  postcode: string | null;
}

interface SiteDetailsAddProps {
  onContinue?: () => void;
}

export default function SiteDetailsAdd({ onContinue }: SiteDetailsAddProps) {
  const { site, setSite } = useContext(SiteContext);
  const { loggedInUser } = useAuth();
  const [enforcedOwner, setEnforcedOwner] = useState<Pick<Company, 'id' | 'name'> | null | undefined>(undefined);

  const form = useForm<SiteDetailsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { ...site },
  });

  const { handleSubmit, reset, formState } = form;

  useEffect(() => {
    if (!loggedInUser) {
      return;
    }

    let owner: Pick<Company, 'id' | 'name'> | null = null;
    if (loggedInUser.roles.find((r) => r.code === Roles.FacilityManagerAdmin)) {
      owner = {
        id: loggedInUser.companyId!,
        name: loggedInUser.companyName!,
      };
    }
    setEnforcedOwner(owner);
    reset({
      ...site,
      owner,
    });
  }, [reset, site, loggedInUser]);

  const saveSite = (values: SiteDetailsFormData) => {
    values.name = values?.name?.trim();
    setSite((prev: SiteAddFormData | undefined) => ({ ...prev, ...values }));
    if (onContinue) onContinue();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(saveSite)}>
        {/* Do not render the site details edit until we know if the owner is enforced or can be chosen */}
        {enforcedOwner !== undefined && (
          <SiteDetailsEdit
            siteOwnedByName={enforcedOwner?.name ?? site?.owner?.name}
            enableOwnerEdition={!enforcedOwner}
          />
        )}
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Button disabled={!formState.isValid} type="submit" variant="contained" color="primary">
            Continue
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
