import { useUserQuery } from '../queries';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import FullNameAvatar from '../components/FullNameAvatar';

export default function Home() {
  const { loading, error, data } = useUserQuery({
    variables: { id: 1 },
  });

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error :(</Typography>;

  return (
    <Container>
      <Paper>
        <Box p={4}>
          {data?.user.fullName && <FullNameAvatar fullName={data?.user.fullName[0]} />}
          <Typography>{data?.user.id}</Typography>
          <Typography>{data?.user.email}</Typography>
          <Typography>{data?.user.fullName}</Typography>
        </Box>
      </Paper>
    </Container>
  );
}
