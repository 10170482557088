import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { DoorModel, useDoorModelsByNameLazyQuery } from '../../../queries';
import DoorContext from '../../../context/DoorContext';
import { DoorFormData } from '../../../pages/DoorAdd';

const useStyles = makeStyles({
  input: {
    maxWidth: 400,
  },
});

interface DoorDoorModelAddProps {
  onContinue: () => void;
}

export default function DoorDoorModelAdd({ onContinue }: DoorDoorModelAddProps) {
  const [inputValue, setInputValue] = useState('');
  const [buttonText, setButtonText] = useState<'Skip' | 'Continue'>('Skip');
  const [debouncedTextCriteria] = useDebounce(inputValue, 300);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { setDoor, door } = useContext(DoorContext);

  const { handleSubmit, control, watch } = useForm<Pick<DoorFormData, 'doorModelId'>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: door,
  });

  const [getDoorModels, { loading, data, error }] = useDoorModelsByNameLazyQuery();

  useEffect(() => {
    if (error) {
      enqueueSnackbar('There was an error fetching door models.', { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (debouncedTextCriteria && debouncedTextCriteria.length >= 3) {
      getDoorModels({
        variables: {
          name: debouncedTextCriteria,
        },
      });
    }
  }, [debouncedTextCriteria, getDoorModels]);

  const selectedDoorModel = watch('doorModelId');

  useEffect(() => {
    if (selectedDoorModel !== undefined) {
      setButtonText('Continue');
    } else {
      setButtonText('Skip');
    }
  }, [selectedDoorModel]);

  const submit = ({ doorModelId }: Pick<DoorFormData, 'doorModelId'>) => {
    doorModelId = doorModelId && Number(doorModelId);
    setDoor((prev: DoorFormData | undefined) => ({ ...prev, doorModelId }));
    onContinue();
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box display="flex" justifyContent="center">
        <Controller
          control={control}
          name="doorModelId"
          render={({ onChange, value }) => (
            <Autocomplete
              fullWidth
              className={classes.input}
              getOptionLabel={(option) => option.name}
              options={data?.doorModelsByName ?? []}
              value={value?.id}
              renderInput={(params) => <TextField {...params} label="Type a door model" variant="outlined" fullWidth />}
              onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
              onChange={(_, newValue: Pick<DoorModel, 'id' | 'name'> | null) => {
                onChange(newValue?.id);
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              loading={loading}
              autoComplete
            />
          )}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" marginTop={3}>
        <Button type="submit" variant="contained" color="primary">
          {buttonText}
        </Button>
      </Box>
    </form>
  );
}
