import React from 'react';
import { Door, DoorModel } from '../queries';
import BuildIcon from '@material-ui/icons/Build';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ImportContactsRoundedIcon from '@material-ui/icons/ImportContactsRounded';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

interface CompletenessProgressProps {
  door?: Partial<Door> | undefined;
  doorModel?: Partial<DoorModel> | undefined;
}

const useStyles = makeStyles({
  activeStatus: {
    opacity: '100%',
  },
  disabledStatus: {
    opacity: '30%',
  },
  container: {
    maxWidth: '100px',
  },
});

export default function CompletenessProgress({ door, doorModel }: CompletenessProgressProps) {
  const classes = useStyles();

  const warrantyComplete = Boolean(door?.warrantyComplete || doorModel?.warrantyComplete);
  const fireCertificateComplete = Boolean(door?.fireCertificateComplete || doorModel?.fireCertificateComplete);
  const installationGuideComplete = Boolean(door?.installationGuideComplete || doorModel?.installationGuideComplete);
  const partsListComplete = Boolean(door?.partsListComplete || doorModel?.partsListComplete);

  const fireCertificateClass =
    fireCertificateComplete && warrantyComplete ? classes.activeStatus : classes.disabledStatus;
  const installationGuideClass = installationGuideComplete ? classes.activeStatus : classes.disabledStatus;
  const partListClass = partsListComplete ? classes.activeStatus : classes.disabledStatus;
  return (
    <Box display="flex" justifyContent="space-around" className={classes.container}>
      <Tooltip title="Parts list" aria-label="parts list">
        <BuildIcon className={partListClass} fontSize="small"></BuildIcon>
      </Tooltip>
      <Tooltip title="Installation guide" aria-label="installation guide">
        <ImportContactsRoundedIcon className={installationGuideClass} fontSize="small"></ImportContactsRoundedIcon>
      </Tooltip>
      <Tooltip title="Certificates and warranty" aria-label="certificates and warranty">
        <DescriptionRoundedIcon className={fireCertificateClass} fontSize="small"></DescriptionRoundedIcon>
      </Tooltip>
    </Box>
  );
}
