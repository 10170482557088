import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import React, { useContext, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router';
import { DoorModelExists } from '../../constants/GraphQLErrorCodes';
import * as Routes from '../../constants/Routes';
import DoorModelContext from '../../context/DoorModelContext';
import { DoorModelFormData, DoorModelDocument } from '../../pages/DoorModelAdd';
import { useSaveDoorModelMutation, DoorModelInput, useDocumentTypesQuery, DocumentType } from '../../queries';
import { removeEmptyFields } from '../../utils/FormHelpers';
import LoadingButton from '../LoadingButton';
import DropZone from '../DropZone';
import AddedFile from '../AddedFile';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Typography } from '@material-ui/core';
import { NoAccessPhoto, Photo } from '../../constants/DocumentTypes';

interface DoorModelDocumentationEditProps {
  onBack: () => void;
}

export default function DoorModelDocumentationEdit({ onBack }: DoorModelDocumentationEditProps) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { doorModel, setDoorModel } = useContext(DoorModelContext);
  const [documents, setDocuments] = useState<DoorModelDocument[]>([]);
  const [documentTypes, setDocumentTypes] = useState<Partial<DocumentType>[]>([]);

  const form = useForm<DoorModelFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: doorModel,
  });

  const { handleSubmit, register, formState, getValues } = form;

  const { loading: loadingDocTypes, data } = useDocumentTypesQuery();
  const [saveDoorModelMutation, { loading }] = useSaveDoorModelMutation();

  useEffect(() => {
    if (data) {
      // exlude the photo type
      setDocumentTypes(data.documentTypes.filter((dt) => dt.code !== Photo && dt.code !== NoAccessPhoto));
    }
  }, [data]);

  const saveDoorModel = async (values: DoorModelFormData) => {
    const doorModelInput: DoorModelInput = { ...doorModel!, documents };
    doorModelInput.fdRatingId = doorModelInput.fdRatingId ? +doorModelInput.fdRatingId : undefined;

    removeEmptyFields(doorModelInput);
    try {
      await saveDoorModelMutation({
        variables: {
          doorModelInput,
        },
      });
      enqueueSnackbar('Door model added successfully.', { variant: 'success' });
      history.push(Routes.DoorModels);
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        if (gqlerror?.extensions?.code === DoorModelExists) {
          enqueueSnackbar(gqlerror.message, {
            variant: 'error',
          });
        }
      });

      enqueueSnackbar('There was an error attempting to add this door model.', {
        variant: 'error',
      });
    }
  };

  const onBackClick = () => {
    const currentFormValues = getValues();
    setDoorModel((prev: DoorModelFormData) => ({ ...prev, ...currentFormValues }));
    onBack();
  };

  const onFilesAdded = (selectedFiles: File[]) => {
    const newDocuments: DoorModelDocument[] = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // have to provide a default description otherwise React complains about controlled/uncontrolled components in console
      newDocuments.push({ file, documentTypeCode: ' ' });
    }
    setDocuments((prev) => [...prev, ...newDocuments]);
  };

  const onFileRemoved = (fileIndex: number) => {
    const filesCopy = Array.from(documents);
    filesCopy.splice(fileIndex, 1);
    setDocuments(filesCopy);
  };

  const onDocumentTypeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    fileIndex: number
  ) => {
    const documentTypeCode = String(e.target.value);
    setDocuments((prev) => prev.map((x, i) => (i === fileIndex ? { ...x, documentTypeCode } : x)));
  };

  // handling error manually here
  const hasError = (doc: DoorModelDocument): boolean => {
    return doc.documentTypeCode === undefined || doc.documentTypeCode.length === 0;
  };

  return (
    <Box p={5} pt={2}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(saveDoorModel)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DropZone
                onFilesAdded={onFilesAdded}
                title="Upload documentation"
                accept=".pdf"
                acceptHelperText="Accepted format: PDF"
                multiple
                maxSizeMb={50}
              />
              <Box mt={3} mb={3}>
                <Typography>Required documentation: Installation guide, Fire certificate, Warranty.</Typography>
                <Typography>Any missing documents must be assigned to the manufactured door.</Typography>
              </Box>
              {documents.map((document, i) => (
                <AddedFile
                  key={i}
                  documentType={document.documentTypeCode}
                  fileName={document.file.name}
                  onRemove={() => onFileRemoved(i)}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor={`documentType${i}Id`} error={hasError(document)}>
                      Type
                    </InputLabel>
                    <Select
                      label="Type"
                      native
                      inputProps={{
                        name: `documentType${i}Id`,
                        id: `documentType${i}IdInput`,
                      }}
                      inputRef={register({ required: 'Type required' })}
                      onChange={(e) => onDocumentTypeChange(e, i)}
                      error={hasError(document)}
                    >
                      <option aria-label="None" value="" />

                      {!loadingDocTypes &&
                        documentTypes &&
                        documentTypes.map((documentType, i) => (
                          <option value={documentType.code} key={documentType.code}>
                            {documentType.name}
                          </option>
                        ))}
                    </Select>
                    <FormHelperText error={hasError(document)}>
                      {hasError(document) ? 'Type is required' : undefined}
                    </FormHelperText>
                  </FormControl>
                </AddedFile>
              ))}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="space-between" marginTop={3}>
            <Button variant="outlined" onClick={onBackClick}>
              Back
            </Button>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formState.isValid || loading}
            >
              Add door model
            </LoadingButton>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
