import create from 'zustand';
import TransferTypes from '../constants/TransferTypes';
import { Company } from '../queries';
type TransferDoorsState = {
  doorIds: string[];
  transferType: TransferTypes | undefined;
  company: Pick<Company, 'id' | 'name'> | undefined;
  setDoorIds: (ids: string[]) => void;
  setTransferType: (type: TransferTypes) => void;
  setCompany: (company: Pick<Company, 'id' | 'name'> | undefined) => void;
};

const useTransferDoorsStore = create<TransferDoorsState>((set) => ({
  doorIds: [],
  transferType: undefined,
  company: undefined,
  setDoorIds: (ids: string[]) => set(() => ({ doorIds: ids })),
  setTransferType: (type: TransferTypes) => set(() => ({ transferType: type })),
  setCompany: (company: Pick<Company, 'id' | 'name'> | undefined) => set(() => ({ company: company })),
}));

export default useTransferDoorsStore;
