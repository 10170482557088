import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Photo } from '../../../constants/DocumentTypes';
import { DoorPhoto } from '../../../pages/DoorAdd';
import AddedFile from '../../AddedFile';
import DropZone from '../../DropZone';

const useStyles = makeStyles({
  descriptionInput: {
    maxWidth: 400,
  },
});

interface CompanyAccreditationsUploadProps {
  photos: DoorPhoto[];
  setPhotos: React.Dispatch<React.SetStateAction<DoorPhoto[]>>;
}

export default function CompanyAccreditationsUpload({ photos, setPhotos }: CompanyAccreditationsUploadProps) {
  const classes = useStyles();

  const onFilesAdded = (selectedFiles: File[]) => {
    const newPhotos: DoorPhoto[] = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // have to provide a default description otherwise React complains about controlled/uncontrolled components in console
      newPhotos.push({ file, description: '' });
    }
    setPhotos((prev) => [...prev, ...newPhotos]);
  };

  const onFileRemoved = (fileIndex: number) => {
    const filesCopy = Array.from(photos);
    filesCopy.splice(fileIndex, 1);
    setPhotos(filesCopy);
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fileIndex: number) => {
    const description = e.target.value;
    setPhotos((prev) => prev.map((x, i) => (i === fileIndex ? { ...x, description } : x)));
  };

  return (
    <Box>
      <DropZone
        onFilesAdded={onFilesAdded}
        title="Upload door photos"
        accept={['.jpg', '.png']}
        acceptHelperText="Accepted formats: JPG, PNG"
        multiple
      />
      {photos.map((photo, i) => (
        <AddedFile key={i} fileName={photo.file.name!} documentType={Photo} onRemove={() => onFileRemoved(i)}>
          <Box flex={1} display="flex" justifyContent="center">
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 255 }}
              className={classes.descriptionInput}
              value={photo.description}
              onChange={(e) => onDescriptionChange(e, i)}
            />
          </Box>
        </AddedFile>
      ))}
    </Box>
  );
}
