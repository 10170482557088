import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

interface TableLoadingProps {
  colSpan?: number;
}

export default function TableLoading({ colSpan = Number.MAX_SAFE_INTEGER }: TableLoadingProps) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </TableCell>
    </TableRow>
  );
}
