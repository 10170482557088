import create from 'zustand';
type RetireDoorsState = {
  doorIds: string[];
  setDoorIds: (ids: string[]) => void;
};

const useRetireDoorsStore = create<RetireDoorsState>((set) => ({
  doorIds: [],
  setDoorIds: (ids: string[]) => set(() => ({ doorIds: ids })),
}));

export default useRetireDoorsStore;
