import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import CompaniesFilterDrawer from './CompaniesFilterDrawer';
import TextFilter from './TextFilter';

export interface CompaniesSearchFilter {
  active: boolean;
  disabled: boolean;
  reportPeriodFrom: Dayjs | null;
  reportPeriodTo: Dayjs | null;
  textFilter: string | null | undefined;
}

interface CompaniesFilterProps {
  onFilterChanged: (filter: CompaniesSearchFilter) => void;
}

export default function CompaniesFilter({ onFilterChanged }: CompaniesFilterProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showFilterBadge, setShowFilterBadge] = useState(false);
  const [filter, setFilter] = useState<CompaniesSearchFilter>({
    active: true,
    disabled: false,
    reportPeriodFrom: null,
    reportPeriodTo: null,
    textFilter: null,
  });

  useEffect(() => {
    onFilterChanged(filter);
  }, [filter, onFilterChanged]);

  const handleMenuOpenClick = () => {
    setIsDrawerOpen(true);
  };

  const onFilterChange = (newFilter: CompaniesSearchFilter, isCustomFilter: boolean) => {
    setFilter(newFilter);
    setShowFilterBadge(isCustomFilter);
  };

  const onSearchTextChanged = useCallback((newSearchText: string | undefined) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, textFilter: newSearchText };
    });
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <TextFilter
          label="Search companies"
          fieldName="searchCompanies"
          searchText={filter.textFilter ?? undefined}
          onSearchTextChanged={onSearchTextChanged}
        />

        <Box pr={2} display="flex" alignItems="center">
          <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuOpenClick}>
            <Badge variant="dot" color="primary" invisible={!showFilterBadge}>
              <FilterListIcon />
            </Badge>
          </IconButton>
        </Box>
      </Box>
      <CompaniesFilterDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        filter={filter}
        setFilter={onFilterChange}
      />
    </>
  );
}
