import React from 'react';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import theme from '../../theme';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  value: {
    color: '#9AA0FF',
    fontWeight: 600,
  },
  valueImproved: {
    color: theme.palette.primary.main,
  },
  valueDeteriorated: {
    color: theme.palette.error.main,
  },
  container: {
    backgroundColor: '#2C2F52',
    padding: '20px',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

type Props = {
  title: string;
  value: number | string | undefined;
  loading?: boolean;
  change?: 'improvement' | 'deterioration' | 'neutral';
  icon?: React.ReactNode;
  footer?: React.ReactNode;
};

export default function KpiCard({ title, value, footer, icon, change = 'neutral', loading }: Props) {
  const classes = useStyles();

  let improvementClass;
  if (change === 'improvement') {
    improvementClass = classes.valueImproved;
  } else if (change === 'deterioration') {
    improvementClass = classes.valueDeteriorated;
  }
  return (
    <Paper className={classes.container}>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="subtitle1">{title}</Typography>
          {loading || value === undefined ? (
            <Skeleton variant="text" animation="wave">
              <Typography variant="h4">1000</Typography>
            </Skeleton>
          ) : (
            <Typography variant="h4" className={clsx(classes.value, improvementClass)}>
              {value}
            </Typography>
          )}
        </Box>
        {icon}
      </Box>

      {footer && loading ? (
        <Skeleton variant="text" animation="wave">
          <Box>{footer}</Box>
        </Skeleton>
      ) : (
        <Box>{footer}</Box>
      )}
    </Paper>
  );
}
