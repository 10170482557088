import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  noResults: {
    opacity: 0.6,
    fontSize: 14,
  },
});

interface NoResultsRowProps {
  colSpan?: number;
  message: string;
}

export default function NoResultsRow({ colSpan = Number.MAX_SAFE_INTEGER, message }: NoResultsRowProps) {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Typography align="center" className={classes.noResults}>
          {message}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
