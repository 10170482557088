import * as winston from 'winston';
import BrowserConsole from 'winston-transport-browserconsole';
import { logLevel } from './EnvironmentService';
export default function init() {
  winston.configure({
    transports: [
      new BrowserConsole({
        format: winston.format.simple(),
        level: logLevel,
      }),
    ],
  });
}
