import React from 'react';
import Layout from '../components/Layout';
import PrivacyPolicyContent from '../components/PrivacyPolicyContent';
import useAuth from '../hooks/useAuth';

export default function PrivacyPolicy() {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return (
      <Layout>
        <PrivacyPolicyContent />
      </Layout>
    );
  }

  return <PrivacyPolicyContent paddingTop={5} />;
}
