import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import HistoryEventTypes from '../../../../../constants/HistoryEventTypes';
import { DoorHistoryFragment } from '../../../../../queries';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import StandardGridItem from './StandardGridItem';
import { baseUrl } from '../../../../../utils/EnvironmentService';
import { makeStyles } from '@material-ui/core/styles';

interface DoorRepairingAdditionalInfo {
  reason?: string;
  documentIds?: string[];
}

interface DoorInstallationDetailDialogProps {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
  isPass: boolean;
}

const useStyles = makeStyles({
  container: {
    maxWidth: '230px',
    margin: '10px',
  },
  item: {
    width: '100%',
  },
});

function DetailDialog({ history, isPass }: DetailDialogProps) {
  const classes = useStyles();

  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorRepairingAdditionalInfo;
  return (
    <Box pt={1} pb={1}>
      <Grid container spacing={3}>
        <StandardGridItem title={'Repair comment'} value={additionalInfo.reason} minWidth={12} />
        <Box display="flex" justifyContent="space-between" flexWrap="wrap" marginTop={3}>
          {additionalInfo.documentIds &&
            additionalInfo.documentIds.map((document) => {
              return (
                <Box className={classes.container}>
                  <img className={classes.item} src={`${baseUrl}/document/${document}`} alt="" />
                </Box>
              );
            })}
        </Box>
      </Grid>
    </Box>
  );
}

export default function DoorRepairingDetailDialog({ open, onClose, history }: DoorInstallationDetailDialogProps) {
  const isPass = history.historyEventType.code === HistoryEventTypes.DoorRepaired;
  const actorName = isPass ? 'Signed off' : 'Failed by';
  return (
    <DoorHistoryDetailDialog open={open} onClose={onClose} history={history} actorName={actorName}>
      <DetailDialog history={history} isPass={isPass} />
    </DoorHistoryDetailDialog>
  );
}
