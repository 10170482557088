import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import { Dictionary } from 'lodash';
import groupBy from 'lodash/groupBy';
import React, { useEffect, useState } from 'react';
import { DoorHistoryFragment } from '../../../../queries';
import DoorHistoryItem from './DoorHistoryItem';

interface DoorHistoryProps {
  history?: DoorHistoryFragment[] | null;
}

export default function DoorHistory({ history }: DoorHistoryProps) {
  const [historyGroupedByDate, setHistoryGroupedByDate] = useState<Dictionary<DoorHistoryFragment[]>>();

  useEffect(() => {
    // group by date
    if (history && history.length > 0) {
      // reverse the history to show newer events on top
      const sortedHistory = [...history].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      const grouped = groupBy(sortedHistory, (a) => a.createdAt.split('T')[0]);
      setHistoryGroupedByDate(grouped);
    }
  }, [history]);

  if (!history || history.length === 0) {
    return (
      <Box p={5} pb={0}>
        <Typography align="center">There is no history available for this door</Typography>
      </Box>
    );
  }

  const numOfGroups = historyGroupedByDate ? Object.keys(historyGroupedByDate).length : 0;
  return (
    <Box p={5} pb={0}>
      <Timeline>
        {historyGroupedByDate &&
          Object.keys(historyGroupedByDate).map((dateGroup, i) => {
            return historyGroupedByDate[dateGroup].map((historyItem, j) => {
              const isLast = j === historyGroupedByDate[dateGroup].length - 1 && i === numOfGroups - 1;
              return (
                <DoorHistoryItem key={`${i}-${j}`} history={historyItem} showDate={j === 0} hideConnector={isLast} />
              );
            });
          })}
      </Timeline>
    </Box>
  );
}
