import React, { useContext, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router';
import { Login } from '../constants/Routes';
import Loading from '../pages/Loading';
import AppContext from '../context/app/AppContext';
import { useLoggedInLazyQuery } from '../queries';
import useAuth from '../hooks/useAuth';

interface ProtectedRouteProps {
  component: any;
  path: string;
  exact?: boolean;
  roles?: string[];
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { component, path, ...rest } = props;
  const appContext = useContext(AppContext);
  const { hasAnyRole } = useAuth();
  const [getLoginStatus, { loading, data, error }] = useLoggedInLazyQuery();
  const location = useLocation();

  useEffect(() => {
    if (appContext.state.loggedIn === undefined) {
      getLoginStatus();
    }
  }, [appContext.state.loggedIn, getLoginStatus]);

  useEffect(() => {
    if (!loading && (!!data || !!error)) {
      if (!!data) {
        appContext.setLoggedInUser({
          id: data.loggedIn.id,
          fullName: data.loggedIn.fullName,
          companyId: data.loggedIn.company?.id,
          companyName: data.loggedIn.company?.name,
          roles: data.loggedIn.roles,
          options: { canReportInstallations: data.loggedIn.company?.canReportInstallations ?? false },
        });
      } else {
        appContext.setLoggedInUser(undefined);
      }
    }
    // do not run this useEffect when appContext changes
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const canUserAccessRoute = (): boolean => {
    if (!appContext.state.loggedIn) {
      return false;
    } else {
      return hasAnyRole(props.roles);
    }
  };

  return (
    <Route
      path={props.path}
      {...rest}
      render={(p) => {
        if (appContext.state.loggedIn === undefined) {
          return <Loading></Loading>;
        } else if (canUserAccessRoute()) {
          return React.createElement(props.component, props);
        } else {
          console.log('User cannot access this route');
          return (
            <Redirect
              to={{
                pathname: Login,
                state: {
                  prevLocation: !((location?.state as any)?.fromLogin as any) ? location : undefined, // User is not allowed to access the route and they are arriving here from the login page. Remove previous location so the user can land to their normal page on next login
                  error: 'You need to login first!',
                },
              }}
            />
          );
        }
      }}
    />
  );
}
