import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { DoorFragment } from '../../../queries';
import DoorField from './DoorField';

interface DoorPartsListProps {
  door?: DoorFragment;
}

export default function DoorPartsList({ door }: DoorPartsListProps) {
  return (
    <Box p={5}>
      <Grid container spacing={3}>
        <DoorField title="Lock/latch" value={door?.model?.doorModelParts?.lockLatch || door?.doorParts?.lockLatch} />
        <DoorField title="Door closer" value={door?.model?.doorModelParts?.closer || door?.doorParts?.closer} />
        <DoorField
          title="Intumescent strip"
          value={door?.model?.doorModelParts?.intumescentStrip || door?.doorParts?.intumescentStrip}
        />
        <DoorField title="Hinges" value={door?.model?.doorModelParts?.hinges || door?.doorParts?.hinges} />
        <DoorField title="Handles" value={door?.model?.doorModelParts?.handles || door?.doorParts?.handles} />
        <DoorField title="Smoke seals" value={door?.model?.doorModelParts?.smokeSeals || door?.doorParts?.smokeSeals} />
        <DoorField title="Drop seal" value={door?.model?.doorModelParts?.dropSeal || door?.doorParts?.dropSeal} />
        <DoorField title="Cylinder" value={door?.model?.doorModelParts?.cylinder || door?.doorParts?.cylinder} />
        <DoorField title="Letterbox" value={door?.model?.doorModelParts?.letterbox || door?.doorParts?.letterbox} />
        <DoorField title="Spyhole" value={door?.model?.doorModelParts?.spyhole || door?.doorParts?.spyhole} />
        <DoorField
          title="Threshold strip"
          value={door?.model?.doorModelParts?.thresholdStrip || door?.doorParts?.thresholdStrip}
        />
        <DoorField title="Keeps" value={door?.doorParts?.keeps} />
        <DoorField title="Numerals" value={door?.doorParts?.numerals} />
        <DoorField title="Chain" value={door?.doorParts?.chain} />
        <DoorField title="Weather seals" value={door?.doorParts?.weatherSeals} />
        <DoorField
          title="Additional comments"
          value={door?.model?.doorModelParts?.additionalComments || door?.doorParts?.additionalComments}
          fullWidth
        />
      </Grid>
    </Box>
  );
}
