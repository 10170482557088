import React, { useMemo } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import KpiCard from '../KpiCard';
import { useDashboardNbInspectionsFailedLast30DaysKpiQuery } from '../../../queries';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Warning from '@material-ui/icons/Warning';
import theme from '../../../theme';

const useStyles = makeStyles({
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
  },
  footer: {
    color: '#FFFFFF',
  },
  valueImproved: {
    color: theme.palette.primary.main,
  },
  valueDeteriorated: {
    color: theme.palette.error.main,
  },
});

type FooterProps = {
  diff: number;
  change: 'neutral' | 'improvement' | 'deterioration';
};

const Footer = ({ diff, change }: FooterProps) => {
  const classes = useStyles();
  let direction = '';
  if (diff > 0) {
    direction = `Up ${Math.abs(diff)}`;
  } else if (diff < 0) {
    direction = `Down ${Math.abs(diff)}`;
  } else {
    direction = 'No change';
  }
  const message = `${direction} since previous 30 days`;
  const iconClass = change === 'improvement' ? classes.valueImproved : classes.valueDeteriorated;
  return (
    <Box className={classes.footerContainer}>
      {diff > 0 && <ArrowUpward className={iconClass} />}
      {diff < 0 && <ArrowDownward className={iconClass} />}
      <Typography variant="body1" className={classes.footer}>
        {message}
      </Typography>
    </Box>
  );
};

type Props = {
  siteId?: string | null;
};

export default function NumberOfFailedInspectionsKpi({ siteId }: Props) {
  const { loading, data } = useDashboardNbInspectionsFailedLast30DaysKpiQuery({
    variables: { siteId: siteId ?? null },
    fetchPolicy: 'cache-and-network',
  });

  const { diff, change } = useMemo<{ diff: number; change: 'neutral' | 'improvement' | 'deterioration' }>(() => {
    if (!data) {
      return { diff: 0, change: 'neutral' };
    }

    const diff = data?.dashboard.nbInspectionsFailedLast30Days - data?.dashboard.nbInspectionsFailedPrevious30Days;

    if (diff === 0) {
      return { diff, change: 'neutral' };
    } else if (diff > 0) {
      return { diff, change: 'deterioration' };
    } else {
      return { diff, change: 'improvement' };
    }
  }, [data]);

  return (
    <KpiCard
      title="Failed inspections last 30 days"
      value={data?.dashboard.nbInspectionsFailedLast30Days}
      loading={loading}
      change={change}
      footer={<Footer diff={diff} change={change} />}
      icon={
        <Box style={{ position: 'relative', width: 86 }}>
          <Box
            style={{
              position: 'absolute',
              top: -15,
              right: -20,
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="106" height="105" viewBox="0 0 106 105" fill="none">
              <g filter="url(#filter0_d_5019_517)">
                <ellipse cx="52.9575" cy="48.5" rx="32.8359" ry="32.5" fill="#2C2F52" />
                <path
                  d="M84.7934 48.5C84.7934 65.8873 70.5497 80 52.9575 80C35.3653 80 21.1216 65.8873 21.1216 48.5C21.1216 31.1127 35.3653 17 52.9575 17C70.5497 17 84.7934 31.1127 84.7934 48.5Z"
                  stroke="#F65858"
                  strokeWidth="2"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5019_517"
                  x="0.121582"
                  y="0"
                  width="105.672"
                  height="105"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="10" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.964706 0 0 0 0 0.345098 0 0 0 0 0.345098 0 0 0 0.5 0"
                  />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5019_517" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5019_517" result="shape" />
                </filter>
              </defs>
            </svg>
            <Warning style={{ position: 'absolute', left: 41, top: 35 }} />
          </Box>
        </Box>
      }
    />
  );
}
