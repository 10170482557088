import { createMuiTheme } from '@material-ui/core/styles';
import { AlertClassKey, AutocompleteClassKey, TimelineItemClassKey } from '@material-ui/lab';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { CSSProperties } from '@material-ui/styles';

interface Overrides extends CoreOverrides {
  // Define additional lab components here as needed....
  MuiAlert?: Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
  MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
  MuiTimelineItem?: Partial<Record<TimelineItemClassKey, CSSProperties | (() => CSSProperties)>> | undefined;
}

const overrides: Overrides = {
  MuiAlert: {
    filledSuccess: {
      backgroundColor: '#00BC91',
    },
  },
  MuiListItemText: {
    primary: {
      color: '#fff',
    },
  },
  MuiListItem: {
    root: {
      '&$selected': {
        backgroundColor: '#2E3047',
      },
    },
  },
  MuiTextField: {
    root: {
      '& .Mui-disabled': {
        opacity: 0.6,
      },
    },
  },
  MuiFormControl: {
    root: {
      '& .Mui-disabled': {
        opacity: 0.6,
      },
    },
  },
  MuiAutocomplete: {
    paper: {
      backgroundColor: '#42445d',
      color: '#fff',
    },
    loading: {
      color: '#fff',
    },
    noOptions: {
      color: '#fff',
    },
  },
  MuiInputLabel: {
    outlined: {
      backgroundColor: '#2E3047',
    },
  },
  MuiTimelineItem: {
    missingOppositeContent: {
      '&::before': {
        flex: 0,
      },
    },
  },
  MuiMenuItem: {
    root: {
      color: '#fff',
      '&$selected': {
        fontWeight: 700,
      },
    },
  },
  MuiMenu: {
    list: {
      backgroundColor: '#42445d',
    },
  },
  MuiChip: {
    root: {
      backgroundColor: '#42445d',
    },
  },
};

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#222334',
      paper: '#2E3047',
    },
    primary: {
      main: '#00BC91',
      light: '#5befc1',
      dark: '#008b63',
      contrastText: '#fff',
    },
    secondary: {
      main: '#656DFF',
      light: '#9e9bff',
      dark: '#1a42cb',
      contrastText: '#fff',
    },
    error: {
      main: '#ff556c',
      light: '#ff899a',
      dark: '#c61441',
      contrastText: '#fff',
    },
    success: {
      main: '#00BC91',
      light: '#5befc1',
      dark: '#008b63',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    allVariants: {
      color: '#fff',
    },
    h1: {
      fontWeight: 200,
      fontSize: 93.4,
      lineHeight: '140px',
      letterSpacing: -1.5,
    },
    h2: {
      fontWeight: 200,
      fontSize: 58.38,
      lineHeight: '87px',
      letterSpacing: -0.5,
    },
    h3: {
      fontWeight: 400,
      fontSize: 46.19,
      lineHeight: '70px',
    },
    h4: {
      fontWeight: 400,
      fontSize: 32.72,
      lineHeight: '48px',
      letterSpacing: 0.25,
    },
    h5: {
      fontWeight: 400,
      fontSize: 23.1,
      lineHeight: '34px',
    },
    h6: {
      fontWeight: 700,
      fontSize: 19.18,
      lineHeight: '29px',
      letterSpacing: 0.5,
    },
    body1: {
      fontWeight: 400,
      fontSize: 15.4,
      lineHeight: '28px',
      letterSpacing: 0.5,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    body2: {
      fontWeight: 400,
      fontSize: 13.47,
      lineHeight: '20px',
      letterSpacing: 0.25,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 15.4,
      lineHeight: '24px',
      letterSpacing: 0.15,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 13.42,
      lineHeight: '24px',
      letterSpacing: 0.1,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    button: {
      fontWeight: 500,
      letterSpacing: 1.25,
      fontSize: 13.42,
    },
    caption: {
      fontWeight: 400,
      fontSize: 11.55,
      lineHeight: '16px',
      letterSpacing: 0.4,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    overline: {
      fontWeight: 500,
      fontSize: 11.51,
      lineHeight: '16px',
      letterSpacing: 2,
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  overrides,
});

export default theme;
