import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DoorPhoto } from '../../../pages/DoorAdd';
import { CompanyQuery, User } from '../../../queries';
import CompanyAccreditationsUpload from './CompanyAccreditationsUpload';

export type UserLookup = Pick<User, 'id' | 'fullName' | 'email'> | null;

interface Props {
  isEdit: boolean;
  existingCompany: CompanyQuery | undefined;
  photos: DoorPhoto[];
  setPhotos: React.Dispatch<React.SetStateAction<DoorPhoto[]>>;
}

export default function CompanyCertificationAddEdit(props: Props) {
  const { register } = useFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} xs={12}>
        <TextField
          name="installationCertificateAdditionalText"
          label="Installation additional text"
          variant="outlined"
          fullWidth
          multiline
          inputRef={register({ maxLength: 400 })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <TextField
          name="inspectionCertificateAdditionalText"
          label="Inspection additional text"
          variant="outlined"
          fullWidth
          multiline
          inputRef={register({ maxLength: 400 })}
          InputLabelProps={{ shrink: props.isEdit ? true : undefined }}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <CompanyAccreditationsUpload photos={props.photos} setPhotos={props.setPhotos} />
      </Grid>
    </Grid>
  );
}
