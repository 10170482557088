import React from 'react';
import LoggedInUser from '../LoggedInUser';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import * as Routes from '../../constants/Routes';
import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';
import CompaniesMenuItem from './menu-items/CompaniesMenuItem';
import MyCompanyMenuItem from './menu-items/MyCompanyMenuItem';
import SitesMenuItem from './menu-items/SitesMenuItem';
import DoorsMenuItem from './menu-items/DoorsMenuItem';
import BillingMenuItem from './menu-items/BillingMenuItem';
import DashboardMenuItem from './menu-items/DashboardMenuItem';
import ReportsMenuItem from './menu-items/ReportsMenuItem';

const useStyles = (props: StyleProps) =>
  makeStyles((theme) => ({
    logo: {
      width: '100%',
    },
    drawer: {
      width: props.drawerWidth,
      flexShrink: 0,
    },
    drawerHeader: {
      height: props.appBarHeight,
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    drawerPaper: {
      width: props.drawerWidth,
      backgroundColor: '#1A1B28',
      borderRight: 0,
      padding: theme.spacing(0, 2, 3, 4),
    },
  }));

export interface SideMenuProps {
  open: boolean;
  drawerWidth: number;
  appBarHeight: number;
}

export interface StyleProps {
  drawerWidth?: number;
  appBarHeight?: number;
}

export default function SideMenu({ open, drawerWidth, appBarHeight }: SideMenuProps) {
  const classes = useStyles({ drawerWidth, appBarHeight })();
  return (
    <Drawer open={open} variant="persistent" className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
      <div className={classes.drawerHeader}>
        <img src="/assets/logo.svg" alt="logo" className={classes.logo} />
      </div>
      <Box mb={2}>
        <LoggedInUser />
      </Box>
      <Divider />
      <List>
        <CompaniesMenuItem />
        <BillingMenuItem />
        <DashboardMenuItem />
        <MyCompanyMenuItem />
        <ReportsMenuItem />
        <SitesMenuItem />
        <DoorsMenuItem />
      </List>
      <Box mt="auto">
        <Typography display="block" gutterBottom paragraph variant="caption">
          <MaterialLink
            color="inherit"
            underline="none"
            target="_blank"
            href="https://www.doordatasystems.co.uk/software-help"
          >
            Help and Support
          </MaterialLink>
        </Typography>
        <Typography display="block" gutterBottom paragraph variant="caption">
          <MaterialLink color="inherit" underline="none" component={Link} to={Routes.PrivacyPolicy}>
            Privacy Policy
          </MaterialLink>
        </Typography>
        <Typography display="block" variant="caption">
          <MaterialLink color="inherit" underline="none" component={Link} to={Routes.TermsAndConditions}>
            Terms and Conditions
          </MaterialLink>
        </Typography>
      </Box>
    </Drawer>
  );
}
