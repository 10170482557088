import React from 'react';
import KpiCard from '../KpiCard';
import { useDashboardNbInspectionsDueThisMonthKpiQuery } from '../../../queries';

type Props = {
  siteId?: string | null;
};

export default function InspectionsDueThisMonthKpi({ siteId }: Props) {
  const { loading, data } = useDashboardNbInspectionsDueThisMonthKpiQuery({
    variables: { siteId: siteId ?? null },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <KpiCard title="Inspections due this month" value={data?.dashboard.nbInspectionsDueThisMonth} loading={loading} />
  );
}
