import React, { useEffect, useState } from 'react';
import { Document } from '../../queries';
import DocumentList from '../DocumentList';
import {
  InstallationGuide,
  FireCertificate,
  Warranty,
  TechnicalDrawing,
  SBDCertificate,
  AcousticCertificate,
} from '../../constants/DocumentTypes';
import { Box } from '@material-ui/core';

const documentTypeCodes = [
  InstallationGuide,
  FireCertificate,
  Warranty,
  TechnicalDrawing,
  SBDCertificate,
  AcousticCertificate,
];

interface DoorModelDocumentationProps {
  documents?: Partial<Document>[] | null;
}

export default function DoorModelDocumentation({ documents }: DoorModelDocumentationProps) {
  const [documentList, setDocumentList] = useState<Document[]>([]);

  useEffect(() => {
    if (documents) {
      const tempList: Document[] = [];
      for (let i = 0; i < documents.length; i++) {
        const document = documents[i];
        if (documentTypeCodes?.includes(document?.documentType!.code)) {
          tempList.push({
            documentType: document.documentType!,
            id: document.id!,
            originalFilename: document.originalFilename!,
            description: document.description,
          });
        }
      }
      setDocumentList(tempList);
    }
  }, [documents]);

  return (
    <Box p={5}>
      <DocumentList documents={documentList} />
    </Box>
  );
}
