import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../components/Layout';
import { useRetireDoorsMutation } from '../queries';
import * as Routes from '../constants/Routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useDialog from '../hooks/useDialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import { Link } from 'react-router-dom';
import useRetireDoorsStore from '../store/RetireDoorsStore';
import TextField from '@material-ui/core/TextField/TextField';

interface RetireDoorFormData {
  reason: string;
}

export default function RetireDoors() {
  const { showDialog } = useDialog();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { id: siteId } = useParams();
  const { doorIds, setDoorIds } = useRetireDoorsStore();
  if (doorIds.length === 0) {
    history.push(`${Routes.Sites}/${siteId}`);
  }
  const [retireDoors] = useRetireDoorsMutation();

  const form = useForm<RetireDoorFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, formState, register, errors } = form;

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        setDoorIds([]);
        history.goBack();
      },
    });
  };

  const saveChanges = async (values: RetireDoorFormData) => {
    try {
      await retireDoors({
        variables: {
          retireDoorInput: {
            ids: doorIds.map((did) => Number(did)),
            reason: values.reason,
          },
        },
      });

      enqueueSnackbar('Retired door recorded.', { variant: 'success' });
      history.goBack();
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    }
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5">Retire door</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.Sites}>
                  <Typography variant="body2">Sites</Typography>
                </MaterialLink>
                <Typography variant="body2">Retire door</Typography>
              </Breadcrumbs>
            </Box>
            <Box minWidth={96}>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>
          <Box mt={1}>
            <Paper>
              <Box p={5} marginTop={3}>
                <Box marginBottom={3}>
                  <Typography>
                    Retiring a door record should only be using when removing or replacing a door. This process cannot
                    be undone.
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit(saveChanges)}>
                  <Box flex={1} display="flex" justifyContent="center">
                    <TextField
                      name="reason"
                      label="Reason"
                      variant="outlined"
                      fullWidth
                      inputRef={register({
                        required: 'Please specify the reason',
                        minLength: {
                          value: 5,
                          message: 'The reason should contain at least 5 symbols',
                        },
                      })}
                      multiline
                      rows={4}
                      error={!!errors.reason}
                      helperText={errors.reason?.message}
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end" marginTop={3}>
                    <Button type="submit" variant="contained" color="primary" disabled={!formState.isValid}>
                      Retire door
                    </Button>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
