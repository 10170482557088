import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { GraphQLError } from 'graphql';
import { SiteAddFormData, SiteDocument } from '../../../pages/SiteAdd';
import LoadingButton from '../../LoadingButton';
import SiteDrawingsUpload from './SiteDrawingUpload';
import { SiteInput, useAddSiteMutation } from '../../../queries';
import SiteContext from '../../../context/SiteContext';
import { useForm } from 'react-hook-form';

interface SiteDrawingsAddProps {
  onBack: () => void;
}

export default function SiteDrawingsAdd({ onBack }: SiteDrawingsAddProps) {
  const { site, setSite } = useContext(SiteContext);
  const [addSite, { loading }] = useAddSiteMutation();

  const [drawings, setDrawings] = useState<SiteDocument[]>(site?.drawings ?? []);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const form = useForm<SiteAddFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...site,
    },
  });

  const { handleSubmit, getValues } = form;

  const onBackClick = () => {
    const values = getValues();
    values.drawings = drawings;
    setSite((prev: SiteAddFormData | undefined) => ({ ...prev, ...values }));
    if (onBack) onBack();
  };

  const saveSite = async (values: SiteAddFormData) => {
    const siteInput: SiteInput = {
      name: site?.name!,
      reference: site?.reference,
      areas: Number(site?.areas),
      addressOne: site?.addressOne,
      addressTwo: site?.addressTwo,
      city: site?.city,
      postcode: site?.postcode,
      owner:
        site?.owner === null
          ? null
          : {
              id: site?.owner?.id,
              name: site?.owner?.name!,
            },
      photos: site?.photos,
      drawings: drawings,
      contact: {
        name: site?.contactName!,
        id: site?.contactId,
        phoneNumber: site?.contactPhoneNumber,
        mobileNumber: site?.contactMobileNumber,
        emailAddress: site?.contactEmailAddress,
      },
    };

    try {
      await addSite({
        variables: {
          siteInput: siteInput,
        },
      });

      enqueueSnackbar('Site added successfully.', { variant: 'success' });

      history.push('/my-sites');
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(saveSite)}>
      <SiteDrawingsUpload drawings={drawings} setDrawings={setDrawings} />
      <Box display="flex" justifyContent="space-between" marginTop={3}>
        <Button variant="outlined" onClick={onBackClick}>
          Back
        </Button>
        <LoadingButton loading={loading} disabled={loading} type="submit" variant="contained" color="primary">
          Add site
        </LoadingButton>
      </Box>
    </form>
  );
}
