export const Home: string = '/home';
export const Login: string = '/login';
export const Companies: string = '/companies';
export const Billing: string = '/billing';
export const InviteCompany: string = '/companies/invite';
export const ProcessInvite: string = '/invite';
export const PrivacyPolicy: string = '/privacy-policy';
export const TermsAndConditions: string = '/terms-and-conditions';
export const ManufacturedDoors: string = '/manufactured-doors';
export const ManufacturedDoorsAdd: string = '/manufactured-doors/add';
export const ViewDoor: string = '/door';
export const ViewDoorByTag: string = '/door-tag';
export const DoorModels: string = '/door-models';
export const DoorModelAdd: string = '/door-models/add';
export const ImportDoors: string = '/import-doors';
export const Repair: string = '/repair';
export const Sites: string = '/sites';
export const SitesAdd: string = '/sites/add';
export const TransferDoors: string = '/manufactured-doors/transfer';
export const RequestAccount: string = '/request-account';
export const Welcome: string = '/welcome';
export const MySites: string = '/my-sites';
export const ForgotPassword: string = '/forgot-password';
export const ResetPassword: string = '/reset-password';
export const AccountInfo: string = '/account/info';
export const Dashboard: string = '/dashboard';
export const Reports: string = '/reports';
