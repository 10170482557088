import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Business from '@material-ui/icons/Business';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import * as Routes from '../../../constants/Routes';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

export default function SitesMenuItem() {
  const location = useLocation();
  const [sitesMenuOpen, setSitesMenuOpen] = useState(true);
  return (
    <ProtectedSection
      roles={[
        Roles.InstallerAdmin,
        Roles.InspectorAdmin,
        Roles.FacilityManagerAdmin,
        Roles.FacilityManager,
        Roles.ServiceEngineerAdmin,
      ]}
    >
      <ListItem
        button
        onClick={() => {
          setSitesMenuOpen((prev) => !prev);
        }}
      >
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText primary="Sites" />
        {sitesMenuOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={sitesMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ProtectedSection roles={[Roles.InstallerAdmin, Roles.InspectorAdmin]}>
            <ListItem
              button
              component={Link}
              to={`${Routes.MySites}/add`}
              selected={location.pathname.startsWith(`${Routes.MySites}/add`)}
            >
              <ListItemText primary="Site search" />
            </ListItem>
          </ProtectedSection>
          <ListItem
            button
            component={Link}
            to={Routes.MySites}
            selected={
              location.pathname.startsWith(`${Routes.MySites}`) &&
              !location.pathname.startsWith(`${Routes.MySites}/add`)
            }
          >
            <ListItemText primary="My site list" />
          </ListItem>
        </List>
      </Collapse>
    </ProtectedSection>
  );
}
