import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '16px',
  },
}));

type Props = {
  show: boolean;
};
const LoadingOverlay = ({ show }: Props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, show ? classes.show : classes.hide)}>
      <Box className={classes.content}>
        <Warning style={{ width: 40, height: 40 }} />
        <Box>
          <Typography variant="subtitle2">Unable to load data</Typography>
          <Typography variant="subtitle2">Please refresh the page</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingOverlay;
