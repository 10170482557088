import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import NoResultsRow from '../components/NoResultsRow';
import TableLoading from '../components/TableLoading';
import InfoImageRow from '../components/InfoImageRow';
import * as Routes from '../constants/Routes';
import useTable from '../hooks/useTable';
import { Company, Site, useSitesLazyQuery, useAddToMySitesMutation } from '../queries';
import SitesFilter, { SitesSearchFilter } from '../components/site/SitesFilter';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import { useSnackbar } from 'notistack';
import MessageWithButtonRow from '../components/MessageWithButtonRow';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';
import ProtectedSection from '../components/ProtectedSection';
import Roles from '../constants/Roles';

export default function MySitesAdd() {
  const { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort } = useTable('name');
  const { enqueueSnackbar } = useSnackbar();

  const [filter, setFilter] = useState<SitesSearchFilter>({} as SitesSearchFilter);
  const [selectedSites, setSelectedSites] = useState<Partial<Company>[]>([]);

  const [addToMySitesMutation] = useAddToMySitesMutation();
  const [getSites, { loading, error, data, refetch }] = useSitesLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: pageSize * page,
      take: pageSize,
      sortDirection,
      sortField,
      textFilter: filter?.textFilter,
    },
  });

  const useStyles = makeStyles({
    tableRow: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  });
  const classes = useStyles();
  const history = useHistory();

  const onRowClick = (siteId: string) => {
    history.push(`${Routes.Sites}/${siteId}`);
  };

  const isUserSelected = (site: Partial<Site> | null | undefined): boolean => {
    return selectedSites.filter((s) => s?.id === site?.id).length > 0;
  };

  const toggleUser = (site: Partial<Site> | null | undefined, checked: boolean): void => {
    if (!site) {
      return;
    }

    if (checked) {
      setSelectedSites((prevState) => [...prevState, site]);
    } else {
      setSelectedSites((prevState) => prevState.filter((u) => u?.id !== site?.id));
    }
  };

  const addSelectedSites = async () => {
    const validIds: number[] = [];
    selectedSites.forEach((s) => {
      if (s && s.id) {
        validIds.push(parseInt(s.id));
      }
    });

    try {
      await addToMySitesMutation({
        variables: {
          idListInput: {
            ids: validIds,
          },
        },
      });
      setSelectedSites([]);

      const message =
        validIds.length > 1 ? `sites added to your list` : `site ${selectedSites[0].name} added to your list.`;
      enqueueSnackbar(message, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('An error has occurred executing the action.', { variant: 'error' });
    }
  };

  useEffect(() => {
    setSelectedSites([]); // Clearing selected sites when filter changes
    if (filter.textFilter) {
      if (refetch) {
        refetch();
      } else {
        getSites();
      }
    }
  }, [filter, filter.textFilter, getSites, refetch]);

  return (
    <Layout>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Site Search</Typography>
        <Button
          variant="contained"
          color="secondary"
          disabled={selectedSites.length < 1}
          onClick={() => addSelectedSites()}
        >
          Add to list
        </Button>
      </Box>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <Box display="flex" justifyContent="flex-end">
            <SitesFilter onFilterChanged={(filter) => setFilter(filter)} />
          </Box>
          <Divider />
          <Table aria-label="Search sites table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>
                  <TableSortLabel active={sortField === 'name'} direction={sortDirection} onClick={() => sort('name')}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Site ID</TableCell>
                <TableCell>Postcode</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'ownedBy.name'}
                    direction={sortDirection}
                    onClick={() => sort('ownedBy.name')}
                  >
                    Site owner
                  </TableSortLabel>
                </TableCell>
                <TableCell>Areas / floors</TableCell>
                <ProtectedSection roles={[Roles.FacilityManager, Roles.FacilityManagerAdmin]}>
                  <TableCell>Installed doors</TableCell>
                </ProtectedSection>
              </TableRow>
            </TableHead>
            <TableBody>
              {!filter.textFilter && (
                <InfoImageRow
                  colSpan={12}
                  imageSrc="/assets/town-with-looking-glass.svg"
                  text="Search for a site to return results"
                />
              )}
              {filter.textFilter && loading && <TableLoading />}
              {filter.textFilter && !loading && !error && data?.sites.items.length === 0 && (
                <NoResultsRow message="No sites to display" />
              )}
              {filter.textFilter && !loading && error && <NoResultsRow message="There was an error fetching sites." />}
              {filter.textFilter &&
                !loading &&
                !error &&
                data?.sites &&
                data?.sites.items.length > 0 &&
                data?.sites.items.map((site, i) => (
                  <TableRow className={classes.tableRow} hover key={i} onClick={() => onRowClick(site.id)}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={isUserSelected(site as Partial<Site>)}
                        onChange={(e, checked) => toggleUser(site as Partial<Site>, checked)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell>
                    <TableCell>{site.name}</TableCell>
                    <TableCell>{site.id}</TableCell>
                    <TableCell>{site.postcode}</TableCell>
                    <TableCell>{site.ownedBy?.name}</TableCell>
                    <TableCell>{site.areas}</TableCell>
                    <ProtectedSection roles={[Roles.FacilityManager, Roles.FacilityManagerAdmin]}>
                      <TableCell>{site.installedDoorCount ?? '-'}</TableCell>
                    </ProtectedSection>
                  </TableRow>
                ))}
              {filter.textFilter && (
                <MessageWithButtonRow
                  colSpan={12}
                  message="Didn't find what you were looking for?"
                  buttonText="Add new site"
                  buttonColor="primary"
                  onClicked={() => history.push(Routes.SitesAdd)}
                />
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.sites.total ?? 0}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={(_, newPage) => pageChanged(newPage)}
            onChangeRowsPerPage={pageSizeChanged}
          />
        </TableContainer>
      </Box>
    </Layout>
  );
}
