const Roles = {
  SuperUser: 'SuperUser',
  Manufacturer: 'Manufacturer',
  ManufacturerAdmin: 'ManufacturerAdmin',
  Installer: 'Installer',
  InstallerAdmin: 'InstallerAdmin',
  FacilityManager: 'FacilityManager',
  FacilityManagerAdmin: 'FacilityManagerAdmin',
  Inspector: 'Inspector',
  InspectorAdmin: 'InspectorAdmin',
  ServiceEngineerAdmin: 'ServiceEngineerAdmin',
  ServiceEngineer: 'ServiceEngineer',
};
export default Roles;
