import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import useAuth from '../hooks/useAuth';
import * as Routes from '../constants/Routes';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  appBar: ({ appBarHeight }: HeaderBarProps) => ({
    height: appBarHeight,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  }),
  appBarShift: ({ drawerWidth }: HeaderBarProps) => ({
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  toolbarGutter: {
    padding: theme.spacing(0, 4),
    flex: 1,
    justifyContent: 'space-between',
  },
  icon: {
    opacity: 0.6,
  },
}));

interface HeaderBarProps {
  drawerWidth: number;
  appBarHeight: number;
  drawerOpen: boolean;
  toggleDrawer: () => void;
}

export default function HeaderBar(props: HeaderBarProps) {
  const classes = useStyles(props);
  const history = useHistory();
  const { logout } = useAuth();

  const onLogout = async () => {
    try {
      await logout();
    } finally {
      history.push(Routes.Login);
    }
  };
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.drawerOpen,
      })}
    >
      <Toolbar classes={{ gutters: classes.toolbarGutter }}>
        <IconButton color="inherit" aria-label="toggle drawer" onClick={props.toggleDrawer} edge="start">
          <MenuIcon className={classes.icon} />
        </IconButton>
        <IconButton color="inherit" aria-label="logout" onClick={onLogout} edge="end">
          <ExitToAppIcon className={classes.icon} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
