import { Checkbox } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import NoResultsRow from '../../components/NoResultsRow';
import TableLoading from '../../components/TableLoading';
import useTable from '../../hooks/useTable';
import { BillingEventsSummary, useBillingEventsSummaryQuery, useCompanyBillingReportLazyQuery } from '../../queries';
import BillingFilter, { BillingFilterTab } from './components/BillingFilter';
import { baseUrl } from '../../utils/EnvironmentService';
import { getMonthBillingDateRange } from './helpers/dateHelpers';

const useStyles = makeStyles({
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chipCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  chip: {
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
  },
});

export interface BillingSearchFilter {
  reportPeriodFrom: Dayjs | null;
  reportPeriodTo: Dayjs | null;
  textFilter: string | null | undefined;
}

const { dateFrom: defaultPeriodFrom, dateTo: defaultPeriodTo } = getMonthBillingDateRange(new Date());

export default function Billing() {
  const { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort } = useTable('companyName');
  const [currentTab, setCurrentTab] = useState<BillingFilterTab>('installer');

  const [filter, setFilter] = useState<BillingSearchFilter>({
    reportPeriodFrom: dayjs(defaultPeriodFrom),
    reportPeriodTo: dayjs(defaultPeriodTo),
    textFilter: null,
  });
  const [selectedCompany, setSelectedCompany] = useState<BillingEventsSummary | undefined>();

  const { loading, error, data } = useBillingEventsSummaryQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: pageSize * page,
      take: pageSize,
      sortDirection,
      sortField,
      reportPeriodFrom: filter.reportPeriodFrom,
      reportPeriodTo: filter.reportPeriodTo,
      textCriteria: filter.textFilter,
      type: currentTab === 'fm' ? 'fm' : 'installer',
    },
  });

  const [
    generateBillingReport,
    { data: reportGeneratedData, loading: generatingReport },
  ] = useCompanyBillingReportLazyQuery({ fetchPolicy: 'no-cache' });
  const classes = useStyles();

  useEffect(() => {
    setSelectedCompany(undefined);
  }, [currentTab]);

  const onGenerateReport = () => {
    if (!selectedCompany) {
      return;
    }
    generateBillingReport({
      variables: {
        companyId: selectedCompany!.companyId,
        type: currentTab === 'fm' ? 'fm' : 'installer',
        reportPeriodFrom: filter.reportPeriodFrom,
        reportPeriodTo: filter.reportPeriodTo,
      },
    });
  };

  useEffect(() => {
    if (reportGeneratedData?.companyBillingReport.path) {
      let url = `${baseUrl}${reportGeneratedData?.companyBillingReport.path}`;
      // fetch data from url
      fetch(url, {
        credentials: 'include',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `BillingReport_${selectedCompany!.companyName.replace(' ', '_')}.pdf`;
          link.click();
        })
        .catch(console.error);
    }
  }, [reportGeneratedData]);

  return (
    <Layout>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Billing</Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedCompany || generatingReport}
          onClick={() => onGenerateReport()}
        >
          Generate report
        </Button>
      </Box>

      <Box mt={3}>
        <TableContainer component={Paper}>
          <BillingFilter
            onTabChanged={(newTab) => {
              setCurrentTab(newTab);
            }}
            tab={currentTab}
            filter={filter}
            onFilterChanged={setFilter}
          />
          <Divider />
          <Table aria-label="Billing table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'companyName'}
                    direction={sortDirection}
                    onClick={() => sort('companyName')}
                  >
                    Company name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'billingEmail'}
                    direction={sortDirection}
                    onClick={() => sort('billingEmail')}
                  >
                    Billing email
                  </TableSortLabel>
                </TableCell>
                {currentTab === 'installer' && (
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'installations'}
                      direction={sortDirection}
                      onClick={() => sort('installations')}
                    >
                      Installations
                    </TableSortLabel>
                  </TableCell>
                )}
                {currentTab === 'installer' && (
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'installationsReported'}
                      direction={sortDirection}
                      onClick={() => sort('installationsReported')}
                    >
                      Installations reported
                    </TableSortLabel>
                  </TableCell>
                )}
                {currentTab === 'fm' && (
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'doorCount'}
                      direction={sortDirection}
                      onClick={() => sort('doorCount')}
                    >
                      Door count
                    </TableSortLabel>
                  </TableCell>
                )}
                {currentTab === 'fm' && (
                  <TableCell>
                    <TableSortLabel
                      active={sortField === 'inspections'}
                      direction={sortDirection}
                      onClick={() => sort('inspections')}
                    >
                      Initial inspections
                    </TableSortLabel>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoading />}
              {!loading && !error && data?.billingEventsSummary.items.length === 0 && (
                <NoResultsRow message="No companies to display" />
              )}
              {!loading && error && <NoResultsRow message="There was an error fetching companies." />}
              {!loading &&
                !error &&
                data?.billingEventsSummary &&
                data?.billingEventsSummary.items.length > 0 &&
                data?.billingEventsSummary.items.map((company, i) => (
                  <TableRow hover key={i} className={classes.tableRow}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={company?.companyId === selectedCompany?.companyId}
                        onChange={(e, checked) => {
                          if (selectedCompany?.companyId !== company?.companyId) {
                            setSelectedCompany(company);
                          } else {
                            setSelectedCompany(undefined);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>{company.companyName}</TableCell>
                    <TableCell>{company.billingEmail}</TableCell>
                    {currentTab === 'installer' && <TableCell>{company.installations}</TableCell>}
                    {currentTab === 'installer' && <TableCell>{company.installationsReported}</TableCell>}
                    {currentTab === 'fm' && <TableCell>{company.doorCount}</TableCell>}
                    {currentTab === 'fm' && <TableCell>{company.inspections}</TableCell>}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.billingEventsSummary.total ?? 0}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={(_, newPage) => pageChanged(newPage)}
            onChangeRowsPerPage={pageSizeChanged}
          />
        </TableContainer>
      </Box>
    </Layout>
  );
}
