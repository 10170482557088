import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginLeft: theme.spacing(1),
  },
}));

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export default function LoadingButton({ children, loading, ...rest }: LoadingButtonProps) {
  const classes = useStyles();
  return (
    <Button {...rest}>
      {children}
      {loading && <CircularProgress className={classes.spinner} size={20} />}
    </Button>
  );
}
