import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DoorContext from '../../../context/DoorContext';
import { DoorFormData } from '../../../pages/DoorAdd';
import DoorPartsListFields from './DoorPartsListFields';

interface DoorPartsListFormData {
  lockLatch?: string | null;
  closer?: string | null;
  intumescentStrip?: string | null;
  hinges?: string | null;
  handles?: string | null;
  smokeSeals?: string | null;
  dropSeal?: string | null;
  cylinder?: string | null;
  letterbox?: string | null;
  spyhole?: string | null;
  thresholdStrip?: string | null;
  keeps?: string | null;
  numerals?: string | null;
  chain?: string | null;
  weatherSeals?: string | null;
  additionalComments?: string | null;
}

interface DoorPartsListAddProps {
  onContinue: () => void;
  onBack: () => void;
}

export default function DoorPartsListAdd({ onContinue, onBack }: DoorPartsListAddProps) {
  const { setDoor, doorModel, door } = useContext(DoorContext);

  const form = useForm<DoorPartsListFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      lockLatch: doorModel?.doorModel.doorModelParts?.lockLatch,
      closer: doorModel?.doorModel.doorModelParts?.closer,
      intumescentStrip: doorModel?.doorModel.doorModelParts?.intumescentStrip,
      hinges: doorModel?.doorModel.doorModelParts?.hinges,
      handles: doorModel?.doorModel.doorModelParts?.handles,
      smokeSeals: doorModel?.doorModel.doorModelParts?.smokeSeals,
      dropSeal: doorModel?.doorModel.doorModelParts?.dropSeal,
      cylinder: doorModel?.doorModel.doorModelParts?.cylinder,
      letterbox: doorModel?.doorModel.doorModelParts?.letterbox,
      spyhole: doorModel?.doorModel.doorModelParts?.spyhole,
      thresholdStrip: doorModel?.doorModel.doorModelParts?.thresholdStrip,
      additionalComments: doorModel?.doorModel.doorModelParts?.additionalComments,
      ...door,
    },
  });

  const { handleSubmit, getValues } = form;

  const submit = (values: DoorPartsListFormData) => {
    setDoor((prev: DoorFormData | undefined) => ({ ...prev, ...values }));
    onContinue();
  };

  const onBackClick = () => {
    const values = getValues();
    setDoor((prev: DoorFormData | undefined) => ({ ...prev, ...values }));
    onBack();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submit)}>
        <DoorPartsListFields doorModelParts={doorModel?.doorModel.doorModelParts} />
        <Box display="flex" justifyContent="space-between" marginTop={3}>
          <Button variant="outlined" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Continue
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
