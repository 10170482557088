export function removeEmptyFields(data: any) {
  if (data === undefined || data === null) {
    return;
  }

  Object.keys(data).forEach((key) => {
    if (data[key] === '') {
      data[key] = undefined;
    }

    if (typeof data[key] === 'object') {
      removeEmptyFields(data[key]);
    }
  });
}
