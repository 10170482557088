import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { DoorHistoryFragment } from '../../../../../queries';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import StandardGridItem from './StandardGridItem';
import {baseUrl} from "../../../../../utils/EnvironmentService";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        maxWidth: '230px',
        margin: '10px'
    },
    item: {
        width: "100%"
    },
});

interface DoorRetiredDetailDialogProps {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

export interface DoorRetiredAdditionalInfo {
    reason: string;
    documentIds?: string[];
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
}

function DetailDialog({ history }: DetailDialogProps) {
    const classes = useStyles();

    if (!history.additionalInfoJson) {
        return <></>;
    }
    const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorRetiredAdditionalInfo;
    return (
        <Box pt={1} pb={1}>
            <Grid container spacing={3}>
                <StandardGridItem title="Reason" value={additionalInfo.reason} minWidth={12} />
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" marginTop={3}>
                    {additionalInfo.documentIds && additionalInfo.documentIds.map(document => {
                        return <Box className={classes.container}>
                            <img className={classes.item} src={`${baseUrl}/document/${document}`} alt=""/>
                        </Box>
                    })}
                </Box>
            </Grid>
        </Box>
    );
}

export default function DoorRetiredDetailDialog({
 open,
 onClose,
 history,
}: DoorRetiredDetailDialogProps) {
  return (
    <DoorHistoryDetailDialog
      open={open}
      onClose={onClose}
      history={history}
      actorName={'Retired'}
    >
      <DetailDialog history={history} />
    </DoorHistoryDetailDialog>
  );
}
