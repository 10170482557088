import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { InstallationCertificate, InstallationReport } from '../../../../../constants/DocumentTypes';
import { DoorHistoryFragment } from '../../../../../queries';
import DocumentRow from '../../../../DocumentRow';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import StandardGridItem from './StandardGridItem';
import dayjs from 'dayjs';

interface DoorInstallationReportedAdditionalInfo {
  signOffComment?: string;
  site: string;
  installedAt: string;
  location: string;
  locationType?: string;
  reportId?: number;
  reportOriginalFilename: string;
  certificateId?: number;
  certificateOriginalFilename: string;
}

interface DoorInstallationReportedDetailDialogProps {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
}

function DetailDialog({ history }: DetailDialogProps) {
  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorInstallationReportedAdditionalInfo;
  return (
    <Box pt={1} pb={1}>
      <Grid container spacing={3}>
        <StandardGridItem title="Site" value={additionalInfo.site} />
        <StandardGridItem title="Installation date" value={dayjs(additionalInfo.installedAt).format('DD/MM/YYYY')} />
        <StandardGridItem title="Location" value={additionalInfo.location} />

        {additionalInfo.locationType && <StandardGridItem title="Location type" value={additionalInfo.locationType} />}
        <StandardGridItem title={'Signoff comment'} value={additionalInfo.signOffComment} minWidth={12} />
        {additionalInfo.reportId && (
          <DocumentRow
            originalFilename={additionalInfo.reportOriginalFilename}
            documentType={{ code: InstallationReport, name: 'Installation report' }}
            documentId={additionalInfo.reportId}
          />
        )}
        {additionalInfo.certificateId && (
          <DocumentRow
            originalFilename={additionalInfo.certificateOriginalFilename}
            documentType={{ code: InstallationCertificate, name: 'Installation certificate' }}
            documentId={additionalInfo.certificateId}
          />
        )}
      </Grid>
    </Box>
  );
}

export default function DoorInstallationDetailDialog({
  open,
  onClose,
  history,
}: DoorInstallationReportedDetailDialogProps) {
  const actorName = 'Reported by';
  return (
    <DoorHistoryDetailDialog open={open} onClose={onClose} history={history} actorName={actorName}>
      <DetailDialog history={history} />
    </DoorHistoryDetailDialog>
  );
}
