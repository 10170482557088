import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DoorStatus from '../../../constants/Status';
import useTable from '../../../hooks/useTable';
import { Door, useSiteDoorsQuery } from '../../../queries';
import { useHistory } from 'react-router';
import * as Routes from '../../../constants/Routes';
import DoorStage from '../../manufactured-doors/DoorStage';
import NoResultsRow from '../../NoResultsRow';
import TableLoading from '../../TableLoading';
import SiteDoorsFilter, { SiteDoorsFilterTab } from './SiteDoorsFilter';
import NewDoorChip from '../../manufactured-doors/NewDoorChip';
import ProtectedSection from '../../ProtectedSection';
import Roles from '../../../constants/Roles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useAuth from '../../../hooks/useAuth';
import useSiteDoorsFiltersStore from '../../../store/SiteDoorsFiltersStore';
import { SiteDoorsSearchBarFilter } from './SiteDoorsSearchBar';
import AssignmentTypes from '../../../constants/AssignmentTypes';
import ContractorTableCell from './ContractorTableCell';

const useStyles = makeStyles({
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const formatDate = (date?: Date) => {
  if (!date) {
    return '';
  }

  return dayjs(date).format('DD/MM/YYYY');
};

const formatMonths = (value: any): string | undefined => {
  if (value) {
    return `${value} month(s)`;
  }
};

const ignoreTransferStatuses = [
  DoorStatus.ManufacturerReady,
  DoorStatus.ManufacturerIncomplete,
  DoorStatus.InstallerRequiresSignOff,
  DoorStatus.InstallerReady,
  DoorStatus.InstallerInstalling,
  DoorStatus.InstallerInstallationComplete,
  DoorStatus.InstallerIncomplete,
  DoorStatus.InstallerFailedSignOff,
];

const getTabStatuses = (tab: SiteDoorsFilterTab) => {
  let statusFilter: string[] | undefined = undefined;
  if (tab === 'installing') {
    statusFilter = [DoorStatus.InstallerInstalling, DoorStatus.InstallerRequiresSignOff];
  } else if (tab === 'transferred') {
    statusFilter = Object.entries(DoorStatus)
      .filter((k) => !ignoreTransferStatuses.includes(k[1]))
      .map((k) => k[1].toString());
  } else if (tab === 'all') {
    statusFilter = Object.entries(DoorStatus).map((k) => k[1].toString());
  } else {
    statusFilter = [tab];
  }

  return statusFilter;
};

interface SiteDoorsProps {
  siteId: string;
  selectedDoors: Partial<Door>[];
  setSelectedDoors: (newDoors: Partial<Door>[]) => void;
  statuses?: any;
}

export default function SiteDoors({ siteId, selectedDoors, setSelectedDoors }: SiteDoorsProps) {
  const classes = useStyles();
  const history = useHistory();
  const { hasAnyRole } = useAuth();
  const { filters, saveFilter, updateFilter } = useSiteDoorsFiltersStore();
  const savedFilter = useMemo(() => filters.find((f) => f.siteId === siteId), [siteId, filters]);
  const isFacilityManager = hasAnyRole([Roles.FacilityManagerAdmin, Roles.FacilityManager]);
  const isInstaller = hasAnyRole([Roles.InstallerAdmin, Roles.Installer]);
  const isInspectorAdmin = hasAnyRole([Roles.InspectorAdmin]);
  const isServiceEngineerAdmin = hasAnyRole([Roles.ServiceEngineerAdmin]);

  const { page, pageSize, sortField, sortDirection, pageChanged, pageSizeChanged, sort } = useTable(
    savedFilter?.sortField ?? 'location',
    savedFilter?.page,
    savedFilter?.pageSize,
    savedFilter?.sortDirection
  );

  const getDefaultTab = useCallback(() => {
    let defaultTab: SiteDoorsFilterTab = 'installing';
    if (isFacilityManager || isInspectorAdmin || isServiceEngineerAdmin) {
      defaultTab = 'transferred';
    } else if (isInstaller) {
      defaultTab = DoorStatus.InstallerReady;
    }

    return defaultTab as SiteDoorsFilterTab;
  }, [isFacilityManager, isInspectorAdmin, isInstaller, isServiceEngineerAdmin]);

  useEffect(() => {
    updateFilter(siteId, { page, pageSize, sortField, sortDirection });
  }, [updateFilter, siteId, page, pageSize, sortField, sortDirection]);

  useEffect(() => {
    if (!savedFilter) {
      // Create a default filter
      const subTab = getDefaultTab();
      saveFilter({ siteId, statuses: getTabStatuses(subTab), tab: subTab, active: true } as any);
    }
  }, [saveFilter, savedFilter, siteId, getDefaultTab]);

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const showAssignment = hasAnyRole([
    Roles.FacilityManagerAdmin,
    Roles.FacilityManager,
    Roles.ServiceEngineerAdmin,
    Roles.InspectorAdmin,
    Roles.InstallerAdmin,
  ]);

  const { loading, error, data } = useSiteDoorsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      skip: (savedFilter?.pageSize ?? pageSize) * (savedFilter?.page ?? page),
      take: savedFilter?.pageSize ?? pageSize,
      sortDirection,
      sortField,
      siteId,
      active: savedFilter?.active,
      doorsTextFilter: savedFilter?.textFilter,
      statuses: savedFilter?.statuses ?? [],
      withActiveAssignments: showAssignment,
      locationTypes: savedFilter?.locationTypes,
      installationFrom: savedFilter?.installationFrom,
      installationTo: savedFilter?.installationTo,
      inspectionFrom: savedFilter?.inspectionFrom,
      inspectionTo: savedFilter?.inspectionTo,
    },
  });

  const isDoorSelected = (door: Partial<Door> | null | undefined): boolean => {
    return selectedDoors.filter((d) => d?.id === door?.id).length > 0;
  };

  const toggleDoor = (door: Partial<Door> | null | undefined, checked: boolean): void => {
    if (!door) {
      return;
    }

    if (checked) {
      setSelectedDoors([...selectedDoors, door]);
    } else {
      setSelectedDoors(selectedDoors.filter((u) => u?.id !== door?.id));
    }
  };

  const onFilterTabChange = useCallback(
    (newSubTab: SiteDoorsFilterTab) => {
      updateFilter(siteId, {
        statuses: getTabStatuses(newSubTab),
        tab: newSubTab,
        textFilter: undefined, // reset text filter when changing tabs
      });

      setSelectedDoors([]);
    },
    [setSelectedDoors, siteId, updateFilter]
  );

  const toggleAllDoors = (e: any) => {
    setIsSelectAllChecked(e.target.checked);
    setSelectedDoors([]);

    if (e.target.checked) {
      const paginationSelection = Object.assign([], data?.doors.items).splice(0, pageSize);
      setSelectedDoors([...selectedDoors, ...paginationSelection]);
    }
  };

  const onTextFilterChange = useCallback(
    (filter: SiteDoorsSearchBarFilter) => {
      pageChanged(0);
      updateFilter(siteId, { textFilter: filter.textFilter, page: 0 });
    },
    [siteId, updateFilter, pageChanged]
  );

  return (
    <Box py={3}>
      <TableContainer component={Paper}>
        <SiteDoorsFilter
          onFilterChanged={onFilterTabChange}
          onTextFilterChange={onTextFilterChange}
          setAllChecked={setIsSelectAllChecked}
          tab={savedFilter?.tab ?? getDefaultTab()}
          textFilter={savedFilter?.textFilter ?? ''}
        />
        <Divider />
        <Table aria-label="Manufactured doors table">
          <TableHead>
            <TableRow>
              {data?.doors.items.length === 0 ? (
                <TableCell padding="checkbox" />
              ) : (
                <TableCell>
                  <Checkbox
                    color="primary"
                    onClick={(event) => event.stopPropagation()}
                    onChange={(e) => toggleAllDoors(e)}
                    checked={isSelectAllChecked}
                  />
                </TableCell>
              )}
              <TableCell>
                <TableSortLabel active={sortField === 'id'} direction={sortDirection} onClick={() => sort('id')}>
                  DDS Id
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'additionalDoorSearchFields.locationOrDoorRef'}
                  direction={sortDirection}
                  onClick={() => sort('additionalDoorSearchFields.locationOrDoorRef')}
                >
                  Location
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel active={sortField === 'tagId'} direction={sortDirection} onClick={() => sort('tagId')}>
                  Tag ID
                </TableSortLabel>
              </TableCell>
              <TableCell>Installation date</TableCell>
              <ProtectedSection roles={[Roles.InstallerAdmin, Roles.FacilityManagerAdmin, Roles.FacilityManager]}>
                <TableCell>Installed by</TableCell>
              </ProtectedSection>
              <ProtectedSection
                roles={[
                  Roles.FacilityManagerAdmin,
                  Roles.FacilityManager,
                  Roles.ServiceEngineerAdmin,
                  Roles.InspectorAdmin,
                ]}
              >
                <TableCell>Inspection due</TableCell>
                <TableCell>Inspection frequency</TableCell>
              </ProtectedSection>
              <ProtectedSection
                roles={[
                  Roles.FacilityManagerAdmin,
                  Roles.FacilityManager,
                  Roles.ServiceEngineerAdmin,
                  Roles.InspectorAdmin,
                  Roles.InstallerAdmin,
                ]}
              >
                <TableCell>Contractor</TableCell>
                <TableCell>Sub-Contractor</TableCell>
              </ProtectedSection>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'status.name'}
                  direction={sortDirection}
                  onClick={() => sort('status.name')}
                >
                  Stage
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <TableLoading />}
            {!loading && !error && data?.doors.items.length === 0 && <NoResultsRow message="No doors to display" />}
            {!loading && error && <NoResultsRow message="There was an error fetching doors." />}
            {!loading &&
              !error &&
              data?.doors &&
              data?.doors.items.length > 0 &&
              data?.doors.items.map((door, i) => (
                <TableRow
                  hover
                  key={i}
                  onClick={() => {
                    history.push(`${Routes.ViewDoor}/${door.id}`);
                  }}
                  className={classes.tableRow}
                >
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={isDoorSelected(door as Partial<Door>)}
                      onClick={(event) => event.stopPropagation()}
                      onChange={(e, checked) => toggleDoor(door as Partial<Door>, checked)}
                    />
                  </TableCell>
                  <TableCell>
                    {door.id}
                    <ProtectedSection roles={[Roles.FacilityManagerAdmin, Roles.FacilityManager]}>
                      <NewDoorChip transferredAt={door.transferredToFacilityManagementAt} />
                    </ProtectedSection>
                  </TableCell>
                  <TableCell>{door.locationOrDoorRef}</TableCell>
                  <TableCell>{door.tagId}</TableCell>
                  <TableCell>{formatDate(door.latestInstallationDate)}</TableCell>
                  <ProtectedSection roles={[Roles.InstallerAdmin, Roles.FacilityManagerAdmin, Roles.FacilityManager]}>
                    <TableCell>{door.lastestInstallationInstalledBy}</TableCell>
                  </ProtectedSection>
                  <ProtectedSection
                    roles={[
                      Roles.FacilityManagerAdmin,
                      Roles.FacilityManager,
                      Roles.ServiceEngineerAdmin,
                      Roles.InspectorAdmin,
                    ]}
                  >
                    <TableCell>{formatDate(door.nextInspectionDate)}</TableCell>
                    <TableCell>{formatMonths(door.inspectionFrequency)}</TableCell>
                  </ProtectedSection>
                  <ProtectedSection
                    roles={[
                      Roles.FacilityManagerAdmin,
                      Roles.FacilityManager,
                      Roles.ServiceEngineerAdmin,
                      Roles.InspectorAdmin,
                      Roles.InstallerAdmin,
                    ]}
                  >
                    <ContractorTableCell door={door as Door} assignmentType={AssignmentTypes.Contractor} />
                    <ContractorTableCell door={door as Door} assignmentType={AssignmentTypes.SubContractor} />
                  </ProtectedSection>

                  <TableCell>
                    <DoorStage doorStageCode={door.status.code} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data?.doors.total ?? 0}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={(_, newPage) => pageChanged(newPage)}
          onChangeRowsPerPage={pageSizeChanged}
        />
      </TableContainer>
    </Box>
  );
}
