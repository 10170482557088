import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  label: {
    color: '#fff',
    fontWeight: 600,
  },
});

interface DoorFieldProps {
  title: string;
  value: number | string | undefined | null;
  format?: (value: any) => string | undefined;
  fullWidth?: boolean;
}

export default function DoorField({ title, value, format, fullWidth }: DoorFieldProps) {
  const classes = useStyles();
  const defaultFormat = (value: any) => {
    if (value) {
      return String(value);
    }
  };
  const formatValue = format ? format : defaultFormat;

  return (
    <Grid item lg={fullWidth ? 12 : 6} xs={12}>
      <Typography className={classes.label}>{title}</Typography>
      <Typography>{formatValue(value) ?? '-'}</Typography>
    </Grid>
  );
}
