export const Photo: string = 'Photo';
export const Drawing: string = 'Drawing';
export const InstallationGuide: string = 'InstallationGuide';
export const FireCertificate: string = 'FireCertificate';
export const Warranty: string = 'Warranty';
export const TechnicalDrawing: string = 'TechnicalDrawing';
export const SBDCertificate: string = 'SBDCertificate';
export const AcousticCertificate: string = 'AcousticCertificate';
export const InstallationReport: string = 'InstallationReport';
export const InstallationCertificate: string = 'InstallationCertificate';
export const InspectionReport: string = 'InspectionReport';
export const InspectionCertificate: string = 'InspectionCertificate';
export const CSV: string = 'csv';
export const NoAccessPhoto: string = 'NoAccessPhoto';
