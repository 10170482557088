import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DoorContext from '../../../context/DoorContext';
import { DoorFormData } from '../../../pages/DoorAdd';
import { useDoorModelLazyQuery } from '../../../queries';
import DoorDetailsFields from './DoorDetailsFields';

export interface DoorLeaf {
  acousticRating?: string | null;
  coreSupplier?: string | null;
  width?: number | null;
  height?: number | null;
}

interface DoorDetailsFormData {
  projectReference?: string | null;
  doorReference?: string | null;
  fdRatingId?: number | null;
  tagId?: string | null;
  barcode?: string | null;
  lineBarcode?: string | null;
  doorLeafs?: DoorLeaf[];
}

interface DoorDetailsAddProps {
  onContinue: () => void;
  onBack: () => void;
}

export default function DoorDetailsAdd({ onContinue, onBack }: DoorDetailsAddProps) {
  const { door, setDoor, setDoorModel } = useContext(DoorContext);
  const [getDoorModel, { data: doorModel }] = useDoorModelLazyQuery();

  const selectedDoorModelId = door?.doorModelId;
  useEffect(() => {
    if (selectedDoorModelId) {
      getDoorModel({
        variables: {
          id: selectedDoorModelId.toString(),
        },
      });
    }
  }, [selectedDoorModelId, getDoorModel]);

  if (door && !door.doorLeafs) {
    door.doorLeafs = [{}];
  }

  const form = useForm<DoorDetailsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: door ?? {
      doorLeafs: [{}],
    },
  });

  const { getValues, reset, handleSubmit, formState } = form;

  useEffect(() => {
    if (doorModel) {
      const { doorLeafs } = getValues();
      if (doorLeafs) {
        for (let i = 0; i < doorLeafs.length; i++) {
          doorLeafs[i].coreSupplier = doorModel.doorModel.coreSupplier;
        }
      }
      reset({
        ...door,
        fdRatingId: doorModel.doorModel.fdRating?.id,
        doorLeafs: doorLeafs ?? [{ coreSupplier: doorModel.doorModel.coreSupplier }],
      });
    }

    setDoorModel(doorModel);
  }, [doorModel, getValues, reset, setDoorModel, door]);

  const submit = (values: DoorDetailsFormData) => {
    if (values.doorLeafs) {
      for (const leaf of values.doorLeafs) {
        leaf.height = leaf.height && Number(leaf.height);
        leaf.width = leaf.width && Number(leaf.width);
      }
    }
    values.fdRatingId = values.fdRatingId && Number(values.fdRatingId);
    setDoor((prev: DoorFormData | undefined) => ({ ...prev, ...values }));
    onContinue();
  };

  const onBackClick = () => {
    const values = getValues();
    setDoor((prev: DoorFormData | undefined) => ({ ...prev, ...values }));
    onBack();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submit)}>
        <DoorDetailsFields doorModel={doorModel?.doorModel} />
        <Box display="flex" justifyContent="space-between" marginTop={3}>
          <Button variant="outlined" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={!formState.isValid}>
            Continue
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
