import { Grid, FormControl, TextField, InputLabel, Select, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useDebounce from 'use-debounce/lib/useDebounce';
import { CompanyTypes, Company, useCompaniesByNameAndTypeLazyQuery } from '../queries';

interface DoorUserAssignmentAddProps {
  companyTypes: CompanyTypes[];
  errors: any;
  workTypes?: { id: string; name: string }[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DoorUserAssignmentDetails({
  companyTypes,
  errors,
  workTypes,
  setIsLoading,
}: DoorUserAssignmentAddProps) {
  const [companyInputValue, setCompanyInputValue] = useState<string | undefined>(undefined);
  const [debouncedCompanyName] = useDebounce(companyInputValue, 300);

  const { register, control } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const [
    getCompanies,
    { loading: companiesLoading, data: companies, error: companiesError },
  ] = useCompaniesByNameAndTypeLazyQuery();

  useEffect(() => {
    if (companiesError) {
      enqueueSnackbar('There was an error retrieving companies', {
        variant: 'error',
      });
    }
  }, [companiesError, enqueueSnackbar]);

  useEffect(() => {
    if (debouncedCompanyName) {
      getCompanies({
        variables: {
          name: debouncedCompanyName!,
          type: companyTypes,
        },
      });
    }
  }, [debouncedCompanyName, getCompanies, companyTypes]);

  useEffect(() => {
    setIsLoading(companiesLoading);
  }, [companiesLoading, setIsLoading]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <Controller
              control={control}
              name="destinationCompany"
              defaultValue={null}
              rules={{
                required: 'Company is required',
              }}
              render={({ onChange, value }) => (
                <Autocomplete
                  fullWidth
                  autoSelect
                  autoComplete
                  selectOnFocus
                  handleHomeEndKeys
                  freeSolo
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return option.name;
                  }}
                  options={companies?.companiesByNameAndType ?? []}
                  value={value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company"
                      variant="outlined"
                      fullWidth
                      error={!!errors.destinationCompany}
                    />
                  )}
                  onInputChange={(_, newInputValue) => setCompanyInputValue(newInputValue)}
                  onChange={(_, newValue: Pick<Company, 'id' | 'name'> | string | null) => {
                    if (!newValue) {
                      onChange(newValue);
                    }

                    if (typeof newValue === 'string') {
                      const existingOption = companies?.companiesByNameAndType.find(
                        (f) => f.name.trim() === newValue.trim()
                      );
                      if (existingOption) {
                        onChange({ id: existingOption.id, name: existingOption.name });
                      } else {
                        onChange(undefined);
                      }
                    }
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  loading={companiesLoading}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            name="userEmail"
            label="User email"
            variant="outlined"
            fullWidth
            error={!!errors.userEmail}
            helperText={errors?.userEmail?.message}
            inputRef={register({
              required: 'User email is required',
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid email address',
              },
            })}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="workTypeId" error={!!errors.workType} id="workTypeLabel">
              Work type
            </InputLabel>

            <Controller
              control={control}
              name="workType"
              defaultValue={''}
              rules={{
                required: 'Work type is required',
              }}
              render={({ onChange, value }) => (
                <Select
                  label="Work type"
                  labelId="workTypeLabel"
                  inputRef={register}
                  inputProps={{
                    name: 'workType',
                    id: `workTypeId`,
                  }}
                  onChange={onChange}
                  value={value}
                >
                  {workTypes &&
                    workTypes.length > 0 &&
                    workTypes.map((workType: any) => (
                      <MenuItem key={workType.id} value={workType.id}>
                        {workType.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
