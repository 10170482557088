import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MaterialLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import * as Routes from '../constants/Routes';

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: '9999 !important' as any, // material ui uses some high z-index values
  },
  button: {
    margin: theme.spacing(2),
  },
}));

const AcceptButton = (props: any) => {
  const classes = useStyles();
  return <Button {...props} className={classes.button} variant="contained" color="primary" />;
};

export default function CookiePopup() {
  const classes = useStyles();

  return (
    <CookieConsent
      containerClasses={classes.container}
      buttonText="Accept"
      ButtonComponent={AcceptButton}
      disableButtonStyles
      sameSite="lax"
    >
      We only use cookies for authentication, so we can log you in. If you want to use the application you will need to
      accept the use of those cookies. To find out more, read our{' '}
      <MaterialLink component={Link} to={Routes.PrivacyPolicy}>
        privacy policy
      </MaterialLink>
      .
    </CookieConsent>
  );
}
