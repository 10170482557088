import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    transition: 'background-color 0.5s ease-in-out',
    transitionDelay: '0.5s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hide: {
    pointerEvents: 'none',
    backgroundColor: 'rgba(255,255,255,0)',
  },
  show: {
    backgroundColor: 'rgba(255,255,255,0.13)',
  },

  loading: { opacity: 0, transition: 'opacity 0.5s ease-in-out', transitionDelay: '0.5s' },
  loadingShow: { opacity: 1 },
  loadingHide: { opacity: 0 },
}));

type Props = {
  show: boolean;
};
const LoadingOverlay = ({ show }: Props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, show ? classes.show : classes.hide)}>
      <CircularProgress className={clsx(classes.loading, show ? classes.loadingShow : classes.loadingHide)} />
    </Box>
  );
};

export default LoadingOverlay;
