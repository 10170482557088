import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { DoorModelPartsFragment } from '../../queries';

const useStyles = makeStyles({
  label: {
    color: '#fff',
    fontWeight: 600,
  },
});

interface DoorModelPartsListProps {
  doorModelParts?: DoorModelPartsFragment | null;
}

export default function DoorModelPartsList({ doorModelParts }: DoorModelPartsListProps) {
  const classes = useStyles();

  return (
    <Box p={5}>
      <Grid container spacing={3}>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Lock / latch</Typography>
          <Typography>{doorModelParts?.lockLatch ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Door closer</Typography>
          <Typography>{doorModelParts?.closer ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Intumescent strip</Typography>
          <Typography>{doorModelParts?.intumescentStrip ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Hinges</Typography>
          <Typography>{doorModelParts?.hinges ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Handles</Typography>
          <Typography>{doorModelParts?.handles ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Smoke seals</Typography>
          <Typography>{doorModelParts?.smokeSeals ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Drop seal</Typography>
          <Typography>{doorModelParts?.dropSeal ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Cylinder</Typography>
          <Typography>{doorModelParts?.cylinder ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Letterbox</Typography>
          <Typography>{doorModelParts?.letterbox ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Spyhole</Typography>
          <Typography>{doorModelParts?.spyhole ?? '-'}</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography className={classes.label}>Threshold strip</Typography>
          <Typography>{doorModelParts?.thresholdStrip ?? '-'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label}>Additional comments</Typography>
          <Typography>{doorModelParts?.additionalComments ?? '-'}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
