import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import Layout from '../components/Layout';
import { Company, useCompaniesByNameAndTypeLazyQuery, useTransferDoorsMutation, CompanyTypes } from '../queries';
import * as Routes from '../constants/Routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useDialog from '../hooks/useDialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import LoadingButton from '../components/LoadingButton';
import MaterialLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import useTransferDoorsStore from '../store/TransferDoorsStore';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useDebounce from 'use-debounce/lib/useDebounce';
import TransferTypes from '../constants/TransferTypes';
import { GraphQLError } from 'graphql';
import { Hidden } from '@material-ui/core';

interface TransferDoorsFormData {
  destinationCompany: Pick<Company, 'id' | 'name'> | null;
  t1ContractorCompany: Pick<Company, 'id' | 'name'> | null;
  userEmail: string;
  t1UserEmail: string | null;
  orderRef: string;
}

export default function TrransferDoors() {
  const { showDialog } = useDialog();
  const history = useHistory();
  const { doorIds, setDoorIds, transferType, company, setCompany } = useTransferDoorsStore();

  const { enqueueSnackbar } = useSnackbar();
  const [companyInputValue, setCompanyInputValue] = useState<string | undefined>(undefined);
  const [debouncedCompanyName] = useDebounce(companyInputValue, 300);

  const [t1ContractorInputValue, setT1ContractorInputValue] = useState<string | undefined>(undefined);
  const [debouncedT1ContractorName] = useDebounce(t1ContractorInputValue, 300);

  const [transferDoorsMutation] = useTransferDoorsMutation();
  const [
    getCompanies,
    { loading: companiesLoading, data: companies, error: companiesError },
  ] = useCompaniesByNameAndTypeLazyQuery();

  const [
    getT1Contractors,
    { loading: t1ContractorsLoading, data: t1Contractors, error: t1ContractorsError },
  ] = useCompaniesByNameAndTypeLazyQuery();

  const form = useForm<TransferDoorsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, formState, control, register, errors, reset, watch } = form;

  const t1UserEmailRequired = Boolean(watch('t1ContractorCompany'));
  const isCompanyEditable = useMemo(() => {
    return transferType && transferType !== TransferTypes.InstallerToFacilityManagement;
  }, [transferType]);

  useEffect(() => {
    if (!transferType) {
      enqueueSnackbar('Not enough context provided for door transfer, please try again', { variant: 'warning' });
      history.goBack();
    }
  }, [transferType, enqueueSnackbar, history]);

  useEffect(() => {
    if (company) {
      reset({ destinationCompany: company });
    }
  }, [company, reset]);

  useEffect(() => {
    if (companiesError) {
      enqueueSnackbar('There was an error retrieving installer companies', {
        variant: 'error',
      });
    }
    if (t1ContractorsError) {
      enqueueSnackbar('There was an error retrieving T1 contractors', {
        variant: 'error',
      });
    }
  }, [companiesError, enqueueSnackbar, t1ContractorsError]);

  useEffect(() => {
    if (debouncedCompanyName) {
      getCompanies({
        variables: {
          name: debouncedCompanyName!,
          type: [
            transferType === TransferTypes.ManufacturerToInstaller
              ? CompanyTypes.Installer
              : CompanyTypes.FacilityManagement,
          ],
          isT1Contractor: null,
        },
      });
    }
  }, [debouncedCompanyName, getCompanies, transferType]);

  useEffect(() => {
    if (debouncedT1ContractorName) {
      getT1Contractors({
        variables: {
          name: debouncedT1ContractorName!,
          type: [CompanyTypes.Installer],
          isT1Contractor: true,
        },
      });
    }
  }, [debouncedT1ContractorName, getT1Contractors]);

  const getBreadcrumb = () => {
    const linkTo =
      transferType === TransferTypes.InstallerToFacilityManagement ? Routes.Sites : Routes.ManufacturedDoors;
    const text = transferType === TransferTypes.InstallerToFacilityManagement ? 'Sites' : 'Manufactured doors';
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <MaterialLink color="inherit" component={Link} to={linkTo}>
          <Typography variant="body2">{text}</Typography>
        </MaterialLink>
        <Typography variant="body2">Transfer doors</Typography>
      </Breadcrumbs>
    );
  };

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        setDoorIds([]);
        setCompany(undefined);
        history.goBack();
      },
    });
  };

  const saveChanges = async (values: TransferDoorsFormData) => {
    try {
      await transferDoorsMutation({
        variables: {
          destinationCompanyId: values.destinationCompany?.id,
          destinationCompanyName: values.destinationCompany?.name,
          destinationUserEmail: values.userEmail,
          ids: doorIds.map((did) => Number(did)),
          orderRef: values.orderRef,
          transferType: transferType!,
          tagCompanyId: values.t1ContractorCompany?.id,
          tagUserEmail: values.t1UserEmail,
        },
      });

      enqueueSnackbar('Doors transferred successfully.', { variant: 'success' });
      history.goBack();
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        enqueueSnackbar(gqlerror.message, { variant: 'error' });
      });
    }
  };

  const getTransferDestinationAccountType = (): string => {
    switch (transferType) {
      case TransferTypes.ManufacturerToInstaller:
        return 'installer';
    }

    return '';
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5">Transfer doors</Typography>
              {getBreadcrumb()}
            </Box>
            <Box minWidth={96}>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>
          <Box mt={1}>
            <Paper>
              <Box p={5} marginTop={3}>
                <Box marginBottom={3}>
                  <Typography>
                    Please enter the details of the Door Data Systems {getTransferDestinationAccountType()} account you
                    wish to transfer doors to
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit(saveChanges)}>
                  <Grid container spacing={3}>
                    <Grid item lg={6} xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <Controller
                          control={control}
                          name="destinationCompany"
                          defaultValue={null}
                          rules={{
                            required: 'Company is required',
                          }}
                          render={({ onChange, value }) => (
                            <Autocomplete
                              fullWidth
                              autoSelect
                              autoComplete
                              selectOnFocus
                              handleHomeEndKeys
                              freeSolo
                              disabled={!isCompanyEditable}
                              value={value}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  return option;
                                }
                                return option.name;
                              }}
                              options={companies?.companiesByNameAndType ?? []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Company"
                                  variant="outlined"
                                  fullWidth
                                  error={!!errors.destinationCompany}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // prevents browser autofill showing up
                                  }}
                                />
                              )}
                              onInputChange={(_, newInputValue) => setCompanyInputValue(newInputValue)}
                              onChange={(_, newValue: Pick<Company, 'id' | 'name'> | string | null) => {
                                if (!newValue) {
                                  onChange(newValue);
                                }

                                if (newValue && typeof newValue === 'object') {
                                  newValue = newValue?.name;
                                }

                                if (typeof newValue === 'string') {
                                  const existingOption = companies?.companiesByNameAndType.find(
                                    (f) => f.name.trim() === (newValue as string).trim()
                                  );
                                  if (existingOption) {
                                    onChange({ id: existingOption.id, name: existingOption.name });
                                  } else {
                                    onChange({ id: undefined, name: newValue });
                                  }
                                }
                              }}
                              getOptionSelected={(option, value) => option.id === value.id}
                              loading={companiesLoading}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        name="userEmail"
                        label="User email"
                        variant="outlined"
                        fullWidth
                        error={!!errors.userEmail}
                        helperText={errors?.userEmail?.message}
                        inputRef={register({
                          required: 'User email is required',
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Invalid email address',
                          },
                        })}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        name="orderRef"
                        label="Order reference number"
                        variant="outlined"
                        fullWidth
                        error={!!errors.orderRef}
                        helperText={errors?.orderRef?.message}
                        inputRef={register({
                          required: 'Order reference is required',
                        })}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    {transferType === TransferTypes.InstallerToFacilityManagement && (
                      <>
                        <Hidden mdDown>
                          <Grid item lg={6} xs={12}></Grid>
                        </Hidden>
                        <Grid item lg={6} xs={12}>
                          <FormControl variant="outlined" fullWidth>
                            <Controller
                              control={control}
                              name="t1ContractorCompany"
                              defaultValue={null}
                              render={({ onChange, value }) => (
                                <Autocomplete
                                  fullWidth
                                  autoSelect
                                  autoComplete
                                  selectOnFocus
                                  handleHomeEndKeys
                                  freeSolo
                                  getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    return option.name;
                                  }}
                                  options={t1Contractors?.companiesByNameAndType ?? []}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="T1 Contractor"
                                      variant="outlined"
                                      fullWidth
                                      error={!!errors.t1ContractorCompany}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // prevents browser autofill showing up
                                      }}
                                    />
                                  )}
                                  onInputChange={(_, newInputValue) => setT1ContractorInputValue(newInputValue)}
                                  onChange={(_, newValue: Pick<Company, 'id' | 'name'> | string | null) => {
                                    if (!newValue) {
                                      onChange(newValue);
                                    }

                                    if (newValue && typeof newValue === 'object') {
                                      newValue = newValue?.name;
                                    }

                                    if (typeof newValue === 'string') {
                                      const existingOption = t1Contractors?.companiesByNameAndType.find(
                                        (f) => f.name.trim() === (newValue as string).trim()
                                      );
                                      if (existingOption) {
                                        onChange({ id: existingOption.id, name: existingOption.name });
                                      } else {
                                        onChange(undefined);
                                      }
                                    }
                                  }}
                                  getOptionSelected={(option, value) => option.id === value.id}
                                  loading={t1ContractorsLoading}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <TextField
                            name="t1UserEmail"
                            label="T1 Contractor User email"
                            variant="outlined"
                            fullWidth
                            error={!!errors.t1UserEmail}
                            helperText={errors?.t1UserEmail?.message}
                            inputRef={register({
                              required: { value: t1UserEmailRequired, message: 'T1 Contractor user email is required' },
                              pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Invalid email address',
                              },
                            })}
                            inputProps={{ maxLength: 255 }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Box display="flex" justifyContent="flex-end" marginTop={3}>
                    <LoadingButton
                      loading={companiesLoading}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!formState.isValid || companiesLoading || t1ContractorsLoading}
                    >
                      Transfer doors
                    </LoadingButton>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
